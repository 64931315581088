import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"

function ucfirst(str) {
    var firstLetter = str.substr(0, 1)
    return firstLetter.toUpperCase() + str.substr(1)
}

const PageNumLi = ({ control, name, onSelectChange }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [countriesData, setCountriesData] = useState([])

    const loadOptions = async () => {
        const data = [
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
        ]
        setIsLoading(false)
        const countries = data.map(dta => {
            return {
                label: dta?.label && ucfirst(dta.label),
                value: dta.id,
                ...dta,
            }
        })

        setCountriesData(countries)
    }

    useEffect(() => {
        loadOptions()
    }, [])

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                    return (
                        <Select
                            options={countriesData}
                            placeholder={"View"}
                            isLoading={isLoading}
                            inputRef={ref}
                            onChange={e => {
                                onChange(e)
                                if (onSelectChange) onSelectChange(e)
                            }}
                            value={value}
                            classNamePrefix="react-select"
                            className="elevate-dropdown"
                            menuPortalTarget={document.querySelector("body")}
                        />
                    )
                }}
            />
        </div>
    )
}

export default PageNumLi
