import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import EditCustomer from "./edit"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"
import CustomerFilter from "./filter"
import {
  searchCustomer,
  updateCustomerStatus,
} from "../../../services/projectManager/customer"
import { searchProjectMaster } from "../../../services/projectManager/projectMaster"
import { useNavigate } from "react-router-dom"
import { capitalize } from "lodash"

const ProjectMaster = () => {
  document.title = "Project Master | Ever Rise"
  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customerType, setCustomerType] = useState("")
  const [page, setPage] = useState("")
  const [projectCode, setProjectCode] = useState("")
  const [customerCode, setCustomerCode] = useState("")
  const [locationId, setLocationId] = useState("")

  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }
  const handleView = item => {
    setViewData(item)
    toggle()
  }
  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateCustomerStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Project Code",
      renderCell: item => item?.project_code,
    },
    {
      label: "Project Name",
      renderCell: item => {
        return (
          <div>{item?.project_name ? capitalize(item?.project_name) : "-"}</div>
        )
      },
      sortKey: "project_name",
    },
    {
      label: "Project Location",
      renderCell: item =>
        item?.location?.name ? capitalize(item?.location?.name) : "-",
      sortKey: "name",
    },
    {
      label: "Customer Code",
      renderCell: item => item?.customers?.customer_code,
    },
    {
      label: "Customer Name",
      renderCell: item =>
        item?.customers?.customer_name
          ? capitalize(item?.customers?.customer_name)
          : "-",
      sortKey: "customer_name",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => navigate(`/project-master/edit/${item?.id}`)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchProjectMaster({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${
          customerType == "0" || customerType == "1"
            ? `&customer_type=${customerType}`
            : ""
        }${page ? `&page=${page}` : ""}${
          projectCode ? `&project_code=${projectCode}` : ""
        }${customerCode ? `&cus_code=${customerCode}` : ""}${
          locationId ? `&project_location=${locationId}` : ""
        }`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [
    refresh,
    status,
    search,
    customerType,
    page,
    projectCode,
    locationId,
    customerCode,
  ])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }

  const handleCatChange = id => {
    setCustomerType(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  const handleProjectCode = data => {
    setProjectCode(data)
    setPage(1)
  }
  const handleLocationId = data => {
    setLocationId(data)
    setPage(1)
  }
  const handleCustomerCode = data => {
    setCustomerCode(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                { title: "Project Master", link: "/project-master" },
              ]}
            />
            {/* <EditCustomer
              key={"x"}
              onUpdate={handleUpdate}
              onReset={handleReset}
              editValue={editClass}
              buttonLabel="+&nbsp; Add Customer"
              className="px-2 mb-3 btn btn-primary"
            /> */}
          </div>
          <Card className="me-2 px-2 pb-2">
            <CustomerFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCatChange={handleCatChange}
              handleProjectCode={handleProjectCode}
              handleLocationId={handleLocationId}
              handleCustomerCode={handleCustomerCode}
              sampleUrl={"download/customer.xlsx"}
              importUrl={"customer/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="10% 20% 15% 15% 20% 10% 10%"
                    mobileWidth="20% 40% 30% 30% 40% 20% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg" unmountOnClose={false}>
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-4">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Type
              </Col>
              <Col className="primary-text">
                {viewData?.customer_type?.label}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Code
              </Col>
              <Col className="primary-text">{viewData?.customer_code}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Name
              </Col>
              <Col className="primary-text">{viewData?.customer_name}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Person
              </Col>
              <Col className="primary-text">{viewData?.contact_person}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Number
              </Col>
              <Col className="primary-text">{viewData?.customer_number}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Designation
              </Col>
              <Col className="primary-text">
                {viewData?.contact_person_designation}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Email ID
              </Col>
              <Col className="primary-text">{viewData?.email_id}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Fax Number
              </Col>
              <Col className="primary-text">{viewData?.fax_number}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Address
              </Col>
              <Col className="primary-text">{viewData?.address}</Col>
            </Row>
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default ProjectMaster
