import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody, Row } from "reactstrap"
import CountUp from "react-countup"
import ReactApexChart from "react-apexcharts"
import dollarIcon from "../../assets/icons/dollarIcon.svg"
import TrendUp from "../../assets/icons/TrendUp.svg"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const MiniWidget = props => {
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  console.log(`loggedUser`, loggedUser)
  console.log(`props?.fetchData?.accBalance`, props);
  

  return (
    <React.Fragment>
      {/* {loggedUser?.data?.roles[0]?.name == "Superadmin" ? (
        <>
          <Col sm={12} lg={8}>
            <Row>
              <Col sm={4}>
                <div className="dashboard-main-card p-3">
                  <div className="d-flex justify-content-between pb-4">
                    <div className="card-icon-bg">
                      <img src={dollarIcon} alt="icon" />
                    </div>
                    <div>
                      <div className="status-dash-card">
                        <img src={TrendUp} alt="trend up" />
                        {props?.fetchData?.percentageChange_projects >= 0
                          ? "+"
                          : ""}
                        {props?.fetchData?.percentageChange_projects}%
                      </div>
                    </div>
                  </div>
                  <div className="dsah-project-detail py-2">Total Projects</div>
                  <div className="card-bold-text pt-2">
                    {props?.fetchData?.total_projects
                      ? props?.fetchData?.total_projects
                      : 0}
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="dashboard-main-card p-3">
                  <div className="d-flex justify-content-between pb-4">
                    <div className="card-icon-bg">
                      <img src={dollarIcon} alt="icon" />
                    </div>
                    <div>
                      <div className="status-dash-card">
                        <img src={TrendUp} alt="trend up" />
                        {props?.fetchData?.total_employee >= 0 ? "+" : ""}
                        {props?.fetchData?.percentageChange_employee}%
                      </div>
                    </div>
                  </div>
                  <div className="dsah-project-detail py-2">
                    Total Employees
                  </div>
                  <div className="card-bold-text pt-2">
                    {props?.fetchData?.total_employee
                      ? props?.fetchData?.total_employee
                      : 0}
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="dashboard-main-card p-3">
                  <div className="d-flex justify-content-between pb-4">
                    <div className="card-icon-bg">
                      <img src={dollarIcon} alt="icon" />
                    </div>
                    <div>
                      <div className="status-dash-card">
                        <img src={TrendUp} alt="trend up" />
                        {props?.fetchData?.percentageChange_cus >= 0 ? "+" : ""}
                        {props?.fetchData?.percentageChange_cus
                          ? props?.fetchData?.percentageChange_cus
                          : 0}
                        %
                      </div>
                    </div>
                  </div>
                  <div className="dsah-project-detail py-2">Total Clients</div>
                  <div className="card-bold-text pt-2">
                    {props?.fetchData?.total_cus
                      ? props?.fetchData?.total_cus
                      : 0}
                  </div>
                </div>
              </Col>
              <Col sm={12}>
                <div className="dashboard-main-card p-3 mt-4">
                  <ReactApexChart
                    type="bar"
                    series={props?.accBalance?.series || []}
                    options={props?.accBalance || {}}
                    // height={250}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={4}>
            <div className="dashboard-main-card p-3">Chart</div>
          </Col>
        </>
      ) : loggedUser?.data?.roles[0]?.name == "Finance Manager" ? (
        <>
          <Col sm={12}>
            <Row>
              <Col sm={6} lg={3}>
                <div className="dashboard-main-card p-3 card-left-border">
                  <div className="dash-card-title-light">Balance</div>
                  <div className="dash-card-title-dark">$128,320</div>
                </div>
              </Col>
              <Col sm={6} lg={3}>
                <div className="dashboard-main-card p-3 card-left-border">
                  <div className="dash-card-title-light">Total Spent</div>
                  <div className="dash-card-title-dark">$128,320</div>
                </div>
              </Col>
              <Col sm={6} lg={3}>
                <div className="dashboard-main-card p-3 card-left-border">
                  <div className="dash-card-title-light">Total Recieved</div>
                  <div className="dash-card-title-dark">$128,320</div>
                </div>
              </Col>
              <Col sm={6} lg={3}>
                <div className="dashboard-main-card p-3 card-left-border">
                  <div className="dash-card-title-light">Total Projects</div>
                  <div className="dash-card-title-dark">128</div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={7}>

          </Col>
        </>
      ) : (
        <></>
      )} */}
      {props.reports.map((report, key) => (
        <Col md={6} xl={3} key={key}>
          <Card className="dashboard-card">
            <CardBody>
              <div className="float-end mt-2">
                <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type={report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                />
              </div>
              <div>
                <h4 className="mb-1 mt-1">
                  <span>
                    <CountUp
                      end={report.value}
                      separator=","
                      prefix={report.prefix}
                      suffix={report.suffix}
                      decimals={report.decimal}
                    />
                  </span>
                </h4>
                <p className="text-muted mb-0">{report.title}</p>
              </div>
              <p className="text-muted mt-3 mb-0">
                <span className={"text-" + report.color + " me-1"}>
                  <i className={report.icon + " me-1"}></i>
                  {report.badgeValue}
                </span>{" "}
                {report.desc}
              </p>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array,
}
