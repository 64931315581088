import React, { useState, useEffect } from "react"
import { UFInput } from "../../../components/Common/Fields/Input"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import RequestType from "../../../components/Common/chooseFields/requestType"
import { Col, Form, Row } from "reactstrap"
import resetIcon from "../../../assets/icons/reset.svg"
import { useForm } from "react-hook-form"
import ProductList from "../../../components/Common/chooseFields/productList"

const TransferFilter = ({
  handleProjectChange,
  handleTypeChange,
  handleSearchChange,
  handleProductChange,
}) => {
  const { control, watch, reset } = useForm()

  const handleProjectNum = data => {
    handleProjectChange(data?.value)
  }
  const handleType = data => {
    handleTypeChange(data?.label)
  }
  const handleProducttNum = data => {
    handleProductChange(data?.value)
  }
  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])
  const handleReset = () => {
    handleProjectChange()
    handleTypeChange()
    handleProductChange()
    reset({
      project_id: null,
      type: null,
      product_id: null,
      search: "",
    })
  }
  return (
    <div className="d-flex justify-content-between align-items-center my-2 me-2">
      <Form id="user-form" className="filter-wrapper">
        <Row className="mx-0">
          <Col sm={3} className="pe-1">
            <ProjectNo
              control={control}
              name={"project_id"}
              placeholder={"Project No"}
              target={"body"}
              onSelectChange={e => handleProjectNum(e)}
            />
          </Col>
          <Col sm={3} className="pe-1">
            <ProductList
              control={control}
              name={"product_id"}
              placeholder={"Product No"}
              target={"body"}
              onSelectChange={e => handleProducttNum(e)}
            />
          </Col>
          <Col sm={3} className="pe-1">
            <RequestType
              control={control}
              name={"type"}
              onSelectChange={e => handleType(e)}
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex">
        <div className="export-div pointer me-2 reset-img" onClick={() => handleReset()}>
          <img src={resetIcon} height={15} width={15} className="me-2" /> Reset
        </div>
        <UFInput
          control={control}
          placeholder="Search"
          name={"search"}
          className={"search-input"}
        />
      </div>
    </div>
  )
}

export default TransferFilter
