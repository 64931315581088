import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, Col, Container, Row } from "reactstrap"
import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"
import moment from "moment"
import { downloadgrn, fetchGRN } from "../../../services/procurement/grn"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { capitalize } from "lodash"
import {
  fetchFinanceVoucherSingle,
  fetchSingleInvoice,
} from "../../../services/finance/finance"

const VoucherEntryView = ({ fromDashboard, viewValue }) => {
  const { id } = useParams()

  const viewId = fromDashboard ? viewValue?.value?.id : id

  const [invoiceDetails, setInvoiceDetails] = useState()
  const [tabledata, setTabledata] = useState()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchInvoiceData = async () => {
      const response = await fetchFinanceVoucherSingle(viewId)
      setInvoiceDetails(response?.data)
      setTabledata(response?.data?.purchase_order?.purchaseorder_lists)
    }
    if (viewId !== undefined) fetchInvoiceData()
  }, [])

  const result = tabledata?.reduce(
    (acc, item) => {
      const received = item.received_quantity || 0
      return {
        totalreceived: Number(acc.totalreceived) + Number(received),
      }
    },
    { totalreceived: 0 }
  )

  const { totalreceived } = result || {}
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Finance"
                breadcrumbObject={[
                  { title: "Finance", link: "/finance" },
                  { title: "Voucher Entry", link: "/finance-voucher" },
                  {
                    title: invoiceDetails?.voucher_type?.voucher_type
                      ? `${invoiceDetails?.voucher_type?.voucher_type} Voucher View`
                      : "Voucher View",
                    link: `/finance-voucher/view/${viewId}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className="p-3">
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h4 className="main-heading">
                        {invoiceDetails?.voucher_type?.voucher_type
                          ? `${invoiceDetails?.voucher_type?.voucher_type} Voucher`
                          : "Voucher View"}
                      </h4>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={11}>
                    <Row>
                      {invoiceDetails?.voucher_type?.voucher_type && (
                        <Col lg={2} md={4} sm={6}>
                          <div className="supplier-td-head">Voucher Type</div>
                          <div className="supplier-td-body">
                            {invoiceDetails
                              ? invoiceDetails?.voucher_type?.voucher_type
                              : "-"}
                          </div>
                        </Col>
                      )}
                      {invoiceDetails?.credit?.name && (
                        <Col lg={2} md={4} sm={6}>
                          <div className="supplier-td-head">Party Name</div>
                          <div className="supplier-td-body">
                            {invoiceDetails
                              ? invoiceDetails?.credit?.name
                              : "-"}
                          </div>
                        </Col>
                      )}
                      {invoiceDetails?.debit?.name && (
                        <Col lg={2} md={4} sm={6}>
                          <div className="supplier-td-head">
                            Purchase Ledger
                          </div>
                          <div className="supplier-td-body">
                            {invoiceDetails ? invoiceDetails?.debit?.name : "-"}
                          </div>
                        </Col>
                      )}
                      {invoiceDetails?.date && (
                        <Col lg={2} md={4} sm={6}>
                          <div className="supplier-td-head">Date</div>
                          <div className="supplier-td-body">
                            {invoiceDetails
                              ? moment(invoiceDetails?.date).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={12}>
                    <h5 className="productDetail">
                      {invoiceDetails?.voucher_id == 1 ||
                      invoiceDetails?.voucher_id == 3 ||
                      invoiceDetails?.voucher_id == 6 ||
                      invoiceDetails?.voucher_id == 8
                        ? "Payment Details"
                        : "Material Details"}
                    </h5>
                  </Col>
                  <Col lg={12}>
                    {invoiceDetails?.voucher_id == 1 ||
                    invoiceDetails?.voucher_id == 3 ||
                    invoiceDetails?.voucher_id == 6 ||
                    invoiceDetails?.voucher_id == 8 ? (
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Account</th>
                            <th>Debit (AED)</th>
                            <th>Credit (AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetails?.journal_entry?.journal_entry_details?.map(
                            (data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="ps-1">{index + 1}</td>
                                  <td className="ps-1">
                                    {data?.ledger?.name
                                      ? data?.ledger?.name
                                      : "-"}
                                  </td>
                                  <td className="ps-1">
                                    {data?.debit_amount
                                      ? data?.debit_amount
                                      : 0}
                                  </td>
                                  <td className="ps-1">
                                    {data?.credit_amount
                                      ? data?.credit_amount
                                      : 0}
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    ) : invoiceDetails?.voucher_id == 7 ? (
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Ordered Quantity</th>
                            <th>Recieved Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetails?.recipt_note?.materials?.map(
                            (data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="ps-1">{index + 1}</td>
                                  <td className="ps-1">
                                    {data?.product?.product_code}
                                  </td>
                                  <td className="ps-1">
                                    {data?.product?.description}{" "}
                                  </td>
                                  <td className="ps-1">
                                    {data?.product?.unit?.unit_code}{" "}
                                  </td>
                                  <td className="ps-1">{data.po_quantity}</td>
                                  <td className="ps-1">{data.grn_quantity}</td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    ) : invoiceDetails?.voucher_id == 15 ? (
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Unit Price (AED)</th>
                            <th>Sub Total (AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetails?.details?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td className="ps-1">{index + 1}</td>
                                <td className="ps-1">
                                  {data?.product?.product_code}
                                </td>
                                <td className="ps-1">
                                  {data?.product?.description}{" "}
                                </td>
                                <td className="ps-1">
                                  {data?.product?.unit?.unit_code}{" "}
                                </td>
                                <td className="ps-1">{data.qnt}</td>
                                <td className="ps-1">
                                  {Number(
                                    Number(data.unit_price).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td className="ps-1">
                                  {Number(
                                    Number(
                                      Number(data.qnt) * Number(data.unit_price)
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    ) : invoiceDetails?.voucher_id == 16 ? (
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Unit Price (AED)</th>
                            <th>Sub Total (AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetails?.purchaseorder?.materials?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td className="ps-1">{index + 1}</td>
                                <td className="ps-1">
                                  {data?.product?.product_code}
                                </td>
                                <td className="ps-1">
                                  {data?.product?.description}{" "}
                                </td>
                                <td className="ps-1">
                                  {data?.product?.unit?.unit_code}{" "}
                                </td>
                                <td className="ps-1">{data.po_quantity}</td>
                                <td className="ps-1">
                                  {Number(
                                    Number(data.unit_price).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td className="ps-1">
                                  {Number(
                                    Number(
                                      Number(data.po_quantity) * Number(data.unit_price)
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default VoucherEntryView
