import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import {
  handleStatus,
  requestedList,
  searchCentralStoreProductList,
} from "../../../services/centralStore/centralStore"
import { toastSuccess } from "../../../helpers/msg_helper"
import moment from "moment"
import RequestFilter from "./filter"
import { capitalize } from "lodash"

const RequestedList = () => {
  document.title = "Requested List | Everise"

  const [page, setPage] = useState("")
  const [productList, setProductList] = useState("")
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [type, setType] = useState("")
  const [user, setUser] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestedList({
        params: `${
          status == "0" || status == "1" || status == "2"
            ? `&status=${status}`
            : ""
        }${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${type ? `&type=${type}` : ""}${user ? `&user=${user}` : ""}${
          datefrom ? `&from_date=${datefrom}` : ""
        }${dateto ? `&to_date=${dateto}` : ""}`,
      })
      setProductList(response)
    }
    fetchData()
  }, [count, status, search, page, type, user, datefrom, dateto])

  const columns = [
    {
      label: "Requested From",
      renderCell: item => (
        <div className="capitalize-letter">{item?.requested_from?.name}</div>
      ),
    },
    {
      label: "Remarks",
      renderCell: item => (
        <div>{item?.description ? capitalize(item?.description) : "-"}</div>
      ),
    },
    {
      label: "Type",
      renderCell: item => <div className="capitalize-letter">{item?.type}</div>,
    },
    {
      label: "Requested Date",
      renderCell: item => moment(item?.requested_date).format("DD-MM-YYYY"),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status == 0
                ? "status-pending"
                : item?.status == 1
                ? "status-active"
                : "status-inactive"
            }`}
          >
            {item?.status == 0
              ? "New"
              : item?.status == 1
              ? "Issued"
              : "Rejected"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status == 0 && (
                <>
                  <div
                    className="table-popup-content"
                    onClick={() => handleAction(item?.id, 1)}
                  >
                    Issue
                  </div>
                  <div
                    className="table-popup-content"
                    onClick={() => handleAction(item?.id, 2)}
                  >
                    Reject
                  </div>
                </>
              )}
              {/* <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div> */}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handleAction = async (id, status) => {
    const responce = await handleStatus(id, status)
    toastSuccess(responce?.message)
    setCount(count + 1)
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleUserChange = id => {
    setUser(id)
    setPage(1)
  }

  const handleTypeChange = id => {
    setType(id)
    setPage(1)
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Procurement"
              breadcrumbObject={[
                { title: "Procurement", link: "/procurement" },
                {
                  title: "Requested List",
                  link: "/procurement/requested-list",
                },
              ]}
            />
          </div>

          <Card className="me-2 px-2 pb-2">
            <RequestFilter
              handleTypeChange={handleTypeChange}
              handleSearchChange={handleSearchChange}
              handleUserChange={handleUserChange}
              handleDateChange={handleDateChange}
              handleStatusChange={handleStatusChange}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {productList?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={productList?.data?.data}
                    paginate={productList?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 10% 20% 20% 10%"
                    mobileWidth="40% 40% 30% 40% 40% 30%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}
export default RequestedList
