import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import UpdateTable from "../../../components/Common/UpdatedTable"
import { fetchFinanceJournalEntry } from "../../../services/finance/finance"
import moment from "moment"
import JournalEntryFilter from "./filter"

const JournalEntry = ({ fromDashboard }) => {
  document.title = "Journal Entry | Ever Rise"
  const [search, setSearch] = useState("")
  const [page, setPage] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [dateFrom, setDatefrom] = useState("")
  const [dateTo, setDateto] = useState("")
  const [acc, setAcc] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceJournalEntry({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${dateFrom ? `&start_date=${dateFrom}` : ""}${
          dateTo ? `&end_date=${dateTo}` : ""
        }${acc ? `&account_id=${acc}` : ""}${`&sizePerPage=16`}`,
      })
      setFetchedData(responce)
    }
    fetchData()
  }, [page, search, dateFrom, dateTo, acc])

  const columns = [
    {
      label: "Account",
      renderCell: item => (
        <div className="text-capitalize">
          {item?.accounts?.name}({item?.accounts?.code})
        </div>
      ),
    },
    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Credit(AED)",
      renderCell: item => (item?.credit_amount ? item?.credit_amount : 0),
    },
    {
      label: "Debit(AED)",
      renderCell: item => (item?.debit_amount ? item?.debit_amount : 0),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  const handleAccChange = data => {
    setAcc(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div>
            {!fromDashboard ? (
              <>
                <Breadcrumbs
                  title="Finance"
                  breadcrumbObject={[
                    { title: "Finance", link: "/finance" },
                    { title: "Journal Entry", link: "/finance-journal-entry" },
                  ]}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            {!fromDashboard ? (
              <>
                <JournalEntryFilter
                  handleSearchChange={handleSearchChange}
                  handleDateChange={handleDateChange}
                  handleAccChange={handleAccChange}
                  isImport={false}
                />
                <hr className="table-divide" />
              </>
            ) : (
              <div></div>
            )}

            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {fetchedData?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={fetchedData?.data?.data}
                    paginate={fetchedData?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="25% 25% 25% 25%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JournalEntry
