import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import {
  searchMaterialRequest,
  updateAction,
  updateManPowerStatus,
  updateSupplierStatus,
} from "../../../services/procurement/materialrequest"
// import MaterialFilter from "./filter"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { searchManpowerhRequest } from "../../../services/projectManager/projectMaster"

const ManPowerRequest = ({ fromDashboard, projectId }) => {
  document.title = "Manpower Request | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [manPowerData, setManPowerData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [mrno, setMRNo] = useState("")
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  // const handleActivate = item => {
  //   const updateStatus = async item => {
  //     const response = await updateSupplierStatus(item)
  //     toastSuccess(response?.message)
  //     setRefresh(refresh + 1)
  //   }
  //   updateStatus(item)
  // }

  const handleAction = async data => {
    const response = await updateManPowerStatus(data)
    toastSuccess(response?.message)
    // setRefresh1(refresh1 + 1)
  }

  const columns = [
    {
      label: "M-Code",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "materialrequest_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/procurement-materiareq/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.manpower_code}
              </div>
            ) : (
              <div>{item?.manpower_code}</div>
            )}
          </>
        )
      },
    },
    {
      label: "Project Code",
      renderCell: item => item?.project_id?.project_code,
    },
    {
      label: "From date",
      renderCell: item => moment(item?.from_date).format("DD-MM-YYYY"),
    },
    {
      label: "To date",
      renderCell: item => moment(item?.to_date).format("DD-MM-YYYY"),
    },
    {
      label: "Number of Hours",
      renderCell: item => item?.req_manpower_no,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1 || 0 ? "status-inactive" : "status-active"
            }`}
          >
            {item?.status === 0 ? "New" : item?.status === 1 ? "New" : "Closed"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item =>
        fromDashboard ? (
          item?.request_status == 0 ? (
            <>
              <div>
                <Button
                  color={"primary"}
                  className="btn btn-primary me-2"
                  onClick={() =>
                    handleAction({ manPowerReq: item?.id, status: 1 })
                  }
                >
                  Accept
                </Button>
                <Button
                  className="hover-button"
                  onClick={() =>
                    handleAction({ manPowerReq: item?.id, status: 2 })
                  }
                >
                  Reject
                </Button>
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <>
            <div>
              <span id={`PopoverLegacy-${item?.id}`}>
                <img src={action} alt="" className="pointer" />
              </span>
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.id}`}
              trigger="legacy"
            >
              <PopoverBody>
                {item?.listing_status == 1 && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/procurement-purchase-order/add`, {
                        state: item,
                      })
                    }
                  >
                    Convert to PO
                  </div>
                )}
                {/* <div
                className={`table-popup-content`}
                // onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div> */}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  useEffect(() => {
    const fetchManPower = async () => {
      const response = await searchManpowerhRequest({
        params: `${"&searchText"}${
          projectId ? `&project_id=${projectId}` : ""
        }${`&sizePerPage=10`}${`&filter_status=0`}`,
      })
      setManPowerData(response)
    }
    fetchManPower()

    //     const response = await searchMaterialRequest({
    //     params: `${
    //       status == "0" || status == "1" || status == "2"
    //         ? `&status=${status}`
    //         : ""
    //     }${search ? `&searchText=${search}` : "&searchText"}${
    //       mrno ? `&mr_no=${mrno}` : ""
    //     }${datefrom ? `&requestdate_from=${datefrom}` : ""}${
    //       dateto ? `&requestdate_to=${dateto}` : ""
    //     }${
    //       page ? `&page=${page}` : ""
    //     }${`&financial=${financial}`}${`&sizePerPage=10`}${
    //       fromDashboard ? `&filter_status=0` : ""
    //     }${fromDashboard && projectId ? `&project_id=${projectId}` : ""}`,
    //   })
    //   setMaterialRequest(response)
    // }
    // fetchMaterialRequest()
  }, [refresh, status, search, mrno, page, datefrom, dateto, financial])
  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleRequestChange = id => {
    setMRNo(id)
    setPage(1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Material Request",
                    link: "/procurement-materiareq",
                  },
                ]}
              />
            ) : (
              <></>
            )}
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurement-materiareq/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Material Request"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            {/* <MaterialFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleRequestChange={handleRequestChange}
              handleDateChange={handleDateChange}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            /> */}
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {manPowerData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={manPowerData?.data?.data}
                    paginate={manPowerData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 15% 15% 15% 10% 15% "
                    mobileWidth="30% 30% 30% 30% 30% 20% 15% "
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManPowerRequest
