import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "../Common/withRouter"
import { Link, useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import SkeletonLoading from "./SkeletonLoader"

const SidebarContent = props => {
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    const rearrangeMenu = () => {
      if (!Array.isArray(loggedUser?.smenu)) return

      const updatedMenu = [...loggedUser.smenu] // Clone the menu to avoid mutating the original state

      // Find the 'Finance' item
      const financeItem = updatedMenu.find(item => item?.label === "Finance")
      const financeIndex = updatedMenu.indexOf(financeItem)

      // Find the 'User Management' item
      // const userManagementItem = updatedMenu.find(
      //   item => item?.label === "User Management"
      // )
      // const userManagementIndex = updatedMenu.indexOf(userManagementItem)

      if (financeItem) {
        // Remove both 'Finance' and 'User Management' from the original array
        updatedMenu.splice(financeIndex, 1)
        // updatedMenu.splice(
        //   userManagementIndex > financeIndex
        //     ? userManagementIndex - 1
        //     : userManagementIndex,
        //   1
        // )

        // Add 'Finance' and then 'User Management' at the end
        updatedMenu.push(financeItem)
        // updatedMenu.push(userManagementItem)

        setMenuItems(updatedMenu)
      }
    }

    if (loggedUser) {
      setMenuItems(loggedUser?.smenu)
      rearrangeMenu()
    }
  }, [loggedUser])

  // useEffect(() => {
  //   const rearrangeMenu = () => {
  //     if (!Array.isArray(loggedUser?.smenu)) return
  //     const updatedMenu = loggedUser?.smenu
  //     const financeItem = updatedMenu.find(item => item?.label === "Finance")
  //     if (financeItem) {
  //       // Remove 'Finance' from the original array
  //       const index = updatedMenu.indexOf(financeItem)
  //       updatedMenu.splice(index, 1)
  //       // Add 'Finance' to the end
  //       updatedMenu.push(financeItem)
  //       setMenuItems(updatedMenu)
  //     }
  //   }
  //   if (loggedUser) {
  //     setMenuItems(loggedUser?.smenu)
  //     rearrangeMenu()
  //   }
  // }, [loggedUser])

  // const ref = useRef();
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      // scrollElement(item);
      return false
    }
    // scrollElement(item);
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      // if (pathName === items[i].pathname) {
      if (pathName.includes(items[i].pathname)) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  // useEffect(() => {
  //   ref.current.recalculate();
  // }, []);

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }


  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li className="menu-title">{props.t("MODULES")}</li>

            {!loggedUser?.smenu?.length ? (
              <SkeletonLoading />
            ) : (
              <>
                {menuItems?.map((dt, i) => (
                  <li key={`side_${i}`}>
                    {loggedUser?.permission?.some(
                      permissionItem =>
                        permissionItem?.id === dt.id &&
                        permissionItem?.is_menu == 1
                    ) && (
                      <Link to={dt?.linkTo} className="waves-effect  d-flex">
                        <i className={`${dt?.icon} me-3 side-menu-icon`}></i>
                        {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}

                        <div className="d-flex align-items-center">
                          {props.t(`${dt?.label}`)}
                        </div>
                      </Link>
                    )}
                  </li>
                ))}
                {loggedUser?.smenu && (
                  <li>
                    <Link to={"/logout"} className="waves-effect  d-flex">
                      <i className={`signoutImg me-3 side-menu-icon`}></i>
                      {/* <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i> */}
                      <div className="d-flex align-items-center">Logout</div>
                    </Link>
                  </li>
                )}
              </>
            )}
            {/* <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect d-flex align-items-center">
                <i className="uil-user-circle"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
