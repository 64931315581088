import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, Col, Container, Row } from "reactstrap"
import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"
import moment from "moment"
import { downloadgrn, fetchGRN } from "../../../services/procurement/grn"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { capitalize } from "lodash"
import { fetchSingleInvoice } from "../../../services/finance/finance"

const InvoiceView = ({ fromDashboard, viewValue }) => {
  const { id } = useParams()

  const viewId = fromDashboard ? viewValue?.value?.id : id

  const [grnDetails, setGrnDetails] = useState()
  const [tabledata, setTabledata] = useState()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchGRNdata = async () => {
      const response = await fetchSingleInvoice(viewId)
      setGrnDetails(response?.data)
      // setTabledata(
      //   (response?.data?.grnquantitieslist).filter(
      //     item => item.received_quantity != 0
      //   )
      // )
    }
    if (viewId !== undefined) fetchGRNdata()
  }, [])

  const result = tabledata?.reduce(
    (acc, item) => {
      const received = item.received_quantity || 0
      return {
        totalreceived: Number(acc.totalreceived) + Number(received),
      }
    },
    { totalreceived: 0 }
  )

  const { totalreceived } = result || {}
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Finance"
                breadcrumbObject={[
                  { title: "Finance", link: "/finance" },
                  { title: "Invoice", link: "/finance-invoices" },
                  {
                    title: "Invoice View",
                    link: `/finance-invoice/view/${viewId}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <div className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h3 className="main-heading">Invoice </h3>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>

                    <Row className=" pb-2 mt-3">
                      <Col lg="6">
                        <div>From</div>
                        <div className="fw-bold-700">Everrise</div>
                        <div>
                          No - 6/27, Nesamani Nagar, Nagercoil. Mobile : +91
                          98765 43210
                        </div>
                      </Col>
                      <Col lg="6">
                        <div>To</div>
                        <div className="fw-bold-700">Contact Person</div>
                        <div>
                          No - 6/27, Nesamani Nagar, Nagercoil. Mobile : +91
                          98765 43210
                        </div>
                      </Col>
                      {/* <Col lg="3">
                        <span>PO NO</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.po_id?.purchase_orders_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>PO Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {moment(grnDetails?.po_id?.created_at).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Col> */}
                    </Row>
                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>Project No </span>
                        <br />
                        <span className="fw-bold-700">P/24/0001</span>
                      </Col>
                      <Col lg="3">
                        <span>Project Name</span>
                        <br />
                        <span className="fw-bold-700">Office Interior</span>
                      </Col>
                      <Col lg="6">
                        <span>Invoice Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {moment().format("DD-MM-YYYY")}
                        </span>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col sm={4} className="fw-bold-700">
                        Project Value
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        1000000
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4} className="fw-bold-700">
                        Security Cheque
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        200000
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4} className="fw-bold-700">
                        Initial Payment (10%)
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        100000
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4} className="fw-bold-700">
                        Initial Payment (10%)
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        100000
                      </Col>
                    </Row>
                    {/* <div className="table_height">
                      <Row className="mt-4">
                        <Col lg={12}>
                          <h5 className="productDetail">Material Details</h5>
                        </Col>
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>S No</th>
                                <th>Product Code</th>
                                <th>Description</th>
                                <th>Ordered Qty</th>
                                <th>Rcvd.Qty</th>
                                <th>Pend.Qty</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tabledata?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data?.product?.product_code}</td>
                                    <td>{data.description} </td>
                                    <td>{data.ordered_quantity}</td>
                                    <td>{data.received_quantity}</td>
                                    <td>{data.pending_quantity}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between me-2">
                        <p className="quotation-ten-amount">
                          {grnDetails?.pay_terms &&
                            parse(grnDetails?.pay_terms)}
                        </p>
                        <p>
                          Total Qty Received{" "}
                          <span className="bg-total">{totalreceived}</span>
                        </p>
                      </div>
                    </div> */}

                    <Row className="pt-5">
                      <Col sm={6}>
                        <div className="pb-2 pay-terms">Pay Terms:</div>
                        <div className="pay-terms">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                        <div className="pt-5 pb-2 pay-terms">Remarks:</div>
                        <div className="pay-terms">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                      </Col>
                    </Row>

                    <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                      <div className="font-bold-800">Contact Us</div>
                      <div>
                        <img
                          src={phoneImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        +{loggedUser?.settings?.phone}
                      </div>
                      <div>
                        <img
                          src={webImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.website}
                      </div>
                      <div>
                        <img
                          src={locationImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.companyaddress}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* {!fromDashboard && ( */}
              {/* <Row>
                <Col lg={fromDashboard ? 12 : 8}>
                  <div
                    className={`d-flex justify-content-between me-2 ${
                      fromDashboard ? "pe-3" : "pr-50"
                    }`}
                  >
                    <span></span>
                    <Button
                      form={`supplier-form`}
                      color={"primary"}
                      className={"px-2 mb-3 btn btn-primary"}
                      onClick={() => downloadgrn(viewId, "grn.pdf")}
                    >
                      Download
                    </Button>
                  </div>
                </Col>
              </Row> */}
              {/* )} */}
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoiceView
