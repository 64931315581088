import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import {
  fetchCurrentEmp,
  fetchProjectDashboard,
  fetchProjectDetailDashboard,
  fetchStoreMaterialRequest,
  searchManpowerhRequest,
  searchProcurmentDashRequest,
  searchProjectManager,
} from "../../../services/projectManager/projectMaster"
import { useNavigate, useParams } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import ReactApexChart from "react-apexcharts"
import present from "../../../assets/icons/present.svg"
import absent from "../../../assets/icons/absent.svg"
import RightArrow from "../../../assets/icons/rightarrowlong.svg"
import UpdateTable from "../../../components/Common/UpdatedTable"
import DownArrow from "../../../assets/icons/downarrow.svg"
import DarkDot from "../../../assets/icons/darkdot.svg"
import YellowDot from "../../../assets/icons/yellow-dot.svg"
import LightDot from "../../../assets/icons/lightdo.svg"
import moment from "moment"
import TimeLineDD from "../../../components/Common/chooseFields/TimeLineDD"
import { useForm } from "react-hook-form"
import {
  UFInput,
  UFInputDashboard,
} from "../../../components/Common/Fields/Input"
import closeIcon from "../../../assets/icons/close.svg"
import WorkersDetailList from "./workerDetails"
import redLeftArrow from "../../../assets/icons/plusIcon.svg"
import {
  searchMaterialRequest,
  updateAction,
  updateManPowerStatus,
} from "../../../services/procurement/materialrequest"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import MaterialRequest from "../../Procurement/materialrequest"
import MRView from "../../Procurement/materialrequest/view"
import MPView from "../../Procurement/manpowerRequest/view"
import POView from "../../Procurement/purchaseOrder/view"
import GrnView from "../../Procurement/grn/view"
import ManPowerRequest from "../../Procurement/manpowerRequest"
import locationImg from "../../../assets/images/location.svg"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const ProjectDashboardIndex = () => {
  document.title = "Project Dashboard | Ever Rise"

  const { id } = useParams()

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("1")
  const [refresh, setRefresh] = useState(0)
  const [page, setPage] = useState("")
  const [search, setSearch] = useState("")

  const { control, getValues, setValue, watch } = useForm({})

  const [classData, setClassData] = useState()
  const [fetchedData, setFetchedData] = useState()
  const [modal, setModal] = useState(false)
  const [viewData, setViewData] = useState()

  const [materialRequestData, setMaterialRequestData] = useState()

  const [materialData, setMaterialData] = useState()
  const [manPowerData, setManPowerData] = useState()
  const [refresh1, setRefresh1] = useState(0)
  const [refresh2, setRefresh2] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currEmp, setCurrEmp] = useState()
  const [totalWorkerId, setTotalWorkerId] = useState()

  const toggleModal = () => setModal(!modal)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    setSearch(watch("search"))
    setPage(1)
  }, [watch("search")])

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchProjectDashboard({
        params: `${search ? `&searchText=${search}` : ""}${
          id ? `&project_id=${id}` : ""
        }${
          activeTab
            ? `&type=${
                activeTab == 1
                  ? "material"
                  : activeTab == 2
                  ? "manpower"
                  : activeTab == 3
                  ? "subcontract"
                  : "overheads"
              }`
            : ""
        }`,
      })
      setClassData(response)
    }
    if (id) {
      fetchClass()
    }
  }, [id, activeTab, search])

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await searchProcurmentDashRequest({
        params: `${"&searchText"}${id ? `&project_id=${id}` : ""}`,
      })
      setMaterialRequestData(response)
    }
    if (id) {
      fetchMaterialSearch()
    }
  }, [id, refresh1])

  useEffect(() => {
    const fetchMR = async () => {
      const response = await searchMaterialRequest({
        params: `${"&searchText"}${`&sizePerPage=5`}${`&filter_status=0`}${
          id ? `&project_id=${id}` : ""
        }`,
      })
      setMaterialData(response)
    }
    fetchMR()
  }, [id, refresh1])

  useEffect(() => {
    const fetchManPower = async () => {
      const response = await searchManpowerhRequest({
        params: `${"&searchText"}${
          id ? `&project_id=${id}` : ""
        }${`&sizePerPage=5`}${`&filter_status=0`}`,
      })
      setManPowerData(response)
    }
    fetchManPower()
  }, [id, refresh2])

  // useEffect(() => {
  //   const controlValue = getValues1()
  //   const fetchMaterialSearch = async () => {
  //     const response = await fetchStoreMaterialRequest({
  //       params: `${"&searchText"}${
  //         id ? `&project_id=${id}` : ""
  //       }${`&type=materialrequest`}${`&date=${controlValue?.Time_1?.value}`}${
  //         materialPage ? `&page=${materialPage}` : ""
  //       }${
  //         controlValue?.start_date
  //           ? `&start_date=${controlValue?.start_date}`
  //           : ""
  //       }${
  //         controlValue?.end_date ? `&end_date=${controlValue?.end_date}` : ""
  //       }`,
  //     })
  //     setMaterialRequestData(response)
  //   }
  //   if (id && controlValue?.Time_1?.value) {
  //     fetchMaterialSearch()
  //   }
  // }, [refresh1, id])

  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchProjectDetailDashboard(id)
      setFetchedData(response?.data)
    }
    if (id) {
      fetchData(id)
      setValue("time", { label: "Today", value: "today" })
    }
  }, [id])

  const handleView = item => {
    setViewData(item)
    toggleModal()
  }

  const handleAction = async data => {
    try {
      // setIsLoading(data?.status == 1 ? true : false)
      // setLoading(data?.status == 2 ? true : false)
      const action = data?.materialReq
        ? updateAction(data)
        : updateManPowerStatus(data)
      const response = await action
      toastSuccess(response?.message)
      data?.materialReq ? setRefresh1(refresh1 + 1) : setRefresh2(refresh2 + 1)
      // setIsLoading(false)
      // setLoading(false)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Product No",
      renderCell: item => item?.products?.product_code,
    },
    {
      label: "Product Description",
      renderCell: item => {
        return <div>{item?.products?.description}</div>
      },
    },
    {
      label: "Quantity",
      renderCell: item => (item?.quantity ? item?.quantity : 0),
    },
    {
      label: "Quantity in Hands",
      renderCell: item =>
        item?.store_sum_quantity ? item?.store_sum_quantity : 0,
    },
    {
      label: "Quantity in Order",
      renderCell: item => {
        return (
          <div>
            {item?.request_count_sum_quantity && item?.store_sum_quantity
              ? Number(item?.request_count_sum_quantity) -
                Number(item?.store_sum_quantity)
              : 0}
          </div>
        )
      },
    },
  ]

  const mrColumn = [
    {
      label: "MR No",
      renderCell: item => (
        <div
          className="pointer fw-bold"
          onClick={() =>
            handleView({
              id: 4,
              name: "view_MR",
              value: item,
              product_id: "",
            })
          }
        >
          {item?.material_requests_code}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="d-flex justify-content-end">
          <Button
            color={"primary"}
            className="btn btn-primary me-2"
            onClick={() => handleAction({ materialReq: item?.id, status: 1 })}
            loading={isLoading}
            disabled={isLoading}
          >
            Accept
          </Button>
          <Button
            className="hover-button"
            onClick={() => handleAction({ materialReq: item?.id, status: 2 })}
            loading={loading}
            disabled={loading}
          >
            Reject
          </Button>
        </div>
      ),
    },
  ]

  const manPowerSubColumn = [
    {
      label: "M-Code",
      renderCell: item => (
        <div
          className="pointer fw-bold"
          onClick={() =>
            handleView({
              id: 6,
              name: "view_MP",
              value: item,
              product_id: "",
            })
          }
        >
          {item?.manpower_code}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="d-flex justify-content-end">
          <Button
            color={"primary"}
            className="btn btn-primary me-2"
            onClick={() => handleAction({ manPowerReq: item?.id, status: 1 })}
            loading={isLoading}
            disabled={isLoading}
          >
            Accept
          </Button>
          <Button
            className="hover-button"
            onClick={() => handleAction({ manPowerReq: item?.id, status: 2 })}
            loading={loading}
            disabled={loading}
          >
            Reject
          </Button>
        </div>
      ),
    },
  ]

  const manpowerColumn = [
    {
      label: "Category Code",
      renderCell: item => item?.category?.category_code,
    },
    {
      label: "Description",
      renderCell: item => item?.category?.description,
    },
    {
      label: "Number of Labours",
      renderCell: item => item?.no_of_labours,
    },
  ]

  const subColumn = [
    {
      label: "Category Code",
      renderCell: item => item?.category?.category_code,
    },
    {
      label: "Description",
      renderCell: item => item?.category?.description,
    },
    {
      label: "Unit",
      renderCell: item => item?.unit?.unit_code,
    },
    {
      label: "Quantity",
      renderCell: item => item?.duration,
    },
  ]

  const overheadsColumn = [
    {
      label: "Category Code",
      renderCell: item => item?.category?.category_code,
    },
    {
      label: "Description",
      renderCell: item => item?.category?.description,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  // useEffect(() => {
  const handleTimeChange = async (e, id) => {
    setTotalWorkerId(e?.value)
    // const response = await fetchCurrentEmp({
    //   params: `${
    //     e ? `date=${e?.value}` : `date=today`
    //   }${`&project_id=${id}`}${'&searchText=""'}`,
    // })
    // setCurrEmp(response)

    // setRefresh(refresh + 1)
  }
  // }, [id, e])

  useEffect(() => {
    const controlValue = getValues()
    const fetchMaterialSearch = async () => {
      const response = await fetchCurrentEmp({
        params: `${
          totalWorkerId ? `date=${totalWorkerId}` : `date=today`
        }${`&project_id=${id}`}${'&searchText=""'}`,
      })
      setCurrEmp(response)
      // setRefresh(refresh + 1)
    }
    if (id && controlValue?.time?.value) {
      fetchMaterialSearch()
    }
  }, [id, totalWorkerId])
  const startDate = moment(fetchedData?.start_project).format("YYYY MM DD")
  const endDate = moment(fetchedData?.exp_completion_date)
  const currDate = moment()

  const totalDays = endDate.diff(startDate, "days")

  const completedDays = currDate.diff(startDate, "days")

  const toCompleteDays = endDate.diff(currDate, "days")

  let percentageCompletion

  // let exceededDate

  if (endDate.isBefore(currDate)) {
    percentageCompletion = 100
    // exceededDate = currDate.diff(endDate, "days")
  } else {
    percentageCompletion = ((completedDays / totalDays) * 100).toFixed(0)
  }

  // if(endDate.isBefore(currDate))

  // const percentageCompletion = ((completedDays / totalDays) * 100).toFixed(0)

  //  #FAE27C

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [chartColor, setChartColor] = useState("#171717")

  useEffect(() => {
    if (loggedUser?.settings?.theme_option == 0) {
      setChartColor("#171717")
    } else if (loggedUser?.settings?.theme_option == 1) {
      setChartColor("#FAE27C")
    }
  }, [loggedUser])

  const chartData = {
    series: [isNaN(percentageCompletion) ? 0 : percentageCompletion],

    options3: {
      fill: {
        colors: [chartColor],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: 1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },

          track: {
            margin: 0,
            background: "#E9E9E9",
            strokeWidth: "40%",
          },
          dataLabels: {
            show: true,
            value: {
              color: "black",
              fontSize: "30px",
              show: false,
              fontWeight: 700,
              label: "Filled",
            },
            name: {
              show: true,
              color: "black",
              fontSize: "10px",
              fontWeight: 400,
            },
            total: {
              show: true,
              label: [
                `${isNaN(percentageCompletion) ? "0" : percentageCompletion}%`,
                "Completed",
              ],
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  }

  const onGoingColumns = [
    {
      label: "MR No",
      renderCell: item => item?.material_requests_code,
    },
    {
      label: "Quantity",
      renderCell: item => {
        return <div>{item?.requests_sum_quantity}</div>
      },
    },
    {
      label: "PO Generated Qty",
      renderCell: item =>
        item?.procuremrntpo?.reduce(
          (a, b) => a + (Number(b?.po_list_sum_quantity) || 0),
          0
        ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 1
                ? "status-active "
                : item?.status == 0
                ? "status-inactive "
                : "status-closed"
            }`}
          >
            {item?.status === 0
              ? "Receiving"
              : item?.status === 1
              ? "New"
              : "Closed"}
          </span>
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div>
          <Button
            color={"primary"}
            className={"px-2  btn btn-primary"}
            // onClick={() => downloadmr(item?.id, "mr.pdf")}
            onClick={() =>
              handleView({
                id: 4,
                name: "view_MR",
                value: item,
                product_id: "",
              })
            }
          >
            View
          </Button>
        </div>
      ),
    },
  ]

  const subOnGoingColumns = [
    {
      label: "PO No",
      renderCell: item => {
        return (
          <div>
            {item?.grn?.length ? (
              <img src={redLeftArrow} className="expand-arrow plus-wdt me-1" />
            ) : (
              <></>
            )}
            {item?.purchase_orders_code}
          </div>
        )
      },
    },
    {
      label: "Quantity",
      renderCell: item => {
        return <div>{item?.po_list_sum_quantity}</div>
      },
    },
    {
      label: "GRN Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.grn_status == 1
                ? "status-active "
                : item?.grn_status == 0
                ? "status-inactive "
                : "status-active"
            }`}
          >
            {item?.grn_status == 0
              ? "Not Started"
              : item?.grn_status == 1
              ? "Receiving"
              : "Received"}
          </span>
        </div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 0
                ? "status-active "
                : item?.status == 1
                ? "status-inactive "
                : ""
            }`}
          >
            {item?.status == 1 ? "Draft" : "Posted"}
          </span>
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div>
          <Button
            color={"primary"}
            className={"px-2 btn btn-primary"}
            // onClick={() => downloadpo(item?.id, "po.pdf")}
            onClick={() =>
              handleView({
                id: 5,
                name: "view_PO",
                value: item,
                product_id: "",
              })
            }
          >
            {/* Download */}
            View
          </Button>
        </div>
      ),
    },
  ]
  const anotherSubOnGoingColumns = [
    {
      label: "GRN No",
      renderCell: item => {
        return item?.grn.map((element, index) => (
          <div key={index} className="my-3 padd-5">
            {element?.grn_no}
          </div>
        ))
      },
    },
    {
      label: "Quantity",
      renderCell: item => {
        return item?.grn.map((element, index) => (
          <div key={index} className="my-3 padd-5">
            {element?.grnquantitieslist_sum_received_quantity}
          </div>
        ))
      },
    },
    {
      label: "",
      renderCell: item => {
        return item?.grn.map((element, index) => (
          <div className="my-3 ">
            <Button
              color={"primary"}
              className={"px-2 btn btn-primary"}
              // onClick={() => downloadpo(item?.id, "po.pdf")}
              onClick={() =>
                handleView({
                  id: 7,
                  name: "view_GRN",
                  value: element,
                  product_id: "",
                })
              }
            >
              View
            </Button>
          </div>
        ))
      },
    },
    // {
    //   label: "",
    //   renderCell: item => <></>,
    // },
    // {
    //   label: "",
    //   renderCell: item => <></>,
    // },
    // {
    //   label: "",
    //   renderCell: item => <></>,
    // },
  ]

  return (
    <React.Fragment>
      <div className="page-content dash-page-bg">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Project Dashboard",
                  link: "/project-master-dashboard",
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <div className="d-flex justify-content-between">
              <Row className="py-3">
                <Col lg={"auto"} className="secondary-text text-16">
                  Project Name
                </Col>
                <Col className="primary-text text-16 fw-500">
                  {fetchedData?.project_name ? fetchedData?.project_name : "-"}
                </Col>
              </Row>

              <Row className="py-3">
                <Col lg={"auto"} className="secondary-text text-16">
                  Project Location
                </Col>
                <Col className="primary-text text-16 fw-500">
                  {fetchedData?.location?.name
                    ? fetchedData?.location?.name
                    : "-"}
                </Col>
              </Row>
            </div>
            <Row className="mt-3 mx-1 mb-0">
              <Col lg={8}>
                <Row>
                  <Col md={7} xl={7} sm={7} xs={12}>
                    <Card className="rounded-0 border-0 dashboard-cards small-cards">
                      <CardBody>
                        <Row>
                          <Col className="project-status-head">
                            Project Status
                          </Col>
                          <hr className="table-divide" />
                          <Row>
                            <Col sm={8}>
                              <ReactApexChart
                                type="radialBar"
                                series={chartData?.series}
                                options={chartData?.options3}
                                height={250}
                              />
                            </Col>
                            <Col sm={4} className="align-self-center">
                              <div className="d-flex flex-row pb-4">
                                <div>
                                  <img
                                    src={
                                      loggedUser?.settings?.theme_option == 0
                                        ? DarkDot
                                        : YellowDot
                                    }
                                  />
                                </div>
                                <div className="ps-2">
                                  <div className="secondary-text">
                                    Completed Days
                                  </div>
                                  <div className="days-count">
                                    {isNaN(completedDays) ? 0 : completedDays}{" "}
                                    Days
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-row pt-4">
                                <div>
                                  <img src={LightDot} />
                                </div>
                                <div className="ps-2">
                                  <span className="secondary-text">
                                    Remaining Days
                                  </span>
                                  <div className="days-count">
                                    {isNaN(toCompleteDays) ? 0 : toCompleteDays}{" "}
                                    Days
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={5} xl={5} sm={5} xs={12}>
                    <Row className="dashboard-value mb-3 py-4">
                      <Col sm={12} className="secondary-text pb-1 ps-3">
                        Project Value
                      </Col>
                      <Col sm={12} className="dashboard-price ps-3">
                        {`${
                          fetchedData?.project_value
                            ? Number(fetchedData?.project_value).toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            : 0
                        } AED`}
                      </Col>
                    </Row>
                    <Row className="dashboard-value dashboard-val-center mb-3 py-4">
                      <Col className="secondary-text pb-1 ps-3">
                        Spend Value
                      </Col>
                      <Col sm={12} className="dashboard-price ps-3">
                        {`${
                          fetchedData?.spendval
                            ? Number(fetchedData?.spendval).toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            : 0
                        } AED`}
                      </Col>
                    </Row>
                    <Row className="dashboard-value py-4">
                      <Col className="secondary-text pb-1 ps-3">
                        Remaining Value
                      </Col>
                      <Col sm={12} className="dashboard-price ps-3">
                        {`${
                          fetchedData?.spendval && fetchedData?.project_value
                            ? Number(
                                Number(fetchedData?.project_value) -
                                  Number(fetchedData?.spendval)
                              ).toLocaleString("en", {
                                minimumFractionDigits: 2,
                              })
                            : 0
                        } AED`}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="pe-0">
                    <div className="me-0 tab-bg">
                      <Nav tabs className="tab-active-bg">
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "1"
                                ? "active pointer py-2 pad-nav"
                                : "pointer py-2"
                            }
                            onClick={() => {
                              toggle("1")
                              setPage(1)
                            }}
                          >
                            Material
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "2"
                                ? "active pointer py-2"
                                : "pointer py-2"
                            }
                            onClick={() => {
                              toggle("2")
                              setPage(1)
                            }}
                          >
                            Man Power
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "3"
                                ? "active pointer py-2"
                                : "pointer py-2"
                            }
                            onClick={() => {
                              toggle("3")
                              setPage(1)
                            }}
                          >
                            Sub Contract
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "4"
                                ? "active pointer py-2"
                                : "pointer py-2"
                            }
                            onClick={() => {
                              toggle("4")
                              setPage(1)
                            }}
                          >
                            Overheads
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <Row className="pt-3">
                        <Col sm={3} className="pt-2 ps-4">
                          {activeTab == 1
                            ? "Material List"
                            : activeTab == 2
                            ? "Manpower List"
                            : activeTab == 3
                            ? "Subcontract List"
                            : "Overheads List"}
                        </Col>
                        <Col sm={4}>
                          <UFInputDashboard
                            control={control}
                            placeholder="Search"
                            name={"search"}
                          />
                        </Col>
                      </Row>
                      <TabContent activeTab={activeTab} className="pt-3">
                        <TabPane tabId="1">
                          <Row className="mt-2 ever-rise-table dashboardTable">
                            <Col xs={12}>
                              {classData && activeTab == "1" && (
                                <UpdateTable
                                  className="mt-5"
                                  nodes={classData?.data?.data}
                                  paginate={classData?.data}
                                  CurrentPage={page}
                                  columns={columns}
                                  handlePagination={handlePaginate}
                                  tableWidth="20% 40% 10% 15% 15%"
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row className="mt-2 ever-rise-table dashboardTable">
                            <Col xs={12}>
                              {classData && activeTab == "2" && (
                                <UpdateTable
                                  className="mt-5"
                                  nodes={classData?.data?.data}
                                  paginate={classData?.data}
                                  CurrentPage={page}
                                  columns={manpowerColumn}
                                  handlePagination={handlePaginate}
                                  tableWidth="25% 50% 25%"
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row className="mt-2 ever-rise-table dashboardTable">
                            <Col xs={12}>
                              {classData && activeTab == "3" && (
                                <UpdateTable
                                  className="mt-5"
                                  nodes={classData?.data?.data}
                                  paginate={classData?.data}
                                  CurrentPage={page}
                                  columns={subColumn}
                                  handlePagination={handlePaginate}
                                  tableWidth="25% 25% 25% 25%"
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row className="mt-2 ever-rise-table dashboardTable">
                            <Col xs={12}>
                              {classData && activeTab == "4" && (
                                <UpdateTable
                                  className="mt-5"
                                  nodes={classData?.data?.data}
                                  paginate={classData?.data}
                                  CurrentPage={page}
                                  columns={overheadsColumn}
                                  handlePagination={handlePaginate}
                                  tableWidth="50% 50%"
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col sm={12}>
                    <div className="tab-bg border-rds-round px-2 mb-4">
                      <div className="bold-heading ps-2 py-3 pe-3 d-flex justify-content-between">
                        <div className="p-0">Recent MR</div>
                        <div>
                          {materialData?.data?.data?.length ? (
                            <Button
                              color={"primary"}
                              className="btn btn-primary me-2"
                              onClick={() =>
                                handleView({
                                  id: 1,
                                  name: "all_mr",
                                  value: "",
                                })
                              }
                            >
                              View All
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <hr className="m-0" />
                      <Row className="mt-2 ever-rise-table dashboardTable ">
                        <Col xs={12}>
                          {materialData && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialData?.data?.data}
                              columns={mrColumn}
                              tableWidth="50% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="tab-bg border-rds-round px-2 mb-4">
                      <div className="bold-heading ps-2 py-3 pe-3 d-flex justify-content-between">
                        <div className="p-0">Recent Manpower</div>
                        <div>
                          {manPowerData?.data?.data?.length ? (
                            <Button
                              color={"primary"}
                              className="btn btn-primary me-2"
                              onClick={() =>
                                handleView({
                                  id: 2,
                                  name: "all_manpower",
                                  value: "",
                                })
                              }
                            >
                              View All
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <hr className="m-0" />
                      <Row className="mt-2 ever-rise-table dashboardTable ">
                        <Col xs={12}>
                          {manPowerData && (
                            <UpdateTable
                              className="mt-5"
                              nodes={manPowerData?.data?.data}
                              columns={manPowerSubColumn}
                              tableWidth="50% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="dashboard-cards attendance-card mb-3">
                      <div className="dashboard-value">
                        <div className="project-status-head client-details-head">
                          Store Keeper Details
                        </div>
                        <hr className="table-divide-client" />
                        <Row className="ps-4">
                          <Col sm={4} className="secondary-text py-2">
                            Store Keeper
                          </Col>
                          <Col sm={6} className="client-detail-dark py-2">
                            {fetchedData?.store_keeper?.name}
                          </Col>
                        </Row>
                        <Row className="ps-4">
                          <Col sm={4} className="secondary-text py-2">
                            Email
                          </Col>
                          <Col sm={8} className="client-detail-dark py-2">
                            {fetchedData?.store_keeper?.email}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col md={4} xl={4} sm={4} xs={12}>
                <Card className="rounded-0 border-0 dashboard-cards small-cards">
                  <CardBody>
                    <Row>
                      <Col className="project-status-head">Project Status</Col>
                      <hr className="table-divide" />
                      <Row>
                        <Col sm={7}>
                          <ReactApexChart
                            type="radialBar"
                            series={chartData?.series}
                            options={chartData?.options3}
                            height={200}
                          />
                        </Col>
                        <Col sm={5} className="align-self-center">
                          <div className="d-flex flex-row pb-4">
                            <div>
                              <img src={DarkDot} />
                            </div>
                            <div className="ps-2">
                              <div className="secondary-text">
                                Completed Days
                              </div>
                              <div className="days-count">
                                {isNaN(completedDays) ? 0 : completedDays} Days
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-row pt-4">
                            <div>
                              <img src={LightDot} />
                            </div>
                            <div className="ps-2">
                              <span className="secondary-text">
                                Remaining Days
                              </span>
                              <div className="days-count">
                                {isNaN(toCompleteDays) ? 0 : toCompleteDays}{" "}
                                Days
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}

              {/* <Col md={3} xl={3} sm={3} xs={12}>
                <Row className="dashboard-value mb-3 py-4">
                  <Col sm={12} className="secondary-text pb-1">
                    Project Value
                  </Col>
                  <Col sm={12} className="dashboard-price">
                    {`${
                      fetchedData?.project_value
                        ? Number(fetchedData?.project_value).toLocaleString(
                            "en",
                            {
                              minimumFractionDigits: 2,
                            }
                          )
                        : 0
                    } AED`}
                  </Col>
                </Row>
                <Row className="dashboard-value mb-3 py-4">
                  <Col className="secondary-text pb-1">Spend Value</Col>
                  <Col sm={12} className="dashboard-price">
                    {`${
                      fetchedData?.spendval
                        ? Number(fetchedData?.spendval).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        : 0
                    } AED`}
                  </Col>
                </Row>
                <Row className="dashboard-value py-4">
                  <Col className="secondary-text pb-1">Remaining Value</Col>
                  <Col sm={12} className="dashboard-price">
                    {`${
                      fetchedData?.spendval && fetchedData?.project_value
                        ? Number(
                            Number(fetchedData?.project_value) -
                              Number(fetchedData?.spendval)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        : 0
                    } AED`}
                  </Col>
                </Row>
              </Col> */}
              {/* <Col md={5} xl={5} sm={5} xs={12}>
                <div className="dashboard-cards attendance-card mb-3">
                  <div className="p-4 attebdance-bg">
                    <div className="d-flex flex-row justify-content-between attendance-skew">
                      <div>
                        <div className="attendance-head pb-2">
                          Total Workers Assigned
                        </div>
                        <div className="attendance-total">
                          {currEmp?.data?.total ? currEmp?.data?.total : 0}
                        </div>
                      </div>
                      <div>
                        <div>
                          <TimeLineDD
                            control={control}
                            name="time"
                            onSelectChange={e => handleTimeChange(e, id)}
                          />
                        </div>
                        <div className="d-flex pt-3 align-items-end justify-content-end">
                          <span className="pe-2">View all</span>
                          <span className="justify-content-center">
                            <img
                              src={RightArrow}
                              className="pointer"
                              alt="arrow"
                              onClick={() =>
                                handleView({
                                  id: 3,
                                  name: "all_emp",
                                  value: "",
                                })
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="dashboard-value">
                  <div className="project-status-head client-details-head">
                    Client Details
                  </div>
                  <hr className="table-divide-client" />
                  <Row className="ps-4">
                    <Col sm={4} className="secondary-text py-2">
                      Client Code
                    </Col>
                    <Col sm={6} className="client-detail-dark py-2">
                      {fetchedData?.customers?.customer_code}
                    </Col>
                    <Col sm={4} className="secondary-text py-2">
                      Client Name
                    </Col>
                    <Col sm={6} className="client-detail-dark py-2">
                      {fetchedData?.customers?.customer_name}
                    </Col>
                    <Col sm={4} className="secondary-text py-2 pb-4">
                      Phone Number
                    </Col>
                    <Col sm={6} className="client-detail-dark py-2">
                      +{fetchedData?.customers?.customer_number}
                    </Col>
                  </Row>
                </div>
              </Col> */}
            </Row>
            {/* <Row>
              <Col sm={8}>
                <div className="ms-3 px-2 tab-bg">
                  <Nav tabs className="tab-active-bg">
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "1" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("1")
                          setPage(1)
                        }}
                      >
                        Material
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "2" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("2")
                          setPage(1)
                        }}
                      >
                        Man Power
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "3" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("3")
                          setPage(1)
                        }}
                      >
                        Sub Contract
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "4" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("4")
                          setPage(1)
                        }}
                      >
                        Overheads
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="pt-3">
                    <Col sm={3} className="pt-2 ps-4">
                      {activeTab == 1
                        ? "Material List"
                        : activeTab == 2
                        ? "Manpower List"
                        : activeTab == 3
                        ? "Subcontract List"
                        : "Overheads List"}
                    </Col>
                    <Col sm={4}>
                      <UFInputDashboard
                        control={control}
                        placeholder="Search"
                        name={"search"}
                      />
                    </Col>
                  </Row>
                  <TabContent activeTab={activeTab} className="pt-3">
                    <TabPane tabId="1">
                      <Row className="mt-2 ever-rise-table dashboardTable">
                        <Col xs={12}>
                          {classData && activeTab == "1" && (
                            <UpdateTable
                              className="mt-5"
                              nodes={classData?.data?.data}
                              paginate={classData?.data}
                              CurrentPage={page}
                              columns={columns}
                              handlePagination={handlePaginate}
                              tableWidth="20% 40% 10% 15% 15%"
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="mt-2 ever-rise-table dashboardTable">
                        <Col xs={12}>
                          {classData && activeTab == "2" && (
                            <UpdateTable
                              className="mt-5"
                              nodes={classData?.data?.data}
                              paginate={classData?.data}
                              CurrentPage={page}
                              columns={manpowerColumn}
                              handlePagination={handlePaginate}
                              tableWidth="25% 50% 25%"
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row className="mt-2 ever-rise-table dashboardTable">
                        <Col xs={12}>
                          {classData && activeTab == "3" && (
                            <UpdateTable
                              className="mt-5"
                              nodes={classData?.data?.data}
                              paginate={classData?.data}
                              CurrentPage={page}
                              columns={subColumn}
                              handlePagination={handlePaginate}
                              tableWidth="25% 25% 25% 25%"
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row className="mt-2 ever-rise-table dashboardTable">
                        <Col xs={12}>
                          {classData && activeTab == "4" && (
                            <UpdateTable
                              className="mt-5"
                              nodes={classData?.data?.data}
                              paginate={classData?.data}
                              CurrentPage={page}
                              columns={overheadsColumn}
                              handlePagination={handlePaginate}
                              tableWidth="50% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col sm={12}>
                    <div className="dashboard-cards attendance-card mb-3 me-3">
                      <div className="dashboard-value">
                        <div className="project-status-head client-details-head">
                          Store Keeper Details
                        </div>
                        <hr className="table-divide-client" />
                        <Row className="ps-4">
                          <Col sm={4} className="secondary-text py-2">
                            Store Keeper
                          </Col>
                          <Col sm={6} className="client-detail-dark py-2">
                            {fetchedData?.store_keeper?.name}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="tab-bg border-rds-round px-2 mb-4 me-3">
                      <div className="bold-heading ps-2 py-3 pe-3 d-flex justify-content-between">
                        <div className="p-0">Recent MR</div>
                        <div>
                          {materialData?.data?.data?.length ? (
                            <Button
                              color={"primary"}
                              className="btn btn-primary me-2"
                              onClick={() =>
                                handleView({
                                  id: 1,
                                  name: "all_mr",
                                  value: "",
                                })
                              }
                            >
                              View All
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <hr className="m-0" />
                      <Row className="mt-2 ever-rise-table dashboardTable ">
                        <Col xs={12}>
                          {materialData && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialData?.data?.data}
                              columns={mrColumn}
                              tableWidth="50% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="tab-bg border-rds-round px-2 mb-4 me-3">
                      <div className="bold-heading ps-2 py-3 pe-3 d-flex justify-content-between">
                        <div className="p-0">Recent Manpower</div>
                        <div>
                          {manPowerData?.data?.data?.length ? (
                            <Button
                              color={"primary"}
                              className="btn btn-primary me-2"
                              onClick={() =>
                                handleView({
                                  id: 2,
                                  name: "all_manpower",
                                  value: "",
                                })
                              }
                            >
                              View All
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <hr className="m-0" />
                      <Row className="mt-2 ever-rise-table dashboardTable ">
                        <Col xs={12}>
                          {manPowerData && (
                            <UpdateTable
                              className="mt-5"
                              nodes={manPowerData?.data?.data}
                              columns={manPowerSubColumn}
                              tableWidth="50% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <Row className="p-3">
              <Col sm={6}>
                <div className="dashboard-value">
                  <div className="project-status-head client-details-head">
                    Client Details
                  </div>
                  <hr className="table-divide-client" />
                  <Row className="ps-4">
                    <Col sm={4} className="secondary-text py-2">
                      Client Code
                    </Col>
                    <Col sm={6} className="client-detail-dark py-2">
                      {fetchedData?.customers?.customer_code}
                    </Col>
                    <Col sm={4} className="secondary-text py-2">
                      Client Name
                    </Col>
                    <Col sm={6} className="client-detail-dark py-2">
                      {fetchedData?.customers?.customer_name}
                    </Col>
                    <Col sm={4} className="secondary-text py-2 pb-4">
                      Phone Number
                    </Col>
                    <Col sm={6} className="client-detail-dark py-2">
                      +{fetchedData?.customers?.customer_number}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={6} xl={6} sm={6} xs={6}>
                <div className="dashboard-cards attendance-card mb-3">
                  <div className="attebdance-bg">
                    <div className="d-flex flex-row justify-content-between attendance-skew">
                      <div>
                        <div className="attendance-head pb-5">
                          Total Workers Assigned
                        </div>
                        <div className="attendance-total">
                          {currEmp?.data?.total ? currEmp?.data?.total : 0}
                        </div>
                      </div>
                      <div>
                        <div>
                          <TimeLineDD
                            control={control}
                            name="time"
                            onSelectChange={e => handleTimeChange(e, id)}
                          />
                        </div>
                        <div className="d-flex pt-5 align-items-end justify-content-end">
                          <span className="pe-2">View all</span>
                          <span className="justify-content-center">
                            <img
                              src={RightArrow}
                              className="pointer"
                              alt="arrow"
                              onClick={() =>
                                handleView({
                                  id: 3,
                                  name: "all_emp",
                                  value: "",
                                })
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="attendance-text px-4">
                    <div className="d-flex flex-row justify-content-between py-3">
                      <div>
                        <img
                          src={present}
                          width={30}
                          height={30}
                          alt="present"
                        />
                        <span className="ps-2">Present</span>
                      </div>
                      <div>420</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between py-3">
                      <div>
                        <img src={absent} width={30} height={30} alt="absent" />
                        <span className="ps-2">Absent</span>
                      </div>
                      <div>80</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end pe-4">
                    <span className="pe-2">View all</span>
                    <img
                      src={RightArrow}
                      className="pointer"
                      alt="arrow"
                      onClick={() => handleView(id)}
                    />
                  </div> */}
                </div>
              </Col>
            </Row>
            <div className="mx-3 px-2 tab-bg">
              <Row className="mt-2 ever-rise-table dashboardTable dash-table-mt">
                <Col xs={12}>
                  {materialRequestData && (
                    <UpdateTable
                      className="mt-5"
                      nodes={materialRequestData?.data?.data}
                      paginate={materialRequestData?.data}
                      columns={onGoingColumns}
                      subColumn={subOnGoingColumns}
                      anotherSubColumn={anotherSubOnGoingColumns}
                      handlePagination={handlePaginate}
                      subName={"procuremrntpo"}
                      subHeader={true}
                      anotherSubName={"grn"}
                      tableWidth="20% 20% 20% 20% 20%"
                      expand={true}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        unmountOnClose={false}
        size={viewData?.id == 1 || 2 || 3 ? "xl" : "lg"}
      >
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-1">
                {viewData?.id == 1
                  ? "Material Request"
                  : viewData?.id == 2
                  ? "Manpower Request"
                  : viewData?.id == 4
                  ? "Material Request View"
                  : viewData?.id == 7
                  ? "GRN View"
                  : viewData?.id == 3
                  ? "Workers Details"
                  : viewData?.id == 6
                  ? "Manpower Request View"
                  : "Details"}
              </h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggleModal}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            {viewData?.id == 1 ? (
              <MaterialRequest fromDashboard={true} projectId={id} />
            ) : viewData?.id == 2 ? (
              <ManPowerRequest fromDashboard={true} projectId={id} />
            ) : viewData?.id == 4 ? (
              <MRView
                fromDashboard={true}
                viewValue={viewData}
                // toggle={resetAfterCreatePo}
              />
            ) : viewData?.id == 5 ? (
              <POView
                fromDashboard={true}
                viewValue={viewData}
                // toggle={resetAfterCreatePo}
              />
            ) : viewData?.id == 7 ? (
              <GrnView
                fromDashboard={true}
                viewValue={viewData}
                // toggle={resetAfterCreatePo}
              />
            ) : viewData?.id == 3 ? (
              <WorkersDetailList
                item={totalWorkerId != undefined ? totalWorkerId : "today"}
                projectId={id}
                viewData="3"
                fromDashboard={true}
              />
            ) : viewData?.id == 6 ? (
              <MPView fromDashboard={true} viewValue={viewData} />
            ) : (
              <></>
            )}
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default ProjectDashboardIndex
