import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import {
  fetchProcurementDashRequest,
  searchManpowerhRequest,
  searchProcurmentDashRequest,
} from "../../../../services/projectManager/projectMaster"
import { useNavigate, useParams } from "react-router-dom"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import { FormProvider, useForm } from "react-hook-form"
import closeIcon from "../../../../assets/icons/close.svg"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import redLeftArrow from "../../../../assets/icons/redLeftArrow.svg"
import { downloadmr } from "../../../../services/procurement/materialrequest"
import EditPurchaseOrder from "../../../Procurement/purchaseOrder/edit"
import {
  downloadpo,
  updatePOStatus,
} from "../../../../services/procurement/purchaseOrder"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import EditGRN from "../../../Procurement/grn/edit"
import AssignEMployee from "./assignEmployee"
import RejectEmp from "./rejectEmp"
import moment from "moment"
const HrmsIndex = () => {
  document.title = "HRMS Dashboard | Ever Rise"

  const { id } = useParams()
  const navigate = useNavigate()

  //refresh state
  const [refresh, setRefresh] = useState(0)
  const [refresh1, setRefresh1] = useState(0)

  //pagination state
  const [page, setPage] = useState("")

  //view and model chart
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  //response state
  const [materialData, setMaterialData] = useState()
  const [materialRequestData, setMaterialRequestData] = useState()

  //tab
  const [activeTab, setActiveTab] = useState("1")

  const handleActiveTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const schema = yup.object().shape({})

  const toggle = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
  }

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchProcurementDashRequest(id)
      setMaterialData(response)
    }
    if (id) {
      fetchClass()
    }
  }, [refresh, id])

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await searchManpowerhRequest({
        params: `${"&searchText"}${
          activeTab
            ? `&type=${
                activeTab == 1 ? 1 : activeTab == 2 ? 3 : activeTab == 3 ? 2 : 4
              }`
            : ""
        }${id ? `&project_id=${id}` : ""}`,
      })
      setMaterialRequestData(response)
    }
    if (id) {
      fetchMaterialSearch()
    }
  }, [activeTab, refresh1])

  const newManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: "Number of Hours",
      renderCell: item => {
        return <div>{item?.req_manpower_no}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "",
      renderCell: item => (
        <Button
          color={"primary"}
          onClick={() =>
            handleView({
              id: 1,
              name: "accept_request",
              value: item,
              product_id: "",
            })
          }
        >
          Assign
        </Button>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div>
          <Button
            className="hover-button"
            onClick={() =>
              handleView({
                id: 2,
                name: "reject_request",
                value: item,
                product_id: "",
              })
            }
          >
            Reject
          </Button>
        </div>
      ),
    },
  ]
  const acceptedManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: "Number of Hours",
      renderCell: item => {
        return <div>{item?.req_manpower_no}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
  ]
  const rejectedManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: "Number of Hours",
      renderCell: item => {
        return <div>{item?.req_manpower_no}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
  ]
  const forwardManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: "Number of Hours",
      renderCell: item => {
        return <div>{Number(item?.hr_status)}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const mainCard = [
    {
      id: 1,
      name: "Manpower Requests",
      value: materialRequestData?.newmanpower
        ? materialRequestData?.newmanpower
        : 0,
    },
    {
      id: 2,
      name: "Accepted Requests",
      value: materialRequestData?.acceptedmanpower
        ? materialRequestData?.acceptedmanpower
        : 0,
    },
    {
      id: 3,
      name: "Rejected Requests",
      value: materialRequestData?.rejectedmanpower
        ? materialRequestData?.rejectedmanpower
        : 0,
    },
  ]

  const handleRegistration = async data => {
    console.log(" console log :", data)
  }

  const resetAfterCreatePo = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
      setRefresh1(refresh1 + 1)
      setRefresh(refresh + 1)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Project Dashboard",
                  link: `/project-hrms-dash/${id}`,
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2 hr-dash-bg">
            <Row className="mt-3 mx-1 mb-0">
              {mainCard?.map((dt, i) => (
                <Col md={4} xl={4} sm={4} xs={12}>
                  <Card
                    className={`rounded-0 border-0 procurement-auto-cards `}
                  >
                    <CardBody className={`${dt?.id == 1 && "dark-background"}  card-body-dash-${i}`}>
                      <Row className="px-2">
                        <Col className="bold-heading">{dt?.name}</Col>
                      </Row>
                      <Row className="px-2 pt-2">
                        <Col className=" extra-dark-text ">{dt?.value}</Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>

            <div className="mx-3 px-2 ">
              <Row>
                <Col lg={8} className=" pe-2 ps-0">
                  <div className="tab-bg border-rds-round px-2 mb-4">
                    <Nav tabs className="tab-active-bg">
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "1" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("1")
                            setPage("1")
                          }}
                        >
                          New
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "2" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("2")
                            setPage("1")
                          }}
                        >
                          Accepted
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "3" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("3")
                            setPage("1")
                          }}
                        >
                          Rejected
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "4" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("4")
                            setPage("1")
                          }}
                        >
                          Forward
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <TabContent activeTab={activeTab} className="pt-3">
                        <TabPane tabId="1">
                          <Col xs={12}>
                            {materialRequestData && activeTab == 1 && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialRequestData?.data?.data}
                                paginate={materialRequestData?.data}
                                columns={newManpowerColumn}
                                handlePagination={handlePaginate}
                                tableWidth="20% 15% 15% 15% 20% 15%"
                                mobileWidth="40% 30% 30% 30% 40% 15%"
                              />
                            )}
                          </Col>
                        </TabPane>
                        <TabPane tabId="2">
                          <Col xs={12}>
                            {materialRequestData && activeTab == 2 && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialRequestData?.data?.data}
                                paginate={materialRequestData?.data}
                                columns={acceptedManpowerColumn}
                                handlePagination={handlePaginate}
                                tableWidth="25% 25% 25% 25%"
                                mobileWidth="40% 40% 40% 30%"
                              />
                            )}
                          </Col>
                        </TabPane>
                        <TabPane tabId="3">
                          <Col xs={12}>
                            {materialRequestData && activeTab == 3 && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialRequestData?.data?.data}
                                paginate={materialRequestData?.data}
                                columns={rejectedManpowerColumn}
                                handlePagination={handlePaginate}
                                tableWidth="25% 25% 25% 25%"
                                mobileWidth="40% 40% 40% 40%"
                              />
                            )}
                          </Col>
                        </TabPane>
                        <TabPane tabId="4">
                          <Col xs={12}>
                            {materialRequestData && activeTab == 4 && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialRequestData?.data?.data}
                                paginate={materialRequestData?.data}
                                columns={forwardManpowerColumn}
                                handlePagination={handlePaginate}
                                tableWidth="25% 25% 25% 25%"
                                mobileWidth="40% 40% 40% 40%"
                              />
                            )}
                          </Col>
                        </TabPane>
                      </TabContent>
                    </Row>
                  </div>
                </Col>
                <Col lg={4} className="">
                  <div className="tab-bg border-rds-round px-2 mb-4">
                    <h6 className="p-3 pb-0"> Project Details</h6>
                    <hr className="m-0" />
                    <Row className="pt-2 ps-3">
                      <Col className="assign-light-text " lg={"auto"}>
                        Name :
                      </Col>
                      <Col className="bold-text">
                        {materialData?.data?.project_name}
                      </Col>
                    </Row>
                    <Row className="pt-2 ps-3">
                      <Col className="assign-light-text " lg={"auto"}>
                        Code :
                      </Col>
                      <Col className="bold-text">
                        {materialData?.data?.project_code}
                      </Col>
                    </Row>
                    <Row className="pt-2 ps-3">
                      <Col className="assign-light-text " lg={"auto"}>
                        Manager :
                      </Col>
                      <Col className="bold-text">
                        {materialData?.data?.project_manager?.name}
                      </Col>
                    </Row>
                    <Row className="pt-2 ps-3 pb-3">
                      <Col className="assign-light-text " lg={"auto"}>
                        Store Keeper :
                      </Col>
                      <Col className="bold-text">
                        {materialData?.data?.store_keeper?.name}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={viewData?.id == 1 ? "xl" : "md"}
            fullscreen={viewData?.id == 1 ? "xl" : "md"}
            unmountOnClose={false}
            // className="assign-modal"
          >
            <>
              {/* <FormProvider {...methods}>
                <Form
                  id={`ev-form`}
                  onSubmit={handleSubmit(handleRegistration)}
                > */}
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="ms-4">
                    {viewData?.id == 1
                      ? "Assign Employee"
                      : viewData?.id == 2
                      ? "Reject Request"
                      : ""}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
                {console.log("viewData :", viewData)}
              </ModalHeader>
              <ModalBody>
                {viewData?.id == 1 ? (
                  <AssignEMployee
                    fromDashboard={true}
                    toggle={resetAfterCreatePo}
                    projectDetails={viewData?.value}
                  />
                ) : viewData?.id == 2 ? (
                  <RejectEmp
                    fromDashboard={true}
                    projectDetails={viewData?.value}
                    toggle={resetAfterCreatePo}
                  />
                ) : (
                  ""
                )}
              </ModalBody>
              {/* </Form>
              </FormProvider> */}
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HrmsIndex
