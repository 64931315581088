import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { UFDate } from "../../../components/Common/Fields/Input"
import moment from "moment"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { fetchFinanceProfitLoss } from "../../../services/finance/finance"
import UpdateTable from "../../../components/Common/UpdatedTable"
import arrow from "../../../assets/icons/arrow.svg"
import { useNavigate } from "react-router-dom"

const ProfitLossAc = ({ fromDashboard }) => {
  document.title = "Profit & Loss A/C | Ever Rise"

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [debitTotal, setDebitTotal] = useState(0)
  const [creditTotal, setCreditTotal] = useState(0)

  const { control, watch, reset } = useForm()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceProfitLoss({
        params: `${startDate ? `&start_date=${startDate}` : ""}${
          endDate ? `&end_date=${endDate}` : ""
        }`,
      })
      setFetchedData(responce)
    }
    fetchData()
  }, [startDate, endDate])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const incomeColumn = [
    {
      label: "Group Name",
      renderCell: item => (
        <span className="fw-500 fs-14 ps-2">{item?.group_name}</span>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {Number(
            Number(item?.debit ? item?.debit : 0).toFixed(2)
          ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
          AED
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {Number(
            Number(item?.credit ? item?.credit : 0).toFixed(2)
          ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
          AED
        </div>
      ),
    },
  ]
  const assetSubColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <div
          onClick={() =>
            navigate(`/finance-ledger-acc`, {
              state: item,
            })
          }
          className="pointer fw-bold fs-13 ps-2"
        >
          {item?.ledger_name}
          <span className="ps-1">
            <img src={arrow} alt="" width="7" />
          </span>
        </div>
      ),
    },
    {
      label: "Debit",
      renderCell: item => (
        <div className="fs-14">
          {Number(Number(item?.debit).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
          })}
          AED
        </div>
      ),
    },
    {
      label: "Credit",
      renderCell: item => (
        <div className="fs-14">
          {Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
          })}{" "}
          AED
        </div>
      ),
    },
  ]

  const reduceBalance = (assets, amount) => {
    // Reduce the balance of each asset and sum them up
    const total = assets.reduce((sum, asset) => {
      return sum + (asset.balance - amount) // Reduce the balance and accumulate
    }, 0)

    return total // Return the total balance after reduction
  }

  const calculateTotals = assets => {
    const totalDebit = assets.reduce((sum, asset) => sum + asset.debit, 0)
    const totalCredit = assets.reduce((sum, asset) => sum + asset.credit, 0)

    return {
      totalDebit,
      totalCredit,
    }
  }

  const getTotalValue = (data, key) => {
    console.log(`data:`, data)

    return data.reduce((total, item) => {
      return total + (item?.[key] || 0) // Add the specified key's value, defaulting to 0 if undefined
    }, 0)
  }

  useEffect(() => {
    if (fetchedData) {
      const totalDebit = Object.keys(fetchedData?.data).reduce((sum, key) => {
        return sum + getTotalValue(fetchedData?.data[key], "debit")
      }, 0)

      const totalCredit = Object.keys(fetchedData?.data).reduce((sum, key) => {
        return sum + getTotalValue(fetchedData?.data[key], "credit")
      }, 0)

      setDebitTotal(totalDebit)
      setCreditTotal(totalCredit)

      console.log(`totalDebit:`, totalDebit)
      console.log(`totalCredit:`, totalCredit)
    }
  }, [fetchedData])

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              {!fromDashboard ? (
                <>
                  <Breadcrumbs
                    title="Finance"
                    breadcrumbObject={[
                      { title: "Finance", link: "/finance" },
                      {
                        title: "Profit & Loss A/C",
                        link: "/finance-profitloss",
                      },
                    ]}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="pe-2">
              <UFDate
                control={control}
                name={"req_date"}
                placeholder={"Date"}
                mode={"range"}
                onDateChange={e => handleRequestdate(e)}
              />
            </div>
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            <Row className="profit-loss-tbl-hgt">
              <Col lg={6}>
                <div className="my-2 d-flex justify-content-center input-card-head">
                  Income
                </div>
                <div className="d-flex justify-content-between px-3 py-2">
                  <div className="fw-500">Opening Stock</div>
                  <div>
                    {Number(
                      Number(
                        fetchedData?.opening_stock
                          ? fetchedData?.opening_stock
                          : 0
                      ).toFixed(2)
                    ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
                    AED
                  </div>
                </div>
                <table className="estimation-table">
                  <thead>
                    <tr>
                      <th className="w-50">Account Name</th>
                      <th className="w-25">Debit</th>
                      <th className="w-25">Credit</th>
                    </tr>
                  </thead>
                </table>
                <Row className="mt-2 reportTable">
                  <Col xs={12}>
                    {fetchedData?.data?.income && (
                      <>
                        <UpdateTable
                          // className="mt-5"
                          nodes={fetchedData?.data?.income}
                          columns={incomeColumn}
                          subColumn={assetSubColumns}
                          subName={"ledgers"}
                          subHeader={false}
                          tableWidth="50% 25% 25%"
                          mobileWidth="50% 25% 25%"
                          expand={true}
                          headerHide={true}
                        />
                      </>
                    )}
                    <table className="estimation-table trial-balance-total">
                      <thead>
                        <tr>
                          <th className="w-50">Total</th>
                          <th className="w-25">
                            {fetchedData?.data?.income
                              ? `${Number(
                                  Number(
                                    calculateTotals(fetchedData?.data?.income)
                                      .totalDebit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })} AED`
                              : "0.00 AED"}
                          </th>
                          <th className="w-25">
                            {fetchedData?.data?.income
                              ? `${Number(
                                  Number(
                                    calculateTotals(fetchedData?.data?.income)
                                      .totalCredit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })} AED`
                              : "0.00 AED"}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <div className="my-2 d-flex justify-content-center input-card-head">
                  Expense
                </div>
                <div className="d-flex justify-content-between px-3 py-2">
                  <div className="fw-500">Closing Stock</div>
                  <div>
                    {Number(
                      Number(
                        fetchedData?.closing_stock
                          ? fetchedData?.closing_stock
                          : 0
                      ).toFixed(2)
                    ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
                    AED
                  </div>
                </div>
                <table className="estimation-table">
                  <thead>
                    <tr>
                      <th className="w-50">Account Name</th>
                      <th className="w-25">Debit</th>
                      <th className="w-25">Credit</th>
                    </tr>
                  </thead>
                </table>
                <Row className="mt-2 reportTable">
                  <Col xs={12}>
                    {fetchedData?.data?.expense && (
                      <UpdateTable
                        // className="mt-5"
                        nodes={fetchedData?.data?.expense}
                        columns={incomeColumn}
                        subColumn={assetSubColumns}
                        subName={"ledgers"}
                        subHeader={false}
                        tableWidth="50% 25% 25%"
                        mobileWidth="50% 25% 25%"
                        expand={true}
                        headerHide={true}
                      />
                    )}
                    <table className="estimation-table trial-balance-total">
                      <thead>
                        <tr>
                          <th className="w-50">Total</th>
                          <th className="w-25 ps-2">
                            {fetchedData?.data?.expense
                              ? `${Number(
                                  Number(
                                    calculateTotals(fetchedData?.data?.expense)
                                      .totalDebit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })} AED`
                              : "0.00 AED"}
                          </th>
                          <th className="w-25">
                            {fetchedData?.data?.expense
                              ? `${Number(
                                  Number(
                                    calculateTotals(fetchedData?.data?.expense)
                                      .totalCredit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })} AED`
                              : "0.00 AED"}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                </Row>
              </Col>
            </Row>
            <table className="estimation-table trial-balance-total">
              <thead>
                <tr>
                  <th className="w-50">Net Profit/Loss</th>

                  <th className="w-50">
                    {fetchedData?.net_profit_or_loss
                      ? `${Number(
                          Number(fetchedData?.net_profit_or_loss).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })} AED`
                      : 0}
                  </th>
                </tr>
              </thead>
            </table>
            {/* <Row>
              <Col lg={6}>Net Profit/Loss</Col>
              <Col lg={6}>{fetchedData?.net_profit_or_loss}</Col>
            </Row> */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProfitLossAc
