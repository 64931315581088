import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { UFEditor, UFInput } from "../../../components/Common/Fields/Input"
import Group from "../../../components/Common/chooseFields/group"
import { FormProvider, useForm } from "react-hook-form"
import MaterialList from "../../../components/Common/chooseFields/materialList"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import EstimationProduct from "../../../components/Common/chooseFields/estimationProduct"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import Productcode from "../../../components/Common/chooseFields/Product"
import { getProductCodeProjectNum } from "../../../services/Settings/dropdown"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { requestProduct } from "../../../services/centralStore/centralStore"
import ProductNum from "../../../components/Common/chooseFields/productNum"
import { useNavigate } from "react-router-dom"

const schema = yup.object().shape({
  description: yup.string().required("Description is Required"),
  project_id: yup.mixed().required("Project Code is Required"),
  product_id: yup.mixed().required("Product Code is Required"),
  quantity: yup.number().required("Quantity is Required"),
})

const RequestProduct = () => {
  document.title = "Request Product | Everise"

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      project_id: null,
      product_id: null,
      quantity: "",
      description: "",
    },
  })

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const responce = await requestProduct({
        ...data,
        project_id: data?.project_id?.id,
        product_id: data?.product_id?.product_id?.id,
        type: "request"
      })
      toastSuccess(responce?.message)
      navigate("/procurement")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`general-setting-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between">
                <Breadcrumbs
                  title="Procurement"
                  breadcrumbObject={[
                    { title: "Procurement", link: "/procurement" },
                    {
                      title: "Request Product",
                      link: "/procurement/request-product",
                    },
                  ]}
                />

                <LoadingButton
                  form={`general-setting-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  className={"px-2 mb-3 btn btn-primary"}
                  type="submit"
                >
                  Request Product
                </LoadingButton>
              </div>

              <Card className="me-2 px-2 pb-2">
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    <Row className="pt-4">
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Code<span className="mandatory">*</span>
                          </Label>
                          <ProjectNo
                            control={control}
                            name={"project_id"}
                            invalid={!!errors.project_id}
                          />
                          <FormFeedback invalid>
                            {errors.project_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Product Code<span className="mandatory">*</span>
                          </Label>
                          <ProductNum
                            control={control}
                            name={"product_id"}
                            projectNo={watch(`project_id`)}
                            invalid={!!errors.product_id}
                          />
                          <FormFeedback invalid>
                            {errors.product_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Description<span className="mandatory">*</span>
                          </Label>
                          <UFEditor
                            control={control}
                            placeholder="Enter the Description"
                            rows="5"
                            name={"description"}
                            invalid={!!errors.description}
                          />
                          <FormFeedback invalid>
                            {errors.description?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Quantity<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Quantity"
                            name={"quantity"}
                            invalid={!!errors.quantity}
                          />
                          <FormFeedback invalid>
                            {errors.quantity?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}
export default RequestProduct
