import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import { updateSupplierStatus } from "../../../services/procurement/supplier"
import { downloadgrn, searchGRN } from "../../../services/procurement/grn"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  fetchAccReceivable,
  fetchGRNCosting,
} from "../../../services/finance/grnCosting"
import AccountReceivableFilter from "./filter"

const AccountReceivable = () => {
  document.title = "Account Receivable | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [grnData, setGRNData] = useState()
  const [search, setSearch] = useState("")
  const [cusCode, setCus] = useState("")
  const [subCat, setSubCat] = useState("")
  const [arCode, setARCode] = useState("")
  const [projectno, setProjectno] = useState("")
  // const [purchaseno, setPurchaseno] = useState("")
  const [page, setPage] = useState("")
  // const [datefrom, setDatefrom] = useState("")
  // const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  const [company, setCompany] = useState(false)

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateSupplierStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "AR No",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "accountreceivable_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/finance-account-receivable/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.code}
              </div>
            ) : (
              <div>{item?.code}</div>
            )}
          </>
        )
      },
    },
    // {
    //   label: "C-Code",
    //   renderCell: item => item?.cus_id?.customer_code,
    // },
    {
      label: "Customer Code",
      renderCell: item =>
        item?.cus_id?.customer_code != null
          ? item?.cus_id?.customer_code
          : "-",
    },
    {
      label: "Project Code",
      renderCell: item =>
        item?.project?.project_code != null
          ? item?.project?.project_code
          : "-",
    },
    {
      label: "Account",
      renderCell: item =>
        item?.account?.code ? item?.account?.code : "-",
    },
    {
      label: "Amount",
      renderCell: item =>
        `${Number(Number(item?.amount).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })} AED`,
    },
    // {
    //   label: "Action",
    //   renderCell: item => (
    //     <>
    //       <div>
    //         <span id={`PopoverLegacy-${item?.id}`}>
    //           <img src={action} alt="" className="pointer" />
    //         </span>
    //       </div>
    //       <UncontrolledPopover
    //         placement="bottom"
    //         target={`PopoverLegacy-${item?.id}`}
    //         trigger="legacy"
    //       >
    //         <PopoverBody>
    //           <div
    //             className="table-popup-content"
    //             onClick={() => navigate(`/procurement-grn/view/${item?.id}`)}
    //           >
    //             View
    //           </div>
    //           <div
    //             className="table-popup-content"
    //             onClick={() => downloadgrn(item?.id, "grn.pdf")}
    //           >
    //             Download
    //           </div>
    //         </PopoverBody>
    //       </UncontrolledPopover>
    //     </>
    //   ),
    // },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  // const handleDateChange = (fromdate, todate) => {
  //   setDatefrom(fromdate)
  //   setDateto(todate)
  //   setPage(1)
  // }
  useEffect(() => {
    const fetchGRN = async () => {
      const response = await fetchAccReceivable({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          cusCode ? `&cus_id=${cusCode}` : ""
        }${arCode ? `&code=${arCode}` : ""}${
          projectno ? `&project_id=${projectno}` : ""
        }${subCat ? `&sub_cat_id=${subCat}` : ""}${
          page ? `&page=${page}` : ""
        }${`&financial=${financial}`}${
          company == true ? `&company_search=""` : ""
        }${`&sizePerPage=16`}`,
      })
      setGRNData(response)
    }
    fetchGRN()
  }, [
    refresh,
    search,
    cusCode,
    page,
    projectno,
    financial,
    company,
    arCode,
    subCat,
  ])

  const handleCusChange = data => {
    setCus(data)
    setPage(1)
  }
  const handleSubCatChange = data => {
    setSubCat(data)
    setPage(1)
  }
  const handleARCodeChange = data => {
    setARCode(data)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  // const handlePurchaseChange = data => {
  //   setPurchaseno(data)
  //   setPage(1)
  // }
  const handleProjectChange = data => {
    setProjectno(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleCompanyChange = id => {
    setCompany(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Finance"
              breadcrumbObject={[
                { title: "Finance", link: "/finance" },
                {
                  title: "Account Receivable",
                  link: "/finance-account-receivable",
                },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "accountreceivable_create"
            ) && (
              <Link
                to="/finance-account-receivable/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Account Receivable"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <AccountReceivableFilter
              handleSearchChange={handleSearchChange}
              handleCusChange={handleCusChange}
              handleSubCatChange={handleSubCatChange}
              handleARCodeChange={handleARCodeChange}
              // handleDateChange={handleDateChange}
              // handlePurchaseChange={handlePurchaseChange}
              handleProjectChange={handleProjectChange}
              handleFinacialChange={handleFinacialChange}
              handleCompanyChange={handleCompanyChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {grnData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={grnData?.data?.data}
                    paginate={grnData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 20% 20% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AccountReceivable
