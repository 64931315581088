import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFInput } from "../../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../../assets/icons/close.svg"
import { Link } from "react-router-dom"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  postFinanceAccountGroup,
  postFinanceCategory,
  postFinanceSubCategory,
} from "../../../services/finance/finance"
import FinanceCat from "../../../components/Common/chooseFields/financeCat"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"
import DataTypeLists from "../../../components/Common/chooseFields/dataTypesLi"
import { capitalize } from "lodash"

const schema = yup.object().shape({
  name: yup.string().required("Account Name is Required"),
})

const Edit = ({ loading, onUpdate, id, error, close, ...props }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)
  // const [groupFields, setGroupFields] = useState([])

  useEffect(() => {
    if (props?.editItem?.modal) {
      // const groupReset = props?.editItem?.value?.group_fields?.map(dt => ({
      //   ...dt,
      //   label: dt?.label,
      //   value: dt?.id,
      // }))
      // let settingMandatory = props?.editItem?.value?.group_fields?.map(dt => ({
      //   id: dt?.id,
      //   mandatory: dt?.pivot?.mandatory,
      // }))
      // setGroupFields(settingMandatory)
      reset({
        ...props?.editItem?.value,
        parent_id: {
          value: props?.editItem?.value?.parent?.id,
          label: props?.editItem?.value?.parent?.name,
        },
        type: props?.editItem?.value?.type?.id,
        // group_fields: groupReset,
      })
    }
  }, [props?.editItem?.modal])

  const handleSeriesAdd = async data => {
    console.log(`regist---`, data)

    try {
      setIsLoading(true)
      const createdSeries = await postFinanceAccountGroup({
        ...data,
        parent_id: data?.parent_id?.value,
        type: data?.parent_id?.parent?.type
          ? data?.parent_id?.parent?.type
          : props?.editItem?.value?.type?.id,
        name: data?.name,
        // ...(groupFields && { group_fields: groupFields }),
      })
      toastSuccess(createdSeries?.message)
      onUpdate()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
      close()
    }
  }

  //   useEffect(() => {
  //     console.log(`watch("parent_id")`, watch("parent_id"))
  //     let labelSet = watch("parent_id")

  //     setValue("type", { label: labelSet?.type?.name, value: labelSet?.type?.id })
  //   }, [watch("parent_id")])

  //   useEffect(() => {
  //     if (watch("group_fields")) {
  //       const groupField = watch("group_fields").map(dt => ({
  //         id: dt?.id,
  //         mandatory: 0,
  //       }))
  //       setGroupFields(groupField)
  //     }
  //   }, [watch("group_fields")])

  //   const handleFieldChange = e => {
  //     const existingGroupFields = watch("group_fields")

  //     const updatedGroupFields = existingGroupFields.map(field => ({
  //       id: field.id, // Keep the id from each group field
  //       ...field, // Preserve other existing properties
  //       mandatory: field.mandatory ?? 0, // Set mandatory to 0 if it's not defined
  //     }))
  //     setGroupFields(updatedGroupFields)
  // }

  // const handleFieldChange = e => {
  //   const existingGroupFields = watch("group_fields")

  //   const updatedGroupFields = existingGroupFields.map(field => {
  //     const previousField = groupFields.find(
  //       prevField => prevField.id === field.id
  //     )

  //     return {
  //       ...field,
  //       id: field.id, // Keep the id from each group field
  //       mandatory: previousField
  //         ? previousField.mandatory
  //         : field.mandatory ?? 0, // Use previous mandatory if available, else default
  //     }
  //   })

  //   setGroupFields(updatedGroupFields)
  // }

  // const handleMandatoryChk = (e, i) => {
  //   setGroupFields(prevFields => {
  //     const updatedFields = [...prevFields]
  //     updatedFields[i] = {
  //       ...updatedFields[i],
  //       mandatory: !updatedFields[i].mandatory === false ? 0 : 1,
  //     }
  //     return updatedFields
  //   })
  // }

  // console.log(`watch---`, groupFields, watch("group_fields"))

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{props?.editItem?.modal ? "Edit Group" : "New Group"}</h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleSeriesAdd)}>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Group Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Acc Name"
                        name={"name"}
                        invalid={!!errors.name}
                      />
                      <FormFeedback invalid>
                        {errors.name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup className="mb-4">
                      <Label>Group Type</Label>
                      <FinanceGroupType
                        control={control}
                        placeholder="Select"
                        name={"parent_id"}
                        clearable={true}
                        invalid={!!errors.parent_id}
                        // parentChk={true}
                      />
                      <FormFeedback invalid>
                        {errors.parent_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                  {/* <Col lg={4}>
                    <FormGroup className="mb-4">
                      <Label>Nature Of Account</Label>
                      <FinanceCat
                        control={control}
                        placeholder="Select"
                        name={"type"}
                        invalid={!!errors.type}
                        disabled={watch("parent_id")?.value ? true : false}
                      />
                      <FormFeedback invalid>
                        {errors.type?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col> */}
                  {/* <Col lg={12}>
                    <FormGroup className="mb-4">
                      <Label>Group Fields</Label>
                      <DataTypeLists
                        control={control}
                        placeholder="Select"
                        name={"group_fields"}
                        invalid={!!errors.group_fields}
                        onSelectChange={e => handleFieldChange(e)}
                      />
                      <FormFeedback invalid>
                        {errors.group_fields?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  {watch("group_fields")?.value ? (
                    <Col sm={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>Group Names</th>
                            <th>Data Type</th>
                            <th className="tbl-width">Is Mandatory</th>
                          </tr>
                        </thead>
                        <tbody>
                          {watch("group_fields")?.map((dt, i) => (
                            <tr key={`group_tbl_${i}`}>
                              <td className="ps-2">{capitalize(dt?.label)}</td>
                              <td className="ps-2">
                                {capitalize(dt?.data_type)}
                              </td>
                              <td className="tbl-width">
                                <div
                                  className="px-2 d-flex justify-content-center"
                                  onClick={e => handleMandatoryChk(e, i)}
                                >
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={`group_fields.${i}.mandatory`}
                                    checked={groupFields[i]?.mandatory == 1}
                                    // disabled={
                                    //   watch(
                                    //     `estimation_materials.${index}.product_id`
                                    //   )
                                    //     ? false
                                    //     : true
                                    // }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  ) : (
                    <></>
                  )} */}
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditFinanceGroup = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditFinanceGroup
