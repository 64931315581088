import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../components/Common/LoadingButton"
import {
  fetchModules,
  fetchPermissions,
  fetchRolesData,
  postRoles,
} from "../../services/Inventory/inventory"
import redLeftArrow from "../../assets/icons/redLeftArrow.svg"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import { UFInput } from "../../components/Common/Fields/Input"

const schema = yup.object().shape({
  // first_name: yup.string().required("First Name is Required"),
  // middle_name: yup.string().required("Middle Name is Required"),
})

const EditRole = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [modules, setModules] = useState([])
  const [roleData, setRoleData] = useState([])
  const [permission, setPermission] = useState([])

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // first_name: "",
      // middle_name: "",
    },
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleRegistration = async data => {
    try {
      const response = await postRoles({
        name: data?.name,
        id: roleData?.id,
        permission: permission?.map(dt => dt?.name),
        // ...roleData,
      })
      // if (response?.status) {
      toastSuccess(response?.message)
      navigate("/user-management")
    } catch (err) {
      toastError(err?.message)
    }
  }

  useEffect(() => {
    const handleFetchRoles = async () => {
      const response = await fetchPermissions()
      setModules(response?.data)
      setPermission([])
      if (id) {
        const roleResponse = await fetchRolesData(id)
        setRoleData(roleResponse?.data)
        setPermission(roleResponse?.data?.permissions)
        setValue("name", roleResponse?.data?.name)
      }
    }
    handleFetchRoles()
  }, [id])
  const handleMainCheckBox = data => {
    if (data?.childmodules?.length) {
      let array = []
      array.push(...data?.childmodules)
      array.push(data)
      data?.childmodules?.map(dt => array.push(...dt?.submodule))

      let is_present = permission?.some(dt => dt?.id == data?.id)
      setPermission(pre => {
        let response
        if (is_present) {
          response = pre?.filter(item => !array?.some(fd => fd.id === item.id))
        } else {
          const uniqueArray = array?.filter(
            item => !pre?.some(p => p.id === item.id)
          )
          response = [...pre, ...uniqueArray]
        }
        return response
      })
    } else if (data?.submodule?.length) {
      let array = []
      array.push(...data?.submodule)
      array.push(data)

      let is_present = permission?.some(dt => dt?.id == data?.id)
      setPermission(pre => {
        let response
        if (is_present) {
          response = pre?.filter(item => !array?.some(fd => fd.id === item.id))
        } else {
          const uniqueArray = array?.filter(
            item => !pre?.some(p => p.id === item.id)
          )
          response = [...pre, ...uniqueArray]
        }
        return response
      })
    } else {
      let array = []
      array.push(data)

      let is_present = permission?.some(dt => dt?.id == data?.id)
      setPermission(pre => {
        let response
        if (is_present) {
          response = pre?.filter(item => !array?.some(fd => fd.id === item.id))
        } else {
          const uniqueArray = array?.filter(
            item => !pre?.some(p => p.id === item.id)
          )
          response = [...pre, ...uniqueArray]
        }
        return response
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form id={`role-form`} onSubmit={handleSubmit(handleRegistration)}>
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="Roles and Permissions"
                    breadcrumbObject={[
                      { title: "User Management", link: "/user-management" },
                      {
                        title: id
                          ? "Edit Roles and Permissions"
                          : "Add Roles and Permissions",
                        link: "",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`role-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row>
                    <Col sm={1} className="font-16 py-3">
                      {/* <h6> */}
                      <div className="text-mute">
                        <span className="font-16">Role : </span>
                      </div>
                      {/* {roleData?.name} */}
                      {/* </h6> */}
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <UFInput
                          control={control}
                          placeholder="Enter the role"
                          name={"name"}
                          invalid={!!errors.name}
                        />
                        <FormFeedback invalid>
                          {errors.name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col sm={12} className="roles-accordion">
                      <UncontrolledAccordion defaultOpen={["1"]}>
                        <div className="ever-rise-table">
                          <table className="ever-rise-role-table">
                            <thead>
                              <tr>
                                <th className="w-50"></th>
                                <th className="wdt-10">Add</th>
                                <th className="wdt-10">View</th>
                                <th className="wdt-10">Edit</th>
                                <th className="wdt-10">Delete</th>
                                <th className="wdt-10">Allow All</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="role-div-hgt overflow-auto">
                          {modules &&
                            modules?.map(dt => (
                              <>
                                <AccordionItem>
                                  <AccordionHeader targetId={dt?.id}>
                                    <table className="ever-rise-role-table pb-2">
                                      <tbody>
                                        <tr>
                                          <td className="w-50 pb-3">
                                            <input
                                              type="checkbox"
                                              className="ever-rise-checkbox"
                                              name={dt?.name}
                                              checked={permission?.some(
                                                d => d?.id == dt?.id
                                              )}
                                              onClick={e =>
                                                handleMainCheckBox(dt)
                                              }
                                            />

                                            <h6 className="d-inline ps-2">
                                              {dt?.name}
                                              {dt?.childmodules?.length ? (
                                                <img
                                                  src={redLeftArrow}
                                                  className="expand-arrow"
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </h6>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </AccordionHeader>
                                  <AccordionBody accordionId={dt?.id}>
                                    <div className="ever-rise-table">
                                      <table className="ever-rise-role-table">
                                        {dt?.childmodules?.map(
                                          (data, index) => (
                                            <tbody>
                                              <tr>
                                                <td className="w-50">
                                                  <li>{data?.name}</li>
                                                </td>
                                                {data?.submodule?.length ? (
                                                  <>
                                                    {data?.submodule?.map(d => (
                                                      <td className="wdt-10">
                                                        <input
                                                          type="checkbox"
                                                          className="ever-rise-checkbox"
                                                          checked={permission?.some(
                                                            dt =>
                                                              dt?.id == d?.id
                                                          )}
                                                          name={d?.name}
                                                          onClick={e =>
                                                            handleMainCheckBox(
                                                              d
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    ))}
                                                    <td className="wdt-10">
                                                      <input
                                                        type="checkbox"
                                                        className="ever-rise-checkbox"
                                                        checked={data?.submodule.every(
                                                          item =>
                                                            permission?.some(
                                                              dt =>
                                                                dt?.id ===
                                                                item?.id
                                                            )
                                                        )}
                                                        name={data?.name}
                                                        onClick={e =>
                                                          handleMainCheckBox(
                                                            data
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="wdt-10"></td>
                                                    <td className="wdt-10">
                                                      <input
                                                        type="checkbox"
                                                        className="ever-rise-checkbox"
                                                        checked={permission?.some(
                                                          dt =>
                                                            dt?.id == data?.id
                                                        )}
                                                        name={data?.name}
                                                        onClick={e =>
                                                          handleMainCheckBox(
                                                            data
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </AccordionBody>
                                </AccordionItem>
                                <hr className="m-0" />
                              </>
                            ))}
                        </div>
                      </UncontrolledAccordion>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditRole
