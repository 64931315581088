import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import UpdateTable from "../../../components/Common/UpdatedTable"
import {
  fetchFinanceJournalEntry,
  fetchFinanceLedgerAcc,
} from "../../../services/finance/finance"
import moment from "moment"
import JournalEntryFilter from "./filter"
import { useLocation } from "react-router-dom"

const LedgerAccounts = ({ fromDashboard }) => {
  document.title = "Ledger Accounts | Ever Rise"
  const [search, setSearch] = useState("")
  const [page, setPage] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [dateFrom, setDatefrom] = useState("")
  const [dateTo, setDateto] = useState("")
  const [acc, setAcc] = useState("")

  const location = useLocation()

  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceLedgerAcc({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${dateFrom ? `&start_date=${dateFrom}` : ""}${
          dateTo ? `&end_date=${dateTo}` : ""
        }${`&sizePerPage=16`}${
          location?.state || acc
            ? `&ledger_id=${
                location?.state?.item?.ledger_id
                  ? location?.state?.item?.ledger_id
                  : location?.state?.ledger_id
                  ? location?.state?.ledger_id
                  : location?.state?.id
                  ? location?.state?.id
                  : acc
              }`
            : ""
        }`,
      })
      setFetchedData(responce)
    }
    fetchData()
  }, [page, search, dateFrom, dateTo, acc, location])

  const shouldShowLedgerColumn = !(
    location?.state?.item?.ledger_name ||
    location?.state?.name ||
    location?.state?.ledger_name
  )

  const columns = [
    // ...(shouldShowLedgerColumn ? [
    // {
    //   label: "Ledger",
    //   renderCell: item => (
    //     <div className="text-capitalize">
    //       {item?.ledger?.name ? item?.ledger?.name : "-"}
    //     </div>
    //   ),
    // },
    // ]: []),
    {
      label: "Voucher Type",
      renderCell: item => (
        <div className="text-capitalize">{item?.voucher_type}</div>
      ),
    },
    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Credit(AED)",
      renderCell: item =>
        item?.credit_amount
          ? `${Number(Number(item?.credit_amount).toFixed(2)).toLocaleString(
              "en",
              { minimumFractionDigits: 2 }
            )} AED`
          : 0,
    },
    {
      label: "Debit(AED)",
      renderCell: item =>
        item?.debit_amount
          ? `${Number(Number(item?.debit_amount).toFixed(2)).toLocaleString(
              "en",
              { minimumFractionDigits: 2 }
            )} AED`
          : 0,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  const handleAccChange = data => {
    setAcc(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div>
            {!fromDashboard ? (
              <>
                <Breadcrumbs
                  title="Finance"
                  breadcrumbObject={[
                    { title: "Finance", link: "/finance" },
                    { title: "Ledger Account", link: "/finance-ledger-acc" },
                  ]}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <Row>
            <Col lg={3}>
              <Card className="me-2 px-2 pb-2">
                <JournalEntryFilter
                  handleSearchChange={handleSearchChange}
                  handleDateChange={handleDateChange}
                  handleAccChange={handleAccChange}
                  initialLedger={
                    location?.state?.item?.ledger_name ||
                    location?.state?.ledger_name ||
                    location?.state?.name
                      ? {
                          label: location?.state?.item?.ledger_name
                            ? location?.state?.item?.ledger_name
                            : location?.state?.ledger_name
                            ? location?.state?.ledger_name
                            : location?.state?.name,
                          value: location?.state?.item?.ledger_id
                            ? location?.state?.item?.ledger_id
                            : location?.state?.ledger_id
                            ? location?.state?.ledger_id
                            : location?.state?.id,
                        }
                      : null
                  }
                  initialDate={[
                    location?.state?.startDate,
                    location?.state?.endDate,
                  ]}
                  isImport={false}
                />
              </Card>
            </Col>
            <Col lg={9}>
              <Card className="me-2 px-2 pb-2">
                <div className="d-flex justify-content-between px-2 py-3 fw-600">
                  {location?.state?.item?.ledger_name ||
                  location?.state?.name ||
                  location?.state?.ledger_name ? (
                    <div>
                      Ledger:{" "}
                      {location?.state?.item?.ledger_name
                        ? location?.state?.item?.ledger_name
                        : location?.state?.name
                        ? location?.state?.name
                        : location?.state?.ledger_name}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div>
                    Opening Balance:{" "}
                    {fetchedData?.closingBalance
                      ? `${Number(
                          Number(fetchedData?.openingBalance).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })} AED`
                      : "-"}
                  </div>
                  <div>
                    Closing Balance:{" "}
                    {fetchedData?.closingBalance
                      ? `${Number(
                          Number(fetchedData?.closingBalance).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })} AED`
                      : "-"}
                  </div>
                </div>
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {fetchedData?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={fetchedData?.data?.data}
                        paginate={fetchedData?.data}
                        CurrentPage={page}
                        columns={columns}
                        handlePagination={handlePaginate}
                        tableWidth="25% 25% 25% 25%"
                        mobileWidth="40% 40% 30% 30%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LedgerAccounts
