import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchHrmsRoute,
  fetchRouteEdit,
  postHrmsRoute,
  updateRouteStatus,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"
import TicketClassDrop from "../../../components/Common/chooseFields/ticketClass"

const schema = yup.object().shape({
  route: yup.string().required("Route is Required"),
  class_type_id: yup.mixed().required("Ticket Class is Required"),
  ticket_amount_aed: yup
    .string()
    .matches(
      /^([+]?)\d+(\.\d{1,4})?$/,
      "Ticket Amount must be a valid number with up to 4 digits after the decimal point"
    )
    .required("Ticket Amount is required"),
})
const TicketRoute = () => {
  document.title = "Route | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      route: "",
      class_type_id: "",
      ticket_amount_aed: "",
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState("")

  const [listData, setListData] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [edit, setEdit] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchHrmsRoute({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}`,
      })
      setListData(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (edit.id) {
      reset({
        ...edit,
        class_type_id: {
          value: edit.class_type.id,
          label: edit.class_type.name,
        },
      })
    }
  }, [edit.id])

  const handleQualification = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postHrmsRoute({
        ...data,
        class_type_id: data?.id
          ? data.class_type_id.value
          : data.class_type_id.id,
      })
      toastSuccess(createdSeries?.message)
      reset({
        route: "",
        class_type_id: "",
        ticket_amount_aed: "",
      })
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEdit("")
    }
  }

  const handleEdit = async item => {
    const response = await fetchRouteEdit(item)
    setEdit(response?.data)
  }

  const handleStatus = async id => {
    try {
      const response = await updateRouteStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Route",
      renderCell: item => (
        <div className="capitalize-letter">{item?.route}</div>
      ),
      sortKey: "route",
    },
    {
      label: "Class",
      renderCell: item => item?.class_type?.name,
    },
    {
      label: "Ticket Amount (AED)",
      renderCell: item =>
        Number(Number(item?.ticket_amount_aed).toFixed(2)).toLocaleString(
          "en",
          {
            minimumFractionDigits: 2,
          }
        ),
      sortKey: "ticket_amount_aed",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item?.id)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      degree_name: "",
      department_name: "",
    })
    setEdit("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Route", link: "/hrms-route" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {edit?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider {...methods}>
                  <Form
                    id={`ev-form`}
                    onSubmit={handleSubmit(handleQualification)}
                  >
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Route<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Route"
                            name={"route"}
                            invalid={!!errors.route}
                          />
                          <FormFeedback invalid>
                            {errors.route?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Class Type<span className="mandatory">*</span>
                          </Label>
                          <TicketClassDrop
                            control={control}
                            name={"class_type_id"}
                          />
                          <FormFeedback invalid>
                            {errors.class_type_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Ticket Amount (AED)
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Ticket Amount"
                            name={"ticket_amount_aed"}
                            invalid={!!errors.ticket_amount_aed}
                          />
                          <FormFeedback invalid>
                            {errors.ticket_amount_aed?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="float-end">
                      <Button
                        className="btn-secondary me-2"
                        form={`ev-form`}
                        color={"secondary"}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        form={`ev-form`}
                        color={"primary"}
                        loading={isLoading}
                        disabled={isLoading}
                        type="submit"
                      >
                        {edit?.id ? "Update" : "Create"}
                      </LoadingButton>
                    </div>
                  </Form>
                </FormProvider>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Search..."
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {listData?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={listData?.data?.data}
                        paginate={listData?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="20% 20% 30% 15% 15%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TicketRoute
