import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Form,
  FormGroup,
} from "reactstrap"
import SimpleBar from "simplebar-react"
//i18n
import { withTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { UFInput } from "../../components/Common/Fields/Input"
import LoadingButton from "../../components/Common/LoadingButton"
import close from "../../assets/icons/close.svg"
import filter from "../../assets/icons/filter.svg"

const NotificationDropdown = ({
  onApplyFilters,
  resetSignal,
  resetFilters,
}) => {
  // Declare a new state variable, which we'll call "menu"
  const { control, handleSubmit, reset } = useForm()
  const [menu, setMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleFilterSearch = async data => {
    await onApplyFilters(data)
    setMenu(!menu)
  }

  const handleReset = e => {
    e.stopPropagation() // Prevent dropdown toggle
    reset({ search: "" }) // Reset form fields
    setMenu(false) // Close the dropdown
    resetFilters() // Notify parent
  }

  useEffect(() => {
    reset({ search: "" })
  }, [resetSignal, reset])

  // useEffect(() => {
  //   reset({
  //     search: "",
  //   })
  // }, [resetSignal])

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block pe-2"
        tag="li"
      >
        <DropdownToggle
          className=" noti-icon waves-effect filter-toggle-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <div className="d-flex align-items-center filter-icon">
            <img src={filter} alt="" width="15" />
            <div>Filter</div>
            <div className="reset-img" onClick={handleReset}>
              <img src={close} alt="" width="10" />
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0 filter-dropdown-menu">
          <div className="filter-form-wrapper">
            <Form id="ev-form" onSubmit={handleSubmit(handleFilterSearch)}>
              <Row className="justify-content-end">
                <Col sm={3} className="pe-1">
                  <FormGroup className="form-group-bottom">
                    <UFInput
                      control={control}
                      placeholder="Search"
                      name={"search"}
                      className={"search-input"}
                    />
                  </FormGroup>
                </Col>
                {/* <Col sm={3} className="pe-1">
                  <FormGroup className="form-group-bottom">
                    <UFInput
                      control={control}
                      placeholder="Part Number"
                      name={"description"}
                    />
                  </FormGroup>
                </Col> */}
              </Row>
              <hr />
            </Form>
          </div>
          <div className="apply-wrapper">
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Apply Filters
            </LoadingButton>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
