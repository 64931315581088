import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFEditor, UFInput } from "../../../components/Common/Fields/Input"
import CurrencyList from "../../../components/Common/chooseFields/currency"
import Country from "../../../components/Common/chooseFields/country"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchSupplier,
  postSupplier,
} from "../../../services/procurement/supplier"
import uploadicon from "../../../assets/icons/export-light.svg"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import BankList from "../../../components/Common/chooseFields/bankList"
import CreditPeriod from "../../../components/Common/chooseFields/creditPeriod"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"
import BalanceTypeDrop from "../../../components/Common/chooseFields/balanceType"
import FinanceLedgersLi from "../../../components/Common/chooseFields/ledgers"

const schema = yup.object().shape({
  name: yup.string().required("Name is Required"),
  // vat_no: yup.string().required("VAT Number is Required"),
  vat_no: yup.string()
    .required("VAT Number is Required")
    .matches(/^\d+$/, "VAT Number must only contain numbers")
    .min(15, "VAT Number must be at least 15 character")
    .max(15, "VAT Number must be at most 15 characters"),
  address: yup.string().required("Address is Required"),
  email_id: yup
    .string()
    .email("Must be a valid Email")
    .required("Email is Required"),
  contact_number: yup
    .string()
    .matches(/^([+]?)?[0-9]+$|^$/, "Contact Number must be only digits")
    .max(15, "Maximum limit is 15")
    .required("Contact Number is Required"),
  // payment_mode: yup.mixed().required("Payment Mode is Required"),
  creditperiod: yup.mixed().required("Credit Period is Required"),
  country_id: yup.mixed().required("Country is Required"),
  // account_group_id: yup.mixed().required("Nature of Account is Required"),
  // balance_type: yup.mixed().required("Balance Type is Required"),
  // inventory_account_id: yup.mixed().required("Inventory Account is Required"),
  // vat_account_id: yup.mixed().required("VAT Account is Required"),
  // accounts_payable_id: yup.mixed().required("Payable Account is Required"),
  opening_balance: yup.string().required("Opening Balance is Required"),
  currency_id: yup.mixed().required("Currency is Required"),
  bank_details: yup.array().of(
    yup.object().shape({
      ac_holder_name: yup.string().required("Account Holder name is Required"),
      ac_number: yup.string().required("Account Number is required"),
      bank_name: yup.mixed().required("Bank Name is required"),
      branch_name: yup.string().required("Branch Name is required"),
      iban: yup.string().required("IBAN is required"),
      swift_code: yup.string().required("Swift Code is required"),
    })
  ),
  contact_person: yup.array().of(
    yup.object().shape({
      email_id: yup
        .string()
        .email("Must be a valid Email")
        .required("Email is Required"),
      designation: yup.string().required("Designation is Required"),
      phone_no: yup
        .string()
        .matches(/^([+]?)?[0-9]+$|^$/, "Phone Number must be only digits")
        .max(15, "Maximum limit is 15")
        .required("Phone Number is Required"),
      supplier_contact_name: yup
        .string()
        .required("Supplier contact Name is required"),
    })
  ),
})

const EditSupplier = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [vatFile, setVatFile] = useState()
  const [tradeFile, setTradeFile] = useState()
  const [isChecked, setIsChecked] = useState()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email_id: "",
      payment_mode: null,
      creditperiod: null,
      contact_number: "",
      country_id: null,
      // account_group_id: null,
      balance_type: null,
      balance_type: null,
      opening_balance: 0,
      // inventory_account_id: null,
      // vat_account_id: null,
      // accounts_payable_id: null,
      vat_no: "",
      currency_id: null,
      is_subcontractor: false,
      address: "",
      trade_document: null,
      vat_document: null,
      bank_details: [
        {
          ac_holder_name: "",
          ac_number: "",
          bank_name: null,
          branch_name: "",
          iban: "",
          swift_code: "",
        },
      ],
      contact_person: [
        {
          email_id: "",
          designation: "",
          phone_no: "",
          supplier_contact_name: "",
        },
      ],
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const handleFetchSupplier = async () => {
      const response = await fetchSupplier(id)
      const bankDetails = response?.data?.bank_details?.map(dt => ({
        id: dt?.id,
        ac_holder_name: dt?.ac_holder_name,
        ac_number: dt?.ac_number,
        bank_name: {
          label: dt?.banks?.label,
          value: dt?.banks?.id,
          id: dt?.banks?.id,
        },
        branch_name: dt?.branch_name,
        iban: dt?.iban,
        swift_code: dt?.swift_code,
      }))
      reset({
        id: id,
        name: response?.data?.name,
        email_id: response?.data?.email_id,
        contact_number: response?.data?.contact_number,
        vat_no: response?.data?.vat_no,
        address: response?.data?.address,
        is_subcontractor: response?.data?.is_subcontractor === 1 ? true : false,
        // account_group_id: {
        //   value: response?.data?.account_group?.id,
        //   label: response?.data?.account_group?.name,
        // },
        // inventory_account_id: {
        //   value: response?.data?.inventory_account?.id,
        //   label: response?.data?.inventory_account?.name,
        // },
        // vat_account_id: {
        //   value: response?.data?.vat_account?.id,
        //   label: response?.data?.vat_account?.name,
        // },
        // accounts_payable_id: {
        //   value: response?.data?.accounts_payable?.id,
        //   label: response?.data?.accounts_payable?.name,
        // },
        payment_mode: {
          value: response?.data?.payment?.id,
          label: response?.data?.payment?.name,
        },
        creditperiod: {
          value: response?.data?.creditperiod?.id,
          label: response?.data?.creditperiod?.label,
        },
        country_id: {
          value: response?.data?.country?.id,
          label: response?.data?.country?.name,
        },
        currency_id: {
          value: response?.data?.currency?.id,
          label: response?.data?.currency?.currency_code,
        },
        balance_type: {
          value: response?.data?.ledger?.balance_type,
          label:
            response?.data?.ledger?.balance_type == "debit"
              ? "Debit"
              : "Credit",
        },
        opening_balance: response?.data?.ledger?.opening_balance,
        // bank_details: [...response?.data?.bank_details],
        bank_details: bankDetails,
        contact_person: [...response?.data?.contact],
      })
      setTradeFile(response?.data?.trade?.[0]?.file_path?.split("/").pop())
      setVatFile(response?.data?.vat?.[0]?.file_path?.split("/").pop())
      setIsChecked(response?.data?.is_subcontractor === 1 ? true : false)
    }
    if (id) {
      handleFetchSupplier()
    }
  }, [id])

  const handleRegistration = async data => {
    console.log(`data`, data)
    if (!id && !vatFile && !tradeFile) {
      if (!vatFile) {
        toastError("VAT file is Required")
      }
      if (!tradeFile) {
        toastError("Trade file is Required")
      }
    } else {
      const bank_details = data?.bank_details?.map(dt => ({
        ac_holder_name: dt?.ac_holder_name,
        ac_number: dt?.ac_number,
        bank_name: dt?.bank_name?.id,
        branch_name: dt?.branch_name,
        iban: dt?.iban,
        swift_code: dt?.swift_code,
        id: id ? dt?.id : "",
      }))
      try {
        setIsLoading(true)
        const updateDepartment = await postSupplier({
          ...data,
          bank_details: bank_details,
          is_subcontractor: data?.is_subcontractor ? 1 : 0,
          payment_mode: data?.payment_mode?.value,
          creditperiod: data?.creditperiod?.value,
          country_id: data?.country_id?.value,
          currency_id: data?.currency_id?.value,
          // account_group_id: data?.account_group_id?.value,
          balance_type: data?.balance_type?.value,
          opening_balance: data?.opening_balance ? data?.opening_balance : 0,
          // inventory_account_id: data?.inventory_account_id?.value,
          // vat_account_id: data?.vat_account_id?.value,
          // accounts_payable_id: data?.accounts_payable_id?.value,
          ...(typeof vatFile != "string" && { vat_document: vatFile }),
          ...(typeof tradeFile != "string" && { trade_document: tradeFile }),
        })
        navigate("/procurement-supplier")
        toastSuccess(updateDepartment?.message)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const {
    fields: contactFields,
    append: contactAppend,
    remove: contactRemove,
  } = useFieldArray({
    control,
    name: "contact_person",
  })
  const {
    fields: accountFields,
    append: accountAppend,
    remove: accountRemove,
  } = useFieldArray({
    control,
    name: "bank_details",
  })

  const onVatChange = event => {
    setVatFile(event?.target?.files?.[0])
  }

  const onTradeChange = event => {
    setTradeFile(event?.target?.files?.[0])
  }
  const handleCheck = e => {
    if (e?.target?.checked) {
      setValue("is_subcontractor", true)
    } else {
      setValue("is_subcontractor", false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  <Breadcrumbs
                    title="Procurement"
                    breadcrumbObject={[
                      { title: "Procurement", link: "/procurement" },
                      { title: "Suppliers", link: "/procurement-supplier" },
                      {
                        title: id ? "Edit Suppliers" : "Add Suppliers",
                        link: "",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-0 mt-2">
                    <Col sm={3}>
                      <FormGroup className="mb-3">
                        <Label>
                          Supplier Name<span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Supplier Name"
                          name={"name"}
                          invalid={!!errors.name}
                        />
                        <FormFeedback invalid>
                          {errors.name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-3">
                        <Label>
                          Email ID<span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Email ID"
                          name={"email_id"}
                          invalid={!!errors.email_id}
                        />
                        <FormFeedback invalid>
                          {errors.email_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col sm={3}>
                      <FormGroup className="mb-3">
                        <Label>
                          Payment Mode<span className="mandatory">*</span>
                        </Label>
                        <PaymentMode
                          control={control}
                          placeholder="select"
                          name={"payment_mode"}
                          invalid={!!errors.payment_mode}
                          target={"body"}
                        />
                        <FormFeedback invalid>
                          {errors.payment_mode?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col sm={3}>
                      <FormGroup className="mb-3">
                        <Label>
                          Credit Period<span className="mandatory">*</span>
                        </Label>
                        <CreditPeriod
                          control={control}
                          placeholder="select"
                          name={"creditperiod"}
                          invalid={!!errors.creditperiod}
                          target={"body"}
                        />
                        <FormFeedback invalid>
                          {errors.creditperiod?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-3">
                        <Label>
                          Contact Number<span className="mandatory">*</span>
                        </Label>
                        {/* <UFInput
                              control={control}
                              placeholder="Enter the Contact Number"
                              name={"contact_number"}
                              invalid={!!errors.contact_number}
                            /> */}
                        <PhoneInputField
                          control={control}
                          name={"contact_number"}
                          invalid={!!errors.contact_number}
                        />
                        <FormFeedback invalid>
                          {errors.contact_number?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Country<span className="mandatory">*</span>
                        </Label>
                        <Country
                          control={control}
                          placeholder="Select"
                          name={"country_id"}
                          invalid={!!errors.country_id}
                          target={"body"}
                        />
                        <FormFeedback invalid>
                          {errors.country_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Currency<span className="mandatory">*</span>
                        </Label>
                        <CurrencyList
                          control={control}
                          placeholder="Currency"
                          name={"currency_id"}
                          invalid={!!errors.currency_id}
                          target={"body"}
                        />
                        <FormFeedback invalid>
                          {errors.currency_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          VAT No<span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the VAT No"
                          rows="3"
                          name={"vat_no"}
                          invalid={!!errors.vat_no}
                        />
                        <FormFeedback invalid>
                          {errors.vat_no?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Address<span className="mandatory">*</span>
                        </Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the Address"
                          rows="5"
                          name={"address"}
                          invalid={!!errors.address}
                          className={"pb-0"}
                        />
                        <FormFeedback invalid>
                          {errors.address?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Trade Licence<span className="mandatory">*</span>
                        </Label>

                        <Col className="file-import-div import-light p-4">
                          <label
                            htmlFor="trade_document"
                            className="m-0 pointer w-100"
                          >
                            <input
                              type="file"
                              id="trade_document"
                              name="trade_document"
                              accept=".pdf"
                              onChange={event => {
                                onTradeChange(event)
                              }}
                            />

                            <div className="d-flex justify-content-center pb-2">
                              <img
                                src={uploadicon}
                                alt="Export-light"
                                className="Export-light "
                              />
                            </div>
                            {tradeFile ? (
                              <div className="d-flex justify-content-center secondary-text">
                                <span>{tradeFile?.name || "Trade.pdf"}</span>
                              </div>
                            ) : (
                              <>
                                <div className="d-flex justify-content-center secondary-text">
                                  <span className="upload">Upload&nbsp;</span>
                                  <span>Trade Document</span>
                                </div>
                              </>
                            )}
                          </label>
                        </Col>
                        <FormFeedback invalid>
                          {errors.trade_document?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          VAT Certificate<span className="mandatory">*</span>
                        </Label>
                        <Col className="file-import-div import-light p-4">
                          <label
                            htmlFor="vat_document"
                            className="m-0 pointer w-100"
                          >
                            <input
                              type="file"
                              id="vat_document"
                              name="vat_document"
                              accept=".pdf"
                              onChange={event => {
                                onVatChange(event)
                              }}
                            />

                            <div className="d-flex justify-content-center pb-2">
                              <img
                                src={uploadicon}
                                alt="Export-light"
                                className="Export-light "
                              />
                            </div>
                            {vatFile ? (
                              <div className="d-flex justify-content-center secondary-text">
                                <span>{vatFile?.name || "Vat.pdf"}</span>
                              </div>
                            ) : (
                              <>
                                <div className="d-flex justify-content-center secondary-text">
                                  <span className="upload">Upload&nbsp;</span>
                                  <span>VAT Certificate</span>
                                </div>
                              </>
                            )}
                          </label>
                        </Col>
                        <FormFeedback invalid>
                          {errors.vat_document?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {/* <Col lg={8}>
                      <Row></Row> */}
                    {/* <Row>
                        <Col sm={6}>
                          <FormGroup className="mb-4">
                            <Label>
                              VAT No<span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the VAT No"
                              rows="3"
                              name={"vat_no"}
                              invalid={!!errors.vat_no}
                            />
                            <FormFeedback invalid>
                              {errors.vat_no?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row> */}
                    {/* </Col> */}

                    <Col sm={12}>
                      <FormGroup>
                        <input
                          control={control}
                          type="checkbox"
                          className="ever-rise-checkbox"
                          name={"is_subcontractor"}
                          onChange={e => handleCheck(e)}
                          disabled={id ? true : false}
                          checked={id && isChecked}
                        />
                        <Label className="ms-2"> Sub Contract</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  <Row className="py-2">
                    <h5>Account Detail</h5>
                  </Row>
                  <Row className="mb-4">
                    {/* <Col lg={3}>
                      <FormGroup>
                        <Label>
                          Nature Of Account
                          <span className="mandatory">*</span>
                        </Label>
                        <FinanceGroupType
                          control={control}
                          placeholder="select"
                          name={"account_group_id"}
                          invalid={!!errors.account_group_id}
                          target={"body"}
                        />
                        <FormFeedback invalid>
                          {errors.account_group_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col lg={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Opening Balance<span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Opening Balance"
                          name={"opening_balance"}
                          invalid={!!errors.opening_balance}
                        />
                        <FormFeedback invalid>
                          {errors.opening_balance?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>Balance Type</Label>
                        <BalanceTypeDrop
                          control={control}
                          placeholder="Balance Type"
                          name={"balance_type"}
                          invalid={!!errors.balance_type}
                        />
                        <FormFeedback invalid>
                          {errors.balance_type?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <hr className="m-0" />
                  <Row className="py-4 mt-4">
                    <h5>Other Accounts</h5>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          Inventory Account
                          <span className="mandatory">*</span>
                        </Label>
                        <FinanceLedgersLi
                          control={control}
                          placeholder="select"
                          name={"inventory_account_id"}
                          invalid={!!errors.inventory_account_id}
                          target={"body"}
                          filSupplier={id}
                        />
                        <FormFeedback invalid>
                          {errors.inventory_account_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          Payable Account
                          <span className="mandatory">*</span>
                        </Label>
                        <FinanceLedgersLi
                          control={control}
                          placeholder="select"
                          name={"accounts_payable_id"}
                          invalid={!!errors.accounts_payable_id}
                          target={"body"}
                          filSupplier={id}
                        />
                        <FormFeedback invalid>
                          {errors.accounts_payable_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          VAT Account
                          <span className="mandatory">*</span>
                        </Label>
                        <FinanceLedgersLi
                          control={control}
                          placeholder="select"
                          name={"vat_account_id"}
                          invalid={!!errors.vat_account_id}
                          target={"body"}
                          filSupplier={id}
                        />
                        <FormFeedback invalid>
                          {errors.vat_account_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <hr className="m-0" />
                  <Row className="py-4 mt-4">
                    <h5>Contact Person</h5>
                  </Row>
                  <Row className="mb-4">
                    {contactFields.map((item, index) => (
                      <Row key={item.id}>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Name<span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Contact Name"
                              name={`contact_person.${index}.supplier_contact_name`}
                              invalid={
                                !!errors?.contact_person?.[index]
                                  ?.supplier_contact_name
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors.contact_person?.[index]
                                  ?.supplier_contact_name?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Email ID
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Contact Email ID"
                              rows="3"
                              name={`contact_person.${index}.email_id`}
                              invalid={
                                !!errors?.contact_person?.[index]?.email_id
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors.contact_person?.[index]?.email_id
                                  ?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Designation
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Contact Designation"
                              name={`contact_person.${index}.designation`}
                              invalid={
                                !!errors?.contact_person?.[index]?.designation
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors.contact_person?.[index]?.designation
                                  ?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Phone Number
                              <span className="mandatory">*</span>
                            </Label>
                            {/* <UFInput
                              control={control}
                              placeholder="Enter the Contact Phone number"
                              name={`contact_person.${index}.phone_no`}
                              invalid={
                                !!errors?.contact_person?.[index]?.phone_no
                              }
                            /> */}
                            <PhoneInputField
                              control={control}
                              name={`contact_person.${index}.phone_no`}
                              invalid={
                                !!errors.contact_person?.[index]?.phone_no
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors.contact_person?.[index]?.phone_no
                                  ?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          {contactFields.length == index + 1 ? (
                            <Button
                              color="secondary"
                              className="addButton mt-2"
                              onClick={() =>
                                contactAppend({
                                  email_id: "",
                                  designation: "",
                                  phone_no: "",
                                  name: "",
                                })
                              }
                            >
                              Add Row
                            </Button>
                          ) : (
                            ""
                          )}
                          {contactFields.length > 1 ? (
                            <Button
                              color="primary"
                              className="removeButton mt-2 ms-3"
                              onClick={() => contactRemove(index)}
                            >
                              Delete Row
                            </Button>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Row>
                  <hr className="m-0" />
                  <Row className="py-4 mt-4">
                    <h5>Bank Details</h5>
                  </Row>
                  <Row className="mb-4">
                    {accountFields.map((item, index) => (
                      <Row key={item.id}>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Account Holder Name
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Account Holder Name"
                              name={`bank_details.${index}.ac_holder_name`}
                              invalid={
                                !!errors?.bank_details?.[index]?.ac_holder_name
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors?.bank_details?.[index]?.ac_holder_name
                                  ?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Account Number
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Account Number"
                              name={`bank_details.${index}.ac_number`}
                              invalid={
                                !!errors?.bank_details?.[index]?.ac_number
                              }
                            />
                            <FormFeedback invalid>
                              {errors.bank_details?.[index]?.ac_number?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Bank Name
                              <span className="mandatory">*</span>
                            </Label>
                            <BankList
                              control={control}
                              placeholder="Enter the Bank Name"
                              name={`bank_details.${index}.bank_name`}
                            />
                            {/* <UFInput
                              control={control}
                              placeholder="Enter the Bank Name"
                              name={`bank_details.${index}.bank_name`}
                              invalid={
                                !!errors?.bank_details?.[index]?.bank_name
                              }
                            /> */}
                            <FormFeedback invalid>
                              {errors.bank_details?.[index]?.bank_name?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              Branch Name
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Branch Name"
                              name={`bank_details.${index}.branch_name`}
                              invalid={
                                !!errors?.bank_details?.[index]?.branch_name
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors.bank_details?.[index]?.branch_name
                                  ?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              IBAN
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the IBAN Name"
                              name={`bank_details.${index}.iban`}
                              invalid={!!errors?.bank_details?.[index]?.iban}
                            />
                            <FormFeedback invalid>
                              {errors.bank_details?.[index]?.iban?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="3" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>
                              SWIFT/BIC Code
                              <span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Swift Code"
                              name={`bank_details.${index}.swift_code`}
                              invalid={
                                !!errors?.bank_details?.[index]?.swift_code
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors.bank_details?.[index]?.swift_code
                                  ?.message
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          {accountFields.length == index + 1 ? (
                            <Button
                              color="secondary"
                              className="addButton mt-2"
                              onClick={() =>
                                accountAppend({
                                  ac_holder_name: "",
                                  ac_number: "",
                                  bank_name: null,
                                  branch_name: "",
                                  iban: "",
                                  swift_code: "",
                                })
                              }
                            >
                              Add Row
                            </Button>
                          ) : (
                            ""
                          )}
                          {accountFields.length > 1 ? (
                            <Button
                              color="primary"
                              className="removeButton mt-2 ms-3"
                              onClick={() => accountRemove(index)}
                            >
                              Delete Row
                            </Button>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditSupplier
