import React, { useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import LoadingButton from "../../../../components/Common/LoadingButton"
import { useNavigate, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import MaterialRequest from "../../../../components/Common/chooseFields/materialrequest"
import PaymentMode from "../../../../components/Common/chooseFields/paymentMode"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
  UFInputDashboard,
} from "../../../../components/Common/Fields/Input"
import SupplierList from "../../../../components/Common/chooseFields/supplier"
import uploadicon from "../../../../assets/icons/export-light.svg"
import lightTrash from "../../../../assets/icons/light-trash.svg"
import {
  fetchPurchase,
  postPurchaseOrder,
} from "../../../../services/procurement/purchaseOrder"
import { fetchRequest } from "../../../../services/procurement/materialrequest"
import { fetchSupplier } from "../../../../services/procurement/supplier"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import Productcode from "../../../../components/Common/chooseFields/Product"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import POMaterialRequest from "../../../../components/Common/chooseFields/poMaterialRequest"
import Unit from "../../../../components/Common/chooseFields/unit"
import PhoneInputField from "../../../../components/Common/Fields/phoneInput"
import moment from "moment"
import { useSelector } from "react-redux"
import Info from "../../../../assets/icons/info.svg"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import { searchProcurmentDashRequest } from "../../../../services/projectManager/projectMaster"
import {
  fetchHrmsEmployee,
  postAssignEmployee,
} from "../../../../services/Hrms/hrms"
import Flatpickr from "react-flatpickr"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import closeIcon from "../../../../assets/icons/close.svg"

const AssignEMployee = ({ fromDashboard, projectDetails, toggle }) => {
  document.title = "Assign Employee | Ever Rise"
  const { action, editId } = useParams()
  const navigate = useNavigate()
  const [assignCheck, setAssignCheck] = useState([])
  const [assignDate, setAssignDate] = useState([])
  const id = fromDashboard ? "" : editId
  const schema = yup.object().shape({
    // material_requests_id: yup.mixed().required("MR no is Required"),
  })

  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  //pagination state
  const [page, setPage] = useState("")
  const [materialRequestData, setMaterialRequestData] = useState()
  const [viewEmployee, setViewEmployee] = useState()

  const calendarRef = useRef(null)

  // const [events, setEvents] = useState([
  //   { id: "1", title: "Event 1", start: "2024-07-08", editable: true },
  //   // { id: "2", title: "Event 2", start: "2024-07-09", editable: true },
  // ])

  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])

  const [activeTab, setActiveTab] = useState("1")

  const [modal, setModal] = useState(false)

  const handleEventDrop = eventDropInfo => {
    // Update the event in state or send it to the server
    const updatedEvents = events.map(event =>
      event.id == eventDropInfo.event.id
        ? { ...event, start: eventDropInfo.event.start.toISOString() }
        : event
    )
    setEvents(updatedEvents)
  }
  const handleEventResize = eventResizeInfo => {
    const updatedEvents = events.map(event =>
      event.id == eventResizeInfo.event.id
        ? {
            ...event,
            start: eventResizeInfo.event.start.toISOString(),
            end: eventResizeInfo.event.end.toISOString(),
          }
        : event
    )
    setEvents(updatedEvents)
  }

  const handleDrageDate = event => {
    console.log(`drag date`, event)

    const updatedEvents = events?.map(e =>
      e?.id == event?.event?.id
        ? {
            ...e,
            start: moment(event.event.start).format("YYYY-MM-DD"),
            end: moment(event.event.end).format("YYYY-MM-DD"),
          }
        : e
    )
    console.log(`drag date`, updatedEvents)
    setEvents(updatedEvents)
  }

  const handleEventDragStart = eventDragInfo => {
    console.log(
      "Event drag started. Start Date:",
      eventDragInfo.event.start.toISOString()
    )
  }

  const handleEventDrag = eventDragInfo => {
    console.log(
      "Event is being dragged. Current Start Date:",
      eventDragInfo.event.start.toISOString()
    )
  }

  const handleEventDragStop = eventDragInfo => {
    console.log(
      "Event drag stopped. Final Start Date:",
      eventDragInfo.event.start.toISOString()
    )
  }

  const handleDateClick = event => {
    // const isDuplicate = events.some(e =>
    //   e.start == event?.startStr
    // )

    // console.log(`event :`, isDuplicate, event)

    // if (!isDuplicate) {
    setEvents([
      ...events,
      {
        id: Number(events[events?.length - 1]?.id) + 1,
        emp_id: viewEmployee?.id,
        title: projectDetails?.project_id?.project_name,
        start: moment(event?.start).format("YYYY-MM-DD"),
        overlap: false,
      },
    ])
    // } else {
    //   toastError("Duplicate event not added")
    // }
  }

  const handleDrop = info => {
    // Handle drop event here, update state with new event
    const newEvent = {
      title: "New Event",
      start: info.dateStr,
      allDay: info.allDay,
    }
    setEvents([...events, newEvent])
  }

  const handleDateSelect = selectInfo => {
    const { start, end } = selectInfo

    // Parse the project details dates as Moment objects
    const startDate = moment(projectDetails?.from_date, "YYYY-MM-DD")
    const endDate = moment(projectDetails?.to_date, "YYYY-MM-DD")

    console.log(
      `selectInfo 1`,
      start,
      end,
      `chl`,
      endDate.format("YYYY-MM-DD"),
      startDate.format("YYYY-MM-DD")
    )

    if (
      moment(start).isSameOrAfter(startDate) &&
      moment(start).isSameOrBefore(endDate)
    ) {
      // Selection is within the allowed range
      handleDateClick(selectInfo)
      return true
    } else {
      // Selection is outside the allowed range
      setModal(true)
      return false
    }
  }

  const toggleAlert = () => {
    setModal(!modal)
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tax: 0,
      hr_status: 0,
      description: "",
      per_day: 0,
    },
  })

  let startDate = moment(projectDetails?.from_date)
  let endDate = moment(projectDetails?.to_date)
  let dates = []

  while (startDate <= endDate) {
    dates.push(startDate.format("YYYY-MM-DD"))
    startDate = startDate.clone().add(1, "days")
  }

  const eventIdArr = []
  const eventDateArr = []
  events?.length &&
    events?.map(dt => dt?.emp_id != undefined && eventIdArr.push(dt?.emp_id))

  events?.length &&
    events?.map(dt => dt?.emp_id != undefined && eventDateArr.push(dt?.start))
  const filteredDateArr = [...new Set(eventDateArr)]
  const filteredIdArr = [...new Set(eventIdArr)]

  useEffect(() => {
    setFilteredEvents(events.filter(event => event.emp_id === viewEmployee?.id))
  }, [events, viewEmployee])

  const handleRegistration = async data => {
    if (assignCheck?.length > Number(projectDetails?.req_manpower_no)) {
      toastError("Assign Employee Exceed Requested Manpower")
    } else if (events?.length) {
      const datesInRange = []

      while (startDate.isSameOrBefore(endDate, "day")) {
        datesInRange.push(startDate.format("YYYY-MM-DD"))
        startDate.add(1, "days")
      }

      const assignedArr = assignDate?.flatMap(dt =>
        dt?.assignedDate?.map(dt => moment(dt).format("YYYY-MM-DD"))
      )

      const uniqueDates = [...new Set(assignedArr)]

      const arrayData = filteredDateArr?.map(dt => {
        const empIds = []
        events.forEach(event => {
          if (event.start === dt && event.emp_id !== undefined) {
            empIds.push(event.emp_id)
          }
        })
        const filteredIds = [...new Set(empIds)]
        return {
          project_id: projectDetails?.project_id?.id,
          date: dt,
          employee_ids: filteredIds,
        }
      })

      try {
        setIsLoading(true)
        const updateDepartment = await postAssignEmployee({
          assignments: arrayData,
          hr_issued: assignCheck?.length,
          manpower_request_id: projectDetails?.id,
          ...(assignCheck?.length ===
            Number(projectDetails?.req_manpower_no) && {
            isu_manpower_no: assignCheck?.length,
          }),
          hr_status: data?.hr_status,
          description: data?.description,
          per_day: data?.per_day,
          status: data?.hr_status == 0 ? 3 : data?.hr_status > 0 ? 4 : 0,
        })
        toggle()
        toastSuccess(updateDepartment?.message)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    } else {
      toastError("Atleast Assign Any One Employee")
    }
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchHrmsEmployee({
        params: `${"&searchText"}${page ? `&page=${page}` : ""}${
          projectDetails?.from_date
            ? `&date_start=${moment(projectDetails?.from_date).format(
                "YYYY-MM-DD"
              )}`
            : ""
        }${
          projectDetails?.to_date
            ? `&date_end=${moment(projectDetails?.to_date).format(
                "YYYY-MM-DD"
              )}`
            : ""
        }${
          projectDetails?.category?.designation?.id
            ? `&designation=${projectDetails?.category?.designation?.id}`
            : ""
        }${
          projectDetails?.project_id?.id
            ? `&project_id=${projectDetails?.project_id?.id}`
            : ""
        }`,
      })
      setMaterialRequestData(response)
      setViewEmployee(response?.data?.data?.[0])
    }
    fetchClass()
  }, [page, projectDetails])

  useEffect(() => {
    setValue("hr_status", 0)
    setEvents([
      ...events,
      {
        id: events?.length + 1,
        emp_id: viewEmployee && viewEmployee?.id,
        title: projectDetails?.project_id?.project_name,
        start: moment(projectDetails?.from_date).format("YYYY-MM-DD"),
      },
    ])
  }, [projectDetails])

  const handleAssignCheck = dt => {
    if (assignCheck?.some(d => d?.id == dt?.id)) {
      const arr = assignCheck?.filter(d => d?.id != dt?.id)
      setAssignCheck(arr)
      if (assignDate?.some(d => d?.id == dt?.id)) {
        const dateArr = assignDate?.filter(d => d?.id != dt?.id)
        setAssignDate(dateArr)
        setValue("date", [])
      }
    } else {
      if (Number(projectDetails?.req_manpower_no) > assignCheck?.length) {
        setAssignCheck(pre => [...pre, dt])
        setAssignDate(prev => [
          ...prev,
          { assignedDate: watch("date"), id: dt?.id },
        ])
      } else {
        toastError("Assign Employee Exceed Requested Manpower")
      }
    }
  }

  const handleViewEmployee = item => {
    setViewEmployee(item)
  }

  const assignColumn = [
    {
      label: "Emp Id",
      renderCell: item => {
        return (
          <div
            onClick={() => handleViewEmployee(item)}
            className={`cursor-pointer ${
              viewEmployee?.id == item?.id && "fw-600 text-14"
            }`}
          >
            {item?.employee_code}
          </div>
        )
      },
    },
    {
      label: "Emp Name",

      renderCell: item => {
        return (
          <div
            className="py-2"
            // onClick={() => handleViewEmployee(item)}
            // className={`cursor-pointer ${
            //   viewEmployee?.id == item?.id && "fw-500 text-16"
            // }`}
          >
            {item?.first_name}
          </div>
        )
      },
    },
    // {
    //   label: "Projects",
    //   renderCell: item => {
    //     const projectNames = item?.projects?.map(dt => dt?.project_name) || []
    //     const uniqueProjectNames = [...new Set(projectNames)]
    //     console.log("uniqueProjectNames :", uniqueProjectNames)
    //     return (
    //       <div>
    //         {uniqueProjectNames.length
    //           ? uniqueProjectNames.map(projectName => (
    //               <span key={projectName}>{projectName}</span>
    //             ))
    //           : "-"}
    //       </div>
    //     )
    //   },
    // },
    {
      label: "Assign",
      renderCell: item => (
        <Col lg={"auto"} onClick={() => handleAssignCheck(item)}>
          <FormGroup className="form-group-margin">
            <input
              type="checkbox"
              className="ever-rise-checkbox"
              name={`is_assign_${item?.id}`}
              checked={assignCheck?.some(dt => dt?.id == item?.id)}
              disabled={filteredIdArr.includes(item.id) ? false : true}
            />
          </FormGroup>
        </Col>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const mainCard = [
    {
      id: 1,
      name: "Project Name",
      value: `${projectDetails?.project_id?.project_name}`,
    },
    {
      id: 2,
      name: "Requested Dates",
      value: `${moment(projectDetails?.from_date).format(
        "DD-MM-YYYY"
      )} to ${moment(projectDetails?.to_date).format("DD-MM-YYYY")}`,
    },
    {
      id: 3,
      name: "Designation",
      value: `${projectDetails?.category?.designation?.name}`,
    },
  ]

  const imgUrl = process.env.REACT_APP_IMG_APIKEY

  // const AlwaysOpenDatePicker = () => {
  const datePickerRef = useRef(null)

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.flatpickr.open()
    }
  }, [])

  const handleDateChange = e => {
    setValue("date", e)
  }

  const handleCalender = e => {
    console.log(`handleCalender`, e)
  }

  const handleForward = async () => {
    const value = getValues()
    if (value?.hr_status <= 0) {
      toastError("Number of Hours must be greater than 0")
    } else {
      try {
        setIsLoading1(true)

        const updateDepartment = await postAssignEmployee({
          manpower_request_id: projectDetails?.id,
          hr_status: value?.hr_status,
          description: value?.description,
          per_day: value?.per_day,
          status: 4,
        })
        toggle()
        toastSuccess(updateDepartment?.message)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading1(false)
      }
    }
  }

  const showTooltip = info => {
    console.log(`showTooltip`, info)
    // const id = info.event.id
    // const title = info.event.title
    // ;<>
    //   <div id={`popover-${id}`} style={{ cursor: "pointer" }}>
    //     {title}
    //   </div>
    //   <UncontrolledPopover
    //     trigger="hover"
    //     placement="top"
    //     target={`popover-${id}`}
    //   >
    //     <PopoverBody>{title}</PopoverBody>
    //   </UncontrolledPopover>
    // </>
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                {fromDashboard ? (
                  <div></div>
                ) : (
                  <Breadcrumbs
                    title="Procurment"
                    breadcrumbObject={[
                      { title: "Procurment", link: "/procurement" },
                      {
                        title: "Purchase Order",
                        link: "/procurement-purchase-order",
                      },
                      {
                        title: id
                          ? "Edit Purchase Order"
                          : "Create Purchase Order",
                        link: id
                          ? `/procurement-purchase-order/edit/${id}`
                          : "/procurement-purchase-order/add",
                      },
                    ]}
                  />
                )}
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4 invoice-main-card-hr">
                  <Row>
                    {mainCard?.map((dt, i) => (
                      <Col md={4} xl={4} sm={4} xs={12}>
                        <Card
                          className={`rounded-0 border-0 procurement-auto-cards `}
                        >
                          <CardBody
                            className={`${
                              dt?.id == 1 && "dark-background"
                            } card-body-dash-${i}`}
                          >
                            <Row className="px-2">
                              <Col className="bold-heading text-12">
                                {dt?.name}
                              </Col>
                            </Row>
                            <Row className="px-2 pt-2">
                              <Col className="extra-dark-text text-16">
                                {dt?.value}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="tab-bg border-rds-round px-2 mb-4 pt-3">
                        <Row className="pb-3">
                          <Col sm={6}></Col>
                          <Col sm={6}>
                            <UFInputDashboard
                              control={control}
                              placeholder="Search"
                              name={"search"}
                            />
                          </Col>
                        </Row>
                        <hr className="m-0" />
                        <Row className="mt-2 ever-rise-table dashboardTable">
                          {materialRequestData?.data && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialRequestData?.data?.data}
                              paginate={materialRequestData?.data}
                              columns={assignColumn}
                              handlePagination={handlePaginate}
                              tableWidth="40% 40% 20%"
                              mobileWidth="40% 40% 20%"
                              noShowCount={true}
                            />
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={8}>
                      {/* <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        weekends={true}
                        editable={true}
                        droppable={true}
                        drop={handleDrop}
                        events={events}
                      /> */}
                      <div className="tab-bg mb-0 px-2 pb-0 tab-emp">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "1"
                                  ? "active pointer py-2"
                                  : "pointer py-2"
                              }
                              onClick={() => {
                                setActiveTab("1")
                              }}
                            >
                              Calender
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "2"
                                  ? "active pointer py-2"
                                  : "pointer py-2"
                              }
                              onClick={() => {
                                setActiveTab("2")
                              }}
                            >
                              Forward
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      {activeTab == "1" ? (
                        <>
                          <div className="tab-bg px-2 mb-0 py-3 mt-3">
                            <h6>Employee Details</h6>
                            <hr className="m-0" />
                            <Row className="py-2">
                              <Col sm={2}>
                                <div className="secondary-text">Name</div>
                                <div className="primary-text">
                                  {viewEmployee?.first_name}
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div className="secondary-text">Emp. Code</div>
                                <div className="primary-text">
                                  {viewEmployee?.employee_code}
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div className="secondary-text">Emp. Type</div>
                                <div className="primary-text">
                                  {
                                    viewEmployee?.personal_details
                                      ?.employee_type_id?.employee_type
                                  }
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div className="secondary-text">Email ID</div>
                                <div className="primary-text">
                                  {viewEmployee?.official_email_id}
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div className="secondary-text">Contact No</div>
                                <div className="primary-text">
                                  +{viewEmployee?.official_contact_number}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr className="m-0 pb-3" />
                          <div className="calender">
                            <FullCalendar
                              plugins={[dayGridPlugin, interactionPlugin]}
                              initialView="dayGridMonth"
                              events={filteredEvents}
                              ref={calendarRef}
                              editable={true}
                              // validRange={{
                              //   start: moment(projectDetails?.from_date).format(
                              //     "YYYY-MM-DD"
                              //   ),
                              //   end: moment(projectDetails?.to_date).format(
                              //     "YYYY-MM-DD"
                              //   ),
                              // }}
                              // eventDrop={handleEventDrop}
                              // eventResize={handleDrageDate}
                              // eventDragStart={handleEventDragStart}
                              // eventDrag={handleEventDrag}
                              // eventDragStop={handleEventDragStop}
                              // dateClick={handleDateClick}
                              create={true}
                              select={handleDateSelect}
                              selectable={true}
                              eventMaxStack={2}
                              // dayMaxEvents={true}
                              eventDidMount={info => {
                                info.el.addEventListener("mouseover", () =>
                                  showTooltip(info)
                                )
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="tab-bg px-2 mb-4 py-3 mt-3">
                          <h6>Forward Details</h6>
                          <hr className="m-0" />
                          <Row className="mb-4 mt-2">
                            <Col sm={4}>
                              <FormGroup>
                                <Label>Forward Hours</Label>
                                <UFInputDashboard
                                  control={control}
                                  placeholder="Forward"
                                  name={"hr_status"}
                                />
                                <FormFeedback invalid>
                                  {errors.hr_status?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <FormGroup>
                                <Label>Labours Per Day</Label>
                                <UFInputDashboard
                                  control={control}
                                  placeholder="Forward"
                                  name={"per_day"}
                                />
                                <FormFeedback invalid>
                                  {errors.per_day?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <FormGroup>
                                <Label>Description</Label>
                                <UFInputDashboard
                                  control={control}
                                  placeholder="Forward"
                                  name={"description"}
                                />
                                <FormFeedback invalid>
                                  {errors.description?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr className="m-0" />
                          <div className="pt-2 text-end">
                            <LoadingButton
                              form={`supplier-form`}
                              color={"primary"}
                              loading={isLoading}
                              disabled={isLoading}
                              className={"px-2 btn btn-primary"}
                              type="submit"
                            >
                              Assign & Forward
                            </LoadingButton>

                            <LoadingButton
                              form={`supplier-form`}
                              color={"primary"}
                              loading={isLoading1}
                              disabled={isLoading1}
                              className={"px-2 ms-2 btn btn-primary"}
                              type="button"
                              onClick={handleForward}
                            >
                              Forward
                            </LoadingButton>
                          </div>
                        </div>
                      )}
                      {/* <div className="tab-bg border-rds-round px-2 mb-4 py-3">
                        <h6> Employee Details</h6>
                        <hr className="m-0" />
                        {viewEmployee ? (
                          <div className="pt-3">
                            <div className="d-flex justify-content-center">
                              <div className="employee-assign-img">
                                <img src={`${imgUrl}${viewEmployee?.photo}`} />
                              </div>
                            </div>
                            <Row className="pt-2">
                              <Col className="assign-light-text text-end">
                                Name :
                              </Col>
                              <Col className="bold-text">
                                {viewEmployee?.first_name}
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col className="assign-light-text text-end">
                                Emp. Code :
                              </Col>
                              <Col className="bold-text">
                                {viewEmployee?.employee_code}
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col className="assign-light-text text-end">
                                Emp. Type :
                              </Col>
                              <Col className="bold-text">
                                {
                                  viewEmployee?.personal_details
                                    ?.employee_type_id?.employee_type
                                }
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col className="assign-light-text text-end">
                                Email ID :
                              </Col>
                              <Col className="bold-text">
                                {viewEmployee?.official_email_id}
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col className="assign-light-text text-end">
                                Contact No :
                              </Col>
                              <Col className="bold-text">
                                {viewEmployee?.official_contact_number}
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col sm={12} className="flatpickr-input-multi">
                                {viewEmployee?.id && (
                                  <Flatpickr
                                    ref={datePickerRef}
                                    control={control}
                                    options={{
                                      mode: "multiple",
                                      dateFormat: "Y-m-d",
                                      inline: true,
                                      onChange: handleDateChange,
                                      enable: dates,
                                    }}
                                    name="date"

                                    // enable={console.log(`dates1`,dates)}
                                    // value={selectedDates}
                                  />
                                )}
                                {/* <UFDate
                                control={control}
                                placeholder="Requested Date"
                                name={"date"}
                                mode="range"
                                // enable={dates}
                                minDate={startDate}
                                maxDate={endDate}
                                // onDateChange={e => handleRequestdate(e)}
                              /> */}
                      {/* </Col>
                            </Row>
                          </div>
                        ) : (
                          <div className="pt-2">No Employee Found</div>
                        )}
                      </div>
                      <div className="tab-bg border-rds-round px-2 mb-4 py-3">
                        <h6> Assigned Details</h6>
                        <hr className="m-0" />
                        <div>
                          {assignCheck?.length ? (
                            assignCheck?.map(dt => (
                              <div className="assign-img mt-3">
                                <img src={`${imgUrl}${dt?.photo}`} />
                              </div>
                            ))
                          ) : (
                            <div className="pt-3">
                              Select Employee To Assign
                            </div>
                          )}
                        </div>
                        <hr className="m-0 mt-5 mb-2" />
                        <Row>
                          <Col sm={6}>Forward</Col>
                          <Col sm={6}>
                            <UFInputDashboard
                              control={control}
                              placeholder="Forward"
                              name={"hr_status"}
                            />
                          </Col>
                        </Row>

                        <div className="pt-2 text-end">
                          <LoadingButton
                            form={`supplier-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            className={"px-2 btn btn-primary"}
                            type="submit"
                          >
                            Assign
                          </LoadingButton>
                        </div>
                      </div> */}
                    </Col>
                    <Col sm={12}>
                      <div className="tab-bg border-rds-round px-2 mb-4 py-3 mt-3 px-3">
                        <h6> Assigned Employees</h6>
                        <hr className="m-0" />
                        <div>
                          {assignCheck?.length ? (
                            assignCheck?.map(dt => (
                              <div className="assign-img my-3">
                                <img src={`${imgUrl}${dt?.photo}`} />
                              </div>
                            ))
                          ) : (
                            <div className="pt-3">
                              Select Employee To Assign
                            </div>
                          )}
                        </div>
                        <hr className="m-0" />
                        <div className="pt-2 text-end">
                          <LoadingButton
                            form={`supplier-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            className={"px-2 btn btn-primary"}
                            type="submit"
                          >
                            Assign
                          </LoadingButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Modal
                  isOpen={modal}
                  toggle={toggleAlert}
                  size="md"
                  unmountOnClose={false}
                >
                  <>
                    <ModalHeader className="custom-modal-header">
                      <div className="d-flex justify-content-between">
                        <h5 className="">
                          {/* {isGenerate && ( */}
                          <span className={"warning-icon"}>
                            <i className={" uil-exclamation-octagon"}></i>{" "}
                          </span>
                          Alert
                          {/* )}
                              {isGenerate ? `Confirmation` : "Edit Attendance"} */}
                        </h5>
                        <img
                          src={closeIcon}
                          alt=""
                          onClick={toggleAlert}
                          width="10"
                          role="button"
                        />
                      </div>
                    </ModalHeader>
                    <ModalBody className="pb-4">
                      <>
                        This is a friendly reminder that you are allowed to
                        assign only between `
                        {moment(projectDetails?.from_date).format("DD-MM-YYYY")}{" "}
                        to{" "}
                        {moment(projectDetails?.to_date).format("DD-MM-YYYY")}`
                      </>
                    </ModalBody>
                    {/* <ModalFooter>
                <LoadingButton
                  form={`leave-form`}
                  color={"primary"}
                  loading={isLoading2}
                  disabled={isLoading2}
                  type="submit"
                >
                  {isGenerate ? "Generate" : "Update"}
                </LoadingButton>
              </ModalFooter> */}
                  </>
                </Modal>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssignEMployee
