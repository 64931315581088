import { Col, Form, Label, Row } from "reactstrap"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import resetIcon from "../../../assets/icons/reset.svg"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import moment from "moment"
import { isEmpty } from "lodash"
import AccountsLi from "../../../components/Common/chooseFields/accountsLi"

const JournalEntryFilter = ({
  handleSearchChange,
  handleDateChange,
  handleAccChange,
}) => {
  const { control, watch, reset } = useForm()
  const handleReset = () => {
    handleDateChange()
    handleSearchChange()
    handleAccChange()
    reset({
      search: "",
      account_code: null,
    })
  }
  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    handleDateChange(fromDate, toDate)
  }

  const handleAccno = data => {
    handleAccChange(data?.value)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 me-2">
        <Form id="user-form" className="filter-wrapper">
          <Row className="mx-0">
            <Col sm={2} className="pe-1">
              <AccountsLi
                control={control}
                name={"account_code"}
                placeholder={"Acc-Code"}
                target={"body"}
                fromFilter={true}
                onSelectChange={e => handleAccno(e)}
              />
            </Col>
            <Col sm={2} className="pe-1">
              <UFDate
                control={control}
                name={"req_date"}
                placeholder={"Request Date"}
                mode={"range"}
                onDateChange={e => handleRequestdate(e)}
              />
            </Col>
          </Row>
        </Form>
        <div className="d-flex">
          {/* <Col
            lg={"auto"}
            onClick={() => handleFinacialCheck(finacialCheck)}
            className=" d-flex align-items-center"
          >
            <input
              type="checkbox"
              className="ever-rise-checkbox"
              name={"is_finacial_year"}
              checked={finacialCheck}
            />
            <Label className="ms-2 mb-0">
              FY {loggedUser?.finance?.replace(/\//g, "")}
            </Label>
          </Col> */}
          <div
            className="export-div pointer me-2 reset-img"
            onClick={() => handleReset()}
          >
            <img src={resetIcon} height={15} width={15} className="me-2" />{" "}
            Reset
          </div>
          <UFInput
            control={control}
            placeholder="Search"
            name={"search"}
            className={"search-input"}
          />
          {/* <div className="export-div pointer" onClick={() => toggle()}>
            <img src={exportIcon} />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JournalEntryFilter
