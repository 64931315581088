import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import { updateSupplierStatus } from "../../../services/procurement/supplier"
import { downloadgrn, searchGRN } from "../../../services/procurement/grn"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { fetchPayablesSearch } from "../../../services/finance/grnCosting"
import AccountReceivableFilter from "./filter"

const AccountPayables = () => {
  document.title = "Account Payables | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [grnData, setGRNData] = useState()
  const [search, setSearch] = useState("")
  const [accCode, setAcc] = useState("")
  const [accType, setAccType] = useState("")
  const [apCode, setAPCode] = useState("")
  const [projectno, setProjectno] = useState("")
  // const [purchaseno, setPurchaseno] = useState("")
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  const [company, setCompany] = useState(false)

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateSupplierStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "AP No",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "accountreceivable_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/finance-account-payable/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.code}
              </div>
            ) : (
              <div>{item?.code}</div>
            )}
          </>
        )
      },
    },
    {
      label: "Account Code",
      renderCell: item => item?.account?.code,
    },
    {
      label: "Project Code",
      renderCell: item =>
        item?.project?.project_code != null ? item?.project?.project_code : "-",
    },
    {
      label: "Procurement/HRMS/Others",
      renderCell: item =>
        item?.procurement?.code
          ? item?.procurement?.code
          : item?.hrms_id
          ? item?.hrms?.code
          : item?.description
          ? item?.description
          : "-",
    },
    {
      label: "Date",
      renderCell: item => moment(item?.due_date).format("DD-MM-YYYY"),
    },
    {
      label: "Type",
      renderCell: item => (
        <div
          className={`${
            item?.account?.type === "payable" ? "text-danger" : "text-success"
          } capitalize-letter`}
        >
          {item?.account?.type}
        </div>
      ),
    },
    {
      label: "Amount",
      renderCell: item =>
        `${Number(Number(item?.amount).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })} AED`,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  useEffect(() => {
    const fetchGRN = async () => {
      const response = await fetchPayablesSearch({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          apCode ? `&code=${apCode}` : ""
        }${accCode ? `&account_code=${accCode}` : ""}${
          projectno ? `&project_code=${projectno}` : ""
        }${accType ? `&type=${accType}` : ""}${
          datefrom ? `&start_date=${datefrom}` : ""
        }${dateto ? `&end_date=${dateto}` : ""}${
          page ? `&page=${page}` : ""
        }${`&financial=${financial}`}${
          company == true ? `&company_search=""` : ""
        }${`&sizePerPage=16`}`,
      })
      setGRNData(response)
    }
    fetchGRN()
  }, [
    refresh,
    search,
    accCode,
    page,
    projectno,
    financial,
    company,
    apCode,
    accType,
    datefrom,
    dateto,
  ])

  const handleAccChange = data => {
    setAcc(data)
    setPage(1)
  }
  const handleAccTypeChange = data => {
    setAccType(data)
    setPage(1)
  }
  const handleAPCodeChange = data => {
    setAPCode(data)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  // const handlePurchaseChange = data => {
  //   setPurchaseno(data)
  //   setPage(1)
  // }
  const handleProjectChange = data => {
    setProjectno(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleCompanyChange = id => {
    setCompany(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Finance"
              breadcrumbObject={[
                { title: "Finance", link: "/finance" },
                {
                  title: "Account Payables",
                  link: "/finance-account-receivable",
                },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "accountreceivable_create"
            ) && (
              <Link
                to="/finance-account-payables/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Account Payables"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <AccountReceivableFilter
              handleSearchChange={handleSearchChange}
              handleAccChange={handleAccChange}
              handleAccTypeChange={handleAccTypeChange}
              handleAPCodeChange={handleAPCodeChange}
              handleDateChange={handleDateChange}
              // handlePurchaseChange={handlePurchaseChange}
              handleProjectChange={handleProjectChange}
              handleFinacialChange={handleFinacialChange}
              handleCompanyChange={handleCompanyChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {grnData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={grnData?.data?.data}
                    paginate={grnData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 10% 20% 15% 15% 10%"
                    mobileWidth="40% 40% 30% 40% 30% 30% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AccountPayables
