import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import UpdateTable from "../../../components/Common/UpdatedTable"
import {
  fetchFinanceInvoices,
  postInvoicePay,
} from "../../../services/finance/finance"
import { FormProvider, useForm } from "react-hook-form"
import closeIcon from "../../../assets/icons/close.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import moment from "moment"
import SupplierList from "../../../components/Common/chooseFields/supplier"
import AccountsGroupLi from "../../../components/Common/chooseFields/accountsGroupLi"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import LoadingButton from "../../../components/Common/LoadingButton"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import FinanceLedgersLi from "../../../components/Common/chooseFields/ledgers"

const schema = yup.object().shape({
  payment_method: yup.mixed().required("Payment Method is required"),
  pay_acc: yup.mixed().required("Payment Account is required"),
  amount: yup
    .string()
    .matches(
      /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
      "Amount must be a non-negative number and cannot be zero"
    )
    .test("max", function (value) {
      const { to_pay } = this.parent
      const { paid_amount } = this.parent
      const validateqty = Number(to_pay) - Number(paid_amount ? paid_amount : 0)
      const isValid = parseInt(value) <= validateqty
      return (
        isValid ||
        this.createError({ message: `Amount must not exceed ${validateqty}` })
      )
    })
    .required("Amount is required"),
})

const InvoiceSearch = ({ fromDashboard }) => {
  document.title = "Invoices | Everise"

  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("1")
  const [page, setPage] = useState("")
  const [search, setSearch] = useState("")
  const [financial, setFinancial] = useState(true)
  const [company, setCompany] = useState(false)
  const [fetchedData, setFetchedData] = useState()

  // modal view
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [refresh, setRefresh] = useState(0)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      payment_method: null,
      pay_acc: null,
    },
  })

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFinanceInvoices({
        params: `${
          activeTab &&
          `&entity_type=${activeTab == 1 ? "suppliers" : "customer"}`
        }${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${`&financial=${financial}`}${
          company == true ? `&company_search=""` : ""
        }${`&sizePerPage=16`}`,
      })
      setFetchedData(response)
    }
    fetchData(activeTab)
  }, [activeTab, search, page, financial, company, refresh])

  const handlePaginate = page => {
    setPage(page)
  }
  const columns = [
    {
      label: "INV No",
      renderCell: item => (
        <div
          onClick={() => navigate(`/finance-invoice/view/${item?.id}`)}
          className="pointer fw-bold"
        >
          {" "}
          {item?.invoice_number}{" "}
        </div>
      ),
    },
    // {
    //   label: "GRN No",
    //   renderCell: item => item?.invoice_number,
    // },
    {
      label: "Supplier",
      renderCell: item => item?.entity?.name,
    },
    {
      label: "Date",
      renderCell: item => item?.invoice_date,
    },
    {
      label: "Amount",
      renderCell: item => item?.total_amount,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === "pending"
                ? "status-inactive"
                : item?.status == "partially_paid"
                ? "status-pending"
                : "status-active"
            }`}
          >
            {item?.status === "pending"
              ? "Pending"
              : item?.status == "partially_paid"
              ? "Partially Paid"
              : "Completed"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <div>
          {item?.status === "pending" || item?.status === "partially_paid" ? (
            <Button
              color={"primary"}
              className="btn btn-primary me-2"
              onClick={() =>
                handleView({
                  id: 1,
                  name: "pay_inv",
                  value: item,
                })
              }
            >
              Pay
            </Button>
          ) : (
            <Button
              color={"primary"}
              className="btn btn-primary me-2"
              onClick={() => navigate(`/finance-invoice/view/${item?.id}`)}
            >
              View
            </Button>
          )}
          {/* <div
            className={`font-13 ${
              item?.status === "pending" ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === "pending" ? "Pending" : "Inactive"}
          </div> */}
        </div>
      ),
    },
  ]

  const handleView = item => {
    setViewData(item)
    toggleModal()
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  useEffect(() => {
    reset({
      id: viewData?.value?.id,
      inv_no: viewData?.value?.invoice_number,
      to_pay: viewData?.value?.total_amount,
      paid_amount: viewData?.value?.paid_amount
        ? viewData?.value?.paid_amount
        : 0,
      amount:
        Number(viewData?.value?.total_amount) -
        Number(viewData?.value?.paid_amount ? viewData?.value?.paid_amount : 0),
      due_date: moment(viewData?.value?.due_date).format("YYYY-MM-DD"),
      supplier_id: {
        label: viewData?.value?.entity?.name,
        value: viewData?.value?.entity?.id,
      },
      pay_acc: null,
      payment_method: null,
    })
  }, [viewData])

  // const handleChange = (name, file) => {
  //   setValue(name, file[0])
  // }

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const createInvoice = await postInvoicePay({
        // type: viewData?.value?.module == "GRN" ? "procurement" : "",
        payment_method: data?.payment_method?.value,
        amount: data?.amount,
        pay_acc: data?.pay_acc?.value,
        id: data?.id,
      })
      toastSuccess(createInvoice?.message)
      toggleModal()
      setRefresh(refresh + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
    }
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <Breadcrumbs
                title="Finance"
                breadcrumbObject={[
                  { title: "Finance", link: "/finance" },
                  {
                    title: "Invoices",
                    link: "/finance-invoices",
                  },
                ]}
              />
            ) : (
              <></>
            )}
            <Link
              to="/finance-invoice/add"
              className={"px-2 mb-3 btn btn-primary"}
            >
              {"+ Add Invoice"}
            </Link>
          </div>

          <Card
            className={`me-2 px-2 pb-2 ${fromDashboard ? "dash-table-bg" : ""}`}
          >
            {/* {fromDashboard ? (
              <div className="px-2 py-3">Transaction History</div>
            ) : (
              <TransferFilter
                handleCCChange={handleCCChange}
                handleTypeChange={handleTypeChange}
                handleSearchChange={handleSearchChange}
                // handleProductChange={handleProductChange}
                handleTHChange={handleTHChange}
                handleFinacialChange={handleFinacialChange}
                handleCompanyChange={handleCompanyChange}
              />
            )} */}
            {/* <hr className="table-divide" /> */}
            <div>
              <Nav tabs className={`${fromDashboard ? "tab-active-bg" : ""}`}>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "1" ? "active pointer py-2" : "pointer py-2"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("1")
                      setPage(1)
                    }}
                  >
                    Purchace Invoice
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "2" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("2")
                      setPage(1)
                    }}
                  >
                    Client Invoice
                  </NavLink>
                </NavItem> */}
              </Nav>

              <TabContent
                activeTab={activeTab}
                className="pt-3 dashboard-update-table"
              >
                <TabPane tabId={activeTab} className="dashboard-table-pan">
                  <Row
                    className={`ever-rise-table ${
                      fromDashboard ? "dashboardTable" : ""
                    } `}
                  >
                    <Col xs={12}>
                      {fetchedData?.data?.data && (
                        <UpdateTable
                          className="mt-5"
                          nodes={fetchedData?.data?.data}
                          paginate={fetchedData?.data}
                          columns={columns}
                          CurrentPage={page}
                          handlePagination={handlePaginate}
                          tableWidth="20% 20% 20% 15% 15% 10%"
                          mobileWidth="40% 40% 40% 40% 40% 20%"
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Card>
          <Modal
            isOpen={modal}
            toggle={toggleModal}
            size={
              viewData?.id == 1 || viewData?.id == 2 || viewData?.id == 3
                ? "lg"
                : "md"
            }
            backdrop={"static"}
            unmountOnClose={false}
          >
            <>
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="ms-1">
                    {viewData?.id == 1 ? "Purchase Invoice" : ""}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggleModal}
                    width="10"
                    role="button"
                  />
                </div>
              </ModalHeader>
              {console.log(`viewData :`, viewData)}
              <ModalBody>
                <FormProvider>
                  <Form
                    id={`ev-form`}
                    onSubmit={handleSubmit(handleRegistration)}
                  >
                    <Row>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>INV No</Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Category"
                            name={"inv_no"}
                            invalid={!!errors.inv_no}
                            disabled={true}
                          />
                          <FormFeedback invalid>
                            {errors.inv_no?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {/* <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Total Amount<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Category"
                            name={"amount"}
                            invalid={!!errors.amount}
                            disabled={true}
                          />
                          <FormFeedback invalid>
                            {errors.amount?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>Due Date</Label>
                          <UFDate
                            control={control}
                            placeholder="Select Date"
                            name={"due_date"}
                            invalid={!!errors.due_date}
                            disableCondition={true}
                          />
                          <FormFeedback invalid>
                            {errors.due_date?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>Supplier</Label>
                          <SupplierList
                            control={control}
                            placeholder="select"
                            name={"supplier_id"}
                            invalid={!!errors.supplier_id}
                            target=".modal"
                            disabled={true}
                          />
                          <FormFeedback invalid>
                            {errors.supplier_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Payment Mode<span className="mandatory">*</span>
                          </Label>
                          <PaymentMode
                            control={control}
                            placeholder="select"
                            name={"payment_method"}
                            invalid={!!errors.payment_method}
                            target=".modal"
                          />
                          <FormFeedback invalid>
                            {errors.payment_method?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Payment Account<span className="mandatory">*</span>
                          </Label>
                          <FinanceLedgersLi
                            control={control}
                            name={"pay_acc"}
                            placeholder={"Acc-Code"}
                            target={".modal"}
                            // disabled={true}
                          />
                          <FormFeedback invalid>
                            {errors.pay_acc?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {viewData?.value?.payments?.length ? (
                      <>
                        <div className="mb-2">
                          <h4>Transactions</h4>
                        </div>
                        <table className="estimation-table mb-4">
                          <thead>
                            <tr>
                              <th>Transaction</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewData?.value?.payments?.map((item, i) => (
                              <tr key={`transactions_${i}`}>
                                <td className="ps-2">
                                  #{i + 1}{" "}
                                  <span className="fs-13">{`(Paid on - ${moment(
                                    item?.created_at
                                  ).format("DD-MM-YYYY")})`}</span>
                                </td>
                                <td className="ps-2">{item?.amount} AED</td>
                              </tr>
                            ))}
                            <tr className="total-tbl-bg fw-600">
                              <td className="ps-2 text-end pe-3">Total Paid</td>
                              <td className="ps-2">
                                {viewData?.value?.paid_amount} AED
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col sm={12}>
                        <Row className="mb-3">
                          <Col sm={3}>
                            <div className="pt-2 text-end">Total Amount</div>
                          </Col>
                          <Col sm={3}>
                            <FormGroup>
                              <UFInput
                                control={control}
                                placeholder="Enter the Category"
                                name={"to_pay"}
                                invalid={!!errors.to_pay}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          {/* </Row> */}
                          {/* <Row className="mb-3">
                          <Col sm={8}>
                            <div className="pt-2 text-end">Paid Amount</div>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <UFInput
                                control={control}
                                placeholder="Enter the Category"
                                name={"paid_amount"}
                                invalid={!!errors.paid_amount}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row> */}
                          {/* <Row className="mb-3"> */}
                          <Col sm={3}>
                            <div className="pt-2 text-end">
                              Pay<span className="mandatory">*</span>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <FormGroup>
                              <UFInput
                                control={control}
                                placeholder="Enter the Category"
                                name={"amount"}
                                invalid={!!errors.amount}
                              />
                              <FormFeedback invalid>
                                {errors.amount?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>Description</Label>
                          <UFEditor
                            control={control}
                            placeholder="Enter the Description"
                            rows="5"
                            name={"description"}
                            invalid={!!errors.description}
                          />
                          <FormFeedback invalid>
                            {errors.description?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                      {/* <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Document<span className="mandatory">*</span>
                          </Label>
                          <SingleFileInput
                            accept=".png,.jpg,.jpeg,.svg,.pdf"
                            name={`transaction_doc`}
                            label="Upload File"
                            onlyIcon={true}
                            tooltip="Document Upload"
                            handleChange={handleChange}
                            // downloadPath={certificateSts}
                          />
                          <FormFeedback invalid>
                            {errors.document?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </Form>
                </FormProvider>
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  form={`ev-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  type="submit"
                >
                  Save
                </LoadingButton>
              </ModalFooter>
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoiceSearch
