import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import lightTrash from "../../../assets/icons/light-trash.svg"
import moment from "moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { fetchPurchase } from "../../../services/procurement/purchaseOrder"
import { postGRN } from "../../../services/procurement/grn"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import POPRoducts from "../../../components/Common/chooseFields/poproducts"
import GRNPurchaseNo from "../../../components/Common/chooseFields/grnPurchaseNo"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import FinanceLedgersLi from "../../../components/Common/chooseFields/ledgers"
import {
  fetchFinanceVoucherSingle,
  postFinancePOVoucher,
  postFinanceVoucher,
} from "../../../services/finance/finance"
import VoucherTypes from "../../../components/Common/chooseFields/voucherTypes"
import ProductList from "../../../components/Common/chooseFields/productList"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import closeIcon from "../../../assets/icons/close.svg"
import PurchaseOrder from "../../Procurement/purchaseOrder"
import POMaterialRequest from "../../../components/Common/chooseFields/poMaterialRequest"
import {
  fetchRequest,
  fetchRequestPOvoucher,
} from "../../../services/procurement/materialrequest"
import SupplierList from "../../../components/Common/chooseFields/supplier"
import MaterialRequest from "../../../components/Common/chooseFields/materialrequest"
import VoucherEntryLi from "../../../components/Common/chooseFields/voucherEntryLi"
import VoucherEntrySearch from "."

const EditVoucherEntry = ({ fromDashboard, poValue, toggle }) => {
  const navigate = useNavigate()
  const { editId } = useParams()
  const id = fromDashboard ? "" : editId
  const [isLoading, setIsLoading] = useState(false)
  const [idPO, setIdPO] = useState()
  const [responseData, setResponseData] = useState()
  const [vatCheck, setVatCheck] = useState(false)
  const [discountCheck, setDiscountCheck] = useState(false)
  const [discountAfterCheck, setDiscountAfterCheck] = useState(false)
  const [shippingCheck, setShippingCheck] = useState(false)
  const [othersCheck, setOthersCheck] = useState(false)
  const [modal, setModal] = useState(false)
  const [poData, setPoData] = useState()
  const [projectId, setProjectID] = useState()
  const [viewData, setViewData] = useState()
  const [voucherId, setVoucherId] = useState()
  const [materialData, setMaterialData] = useState()

  console.log(`materialData: `, materialData)

  const toggleSelect = () => setModal(!modal)

  const schema = yup.object().shape({
    voucher_id: yup.mixed().required("Voucher Type is Required"),
    po_date: yup.mixed().required("Date is Required"),
    ...((voucherId == 1 ||
      voucherId == 3 ||
      voucherId == 6 ||
      voucherId == 8) && {
      voucher_contra: yup.array().of(
        yup.object().shape({
          account_id: yup.mixed().required("Particulars is Required"),
          debit: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Debit Amount must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Debit Amount is required"),
          credit: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Credit Amount must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Credit Amount is required"),
        })
      ),
    }),
    ...(voucherId == 16 && {
      credit_id: yup.mixed().required("Party Name is Required"),
      address: yup.string().required("Address is Required"),
      voucher_materials: yup.array().of(
        yup.object().shape({
          product_id: yup.mixed().required("Product Code is Required"),
          qnt: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,2})?$/,
              "Quantity must be a number with up to 4 decimal places"
            )
            .required("Quantity is required"),
          unit_price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Unit Price must be a number with up to 4 decimal places"
            )
            .required("Unit Price is required"),
        })
      ),
    }),
    ...(voucherId == 15 && {
      credit_id: yup.mixed().required("Party Name is Required"),
      debit_id: yup.mixed().required("Purchase Ledger is Required"),
    }),
    // payable_account: yup.mixed().required("Purchase Ledger is required"),
    //   recieved_date: yup
    //     .mixed()
    //     .test(
    //       "is-future-date",
    //       "Make sure the received date is forward-looking",
    //       function (value) {
    //         if (!value) return true
    //         const currentDate = moment()
    //         const selectedDate = moment(value)
    //         return selectedDate.isSameOrBefore(currentDate, "day")
    //       }
    //     )
    //     .required("Received date is required"),
    //   grnproductlist: yup.array().of(
    //     yup.object().shape({
    //       product_code: yup.mixed().required("product code is required"),
    //       description: yup.string().required("description is required"),
    //       unit: yup.mixed().required("Unit is Required"),
    //       received_quantity: yup
    //         .string()
    //         .matches(
    //           /^\d*(\.\d+)?$/,
    //           "Pending quantity must be a non-negative number"
    //         )
    //         .test(
    //           "max",
    //           "pending quantity must not exceed the ordered quantity",
    //           function (value) {
    //             const { pending_quantity } = this.parent
    //             return parseInt(value) <= pending_quantity
    //           }
    //         )
    //         .required("pending quantity is required"),
    //     })
    //   ),
  })

  const location = useLocation()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      po_id: null,
      other_charge: 0,
      shipping_charge: 0,
      voucher_materials: [
        {
          qnt: "",
          product_id: null,
          unit_price: "",
          sub_price: "",
          unit: null,
          id: "",
        },
      ],
      voucher_purchase: [
        {
          qnt: "",
          product_id: null,
          unit_price: "",
          sub_price: "",
          unit: null,
          id: "",
        },
      ],
      voucher_receipt: [
        {
          qnt: 0,
          product_id: null,
          unit_price: "",
          sub_price: "",
          unit: null,
          id: "",
        },
      ],
      voucher_contra: [
        {
          account_id: null,
          debit: 0,
          credit: 0,
        },
      ],
    },
  })
  useEffect(() => {
    if (watch("voucher_id")) {
      setVoucherId(watch("voucher_id")?.value)
    }
  }, [watch("voucher_id")])
  const {
    fields: poFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "grnproductlist",
  })
  const watchTest = useWatch({
    control,
    name: "grnproductlist",
    defaultValue: poFields,
  })
  useEffect(() => {
    if (location?.state && !poValue) {
      reset({
        po_id: location?.state?.purchase_orders_code && {
          label: location?.state?.purchase_orders_code,
          value: location?.state?.id,
        },
      })

      fetchPotData(location?.state?.id)
      setIdPO(location?.state?.id)
    }
  }, [location])

  useEffect(() => {
    if (fromDashboard) {
      reset({
        po_id: poValue?.value && {
          label: poValue?.label,
          value: poValue?.value,
        },
      })

      handlePoChange(poValue)
    }
  }, [fromDashboard])

  useEffect(() => {
    const fetchEditData = async id => {
      const response = await fetchFinanceVoucherSingle(id)
      let materialDataArr
      if (response?.data?.voucher_id == 15) {
        materialDataArr = response?.data?.purchasevoucher?.map(dt => ({
          id: dt?.id,
          product_id: {
            label: dt?.product?.product_code,
            value: dt?.product?.id,
          },
          unit: {
            label: dt?.product?.unit?.unit_code,
            value: dt?.product?.unit?.id,
          },
          description: dt?.product?.description,
          ordered_quantity:
            dt?.voucher_from == 1 ? dt?.grn_quantity : dt?.po_quantity,
          qnt: dt?.qnt,
          unit_price: dt?.unit_price,
          sub_price: Number(dt?.requested_quantity) * Number(dt?.unit_price),
        }))
      } else if (response?.data?.voucher_id == 16) {
        materialDataArr = response?.data?.purchaseorder?.materials?.map(dt => ({
          id: dt?.id,
          product_id: {
            label: dt?.product?.product_code,
            value: dt?.product?.id,
          },
          unit: {
            label: dt?.product?.unit?.unit_code,
            value: dt?.product?.unit?.id,
          },
          description: dt?.product?.description,
          qnt: dt?.po_quantity,
          unit_price: dt?.unit_price,
          sub_price: Number(dt?.po_quantity) * Number(dt?.unit_price),
        }))
      } else if (response?.data?.voucher_id == 7) {
        materialDataArr = response?.data?.recipt_note?.materials?.map(dt => ({
          id: dt?.id,
          product_id: {
            label: dt?.product?.product_code,
            value: dt?.product?.id,
          },
          unit: {
            label: dt?.product?.unit?.unit_code,
            value: dt?.product?.unit?.id,
          },
          description: dt?.product?.description,
          po_quantity: dt?.po_quantity,
          qnt: dt?.grn_quantity,
          unit_price: dt?.unit_price,
          sub_price: Number(dt?.po_quantity) * Number(dt?.unit_price),
        }))
      } else {
        materialDataArr = response?.data?.details?.map(dt => ({
          product_id: {
            label: dt?.product?.product_code,
            value: dt?.product?.id,
          },
          description: dt?.product?.description,
          qnt: dt?.qnt,
          unit_price: dt?.unit_price,
        }))
      }
      let contraArr = response?.data?.journal_entry?.journal_entry_details?.map(
        dt => ({
          account_id: {
            label: dt?.ledger?.name,
            value: dt?.ledger?.id,
          },
          debit: dt?.debit_amount,
          credit: dt?.credit_amount,
        })
      )

      const journalEntryDetails =
        response?.data?.journal_entry?.journal_entry_details || []

      const hasAccountId3 = journalEntryDetails.some(
        detail => detail?.account_id == 3
      )
      const hasAccountId4 = journalEntryDetails.some(
        detail => detail?.account_id == 4
      )

      const shippingCharge = hasAccountId3
        ? journalEntryDetails.find(detail => detail?.account_id == 3)
            ?.debit_amount || 0
        : 0
      const otherCharge = hasAccountId3
        ? journalEntryDetails.find(detail => detail?.account_id == 4)
            ?.debit_amount || 0
        : 0

      setShippingCheck(hasAccountId3)
      setOthersCheck(hasAccountId4)
      setVatCheck(response?.data?.vat != 0 ? true : false)
      setDiscountCheck(response?.data?.discount != 0 ? true : false)
      reset({
        voucher_id: {
          label: response?.data?.voucher_type?.voucher_type,
          value: response?.data?.voucher_id,
          voucher_type: response?.data?.voucher_type?.voucher_type,
        },
        material_request_id: {
          label: response?.data?.purchaseorder
            ? response?.data?.purchaseorder?.material_request?.code
            : response?.data?.recipt_note?.purchase_order?.voucher_entry
                ?.ref_number,
          value: response?.data?.purchaseorder
            ? response?.data?.purchaseorder?.material_request?.id
            : response?.data?.recipt_note?.purchase_order?.voucher_entry?.id,
        },
        purchase_order_id: {
          label:
            response?.data?.recipt_note?.purchase_order?.voucher_entry
              ?.ref_number,
          value: response?.data?.recipt_note?.purchase_order?.voucher_entry?.id,
        },
        request_date: response?.data?.purchaseorder?.date,
        exp_Delivery_date: response?.data?.purchaseorder?.exp_Delivery_date,
        address: response?.data?.purchaseorder?.address,
        narration: response?.data?.narration,
        po_date: response?.data?.date,
        discount: response?.data?.discount,
        shipping_charge: shippingCharge,
        other_charge: otherCharge,
        voucher_materials: materialDataArr,
        voucher_purchase: materialDataArr,
        voucher_receipt: materialDataArr,
        voucher_contra: contraArr,
        credit_id: {
          label: response?.data?.credit?.name,
          value: response?.data?.credit?.id,
        },
        debit_id: {
          label: response?.data?.debit?.name,
          value: response?.data?.debit?.id,
        },
      })
    }

    if (editId) {
      fetchEditData(editId)
    }
  }, [editId])

  const {
    fields: materialFields,
    append: materialListAppend,
    remove: materialListRemove,
  } = useFieldArray({
    control,
    name: "voucher_materials",
  })
  const {
    fields: purchaseFields,
    append: purchaseListAppend,
    remove: purchaseListRemove,
  } = useFieldArray({
    control,
    name: "voucher_purchase",
  })

  const {
    fields: receiptFields,
    append: receiptListAppend,
    remove: receiptListRemove,
  } = useFieldArray({
    control,
    name: "voucher_receipt",
  })
  const {
    fields: contraFields,
    append: contraListAppend,
    remove: contraListRemove,
  } = useFieldArray({
    control,
    name: "voucher_contra",
  })

  const handleRegistration = async data => {
    console.log(`handleRegistration data :`, data)
    let productArray = []
    if (data?.voucher_id?.value == 15) {
      productArray = data?.voucher_purchase?.map(e => ({
        product_id: editId ? e?.id : "",
        material_id: e?.product_id?.value,
        qnt: e?.qnt,
        unit_price: e?.unit_price,
        requested_quantity: e?.requested_quantity,
        voucher_from: e?.voucher_from,
        po_quantity: e?.po_quantity,
        grn_quantity: e?.grn_quantity,
      }))
    } else if (data?.voucher_id?.value == 7) {
      productArray = data?.voucher_receipt?.map(e => ({
        product_id: editId ? e?.id : "",
        material_id: e?.product_id?.value,
        qnt: e?.qnt,
        unit_price: e?.unit_price,
        requested_quantity: e?.requested_quantity,
        po_quantity: e?.po_quantity,
      }))
    } else if (data?.voucher_id?.value == 16) {
      // const result = data?.voucher_materials.map(voucherItem => {
      //   const material = materialData?.data?.materials.find(
      //     mat => mat.id == voucherItem.id
      //   )

      //   console.log(`re :`, material, voucherItem)

      //   if (material) {
      //     const balance = material.requested_quantity - voucherItem.qnt

      //     if (balance > 0) {
      //       return {
      //         qnt: balance,
      //         unit_price: voucherItem.unit_price,
      //         material_id: material?.material_id,
      //       }
      //     }
      //   }

      //   return null // No balance required for matching quantities
      // })

      // // Filter out entries with null values (no balance needed)
      // const filtered = result.filter(item => item !== null)
      // console.log(`re :`, result, filtered)

      productArray = data?.voucher_materials?.map(e => ({
        product_id: editId ? e?.id : "",
        material_id: e?.product_id?.value,
        qnt: e?.qnt,
        requested_quantity: e?.requested_quantity,
        unit_price: e?.unit_price,
      }))
      // productArray = [...productArrayVal, ...filtered]
    }
    let valueArray = []
    if (
      data?.voucher_id?.value == 1 ||
      data?.voucher_id?.value == 3 ||
      data?.voucher_id?.value == 6 ||
      data?.voucher_id?.value == 8
    ) {
      valueArray = data?.voucher_contra?.map(e => ({
        account_id: e?.account_id?.value,
        debit: e?.debit,
        credit: e?.credit,
      }))
    }
    console.log(`re ::`, productArray)

    try {
      setIsLoading(true)
      const update = await postFinanceVoucher({
        ...(productArray?.length && { product_list: productArray }),
        ...(valueArray?.length && { entries: valueArray }),
        material_request_id: data?.material_request_id?.id
          ? data?.material_request_id?.id
          : data?.material_request_id?.value
          ? data?.material_request_id?.value
          : data?.material_request_id,
        purchase_order_id: data?.purchase_order_id?.value ? data?.purchase_order_id?.value : data?.purchase_order_id,
        address: data?.address,
        date: moment(data?.po_date).format("YYYY-MM-DD"),
        exp_Delivery_date: moment(data?.exp_Delivery_date).format("YYYY-MM-DD"),
        project_id: projectId ? projectId : data?.project_id,
        voucher_id: data?.voucher_id?.value,
        credit_id: data?.credit_id?.value,
        debit_id: data?.debit_id?.value,
        voucher_type: data?.voucher_id?.voucher_type,
        date: moment(data?.po_date).format("YYYY-MM-DD"),
        narration: data?.narration,
        discount: !discountCheck ? 0 : data?.discount ? data?.discount : 0,
        vat_charge: vatCheck ? loggedUser?.settings?.tax : 0,
        shipping_charge: shippingCheck ? data?.shipping_charge : 0,
        other_charge: othersCheck ? data?.other_charge : 0,
        ...(data?.project_id && { project_id: data?.project_id }),
        ...(editId && { id: editId }),
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/finance-voucher")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }
  const handlePoChange = () => {
    const { po_id } = getValues()
    fetchPotData(po_id?.value)
    setIdPO(po_id?.value)
  }

  const handleVoucherType = e => {
    reset({
      voucher_id: {
        label: e?.label,
        value: e?.value,
        voucher_type: e?.voucher_type,
      },
    })
  }

  const handleDebitValue = (e, i) => {
    setValue(`voucher_contra.${i}.credit`, 0)
  }
  const handleCreditValue = (e, i) => {
    setValue(`voucher_contra.${i}.debit`, 0)
  }

  const fetchPotData = async idData => {
    const response = await fetchPurchase(idData)
    setResponseData(response?.data)
    if (idData == response.data?.id) {
      const arrayData = response.data?.purchaseorder_lists?.map(dt => {
        const TotalReceivedQty = dt.grnlist.reduce(
          (total, grnqty) => total + parseFloat(grnqty.received_quantity),
          0
        )

        const alreadyReceived = dt.grnlist.length
          ? dt.quantity - TotalReceivedQty
          : 0
        const Received = dt.grnlist.length ? alreadyReceived : dt.quantity

        return {
          product_code: dt?.product_code && {
            label: dt?.product_code.product_code,
            value: dt?.product_code.id,
          },
          description: dt.description,
          ordered_quantity: dt?.quantity,
          received_quantity: dt?.quantity - Received,
          pending_quantity: Received,
          unit: {
            value: dt?.unit?.id,
            label: dt?.unit?.unit_code,
          },
        }
      })

      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_id: response.data?.project_id,
        // material_request_id: response?.data?.material_request_id,
        // po_date: moment(response.data?.created_at).format("DD-MM-YYYY"),
        // mr_no: response.data?.material_request_id?.material_requests_code,
        // mr_date: moment(response.data?.material_request_id?.created_at).format(
        //   "DD-MM-YYYY"
        // ),
        // supplier_code: response.data?.supplier?.suppliers_code,
        // supplier_name: response.data?.supplier?.name,
        grnproductlist: arrayData,
        // pay_terms: response.data?.pay_terms,
        // recieved_date: moment().format("YYYY-MM-DD"),
      })
    }
  }

  const handleCompanyProduct = (e, index) => {
    setValue(`voucher_materials.${index}.description`, e?.description)
    setValue(`voucher_materials.${index}.unit`, {
      label: e?.unit?.unit_code,
      value: e?.unit?.id,
    })
    setValue(`voucher_materials.${index}.unit_price`, e?.price_aed)
  }

  const handleSubPrice = (e, i) => {
    const singlePrice = watch(`voucher_materials.${i}.unit_price`)
    const subPrice =
      Number(watch(`voucher_materials.${i}.qnt`)) * Number(singlePrice)
    setValue(`voucher_materials.${i}.sub_price`, subPrice)
  }

  const handleSubPricePurchase = (e, i) => {
    const singlePrice = watch(`voucher_purchase.${i}.unit_price`)
    const subPrice =
      Number(watch(`voucher_purchase.${i}.qnt`)) * Number(singlePrice)
    setValue(`voucher_purchase.${i}.sub_price`, subPrice)
  }

  const handleVatCheck = dt => {
    setVatCheck(!dt)
  }
  const handleDiscountCheck = dt => {
    setDiscountCheck(!dt)
  }
  const handleDiscountAfterCheck = dt => {
    setDiscountAfterCheck(!dt)
  }
  const handleShippingCheck = dt => {
    setShippingCheck(!dt)
    setValue("shipping_charge", 0)
  }
  const handleOtherCheck = dt => {
    setOthersCheck(!dt)
    setValue("other_charge", 0)
  }

  const calculation = type => {
    const productWatch =
      watch(
        watch(`voucher_id`)?.value == 15
          ? "voucher_purchase"
          : "voucher_materials"
      ) || []
    const taxWatch = vatCheck ? Number(loggedUser?.settings?.tax) : 0
    const discountWatch = Number(watch("discount")) || 0
    const otherChargeWatch = Number(watch("other_charge")) || 0
    const shippingChargeWatch = Number(watch("shipping_charge")) || 0

    switch (type) {
      case "price": {
        const price =
          productWatch?.reduce((a, b) => Number(b.unit_price) + a, 0) || 0
        return Number(price).toFixed(4)
      }
      case "quantity": {
        return productWatch?.reduce((a, b) => Number(b.qnt) + a, 0)
      }
      case "sub_total": {
        const products = productWatch || [] // Ensure productWatch is an array
        const subTotal = products.reduce((acc, product) => {
          const price = Number(product.unit_price)
          const quantity = Number(product.qnt)
          const rowTotal = price * quantity
          return acc + rowTotal
        }, 0)
        return Number(subTotal).toFixed(4)
      }
      case "discounted_amount": {
        const subTotal = calculation("sub_total")
        const discountedAmount = subTotal - (subTotal * discountWatch) / 100
        return Number(discountedAmount).toFixed(4)
      }
      case "tax_total": {
        const baseAmount = discountCheck
          ? calculation("discounted_amount")
          : calculation("sub_total")

        const totalBeforeTax =
          baseAmount + otherChargeWatch + shippingChargeWatch
        const taxAmount = (totalBeforeTax * taxWatch) / 100
        return Number(taxAmount).toFixed(4)
      }
      case "total_amount": {
        const baseAmount = discountCheck
          ? calculation("discounted_amount")
          : calculation("sub_total")

        const totalBeforeTax =
          baseAmount + otherChargeWatch + shippingChargeWatch
        const taxAmount = (totalBeforeTax * taxWatch) / 100
        const totalAmount = Number(totalBeforeTax) + Number(taxAmount)
        return Number(totalAmount).toFixed(4)
      }

      default:
        return 0
    }
  }

  const handleMatrial = async e => {
    const previousDatas = getValues()

    const response = await fetchRequestPOvoucher(e)
    setMaterialData(response)
    setProjectID(response?.data?.project?.id)
    // const projectVar = response?.data?.project?.var_value

    const Requestarray = response.data?.materials?.map(dt => ({
      id: dt?.id,
      product_id: {
        value: dt?.product?.id,
        label: dt?.product?.product_code,
      },
      description: dt?.product?.description,
      qnt: dt?.requested_quantity,
      unit_price: dt?.product?.price_aed,
      requested_quantity: dt?.requested_quantity,
      unit: {
        value: dt?.unit?.id,
        label: dt?.unit?.unit_code,
      },
      // short_supplier: supplierMap,
      sub_price:
        Number(dt?.requested_quantity) * Number(dt?.product?.price_aed),
    }))

    // if (e == response.data?.id) {
    //   const Requestarray = response.data?.materials
    //     ?.map(dt => {
    //       const poFilter = dt.po_list.filter(dt => dt?.po_id?.status == 0)
    //       const projectMrQuantitySum = poFilter.reduce(
    //         (total, mr) => total + parseFloat(mr.quantity),
    //         0
    //       )
    //       const alreadyReceived = dt.po_list.length
    //         ? dt.quantity - projectMrQuantitySum
    //         : dt.quantity

    //       if (alreadyReceived > 0) {
    //         // setValue("sub_total", calculation("sub_total"))

    //         const quantity = dt.po_list.length ? alreadyReceived : dt.quantity
    //         const supplierList = dt?.unique_suppliers
    //         const shortSupplier =
    //           supplierList?.length > 3
    //             ? supplierList?.slice(0, 3)
    //             : supplierList
    //         const supplierMap = shortSupplier?.map(dt => dt)
    //         return {
    //           id: dt?.id,
    //           product_id: {
    //             value: dt?.product?.id,
    //             label: dt?.product?.product_code,
    //           },
    //           description: dt?.description,
    //           qnt: quantity,
    //           unit_price: (dt?.product?.price_aed * projectVar) / 100,
    //           initial_quantity: dt?.quantity,
    //           received_quantity: alreadyReceived,
    //           unit: {
    //             value: dt?.unit?.id,
    //             label: dt?.unit?.unit_code,
    //           },
    //           short_supplier: supplierMap,
    //           sub_price:
    //             Number(quantity) *
    //             Number((dt?.product?.price_aed * projectVar) / 100),
    //         }
    //       }
    //     })
    //     .filter(item => item !== undefined)

    //   const purchaseArr = response?.data?.requests?.map(dt => ({
    //     product_code: {
    //       value: dt?.product?.id,
    //       label: dt?.product?.product_code,
    //     },
    //     description: dt?.product?.description,
    //     qnt: dt?.quantity,
    //     discount: dt?.product?.discount,
    //     unit_price: dt?.product?.price,
    //     unit: {
    //       value: dt?.unit?.id,
    //       label: dt?.unit?.unit_code,
    //     },
    //     sub_price: Number(dt?.quantity) * Number(dt?.product?.price),
    //   }))
    reset({
      ...previousDatas,
      request_date: response?.data?.request_date,
      exp_Delivery_date: response?.data?.exp_Delivery_date,
      voucher_materials: response?.data?.materials?.length
        ? Requestarray
        : [
            {
              product_code: null,
              description: "",
              quantity: "",
              discount: "",
              price: "",
              unit: null,
            },
          ],
    })
  }

  const handlePoReference = async e => {
    const previousDatas = getValues()

    console.log(`previousDatas`, previousDatas)

    const response = await fetchFinanceVoucherSingle(e)
    const materialDataArr = response?.data?.purchaseorder?.materials?.map(
      dt => ({
        id: dt?.id,
        product_id: {
          label: dt?.product?.product_code,
          value: dt?.product?.id,
        },
        unit: {
          label: dt?.product?.unit?.unit_code,
          value: dt?.product?.unit?.id,
        },
        description: dt?.product?.description,
        requested_quantity: dt?.requested_quantity,
        po_quantity: dt?.po_quantity,
        qnt: dt?.po_quantity,
        unit_price: dt?.unit_price,
      })
    )
    reset({
      ...previousDatas,
      // material_request_id: response?.data?.purchaseorder?.material_request_id,
      // purchase_order_id: response?.data?.purchaseorder?.id,
      project_id: response?.data?.project_id,
      voucher_receipt: response?.data?.purchaseorder?.materials?.length
        ? materialDataArr
        : [
            {
              product_code: null,
              description: "",
              quantity: "",
              discount: "",
              price: "",
              unit: null,
            },
          ],
    })
  }

  const handleView = item => {
    setViewData(item)
    toggleSelect()
  }

  const handlePOSelect = async item => {
    setPoData(item)
    const previousDatas = getValues()
    // let materialDataArr = item?.purchaseorder_lists?.map(dt => ({
    //   product_id: {
    //     label: dt?.product_code?.product_code,
    //     value: dt?.product_code?.id,
    //   },
    //   description: dt?.product_code?.description,
    //   qnt: dt?.quantity,
    //   unit_price: dt?.price,
    //   unit: { label: dt?.unit?.unit_code, value: dt?.unit?.id },
    // }))
    // reset({
    //   ...previousDatas,
    //   credit_id: {
    //     label: item?.supplier?.ledger?.name,
    //     value: item?.supplier?.ledger?.id,
    //   },
    //   voucher_materials: materialDataArr,
    //   project_id: item?.project_master?.id,
    // })
    const response = await fetchFinanceVoucherSingle(item?.id)
    let dataMap =
      response?.data?.purchaseorder != null
        ? response?.data?.purchaseorder
        : response?.data?.recipt_note

    let voucherFrom = response?.data?.purchaseorder != null ? 0 : 1

    const materialDataArr = dataMap?.materials?.map(dt => ({
      id: dt?.id,
      product_id: {
        label: dt?.product?.product_code,
        value: dt?.product?.id,
      },
      voucher_from: voucherFrom,
      unit: {
        label: dt?.product?.unit?.unit_code,
        value: dt?.product?.unit?.id,
      },
      description: dt?.product?.description,
      requested_quantity: dt?.requested_quantity,
      grn_quantity: dt?.grn_quantity,
      po_quantity: dt?.po_quantity,
      qnt:
        response?.data?.voucher_id == 16 ? dt?.po_quantity : dt?.grn_quantity,
      unit_price: dt?.unit_price,
      ordered_quantity:
        response?.data?.voucher_id == 16 ? dt?.po_quantity : dt?.grn_quantity,
      sub_price:
        response?.data?.voucher_id == 16
          ? Number(dt?.po_quantity) * Number(dt?.unit_price)
          : Number(dt?.grn_quantity) * Number(dt?.unit_price),
    }))

    reset({
      ...previousDatas,
      material_request_id: response?.data?.purchaseorder?.material_request_id,
      purchase_order_id: response?.data?.purchaseorder?.id,
      project_id: response?.data?.project_id,
      voucher_purchase: dataMap?.materials?.length
        ? materialDataArr
        : [
            {
              product_code: null,
              description: "",
              quantity: "",
              discount: "",
              price: "",
              unit: null,
            },
          ],
    })
    toggleSelect()
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {fromDashboard ? (
                    <div></div>
                  ) : (
                    <Breadcrumbs
                      title="Finance"
                      breadcrumbObject={[
                        { title: "Finance", link: "/finance" },
                        { title: "Voucher", link: "/finance-voucher" },
                        {
                          title: id
                            ? `${
                                watch("voucher_id")
                                  ? `Edit ${watch("voucher_id")?.label} Voucher`
                                  : "Edit Voucher"
                              }`
                            : `${
                                watch("voucher_id")
                                  ? `${watch("voucher_id")?.label} Voucher`
                                  : "Add Voucher"
                              }`,
                          link: "",
                        },
                      ]}
                    />
                  )}
                  <div>
                    {/* {watch("voucher_id")?.value == 15 && !editId && (
                      <>
                        <div
                          className="btn btn-secondary px-2 mb-3 me-2"
                          id={`PopoverLegacy-get-items`}
                        >
                          Get Items From
                        </div>
                        <UncontrolledPopover
                          placement="bottom"
                          target={`PopoverLegacy-get-items`}
                          trigger="legacy"
                        >
                          <PopoverBody>
                            <>
                              <div
                                className="table-popup-content"
                                onClick={() =>
                                  handleView({
                                    id: 1,
                                    name: "list_po",
                                    value: "",
                                    voucher_type: 16,
                                  })
                                }
                              >
                                From PO
                              </div>
                              <div
                                className="table-popup-content"
                                onClick={() =>
                                  handleView({
                                    id: 1,
                                    name: "list_grn",
                                    value: "",
                                    voucher_type: 7,
                                  })
                                }
                              >
                                From Receipt Note
                              </div>
                            </>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </>
                    )} */}
                    {watch("voucher_id")?.value ? (
                      <LoadingButton
                        form={`request-form`}
                        color={"primary"}
                        loading={isLoading}
                        className={"px-2 mb-3 btn btn-primary"}
                        type="submit"
                      >
                        {id ? "Update" : "Save"}
                      </LoadingButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mt-2">
                    <Col sm={6} lg={4} xl={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Voucher Type <span className="mandatory">*</span>
                        </Label>
                        <VoucherTypes
                          control={control}
                          placeholder="Select Voucher Type"
                          name={"voucher_id"}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={e => handleVoucherType(e)}
                          invalid={!!errors.voucher_id}
                          disabled={editId ? true : false}
                          forInvoice={true}
                        />
                        <FormFeedback invalid>
                          {errors.voucher_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={6} lg={4} xl={3}>
                      <FormGroup className="mb-4">
                        <Label>Date</Label>
                        <UFDate
                          control={control}
                          placeholder="Enter the Date "
                          name={"po_date"}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.po_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {/* {watch("voucher_id")?.value == 15 && (
                      <Col sm={6} lg={4} xl={3}>
                        <FormGroup>
                          <Label>
                            Project ID
                          </Label>
                          <ProjectNo
                            control={control}
                            name={"project_id"}
                            placeholder={"Project No"}
                            target={"body"}
                            // onSelectChange={e => handleProjectNum(e)}
                          />
                        </FormGroup>
                      </Col>
                    )} */}
                    {watch("voucher_id")?.value == 16 && (
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>MR Reference</Label>
                          <MaterialRequest
                            control={control}
                            name={"material_request_id"}
                            placeholder={"MR No"}
                            target={fromDashboard ? ".modal" : "body"}
                            onSelectChange={e => handleMatrial(e?.id)}
                            clearable={true}
                            // disabled={id || fromDashboard ? true : false}
                            // withoutRequest={fromDashboard ? false : true}
                          />
                          <FormFeedback invalid>
                            {errors.material_request_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {watch("voucher_id")?.value == 7 && (
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>PO Reference</Label>
                          <VoucherEntryLi
                            control={control}
                            name={"purchase_order_id"}
                            placeholder={"PO No"}
                            target={fromDashboard ? ".modal" : "body"}
                            voucherType={
                              watch("voucher_id")?.value == 7 ? 16 : ""
                            }
                            onSelectChange={e => handlePoReference(e?.id)}
                            clearable={true}
                            // disabled={id || fromDashboard ? true : false}
                            // withoutRequest={fromDashboard ? false : true}
                          />
                          <FormFeedback invalid>
                            {errors.purchase_order_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {/* {watch("voucher_id")?.value == 16 && (
                      <Col sm={6} lg={4} xl={3}>
                        <FormGroup>
                          <Label>
                            Supplier
                            <span className="mandatory">*</span>
                          </Label>
                          <SupplierList
                            control={control}
                            placeholder="select"
                            name={"supplier_id"}
                            invalid={!!errors.supplier_id}
                            target={fromDashboard ? ".modal" : "body"}
                            // onSelectChange={e => handleSupplier(e)}
                          />
                          <FormFeedback invalid>
                            {errors.supplier_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )} */}
                    {(watch("voucher_id")?.value == 15 ||
                      watch("voucher_id")?.value == 16 ||
                      watch("voucher_id")?.value == 13) && (
                      <Col sm={6} lg={4} xl={3}>
                        <FormGroup>
                          <Label>
                            Party Name
                            <span className="mandatory">*</span>
                          </Label>
                          <FinanceLedgersLi
                            control={control}
                            placeholder="Select"
                            name={"credit_id"}
                            invalid={!!errors.credit_id}
                            target={"body"}
                            disabled={editId ? true : false}
                            // ledgerTypes={"asset&expence"}
                          />
                          <FormFeedback invalid>
                            {errors.credit_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {(watch("voucher_id")?.value == 15 ||
                      watch("voucher_id")?.value == 7) && (
                      <Col sm={6} lg={4} xl={3}>
                        <FormGroup>
                          <Label>
                            Purchase Ledger
                            <span className="mandatory">*</span>
                          </Label>
                          <FinanceLedgersLi
                            control={control}
                            placeholder="Select"
                            name={"debit_id"}
                            invalid={!!errors.debit_id}
                            target={"body"}
                            disabled={editId ? true : false}
                            // ledgerTypes={"asset&expence"}
                          />
                          <FormFeedback invalid>
                            {errors.debit_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {watch("voucher_id")?.value == 16 && (
                      <>
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>Request Date</Label>
                            <UFDate
                              control={control}
                              name={"request_date"}
                              invalid={!!errors.request_date}
                            />
                            <FormFeedback invalid>
                              {errors.request_date?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>Exp Delivery Date</Label>
                            <UFDate
                              control={control}
                              name={"exp_Delivery_date"}
                              invalid={!!errors.exp_Delivery_date}
                            />
                            <FormFeedback invalid>
                              {errors.exp_Delivery_date?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Shipping address
                              <span className="mandatory">*</span>
                            </Label>
                            <UFEditor
                              control={control}
                              placeholder="Enter the Address"
                              rows="5"
                              name={"address"}
                              invalid={!!errors.address}
                              className={"pb-0"}
                            />
                            <FormFeedback invalid>
                              {errors.address?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>

                  <hr className="m-0" />

                  <Row className="py-2">
                    <div className="d-flex">
                      <h5 className="pe-2">
                        {watch("voucher_id")?.value == 15 ||
                        watch("voucher_id")?.value == 16
                          ? "Material Details"
                          : watch("voucher_id")?.value == 13
                          ? "Project"
                          : watch("voucher_id")?.value == 1
                          ? "Contra"
                          : watch("voucher_id")?.value == 8
                          ? "Journal"
                          : watch("voucher_id")?.value == 3
                          ? "Payment"
                          : watch("voucher_id")?.value == 6
                          ? "Receipt"
                          : ""}
                      </h5>
                      {watch("voucher_id")?.value == 15 && !editId && (
                        <>
                          <div
                            className="btn btn-secondary px-2 mb-3 me-2"
                            id={`PopoverLegacy-get-items`}
                          >
                            Get Items From{" "}
                            <i className="uil uil-cloud-download font-size-18 align-middle me-1 text-muted"></i>
                          </div>
                          <UncontrolledPopover
                            placement="bottom"
                            target={`PopoverLegacy-get-items`}
                            trigger="legacy"
                          >
                            <PopoverBody>
                              <>
                                <div
                                  className="table-popup-content"
                                  onClick={() =>
                                    handleView({
                                      id: 1,
                                      name: "list_po",
                                      value: "",
                                      voucher_type: 16,
                                    })
                                  }
                                >
                                  From PO
                                </div>
                                <div
                                  className="table-popup-content"
                                  onClick={() =>
                                    handleView({
                                      id: 1,
                                      name: "list_grn",
                                      value: "",
                                      voucher_type: 7,
                                    })
                                  }
                                >
                                  From Receipt Note
                                </div>
                              </>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </>
                      )}
                    </div>
                  </Row>
                  {watch("voucher_id")?.value == 15 ? (
                    <>
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>Product Code</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Ordered Quantity</th>
                                <th>Purchase Quantity</th>
                                <th>Unit Price (AED)</th>
                                <th>Price (AED)</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {purchaseFields.map((item, index) => (
                                <tr key={`purchase${index}`}>
                                  <td className="ps-1">
                                    <ProductList
                                      control={control}
                                      placeholder="Product Code"
                                      // projectid={projectID}
                                      selectedProduct={watch(
                                        `voucher_purchase`
                                      )}
                                      index={index}
                                      name={`voucher_purchase.${index}.product_id`}
                                      invalid={
                                        !!errors?.voucher_purchase?.[index]
                                          ?.product_id
                                      }
                                      onSelectChange={e =>
                                        handleCompanyProduct(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <div className="d-flex justify-content-between">
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`voucher_purchase.${index}.description`}
                                      />
                                    </div>
                                  </td>
                                  <td className="ps-1">
                                    <Unit
                                      control={control}
                                      placeholder="Unit"
                                      name={`voucher_purchase.${index}.unit`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Quantity"
                                      name={`voucher_purchase.${index}.ordered_quantity`}
                                      invalid={
                                        !!errors?.voucher_purchase?.[index]
                                          ?.ordered_quantity
                                      }
                                      disabled={true}
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.voucher_purchase?.[index]
                                          ?.ordered_quantity?.message
                                      }
                                    </FormFeedback>
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Quantity"
                                      name={`voucher_purchase.${index}.qnt`}
                                      invalid={
                                        !!errors?.voucher_purchase?.[index]?.qnt
                                      }
                                      onChange={e =>
                                        handleSubPricePurchase(e, index)
                                      }
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.voucher_purchase?.[index]?.qnt
                                          ?.message
                                      }
                                    </FormFeedback>
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`voucher_purchase.${index}.unit_price`}
                                      onChange={e =>
                                        handleSubPricePurchase(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`voucher_purchase.${index}.sub_price`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <div className="pb-1 px-2 d-flex justify-content-center">
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() =>
                                          purchaseListRemove(index)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <Col
                            lg="12"
                            xs={12}
                            md={2}
                            sm={12}
                            className="d-flex justify-content-between"
                          >
                            {!fromDashboard && (
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  purchaseListAppend({
                                    product_id: "",
                                    description: "",
                                    qnt: "",
                                    discount: "",
                                    unit_price: "",
                                    sub_price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                            )}
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={7}>
                          {watch("voucher_id")?.value != 16 && (
                            <Row className="mb-0">
                              <Col
                                lg={"auto"}
                                onClick={() =>
                                  handleDiscountCheck(discountCheck)
                                }
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_discount"}
                                    checked={discountCheck}
                                  />
                                  <Label className="ms-2">Discount</Label>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={"auto"}
                                onClick={() =>
                                  handleShippingCheck(shippingCheck)
                                }
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_shipping"}
                                    checked={shippingCheck}
                                  />
                                  <Label className="ms-2">
                                    Shipping Charge
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={"auto"}
                                onClick={() => handleOtherCheck(othersCheck)}
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_others"}
                                    checked={othersCheck}
                                  />
                                  <Label className="ms-2">Other Charges</Label>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={"auto"}
                                onClick={() => handleVatCheck(vatCheck)}
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_vat"}
                                    checked={vatCheck}
                                  />
                                  <Label className="ms-2">VAT Applicable</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="mb-4">
                                <Label>Narration</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Narrations"
                                  rows="5"
                                  name={"narration"}
                                  invalid={!!errors.narration}
                                  className={"pb-0"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={5}>
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2 text-end">Sub Total</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={calculation("sub_total")}
                                  name={"sub_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {discountCheck && (
                            <>
                              <Row className="mb-3">
                                <Col sm={6}>
                                  <div className="pt-2 text-end">
                                    Discount %
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter Discount"
                                      name={"discount"}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col sm={6}>
                                  <div className="pt-2 text-end">
                                    Discounted Sub Total
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder={calculation(
                                        "discounted_amount"
                                      )}
                                      name={"discounted_subTotal"}
                                      disabled={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          {shippingCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  Shipping Charge
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Shipping charge"
                                    name={"shipping_charge"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {othersCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  Other Charge
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Other charge"
                                    name={"other_charge"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {vatCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  VAT ({loggedUser?.settings?.tax})%
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder={calculation("tax_total")}
                                    name={"tax"}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2 text-end">Net Total</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={calculation("total_amount")}
                                  name={"net_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : watch("voucher_id")?.value == 16 ? (
                    <>
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>Product Code</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Quantity</th>
                                <th>Unit Price (AED)</th>
                                <th>Price (AED)</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {materialFields.map((item, index) => (
                                <tr key={`purchase${index}`}>
                                  <td className="ps-1">
                                    <ProductList
                                      control={control}
                                      placeholder="Product Code"
                                      // projectid={projectID}
                                      selectedProduct={watch(
                                        `voucher_materials`
                                      )}
                                      index={index}
                                      name={`voucher_materials.${index}.product_id`}
                                      invalid={
                                        !!errors?.voucher_materials?.[index]
                                          ?.product_id
                                      }
                                      onSelectChange={e =>
                                        handleCompanyProduct(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <div className="d-flex justify-content-between">
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`voucher_materials.${index}.description`}
                                      />
                                      {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="svg-font pe-1 pointer"
                                    id={`PopoverLegacy-${index}`}
                                  >
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                  </svg>
                                  <UncontrolledPopover
                                    placement="bottom"
                                    target={`PopoverLegacy-${index}`}
                                    trigger="hover"
                                    container={
                                      fromDashboard ? ".modal" : "body"
                                    }
                                  >
                                    <PopoverBody>
                                      <div className="table-popup-content">
                                        <div className="pb-2">
                                          Recent Suppliers
                                        </div>
                                        {watch(
                                          `purchase.${index}.short_supplier`
                                        )?.map(dt => (
                                          <div className="py-1">
                                            <span>{dt?.suppliers_code}</span>{" "}
                                            <span className="ps-2">
                                              {dt?.name}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </PopoverBody>
                                  </UncontrolledPopover> */}
                                    </div>
                                  </td>
                                  <td className="ps-1">
                                    <Unit
                                      control={control}
                                      placeholder="Unit"
                                      name={`voucher_materials.${index}.unit`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Quantity"
                                      name={`voucher_materials.${index}.qnt`}
                                      invalid={
                                        !!errors?.voucher_materials?.[index]
                                          ?.qnt
                                      }
                                      onChange={e => handleSubPrice(e, index)}
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.voucher_materials?.[index]?.qnt
                                          ?.message
                                      }
                                    </FormFeedback>
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`voucher_materials.${index}.unit_price`}
                                      onChange={e => handleSubPrice(e, index)}
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.voucher_materials?.[index]
                                          ?.unit_price?.message
                                      }
                                    </FormFeedback>
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`voucher_materials.${index}.sub_price`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <div className="pb-1 px-2 d-flex justify-content-center">
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() =>
                                          materialListRemove(index)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <Col
                            lg="12"
                            xs={12}
                            md={2}
                            sm={12}
                            className="d-flex justify-content-between"
                          >
                            {!fromDashboard && (
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  materialListAppend({
                                    product_id: "",
                                    description: "",
                                    qnt: "",
                                    discount: "",
                                    unit_price: "",
                                    sub_price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                            )}
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={7}>
                          {watch("voucher_id")?.value != 16 && (
                            <Row className="mb-0">
                              <Col
                                lg={"auto"}
                                onClick={() =>
                                  handleDiscountCheck(discountCheck)
                                }
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_discount"}
                                    checked={discountCheck}
                                  />
                                  <Label className="ms-2">Discount</Label>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={"auto"}
                                onClick={() =>
                                  handleShippingCheck(shippingCheck)
                                }
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_shipping"}
                                    checked={shippingCheck}
                                  />
                                  <Label className="ms-2">
                                    Shipping Charge
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={"auto"}
                                onClick={() => handleOtherCheck(othersCheck)}
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_others"}
                                    checked={othersCheck}
                                  />
                                  <Label className="ms-2">Other Charges</Label>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={"auto"}
                                onClick={() => handleVatCheck(vatCheck)}
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_vat"}
                                    checked={vatCheck}
                                  />
                                  <Label className="ms-2">VAT Applicable</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="mb-4">
                                <Label>Narration</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Narrations"
                                  rows="5"
                                  name={"narration"}
                                  invalid={!!errors.narration}
                                  className={"pb-0"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={5}>
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2 text-end">Sub Total</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={calculation("sub_total")}
                                  name={"sub_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {discountCheck && (
                            <>
                              <Row className="mb-3">
                                <Col sm={6}>
                                  <div className="pt-2 text-end">
                                    Discount %
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter Discount"
                                      name={"discount"}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col sm={6}>
                                  <div className="pt-2 text-end">
                                    Discounted Sub Total
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder={calculation(
                                        "discounted_amount"
                                      )}
                                      name={"discounted_subTotal"}
                                      disabled={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          {shippingCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  Shipping Charge
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Shipping charge"
                                    name={"shipping_charge"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {othersCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  Other Charge
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Other charge"
                                    name={"other_charge"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {vatCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  VAT ({loggedUser?.settings?.tax})%
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder={calculation("tax_total")}
                                    name={"tax"}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2 text-end">Net Total</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={calculation("total_amount")}
                                  name={"net_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : watch("voucher_id")?.value == 13 ? (
                    <>
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>Project</th>
                                <th>Description</th>
                                <th>Percentage</th>
                                <th>Price (AED)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="ps-1">
                                  <ProjectNo
                                    control={control}
                                    name={"project_ids"}
                                    placeholder={"Project No"}
                                    target={"body"}
                                    // onSelectChange={e => handleProjectNum(e)}
                                  />
                                </td>
                                <td className="ps-1">
                                  <UFInput
                                    control={control}
                                    placeholder="Description"
                                    name={`description`}
                                  />
                                </td>
                                <td className="ps-1">
                                  <UFInput
                                    control={control}
                                    placeholder="Percentage"
                                    name={`percentage`}
                                  />
                                </td>
                                <td className="ps-1">
                                  <UFInput
                                    control={control}
                                    placeholder="Price"
                                    name={`price`}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={7}>
                          <Row className="mb-0">
                            <Col
                              lg={"auto"}
                              onClick={() => handleDiscountCheck(discountCheck)}
                            >
                              <FormGroup>
                                <input
                                  type="checkbox"
                                  className="ever-rise-checkbox"
                                  name={"is_discount"}
                                  checked={discountCheck}
                                />
                                <Label className="ms-2">Discount</Label>
                              </FormGroup>
                            </Col>

                            <Col
                              lg={"auto"}
                              onClick={() => handleShippingCheck(shippingCheck)}
                            >
                              <FormGroup>
                                <input
                                  type="checkbox"
                                  className="ever-rise-checkbox"
                                  name={"is_shipping"}
                                  checked={shippingCheck}
                                />
                                <Label className="ms-2">Shipping Charge</Label>
                              </FormGroup>
                            </Col>
                            <Col
                              lg={"auto"}
                              onClick={() => handleOtherCheck(othersCheck)}
                            >
                              <FormGroup>
                                <input
                                  type="checkbox"
                                  className="ever-rise-checkbox"
                                  name={"is_others"}
                                  checked={othersCheck}
                                />
                                <Label className="ms-2">Other Charges</Label>
                              </FormGroup>
                            </Col>
                            <Col
                              lg={"auto"}
                              onClick={() => handleVatCheck(vatCheck)}
                            >
                              <FormGroup>
                                <input
                                  type="checkbox"
                                  className="ever-rise-checkbox"
                                  name={"is_vat"}
                                  checked={vatCheck}
                                />
                                <Label className="ms-2">VAT Applicable</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="mb-4">
                                <Label>Narration</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Narrations"
                                  rows="5"
                                  name={"narration"}
                                  invalid={!!errors.narration}
                                  className={"pb-0"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={5}>
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2 text-end">Sub Total</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={calculation("sub_total")}
                                  name={"sub_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {discountCheck && (
                            <>
                              <Row className="mb-3">
                                <Col sm={6}>
                                  <div className="pt-2 text-end">
                                    Discount %
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter Discount"
                                      name={"discount"}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col sm={6}>
                                  <div className="pt-2 text-end">
                                    Discounted Sub Total
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder={calculation(
                                        "discounted_amount"
                                      )}
                                      name={"discounted_subTotal"}
                                      disabled={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          {shippingCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  Shipping Charge
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Shipping charge"
                                    name={"shipping_charge"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {othersCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  Other Charge
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Other charge"
                                    name={"other_charge"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {vatCheck && (
                            <Row className="mb-3">
                              <Col sm={6}>
                                <div className="pt-2 text-end">
                                  VAT ({loggedUser?.settings?.tax})%
                                </div>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder={calculation("tax_total")}
                                    name={"tax"}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2 text-end">Net Total</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={Number(
                                    calculation("total_amount").toFixed(4)
                                  )}
                                  name={"net_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : watch("voucher_id")?.value == 1 ||
                    watch("voucher_id")?.value == 3 ||
                    watch("voucher_id")?.value == 6 ||
                    watch("voucher_id")?.value == 8 ? (
                    <>
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th className="w-25">Particulars</th>
                                <th>Debit (AED)</th>
                                <th>Credit (AED)</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {contraFields.map((item, index) => (
                                <tr key={`purchase${index}`}>
                                  <td className="ps-1">
                                    <FinanceLedgersLi
                                      control={control}
                                      placeholder="Select"
                                      name={`voucher_contra.${index}.account_id`}
                                      invalid={
                                        !!errors.voucher_contra?.[index]
                                          ?.account_id
                                      }
                                      target={"body"}
                                      // ledgerTypes={"asset&expence"}
                                    />
                                  </td>
                                  {/* <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Description"
                                      name={`voucher_contra.${index}.debit`}
                                    />
                                  </td> */}
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Debit"
                                      name={`voucher_contra.${index}.debit`}
                                      onChange={e => handleDebitValue(e, index)}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Credit"
                                      name={`voucher_contra.${index}.credit`}
                                      onChange={e =>
                                        handleCreditValue(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    <div className="pb-1 px-2 d-flex justify-content-center">
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() => contraListRemove(index)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <Col
                            lg="12"
                            xs={12}
                            md={2}
                            sm={12}
                            className="d-flex justify-content-between"
                          >
                            {!fromDashboard && (
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  contraListAppend({
                                    account_id: "",
                                    debit: 0,
                                    credit: 0,
                                  })
                                }
                              >
                                Add Row
                              </Button>
                            )}
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <FormGroup className="mb-4">
                            <Label>Narration</Label>
                            <UFEditor
                              control={control}
                              placeholder="Enter the Narrations"
                              rows="5"
                              name={"narration"}
                              invalid={!!errors.narration}
                              className={"pb-0"}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ) : watch("voucher_id")?.value == 7 ? (
                    <>
                      {" "}
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>Product Code</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Ordered Quantity</th>
                                <th>Received Quantity</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {receiptFields.map((item, index) => (
                                <tr key={`purchase${index}`}>
                                  <td className="ps-1">
                                    {/* {action == "company-req" ? ( */}
                                    <ProductList
                                      control={control}
                                      placeholder="Product Code"
                                      // projectid={projectID}
                                      selectedProduct={watch(`voucher_receipt`)}
                                      index={index}
                                      name={`voucher_receipt.${index}.product_id`}
                                      invalid={
                                        !!errors?.voucher_receipt?.[index]
                                          ?.product_id
                                      }
                                      onSelectChange={e =>
                                        handleCompanyProduct(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <div className="d-flex justify-content-between">
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`voucher_receipt.${index}.description`}
                                      />
                                    </div>
                                  </td>
                                  <td className="ps-1">
                                    <Unit
                                      control={control}
                                      placeholder="Unit"
                                      name={`voucher_receipt.${index}.unit`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Ordered Quantity"
                                      name={`voucher_receipt.${index}.po_quantity`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Quantity"
                                      name={`voucher_receipt.${index}.qnt`}
                                      invalid={
                                        !!errors?.voucher_receipt?.[index]?.qnt
                                      }
                                      onChange={e => handleSubPrice(e, index)}
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.voucher_receipt?.[index]?.qnt
                                          ?.message
                                      }
                                    </FormFeedback>
                                  </td>

                                  <td>
                                    <div className="pb-1 px-2 d-flex justify-content-center">
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() => receiptListRemove(index)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <Col
                            lg="12"
                            xs={12}
                            md={2}
                            sm={12}
                            className="d-flex justify-content-between"
                          >
                            {!fromDashboard && (
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  receiptListAppend({
                                    product_id: "",
                                    description: "",
                                    qnt: "",
                                    discount: "",
                                    unit_price: "",
                                    sub_price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                            )}
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={7}>
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="mb-4">
                                <Label>Narration</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Narrations"
                                  rows="5"
                                  name={"narration"}
                                  invalid={!!errors.narration}
                                  className={"pb-0"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {responseData ? (
                    <Row className="py-2">
                      <Col sm={7}>
                        <FormGroup className="mb-4">
                          <Label>Payment Terms</Label>
                          <div className="fs-13 ps-2">
                            {responseData?.pay_terms}
                          </div>
                          {/* <UFEditor
                          control={control}
                          placeholder="Enter the Payment Terms"
                          rows="5"
                          name={"pay_terms"}
                          invalid={!!errors.pay_terms}
                          className={"pb-0"}
                        /> */}
                          {/* <RichTextEditor
                          control={control}
                          name={"pay_terms"}
                          invalid={!!errors.terms}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        /> */}
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="text-end dark-text">Sub Total</div>
                          </Col>
                          <Col sm={6}>
                            <div className="ps-5 dark-text">
                              {responseData?.sub_total
                                ? Number(responseData?.sub_total).toFixed(2)
                                : 0.0}{" "}
                              AED
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="text-end dark-text">Discount %</div>
                          </Col>
                          <Col sm={6}>
                            <div className="ps-5 dark-text">
                              {responseData?.discount
                                ? responseData?.discount
                                : 0}{" "}
                              %
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="text-end dark-text">VAT %</div>
                          </Col>
                          <Col sm={6}>
                            <div className="ps-5 dark-text">
                              {responseData?.tax ? responseData?.tax : 0} %
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="text-end dark-text">
                              Shipping Charge
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="ps-5 dark-text">
                              {responseData?.shipping_charge
                                ? Number(responseData?.shipping_charge).toFixed(
                                    2
                                  )
                                : 0.0}{" "}
                              AED
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="text-end dark-text">
                              Other Charge
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="ps-5 dark-text">
                              {responseData?.other_charge
                                ? Number(responseData?.other_charge).toFixed(2)
                                : 0.0}{" "}
                              AED
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <div className="text-end dark-text">
                              Total Amount
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="ps-5 dark-text">
                              {responseData?.total_amount
                                ? Number(responseData?.total_amount).toFixed(2)
                                : 0.0}{" "}
                              AED
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleSelect}
        size="lg"
        unmountOnClose={false}
      >
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-2">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggleSelect}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <VoucherEntrySearch
              fromDashboard={true}
              onSelect={handlePOSelect}
              voucherType={viewData?.voucher_type}
            />
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default EditVoucherEntry
