import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Department from "../../../components/Common/chooseFields/department"
import { useForm } from "react-hook-form"
import UpdateTable from "../../../components/Common/UpdatedTable"
import { fetchCurrentEmp } from "../../../services/projectManager/projectMaster"

const WorkersDetailList = ({ item, projectId, viewData, fromDashboard }) => {
  const { control } = useForm({})
  const [currEmp, setCurrEmp] = useState()
  const [page, setPage] = useState("")

  const handlePaginate = page => {
    setPage(page)
  }

  console.log(`viewData`,viewData, currEmp, item)

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await fetchCurrentEmp({
        params: `${
          item ? `date=${item}` : `date=today`
        }${`&project_id=${projectId}`}${'&searchText=""'}${
          page ? `&page=${page}` : ""
        }`,
      })
      setCurrEmp(response)
      // setRefresh(refresh + 1)
    }
    if (item) {
      fetchMaterialSearch()
    }
  }, [item, projectId])
  const columns = [
    {
      label: "Emp ID",
      renderCell: item => item?.employee_code,
    },
    {
      label: "Emp Name",
      renderCell: item => item?.first_name,
    },
    {
      label: "Designation",
      renderCell: item => item?.designation_id,
    },
  ]

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <Card className="me-2 px-2 pb-2">
            <Row className="p-3">
              <Col sm={4} className="workers-head">
                Workers
              </Col>
              <Col sm={4}>
                <Department
                  control={control}
                  placeholder="Designation"
                  name={"designation_id"}
                  //   invalid={!!errors.designation_id}
                  target={"modal"}
                  param="designation"
                />
              </Col>
            </Row>
            <Row className="mt-2 px-2 ever-rise-table">
              <Col xs={12}>
                {currEmp && item && projectId && viewData == 3 && (
                  <UpdateTable
                    className="mt-5"
                    nodes={currEmp?.data?.data}
                    paginate={currEmp?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="30% 30% 40%"
                    mobileWidth="30% 30% 40%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WorkersDetailList
