import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import { updateSupplierStatus } from "../../../services/procurement/supplier"
import { downloadgrn, searchGRN } from "../../../services/procurement/grn"
import GRNFilter from "./filter"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { fetchGRNCosting } from "../../../services/finance/grnCosting"
import { UFDate } from "../../../components/Common/Fields/Input"
import resetIcon from "../../../assets/icons/reset.svg"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"

const GRNCosting = ({ fromDashboard, projectId, handleView }) => {
  document.title = `${
    fromDashboard ? "Finance Dashboard" : "GRN Costing "
  } | Ever Rise`

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [grnData, setGRNData] = useState()
  const [search, setSearch] = useState("")
  const [grn, setGrn] = useState("")
  const [grnCost, setGrnCost] = useState("")
  const [projectno, setProjectno] = useState("")
  // const [purchaseno, setPurchaseno] = useState("")
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  const [company, setCompany] = useState(false)

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateSupplierStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const { control, watch, reset } = useForm({
    defaultValues: {
      req_date: null,
    },
  })

  const columns = [
    {
      label: "GRN Cost No",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "grncosting_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/finance-grn-costing/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.code}
              </div>
            ) : (
              <div>{item?.code}</div>
            )}
          </>
        )
      },
    },
    {
      label: "GRN No",
      renderCell: item => item?.grn?.grn_no,
    },
    {
      label: "Project Code",
      renderCell: item =>
        item?.grn?.project_id != null
          ? item?.grn?.project_id?.project_code
          : "-",
    },
    {
      label: "Received by",
      renderCell: item => item?.grn?.received_by,
      sortKey: "received_by",
    },
    {
      label: "Amount",
      renderCell: item =>
        `${Number(Number(item?.pay).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })} AED`,
    },
    // {
    //   label: "Action",
    //   renderCell: item => (
    //     <>
    //       <div>
    //         <span id={`PopoverLegacy-${item?.id}`}>
    //           <img src={action} alt="" className="pointer" />
    //         </span>
    //       </div>
    //       <UncontrolledPopover
    //         placement="bottom"
    //         target={`PopoverLegacy-${item?.id}`}
    //         trigger="legacy"
    //       >
    //         <PopoverBody>
    //           <div
    //             className="table-popup-content"
    //             onClick={() => navigate(`/procurement-grn/view/${item?.id}`)}
    //           >
    //             View
    //           </div>
    //           <div
    //             className="table-popup-content"
    //             onClick={() => downloadgrn(item?.id, "grn.pdf")}
    //           >
    //             Download
    //           </div>
    //         </PopoverBody>
    //       </UncontrolledPopover>
    //     </>
    //   ),
    // },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  useEffect(() => {
    const fetchGRN = async () => {
      const response = await fetchGRNCosting({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          grn ? `&grn_no=${grn}` : ""
        }${grnCost ? `&code=${grnCost}` : ""}${
          projectno ? `&project_id=${projectno}` : ""
        }${page ? `&page=${page}` : ""}${`&financial=${financial}`}${
          company == true ? `&company_search=""` : ""
        }${projectId ? `&project_id=${projectId}` : ""}${
          datefrom ? `&start_date=${datefrom}` : ""
        }${dateto ? `&end_date=${dateto}` : ""}${`&sizePerPage=16`}`,
      })
      setGRNData(response)
    }
    fetchGRN()
  }, [
    refresh,
    search,
    grn,
    page,
    projectno,
    financial,
    company,
    grnCost,
    projectId,
    datefrom,
    dateto,
  ])

  const handleGrnChange = data => {
    setGrn(data)
    setPage(1)
  }
  const handleGrnCostChange = data => {
    setGrnCost(data)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  // const handlePurchaseChange = data => {
  //   setPurchaseno(data)
  //   setPage(1)
  // }
  const handleProjectChange = data => {
    setProjectno(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleCompanyChange = id => {
    setCompany(id)
    setPage(1)
  }
  

  const handleRequestdate = data => {
    console.log(data, "data")
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    console.log(fromDate, toDate, "toDate")
    setDatefrom(fromDate)
    setDateto(toDate)
  }

  const handleReset = () => {
    reset({ req_date: null })
    setDatefrom("")
    setDateto("")
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <>
                <Breadcrumbs
                  title="Finance"
                  breadcrumbObject={[
                    { title: "Finance", link: "/finance" },
                    { title: "GRN Costing", link: "/finance-grn-costing" },
                  ]}
                />

                {loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "grncosting_create"
                ) && (
                  <Link
                    to="/finance-grn-costing/add"
                    className={"px-2 mb-3 btn btn-primary"}
                  >
                    {"+ Add GRN Costing"}
                  </Link>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            {!fromDashboard ? (
              <>
                <GRNFilter
                  handleSearchChange={handleSearchChange}
                  handleGrnChange={handleGrnChange}
                  handleGrnCostChange={handleGrnCostChange}
                  handleDateChange={handleDateChange}
                  // handlePurchaseChange={handlePurchaseChange}
                  handleProjectChange={handleProjectChange}
                  handleFinacialChange={handleFinacialChange}
                  handleCompanyChange={handleCompanyChange}
                  sampleUrl={"download/suppliers.xlsx"}
                  importUrl={"suppliers/import"}
                  isImport={false}
                />
                <hr className="table-divide" />
              </>
            ) : (
              <div>
                {/* <span>GRN Costing</span>
                <Button
                  //   className="hover-button"
                  color={"primary"}
                  onClick={() => handleView()}
                >
                  Add GRN Costing
                </Button> */}
              </div>
            )}
            {fromDashboard && (
              <Row className="pt-2">
                <Col sm={6}>
                  <UFDate
                    control={control}
                    placeholder="Date"
                    name={"req_date"}
                    mode="range"
                    className="ufinput-dashboard"
                    onDateChange={e => handleRequestdate(e)}
                  />
                </Col>
                <Col sm={3}></Col>
                <Col sm={3}>
                  <div
                    className="pointer me-2 text-white btn btn-primary float-end"
                    onClick={() => handleReset()}
                  >
                    <img
                      src={resetIcon}
                      height={15}
                      width={15}
                      className="me-2 svg-clr-reset"
                    />{" "}
                    Reset
                  </div>
                </Col>
              </Row>
            )}
            <Row
              className={`ever-rise-table mt-2 ${
                fromDashboard ? "dashboardTable" : ""
              } `}
            >
              <Col xs={12}>
                {grnData && (
                  <UpdateTable
                    // className="mt-5"
                    nodes={grnData?.data?.data}
                    paginate={grnData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 20% 20% 20%"
                    mobileWidth="40% 40% 40% 40% 40%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GRNCosting
