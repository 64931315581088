import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFInput } from "../../../components/Common/Fields/Input"
import CurrencyList from "../../../components/Common/chooseFields/currency"
import Country from "../../../components/Common/chooseFields/country"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchSupplier,
  postSupplier,
} from "../../../services/procurement/supplier"
import uploadicon from "../../../assets/icons/export-light.svg"
import GradeChoose from "../../../components/Common/chooseFields/grade"
import {
  benefitsList,
  entitlementList,
} from "../../../services/Settings/dropdown"
import Select from "react-select"
import DynamicDropdown from "../../../components/Common/chooseFields/dynamicDropdown"
import {
  fetchHrmsGradeControlEdit,
  postHrmsGradeControl,
} from "../../../services/Hrms/hrms"
import { capitalize } from "lodash"

const schema = yup.object().shape({
  grade_id: yup.mixed().required("Grade is Required"),
  // master_id: yup.mixed().required("Grade is Required"),
})

const EditGradeControl = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState()
  const [benefitsListArr, setBenefitsList] = useState()
  const [entitlementListArr, setEntitlementArr] = useState()
  const [checkboxValues, setCheckboxValues] = useState([])
  const [entityCheckboxValues, setEntityCheckboxValues] = useState([])
  const [filteredBenefitArr, setFilteredBenefits] = useState()
  const [filteredEntitlementsArr, setFilteredEntitlements] = useState()

  console.log(
    `filteredBenefitArr`,
    filteredBenefitArr,
    `filteredEntitlementsArr`,
    filteredEntitlementsArr
  )

  console.log(
    `checkboxValues`,
    checkboxValues,
    `entityCheckboxValues`,
    entityCheckboxValues
  )

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      benefitsLi: [
        {
          grade_id: "",
          master_id: "",
          master: "",
          addons_id: "",
          value: "",
        },
      ],
      entityLi: [
        {
          grade_id: "",
          master_id: "",
          master: "",
          addons_id: "",
          value: "",
        },
      ],
      grade_id: null,
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const gradeId = watch("grade_id")

  useEffect(() => {
    const handleFetchSupplier = async () => {
      const response = await fetchSupplier(id)
      reset({
        id: id,
        name: response?.data?.name,
        email_id: response?.data?.email_id,
        contact_number: response?.data?.contact_number,
        vat_no: response?.data?.vat_no,
        address: response?.data?.address,
        is_subcontractor: response?.data?.is_subcontractor === 1 ? true : false,
        payment_mode: {
          value: response?.data?.payment?.id,
          label: response?.data?.payment?.name,
        },
        country_id: {
          value: response?.data?.country?.id,
          label: response?.data?.country?.name,
        },
        currency_id: {
          value: response?.data?.currency?.id,
          label: response?.data?.currency?.name,
        },
        bank_details: [...response?.data?.bank_details],
        contact_person: [...response?.data?.contact],
      })
    }

    const handleEdit = async id => {
      const response = await fetchHrmsGradeControlEdit(id)
      // setEdit(response?.data)
      const responseArr = response?.data?.map(dt => ({
        id: dt?.addons?.id,
      }))
      const filteredBenefits = response?.data.filter(
        obj => obj.value !== null && obj.master === "Benefits"
      )
      setFilteredBenefits(filteredBenefits)

      const filteredEntitlements = response?.data.filter(
        obj => obj.value !== null && obj.master === "Entitlements"
      )
      setFilteredEntitlements(filteredEntitlements)
      console.log("responseArr", response)
      if (id) {
        reset({
          ...response?.data,
          grade_id: {
            value: response?.data[0]?.grade?.id,
            label: response?.data[0]?.grade?.grade,
          },
        })
      }
      console.log(`fetch`, response)
    }
    handleEdit(id)
  }, [id])

  const handleRegistration = async data => {
    console.log(data, `post`)
    console.log(" benifu=its:", data?.benefitsLi)
    console.log(" entityLi=its:", data?.entityLi)
    console.log(
      " grade_addons ==>array ==>master_id ==>data?.benefitsLi-arr-->id ",
      data?.entityLi
    )
    if (checkboxValues?.length || entityCheckboxValues?.length) {
      try {
        setIsLoading(true)

        const benifitArray = []
        const BenifitsData = data?.benefitsLi?.map((dt, i) =>
          dt?.addons?.map((dts, index) =>
            benifitArray.push({
              master_id: dt?.id,
              grade_id: data?.grade_id?.id,
              addons_id: dts?.id,
              value:
                typeof dts?.data == "string" ? dts?.data : dts?.data?.value,
              master: dts?.master_type,
            })
          )
        )
        const EntitlementData = data?.entityLi?.map((dt, i) =>
          dt?.addons?.map((dts, index) =>
            benifitArray.push({
              master_id: dt?.id,
              grade_id: data?.grade_id?.id,
              addons_id: dts?.id,
              value:
                typeof dts?.data == "string" ? dts?.data : dts?.data?.value,
              master: dts?.master_type,
            })
          )
        )

        const createdSeries = await postHrmsGradeControl({
          ...data,
          grade_addons: benifitArray,
        })
        toastSuccess(createdSeries?.message)
        navigate("/hrms-grade-control")
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    } else {
      toastError("Any one should be added")
    }
  }

  const handleValueCheck = index => {
    setCheckboxValues(checkboxValues => {
      const updatedValues = [...checkboxValues]
      updatedValues[index] = !updatedValues[index]
      return updatedValues
    })
  }

  const handleEntityCheckbox = index => {
    setEntityCheckboxValues(entityCheckboxValues => {
      const updatedValues = [...entityCheckboxValues]
      updatedValues[index] = !updatedValues[index]
      return updatedValues
    })
  }

  const { fields: benefitsArr } = useFieldArray({
    control,
    name: "benefitsLi",
  })

  const { fields: entityArr } = useFieldArray({
    control,
    name: "entityLi",
  })

  // let benifitsMap = id ? filteredBenefitArr : benefitsArr
  console.log(`benefitsArr`, benefitsArr)

  useEffect(() => {
    const handleFormList = async () => {
      const response = await benefitsList()
      console.log(`res`, response)
      const benefitValue = getValues()
      reset({
        ...benefitValue,
        benefitsLi: [...response?.data],
      })
      setBenefitsList(response?.data)
    }
    const handleFormEntitlement = async () => {
      const response = await entitlementList()
      const entityValue = getValues()
      reset({
        ...entityValue,
        entityLi: [...response?.data],
      })
      setEntitlementArr(response?.data)
    }
    handleFormList()
    handleFormEntitlement()
  }, [])

  const checkBoxWatch = watch("master_id")
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`employee-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="w-100">
                <div className="d-flex justify-content-between pe-3">
                  <Breadcrumbs
                    title="Procurement"
                    breadcrumbObject={[
                      { title: "HRMS", link: "/hrms" },
                      { title: "Settings", link: "/hrms" },
                      { title: "Grade Control", link: "/hrms-grade-control" },
                      {
                        title: id
                          ? "Edit Grade Control"
                          : "Create Grade Control",
                        link: "",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`employee-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={12}>
                      <Row>
                        <Col lg={4}>
                          <FormGroup>
                            <GradeChoose
                              control={control}
                              placeholder="select"
                              name={"grade_id"}
                              invalid={!!errors.grade_id}
                              target={"body"}
                              disabled={id ? true : false}
                            />
                            <FormFeedback invalid>
                              {errors.grade_id?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  {gradeId && (
                    <Row className="">
                      <Col sm={12}>
                        <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                          <AccordionItem>
                            <AccordionHeader targetId="1">
                              Benefits
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                              {benefitsArr?.map((dt, i) =>
                                dt?.addons?.length ? (
                                  <div key={i} className="py-1">
                                    <div onClick={() => handleValueCheck(i)}>
                                      <input
                                        control={control}
                                        type="checkbox"
                                        className="ever-rise-checkbox"
                                        name={`benefitsLi.${i}.master_id`}
                                        checked={checkboxValues[i]}
                                      />
                                      <label className="label-padding">
                                        {dt?.name
                                          ? capitalize(dt?.name)
                                          : "-"}
                                      </label>{" "}
                                    </div>
                                    <div
                                      className={`${
                                        i + 1 < benefitsListArr?.length
                                          ? "grade-control-border"
                                          : ""
                                      }`}
                                    >
                                      {checkboxValues[i] && (
                                        <Row className="pl-4 py-4 ps-3">
                                          {dt?.addons?.map((data, index) =>
                                            data?.input_type?.id == 0 ? (
                                              <Col sm={3} key={`text_${index}`}>
                                                <FormGroup className="mb-4">
                                                  <Label>
                                                    {data?.label
                                                      ? capitalize(data?.label)
                                                      : "-"}
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </Label>
                                                  <UFInput
                                                    control={control}
                                                    placeholder={data?.label}
                                                    name={`benefitsLi.${i}.addons.${index}.data`}
                                                    invalid={
                                                      !!errors[
                                                        `value_${data?.id}`
                                                      ]
                                                    }
                                                  />
                                                  <FormFeedback invalid>
                                                    {
                                                      errors[
                                                        `value_${data?.id}`
                                                      ]?.message
                                                    }
                                                  </FormFeedback>
                                                </FormGroup>
                                              </Col>
                                            ) : data?.input_type?.id == 1 ? (
                                              <Col sm={3}>
                                                <Label>
                                                  {data?.label}
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </Label>
                                                <div className="d-flex flex-row align-items-center">
                                                  {data?.value?.map(
                                                    (dt, index) => (
                                                      <div
                                                        key={`radio_${index}`}
                                                        className="ps-2 d-flex align-items-center"
                                                      >
                                                        <div>
                                                          <input
                                                            type="radio"
                                                            id={dt}
                                                            name={`benefitsLi.${i}.data`}
                                                            value={dt}
                                                          />
                                                           {" "}
                                                          <label htmlFor={dt}>
                                                            {dt}
                                                          </label>
                                                          <br></br>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}{" "}
                                                </div>
                                              </Col>
                                            ) : data?.input_type?.id == 2 ? (
                                              <Col sm={3}>
                                                {}
                                                <Label>
                                                  {data?.label}
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </Label>
                                                <DynamicDropdown
                                                  control={control}
                                                  placeholder={data?.label}
                                                  name={`benefitsLi.${i}.addons.${index}.data`}
                                                  invalid={
                                                    !!errors.value_dropdown
                                                  }
                                                  target={"body"}
                                                  arr={data?.value}
                                                />
                                              </Col>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </Row>
                                      )}
                                    </div>
                                  </div>
                                ) : null
                              )}
                              <div></div>
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionHeader targetId="2">
                              Entitlements
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                              {entityArr?.map((dt, i) =>
                                dt?.addons?.length ? (
                                  <div key={i} className="py-1">
                                    {console.log(`dt----`, dt)}
                                    <div
                                      onClick={() => handleEntityCheckbox(i)}
                                    >
                                      <input
                                        type="checkbox"
                                        className="ever-rise-checkbox"
                                        name={`entitlementListArr.${i}.master_id`}
                                        checked={entityCheckboxValues[i]}
                                      />
                                      <label className="label-padding">
                                        {dt?.name}
                                      </label>{" "}
                                    </div>
                                    <div
                                      className={`${
                                        i + 1 < entitlementListArr?.length
                                          ? "grade-control-border"
                                          : ""
                                      }`}
                                    >
                                      {entityCheckboxValues[i] && (
                                        <Row className="pl-4 pt-4 ps-3">
                                          {dt?.addons?.map((data, index) =>
                                            data?.input_type?.id == 0 ? (
                                              <Col sm={3} key={`text_${index}`}>
                                                <FormGroup className="mb-4">
                                                  <Label>
                                                    {data?.label}
                                                    <span className="mandatory">
                                                      *
                                                    </span>
                                                  </Label>
                                                  <UFInput
                                                    control={control}
                                                    placeholder={data?.label}
                                                    name={`entityLi.${i}.addons.${index}.data`}
                                                    invalid={!!errors.name}
                                                  />
                                                  <FormFeedback invalid>
                                                    {errors.name?.message}
                                                  </FormFeedback>
                                                </FormGroup>
                                              </Col>
                                            ) : data?.input_type?.id == 1 ? (
                                              <Col sm={3}>
                                                {data?.value?.map(
                                                  (dt, index) => (
                                                    <div
                                                      sm={3}
                                                      key={`radio_${index}`}
                                                    >
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          id={dt}
                                                          name={`entityLi.${i}.addons.${index}.data`}
                                                          value={dt}
                                                        />
                                                         {" "}
                                                        <label for={dt}>
                                                          {dt}
                                                        </label>
                                                        <br></br>
                                                      </div>
                                                    </div>
                                                  )
                                                )}{" "}
                                              </Col>
                                            ) : data?.input_type?.id == 2 ? (
                                              <Col sm={3}>
                                                {}
                                                <Label>
                                                  {data?.label}
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </Label>
                                                <DynamicDropdown
                                                  control={control}
                                                  placeholder={data?.label}
                                                  name={`entityLi.${i}.addons.${index}.data`}
                                                  invalid={
                                                    !!errors.value_dropdown
                                                  }
                                                  target={"body"}
                                                  arr={data?.value}
                                                />
                                              </Col>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </Row>
                                      )}
                                    </div>
                                  </div>
                                ) : null
                              )}
                            </AccordionBody>
                          </AccordionItem>
                        </UncontrolledAccordion>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditGradeControl
