import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import {
  fetchCategory,
  updateCategoryStatus,
} from "../../../services/Inventory/inventory"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import EditCategory from "./edit"
import InventoryFilter from "../filter"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"

const Category = () => {
  document.title = "Category | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [page, setPage] = useState("")
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [series, setSeries] = useState("")
  const [classId, setClassId] = useState("")
  const [category, setCategory] = useState()
  const [count, setCount] = useState(0)
  const [editCategory, setEditCategory] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCategory({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}${series ? `&series_id=${series}` : ""}${
          classId ? `&class_id=${classId}` : ""
        }${`&sizePerPage=16`}`,
      })
      setCategory(response)
    }
    fetchData()
  }, [count, status, search, page, series, classId])

  const handleEdit = item => {
    setEditCategory({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateCategoryStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Series Code",
      renderCell: item => item?.series?.series,
    },
    {
      label: "Class Code",
      renderCell: item => item?.classcode?.class_code,
    },
    {
      label: "Category Code",
      renderCell: item => item?.category_code,
    },
    // {
    //   label: "Expense Type",
    //   renderCell: item => item?.expense_type,
    // },
    {
      label: "Description",
      renderCell: item =>
        item?.description ? capitalizeFirstLetter(item?.description) : "-",
      sortKey: "description",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "category_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditCategory("")
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSeriesChange = id => {
    setSeries(id)
    setPage(1)
  }
  const handleClassChange = id => {
    setClassId(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Settings"
              breadcrumbObject={[
                { title: "Settings", link: "/settings" },
                { title: "Category", link: "/settings-category" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "category_create"
            ) && (
              <EditCategory
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleResetValue}
                buttonLabel="+&nbsp; Add Category"
                className="px-2 mb-3 btn btn-primary"
                editItem={editCategory}
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <InventoryFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleSeriesChange={handleSeriesChange}
              handleClassChange={handleClassChange}
              sampleUrl={"download/category.xlsx"}
              importUrl={"category/import"}
              showExport={
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "category_create"
                )
                  ? true
                  : false
              }
              isSeries={true}
              isClass={true}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {category?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={category?.data?.data}
                    paginate={category?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 20% 20% 10% 10%"
                    mobileWidth="40% 40% 40% 40% 20% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Category
