import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import lightTrash from "../../../assets/icons/light-trash.svg"
import moment from "moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {
  fetchManpowerPurchase,
  fetchPurchase,
} from "../../../services/procurement/purchaseOrder"
import { postGRN, postManpowerGRN } from "../../../services/procurement/grn"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import POPRoducts from "../../../components/Common/chooseFields/poproducts"
import GRNPurchaseNo from "../../../components/Common/chooseFields/grnPurchaseNo"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import EstimationCategory from "../../../components/Common/chooseFields/estimateCategory"

const schema = yup.object().shape({
  po_req_id: yup.mixed().required("Po number is required"),
  recieved_date: yup
    .mixed()
    .test(
      "is-future-date",
      "Make sure the received date is forward-looking",
      function (value) {
        if (!value) return true
        const currentDate = moment()
        const selectedDate = moment(value)
        return selectedDate.isSameOrBefore(currentDate, "day")
      }
    )
    .required("Received date is required"),
  cat_id: yup.mixed().required("Category Code is Required"),
  description: yup.string().required("Description is Required"),
  no_hours: yup
    .string()
    .matches(/^\d*(\.\d+)?$/, "Pending quantity must be a non-negative number")
    .test(
      "max",
      "pending quantity must not exceed the ordered quantity",
      function (value) {
        const { quantity } = this.parent
        return parseInt(value) <= quantity
      }
    )
    .required("pending quantity is required"),

  // received_by: yup.string().required("Received by is required"),
  //   grnproductlist: yup.array().of(
  //     yup.object().shape({
  //       product_code: yup.mixed().required("product code is required"),
  //       description: yup.string().required("description is required"),
  //       unit: yup.mixed().required("Unit is Required"),
  //       received_quantity: yup
  //         .string()
  //         .matches(
  //           /^\d*(\.\d+)?$/,
  //           "Pending quantity must be a non-negative number"
  //         )
  //         .test(
  //           "max",
  //           "pending quantity must not exceed the ordered quantity",
  //           function (value) {
  //             const { pending_quantity } = this.parent
  //             return parseInt(value) <= pending_quantity
  //           }
  //         )
  //         .required("pending quantity is required"),
  //     })
  //   ),
})

const EditManpowerGRN = ({ fromDashboard, poValue, toggle }) => {
  const navigate = useNavigate()
  const { editId } = useParams()
  const id = fromDashboard ? "" : editId
  const [isLoading, setIsLoading] = useState(false)
  const [idPO, setIdPO] = useState()
  const location = useLocation()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  console.log(`fromDashboard`, fromDashboard, poValue)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      po_req_id: null,
      received_by: "",
      recieved_date: null,
      material_requests_id: "",
    },
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const {
    fields: poFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "grnproductlist",
  })
  const watchTest = useWatch({
    control,
    name: "grnproductlist",
    defaultValue: poFields,
  })
  useEffect(() => {
    if (location?.state && !poValue) {
      reset({
        po_req_id: location?.state?.purchase_orders_code && {
          label: location?.state?.purchase_orders_code,
          value: location?.state?.id,
        },
      })

      fetchPotData(location?.state?.id)
      setIdPO(location?.state?.id)
    }
  }, [location])

  useEffect(() => {
    if (fromDashboard) {
      reset({
        po_req_id: poValue?.value && {
          label: poValue?.label,
          value: poValue?.value,
        },
      })

      handlePoChange(poValue)
    }
  }, [fromDashboard])
  const handleRegistration = async data => {
    // const Payterms = data?.pay_terms
    //   ? draftToHtml(convertToRaw(data?.pay_terms?.getCurrentContent()))
    //   : ""

    console.log(
      `Register ashika`,
      data,
      data?.grnproductlist?.reduce(
        (a, b) => a + Number(b?.received_quantity),
        0
      )
    )
    // if (
    //   data?.grnproductlist?.reduce(
    //     (a, b) => a + Number(b?.received_quantity),
    //     0
    //   ) > 0
    // ) {
    //   let arrayData = data?.grnproductlist?.map(element => ({
    //     product_code: element?.product_code?.value,
    //     description: element.description,
    //     unit: element.unit.value,
    //     ordered_quantity: element.ordered_quantity,
    //     received_quantity: element.received_quantity,
    //     pending_quantity: element.ordered_quantity - element.received_quantity,
    //   }))
    //   console.log(`arrayData`, arrayData)
    try {
      setIsLoading(true)
      const update = await postManpowerGRN({
        ...data,
        project_id: data?.project_id,
        manpower_requests_id: data?.manpower_requests_id,
        po_req_id: data?.po_req_id,
        received_by: loggedUser?.data?.name,
        recieved_date: moment(data?.recieved_date).format("YYYY-MM-DD"),
        pay_terms: data?.pay_terms,
        from_date: data?.from_date,
        to_date: data?.to_date,
        cat_id: data?.cat_id?.value,
        no_hours: data?.no_hours,
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/procurement-grn")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }
  const handlePoChange = () => {
    const { po_req_id } = getValues()
    fetchPotData(po_req_id?.value)
    setIdPO(po_req_id?.value)
  }
  const fetchPotData = async idData => {
    const response = await fetchManpowerPurchase(idData)
    if (idData == response.data?.id) {
      //   const arrayData = response.data?.purchaseorder_lists
      //     ?.map(dt => {
      //       const TotalReceivedQty = dt.grnlist.reduce(
      //         (total, grnqty) => total + parseFloat(grnqty.received_quantity),
      //         0
      //       )

      //       const alreadyReceived = dt.grnlist.length
      //         ? dt.quantity - TotalReceivedQty
      //         : 0
      //       const Received = dt.grnlist.length ? alreadyReceived : dt.quantity

      //       return {
      //         product_code: dt?.product_code && {
      //           label: dt?.product_code.product_code,
      //           value: dt?.product_code.id,
      //         },
      //         description: dt.description,
      //         ordered_quantity: dt?.quantity,
      //         received_quantity: 0,
      //         pending_quantity: Received,
      //         unit: {
      //           value: dt?.unit?.id,
      //           label: dt?.unit?.unit_code,
      //         },
      //       }
      //     })
      //     .filter(item => item.pending_quantity !== 0)

      const receivedHours = response?.data?.manpowergrn.reduce(
        (accumulator, currentObject) => {
          return accumulator + Number(currentObject.no_hours)
        },
        0
      )

      //   const receivedHours = response?.data?.manpowergrn?.reduce(
      //     ((a, b) => Number(b.no_hours) + a, 0) || 0
      //   )
      console.log(`receivedHours`, receivedHours)

      const previousDatas = getValues()
      console.log(`fetchManpowerPurchase`, response)
      reset({
        ...previousDatas,
        project_id: response.data?.project_id,
        manpower_requests_id: response?.data?.manpower_requests_code?.id,
        po_req_id: response?.data?.id,
        from_date: response?.data?.from_date,
        to_date: response?.data?.to_date,
        cat_id: {
          label: response?.data?.category?.designation?.name,
          value: response?.data?.category?.id,
        },
        quantity: Number(response?.data?.quantity) - receivedHours,
        description: response?.data?.manpower_requests_code?.description,
        po_date: moment(response.data?.created_at).format("DD-MM-YYYY"),
        mr_no: response.data?.manpower_requests_code?.manpower_code,
        mr_date: moment(
          response.data?.manpower_requests_code?.created_at
        ).format("DD-MM-YYYY"),
        supplier_code: response.data?.supplier?.suppliers_code,
        supplier_name: response.data?.supplier?.name,
        // grnproductlist: arrayData,
        pay_terms: response.data?.pay_terms,
      })
    }
  }
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {fromDashboard ? (
                    <div></div>
                  ) : (
                    <Breadcrumbs
                      title="Procurement"
                      breadcrumbObject={[
                        { title: "Procurement", link: "/procurement" },
                        { title: "GRN", link: "/procurement-grn" },
                        {
                          title: id ? "Edit GRN" : "Add GRN",
                          link: "",
                        },
                      ]}
                    />
                  )}
                  <LoadingButton
                    form={`request-form`}
                    color={"primary"}
                    loading={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          PO number <span className="mandatory">*</span>
                        </Label>
                        <GRNPurchaseNo
                          control={control}
                          placeholder="Enter the PO number"
                          name={"po_req_id"}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={handlePoChange}
                          invalid={!!errors.po_req_id}
                          disabled={fromDashboard ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.po_req_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>PO Date</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the po date "
                          name={"po_date"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>MAR no</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the project name"
                          name={"mr_no"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>MAR Date</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the  mr date"
                          name={"mr_date"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Supplier Code</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the supplier code"
                          name={"supplier_code"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Supplier Name</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the supplier name "
                          name={"supplier_name"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Received by <span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the requested by"
                          name={"received_by"}
                          invalid={!!errors.received_by}
                        />
                        <FormFeedback invalid>
                          {errors.received_by?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Received Date <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"recieved_date"}
                          invalid={!!errors.recieved_date}
                          maxDate={moment().format("YYYY-MM-DD")}
                        />
                        <FormFeedback invalid>
                          {errors.recieved_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />

                  <Row className="py-4 mt-4">
                    <h5>Material Details</h5>
                  </Row>

                  <Row className="mb-4">
                    <Col lg={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>Category Code</th>
                            <th>Description</th>
                            <th>Number of Hours</th>
                            <th>Received Hours</th>
                            <th>Pending Hours</th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {poFields.map((item, index) => ( */}
                          <tr>
                            <td>
                              <EstimationCategory
                                control={control}
                                placeholder="Select"
                                name="cat_id"
                                disabled={fromDashboard ? true : false}
                              />
                              {/* <POPRoducts
                                  control={control}
                                  placeholder="Product Code"
                                  name={`grnproductlist.${index}.product_code`}
                                  Poid={idPO}
                                  selectedProduct={watch(`grnproductlist`)}
                                  index={index}
                                  invalid={
                                    !!errors?.grnproductlist?.[index]
                                      ?.product_code
                                  }
                                /> */}
                              <FormFeedback invalid>
                                {errors?.cat_id?.message}
                              </FormFeedback>
                            </td>
                            <td>
                              <UFInput
                                control={control}
                                placeholder="Description"
                                name={`description`}
                                invalid={!!errors?.description}
                              />
                              <FormFeedback invalid>
                                {errors?.description?.message}
                              </FormFeedback>
                            </td>
                            {/* <td>
                                <Unit
                                  control={control}
                                  placeholder="Unit"
                                  name={`grnproductlist.${index}.unit`}
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]?.unit
                                      ?.message
                                  }
                                </FormFeedback>
                              </td> */}
                            <td>
                              <p>{watch("quantity")} </p>
                            </td>
                            <td>
                              <UFInput
                                control={control}
                                placeholder="Received Hours"
                                name={`no_hours`}
                                invalid={!!errors?.no_hours}
                              />
                              <FormFeedback invalid>
                                {errors?.no_hours?.message}
                              </FormFeedback>
                            </td>
                            <td>
                              <p>
                                {Number(watch("quantity")) -
                                  Number(watch("no_hours"))}
                              </p>
                            </td>
                            {/* <td>
                                <img
                                  src={lightTrash}
                                  alt="light-trash"
                                  className="trash-img pointer"
                                  onClick={() => materialRemove(index)}
                                />
                              </td> */}
                          </tr>
                          {/* ))} */}
                        </tbody>
                      </table>
                      <Col
                        lg="12"
                        xs={12}
                        md={2}
                        sm={12}
                        className="d-flex justify-content-between"
                      >
                        {!fromDashboard && (
                          <Button
                            color="secondary"
                            className="addButton mt-2"
                            onClick={() =>
                              materialAppend({
                                product_code: null,
                                description: "",
                                // ordered_quantity: 0,
                                received_quantity: 0,
                                // pending_quantity: 0
                              })
                            }
                          >
                            Add Row
                          </Button>
                        )}
                      </Col>
                    </Col>
                  </Row>
                  <Row className="py-4 mt-4">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Payment Terms</Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the Payment Terms"
                          rows="5"
                          name={"pay_terms"}
                          invalid={!!errors.pay_terms}
                          className={"pb-0"}
                        />
                        {/* <RichTextEditor
                          control={control}
                          name={"pay_terms"}
                          invalid={!!errors.terms}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditManpowerGRN
