import Finance from "../../pages/Finance"
import EditAccountPayables from "../../pages/Finance/accountPayables/edit.js"
import AccountPayables from "../../pages/Finance/accountPayables/index.js"
import ViewAccPayables from "../../pages/Finance/accountPayables/view.js"
import EditAccountReceivable from "../../pages/Finance/accountReceivable/edit.js"
import AccountReceivable from "../../pages/Finance/accountReceivable/index.js"
import ViewAccRecievable from "../../pages/Finance/accountReceivable/view.js"
import Accounts from "../../pages/Finance/accounts/index.js"
import BalanceSheet from "../../pages/Finance/balanceSheet/index.js"
import FinanceCategory from "../../pages/Finance/category"
import FinanceCostCenter from "../../pages/Finance/costCenter.js/index.js"
import GRNCosting from "../../pages/Finance/grnCosting.js"
import EditGRNCosting from "../../pages/Finance/grnCosting.js/edit.js"
import ViewCosting from "../../pages/Finance/grnCosting.js/view.js"
import FinanceAccountGroupFields from "../../pages/Finance/groupFields/index.js"
import FinanceAccountGroup from "../../pages/Finance/groups/index.js"
import InvoiceSearch from "../../pages/Finance/invoices/index.js"
import JournalEntry from "../../pages/Finance/journalEntry/index.js"
import EditLedger from "../../pages/Finance/ledger/edit.js"
import LedgerFinance from "../../pages/Finance/ledger/index.js"
import EditMappingSetup from "../../pages/Finance/mappingSetup/edit.js"
import FinanceSubCategory from "../../pages/Finance/subCategory"
import TransactionHistory from "../../pages/Finance/transactionHistory/index.js"
import FinanceProjectDashboard from "../../pages/FinanceDashboard/FinanceProjectDashboard/index.js"
import FinanceDahboard from "../../pages/FinanceDashboard/index.js"
import InvoiceView from "../../pages/Invoice/invoiceView/view.js"

export const financeRoutes = [
  { path: "/finance-dashboard", component: <FinanceDahboard /> },
  { path: "/finance-dashboard/:id", component: <FinanceProjectDashboard /> },
  { path: "/finance", component: <Finance /> },
  { path: "/finance-category", component: <FinanceCategory /> },
  { path: "/finance-sub-category", component: <FinanceSubCategory /> },
  { path: "/finance-grn-costing", component: <GRNCosting /> },
  { path: "/finance-grn-costing/add", component: <EditGRNCosting /> },
  { path: "/finance-grn-costing/view/:id", component: <ViewCosting /> },
  { path: "/finance-account-receivable", component: <AccountReceivable /> },
  {
    path: "/finance-account-receivable/view/:id",
    component: <ViewAccRecievable />,
  },
  {
    path: "finance-account-receivable/add",
    component: <EditAccountReceivable />,
  },
  { path: "/finance-account-payables", component: <AccountPayables /> },
  {
    path: "finance-account-payables/add",
    component: <EditAccountPayables />,
  },
  {
    path: "/finance-account-payable/view/:id",
    component: <ViewAccPayables />,
  },
  { path: "finance-transaction-history", component: <TransactionHistory /> },
  { path: "finance-cost-center", component: <FinanceCostCenter /> },
  { path: "finance-account", component: <Accounts /> },
  { path: "finance-mapping-setup", component: <EditMappingSetup /> },
  { path: "finance-invoices", component: <InvoiceSearch /> },
  { path: "finance-invoice/view/:id", component: <InvoiceView /> },
  { path: "finance-journal-entry", component: <JournalEntry /> },
  { path: "finance-balance-sheet", component: <BalanceSheet /> },
  { path: "finance-accounts", component: <FinanceAccountGroup /> },
  { path: "finance-group-fields", component: <FinanceAccountGroupFields /> },
  { path: "finance-ledger", component: <LedgerFinance /> },
  { path: "finance-ledger/add", component: <EditLedger /> },
  { path: "finance-ledger/edit/:id", component: <EditLedger /> },
]
