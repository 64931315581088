import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import InventoryFilter from "../filter"
import {
  searchInventoryGroup,
  updateGroupStatus,
} from "../../../services/Inventory/inventory"
import EditClassCode from "./edit"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const GroupIndex = () => {
  document.title = "Group | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)
  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [refresh, setRefresh] = useState(0)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [page, setPage] = useState("")

  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }
  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateGroupStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Description",
      renderCell: item => (
        <div className="capitalize-letter">{item?.description}</div>
      ),
      sortKey: "description",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "group_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchInventoryGroup({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [refresh, status, search, page])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Inventory"
              breadcrumbObject={[
                { title: "Product Master", link: "/inventory" },
                { title: "Group", link: "/inventory-group" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "group_edit"
            ) && (
              <EditClassCode
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleReset}
                editValue={editClass}
                buttonLabel="+&nbsp; Add Group"
                className="px-2 mb-3 btn btn-primary"
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <InventoryFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              sampleUrl={"download/group.xlsx"}
              importUrl={"group/import"}
              isExport={
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "group_create"
                )
                  ? true
                  : false
              }
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="40% 40% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GroupIndex
