import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const BalanceTypeDrop = ({ control, name, onSelectChange, target, placeholder }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [countriesData, setCountriesData] = useState([])

  const loadOptions = async () => {
    const data = [
      { label: "Debit", value: "debit" },
      { label: "Credit", value: "credit" },
    ]
    setIsLoading(false)
    const countries = data.map(dta => {
      return {
        label: dta?.label && ucfirst(dta.label),
        value: dta.id,
        ...dta,
      }
    })

    setCountriesData(countries)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={countriesData}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : "body"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default BalanceTypeDrop
