import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFInput } from "../../../components/Common/Fields/Input"
import CurrencyList from "../../../components/Common/chooseFields/currency"
import Country from "../../../components/Common/chooseFields/country"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchSupplier,
  postSupplier,
} from "../../../services/procurement/supplier"
import uploadicon from "../../../assets/icons/export-light.svg"
import GradeChoose from "../../../components/Common/chooseFields/grade"
import {
  benefitsList,
  entitlementList,
} from "../../../services/Settings/dropdown"
import Select from "react-select"
import DynamicDropdown from "../../../components/Common/chooseFields/dynamicDropdown"
import {
  fetchHrmsGradeControlEdit,
  postHrmsGradeControl,
} from "../../../services/Hrms/hrms"
import { capitalize } from "lodash"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"
import BankList from "../../../components/Common/chooseFields/bankList"
import ConfigLi from "../../../components/Common/chooseFields/config"
import {
  fetchFinanceLedgerSingle,
  postFinanceLedger,
} from "../../../services/finance/finance"
import BalanceTypeDrop from "../../../components/Common/chooseFields/balanceType"

const schema = yup.object().shape({
  name: yup.string().required("Ledger Name is Required"),
  account_group_id: yup.mixed().required("Under Group is Required"),
  // master_id: yup.mixed().required("Grade is Required"),
})

const EditLedger = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [benefitsListArr, setBenefitsList] = useState()
  const [entitlementListArr, setEntitlementArr] = useState()
  const [checkboxValues, setCheckboxValues] = useState([])
  const [entityCheckboxValues, setEntityCheckboxValues] = useState([])
  const [bankCheck, setBankCheck] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const gradeId = watch("grade_id")

  useEffect(() => {
    const handleFetchSupplier = async () => {
      const response = await fetchSupplier(id)
      reset({
        id: id,
        name: response?.data?.name,
        email_id: response?.data?.email_id,
        contact_number: response?.data?.contact_number,
        vat_no: response?.data?.vat_no,
        address: response?.data?.address,
        is_subcontractor: response?.data?.is_subcontractor === 1 ? true : false,
        payment_mode: {
          value: response?.data?.payment?.id,
          label: response?.data?.payment?.name,
        },
        country_id: {
          value: response?.data?.country?.id,
          label: response?.data?.country?.name,
        },
        currency_id: {
          value: response?.data?.currency?.id,
          label: response?.data?.currency?.name,
        },
        bank_details: [...response?.data?.bank_details],
        contact_person: [...response?.data?.contact],
      })
    }

    const handleEdit = async id => {
      const response = await fetchFinanceLedgerSingle(id)
      // setEdit(response?.data)
      //   const responseArr = response?.data?.map(dt => ({
      //     id: dt?.addons?.id,
      //   }))
      //   const filteredBenefits = response?.data.filter(
      //     obj => obj.value !== null && obj.master === "Benefits"
      //   )
      //   setFilteredBenefits(filteredBenefits)

      //   const filteredEntitlements = response?.data.filter(
      //     obj => obj.value !== null && obj.master === "Entitlements"
      //   )
      //   setFilteredEntitlements(filteredEntitlements)
      if (id) {
        reset({
          ...response?.data,
          account_group_id: {
            label: response?.data?.account_group?.name,
            value: response?.data?.account_group?.id,
          },
          ban_after_check_printing: {
            label: response?.data?.ban_after_check_printing == 0 ? "No" : "Yes",
            value: response?.data?.ban_after_check_printing,
          },
          ban_after_range_check_book: {
            label:
              response?.data?.ban_after_range_check_book == 0 ? "No" : "Yes",
            value: response?.data?.ban_after_range_check_book,
          },
          ban_check_printing: {
            label: response?.data?.ban_check_printing == 0 ? "No" : "Yes",
            value: response?.data?.ban_check_printing,
          },
          ban_bank_name: {
            label: response?.data?.ban_bank_name?.label,
            value: response?.data?.ban_bank_name?.id,
          },
          mail_country: {
            label: response?.data?.mail_country?.name,
            value: response?.data?.mail_country?.id,
          },
          balance_type: {
            label: response?.data?.balance_type == "debit" ? "Debit" : "Credit",
            value: response?.data?.balance_type,
          },
        })
      }
    }
    if (id) {
      handleEdit(id)
    }
  }, [id])

  const handleRegistration = async data => {
    // console.log(data, `post`)
    // console.log(" benifu=its:", data?.benefitsLi)
    // console.log(" entityLi=its:", data?.entityLi)
    // console.log(
    //   " grade_addons ==>array ==>master_id ==>data?.benefitsLi-arr-->id ",
    //   data?.entityLi
    // )
    // if (checkboxValues?.length || entityCheckboxValues?.length) {
    //   try {
    //     setIsLoading(true)

    //     const benifitArray = []
    //     const BenifitsData = data?.benefitsLi?.map((dt, i) =>
    //       dt?.addons?.map((dts, index) =>
    //         benifitArray.push({
    //           master_id: dt?.id,
    //           grade_id: data?.grade_id?.id,
    //           addons_id: dts?.id,
    //           value:
    //             typeof dts?.data == "string" ? dts?.data : dts?.data?.value,
    //           master: dts?.master_type,
    //         })
    //       )
    //     )
    //     const EntitlementData = data?.entityLi?.map((dt, i) =>
    //       dt?.addons?.map((dts, index) =>
    //         benifitArray.push({
    //           master_id: dt?.id,
    //           grade_id: data?.grade_id?.id,
    //           addons_id: dts?.id,
    //           value:
    //             typeof dts?.data == "string" ? dts?.data : dts?.data?.value,
    //           master: dts?.master_type,
    //         })
    //       )
    //     )

    //     const createdSeries = await postHrmsGradeControl({
    //       ...data,
    //       grade_addons: benifitArray,
    //     })
    //     toastSuccess(createdSeries?.message)
    //     navigate("/hrms-grade-control")
    //   } catch (err) {
    //     toastError(err?.message)
    //   } finally {
    //     setIsLoading(false)
    //   }
    // } else {
    //   toastError("Any one should be added")
    // }
    console.log(`data--`, data)

    try {
      setIsLoading(true)
      const createdLedger = await postFinanceLedger({
        ...data,
        account_group_id: data?.account_group_id?.value,
        opening_balance: data?.opening_balance ? data?.opening_balance : 0,
        ban_after_check_printing: data?.ban_after_check_printing?.value,
        ban_after_range_check_book: data?.ban_after_range_check_book?.value,
        ban_bank_name: data?.ban_bank_name?.value,
        ban_check_printing: data?.ban_check_printing?.value,
        mail_country: data?.mail_country?.value,
        balance_type: data?.balance_type?.value,
      })
      toastSuccess(createdLedger?.message)
      navigate("/finance-ledger")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleValueCheck = index => {
    setCheckboxValues(checkboxValues => {
      const updatedValues = [...checkboxValues]
      updatedValues[index] = !updatedValues[index]
      return updatedValues
    })
  }

  const handleEntityCheckbox = index => {
    setEntityCheckboxValues(entityCheckboxValues => {
      const updatedValues = [...entityCheckboxValues]
      updatedValues[index] = !updatedValues[index]
      return updatedValues
    })
  }

  const { fields: benefitsArr } = useFieldArray({
    control,
    name: "benefitsLi",
  })

  const { fields: entityArr } = useFieldArray({
    control,
    name: "entityLi",
  })


  // useEffect(() => {
  //   const handleFormList = async () => {
  //     const response = await benefitsList()
  //     console.log(`res`, response)
  //     const benefitValue = getValues()
  //     reset({
  //       ...benefitValue,
  //       benefitsLi: [...response?.data],
  //     })
  //     setBenefitsList(response?.data)
  //   }
  //   const handleFormEntitlement = async () => {
  //     const response = await entitlementList()
  //     const entityValue = getValues()
  //     reset({
  //       ...entityValue,
  //       entityLi: [...response?.data],
  //     })
  //     setEntitlementArr(response?.data)
  //   }
  //   handleFormList()
  //   handleFormEntitlement()
  // }, [])

  const checkBoxWatch = watch("master_id")

  const handleBankDetailCheck = dt => {
    setBankCheck(!dt)
  }
  console.log(`bankCheck`, bankCheck)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`employee-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="w-100">
                <div className="d-flex justify-content-between pe-3">
                  <Breadcrumbs
                    title="Procurement"
                    breadcrumbObject={[
                      { title: "Finance", link: "/finance" },
                      { title: "Finance Ledger", link: "/finance-ledger" },
                      {
                        title: id ? "Edit Ledger" : "Create Ledger",
                        link: "",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`employee-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card px-3 pb-3">
                  <div className="mapping-setup-title mb-2 pt-3">
                    Create Ledger
                  </div>

                  <Row className="mb-4 mt-2">
                    <Col sm={12}>
                      <Row>
                        <Col lg={3}>
                          <FormGroup>
                            <Label>
                              Ledger Name<span className="mandatory">*</span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Ledger Name"
                              name={"name"}
                              invalid={!!errors.name}
                            />
                            <FormFeedback invalid>
                              {errors.name?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg={3}>
                          <FormGroup>
                            <Label>
                              Nature Of Account
                              <span className="mandatory">*</span>
                            </Label>
                            <FinanceGroupType
                              control={control}
                              placeholder="select"
                              name={"account_group_id"}
                              invalid={!!errors.account_group_id}
                              target={"body"}
                              //   disabled={id ? true : false}
                            />
                            <FormFeedback invalid>
                              {errors.account_group_id?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg={3}>
                          <FormGroup>
                            <Label>Opening Balance</Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Amount"
                              name={"opening_balance"}
                              invalid={!!errors.opening_balance}
                            />
                            <FormFeedback invalid>
                              {errors.opening_balance?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg={3}>
                          <FormGroup>
                            <Label>Balance Type</Label>
                            <BalanceTypeDrop
                              control={control}
                              placeholder="Balance Type"
                              name={"balance_type"}
                              invalid={!!errors.balance_type}
                            />
                            <FormFeedback invalid>
                              {errors.balance_type?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  {/* <Row className="py-3 estimation-stickey">
                    <Col lg={1}>
                      <h5>Select</h5>
                    </Col>
                    <Col
                      lg={"auto"}
                      onClick={() => handleBankDetailCheck(bankCheck)}
                    >
                      <FormGroup>
                        <input
                          type="checkbox"
                          className="ever-rise-checkbox"
                          name={"is_bank_chk"}
                          checked={bankCheck}
                        />
                        <Label className="ms-2">Bank Details</Label>
                      </FormGroup>
                    </Col>
                  <hr className="m-0" />

                  </Row> */}
                  {/* {gradeId && ( */}
                  <Row className="">
                    <Col sm={12}>
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        {/* {bankCheck ? ( */}
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            Bank Account Details
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            {/* {benefitsArr?.map((dt, i) =>
                              dt?.addons?.length ? (
                                <div key={i} className="py-1">
                                  <div onClick={() => handleValueCheck(i)}>
                                    <input
                                      control={control}
                                      type="checkbox"
                                      className="ever-rise-checkbox"
                                      name={`benefitsLi.${i}.master_id`}
                                      checked={checkboxValues[i]}
                                    />
                                    <label className="label-padding">
                                      {dt?.name ? capitalize(dt?.name) : "-"}
                                    </label>{" "}
                                  </div>
                                  <div
                                    className={`${
                                      i + 1 < benefitsListArr?.length
                                        ? "grade-control-border"
                                        : ""
                                    }`}
                                  >
                                    {checkboxValues[i] && (
                                      <Row className="pl-4 py-4 ps-3">
                                        {dt?.addons?.map((data, index) =>
                                          data?.input_type?.id == 0 ? (
                                            <Col sm={3} key={`text_${index}`}>
                                              <FormGroup className="mb-4">
                                                <Label>
                                                  {data?.label
                                                    ? capitalize(data?.label)
                                                    : "-"}
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </Label>
                                                <UFInput
                                                  control={control}
                                                  placeholder={data?.label}
                                                  name={`benefitsLi.${i}.addons.${index}.data`}
                                                  invalid={
                                                    !!errors[
                                                      `value_${data?.id}`
                                                    ]
                                                  }
                                                />
                                                <FormFeedback invalid>
                                                  {
                                                    errors[`value_${data?.id}`]
                                                      ?.message
                                                  }
                                                </FormFeedback>
                                              </FormGroup>
                                            </Col>
                                          ) : data?.input_type?.id == 1 ? (
                                            <Col sm={3}>
                                              <Label>
                                                {data?.label}
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </Label>
                                              <div className="d-flex flex-row align-items-center">
                                                {data?.value?.map(
                                                  (dt, index) => (
                                                    <div
                                                      key={`radio_${index}`}
                                                      className="ps-2 d-flex align-items-center"
                                                    >
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          id={dt}
                                                          name={`benefitsLi.${i}.data`}
                                                          value={dt}
                                                        />
                                                         {" "}
                                                        <label htmlFor={dt}>
                                                          {dt}
                                                        </label>
                                                        <br></br>
                                                      </div>
                                                    </div>
                                                  )
                                                )}{" "}
                                              </div>
                                            </Col>
                                          ) : data?.input_type?.id == 2 ? (
                                            <Col sm={3}>
                                              {}
                                              <Label>
                                                {data?.label}
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </Label>
                                              <DynamicDropdown
                                                control={control}
                                                placeholder={data?.label}
                                                name={`benefitsLi.${i}.addons.${index}.data`}
                                                invalid={
                                                  !!errors.value_dropdown
                                                }
                                                target={"body"}
                                                arr={data?.value}
                                              />
                                            </Col>
                                          ) : (
                                            <></>
                                          )
                                        )}
                                      </Row>
                                    )}
                                  </div>
                                </div>
                              ) : null
                            )} */}
                            <Row>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Account Holder Name
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Account Holder Name"
                                    name={`ban_account_name`}
                                    invalid={!!errors?.ban_account_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.ban_account_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Account Number
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Account Number"
                                    name={`ban_account_num`}
                                    invalid={!!errors?.ban_account_num}
                                  />
                                  <FormFeedback invalid>
                                    {errors.ban_account_num?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Bank Name
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <BankList
                                    control={control}
                                    placeholder="Enter the Bank Name"
                                    name={`ban_bank_name`}
                                  />
                                  {/* <UFInput
                              control={control}
                              placeholder="Enter the Bank Name"
                              name={`ban_bank_name`}
                              invalid={
                                !!errors?.ban_bank_name
                              }
                            /> */}
                                  <FormFeedback invalid>
                                    {errors?.ban_bank_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Branch Name
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Branch Name"
                                    name={`branch_name`}
                                    invalid={!!errors?.branch_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.branch_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    IBAN
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the IBAN Name"
                                    name={`ban_iban`}
                                    invalid={!!errors?.ban_iban}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.ban_iban?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    SWIFT/BIC Code
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Swift Code"
                                    name={`swift_code`}
                                    invalid={!!errors?.swift_code}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.swift_code?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              {/* <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          {accountFields.length == index + 1 ? (
                            <Button
                              color="secondary"
                              className="addButton mt-2"
                              onClick={() =>
                                accountAppend({
                                  ac_holder_name: "",
                                  ac_number: "",
                                  bank_name: null,
                                  branch_name: "",
                                  iban: "",
                                  swift_code: "",
                                })
                              }
                            >
                              Add Row
                            </Button>
                          ) : (
                            ""
                          )}
                          {accountFields.length > 1 ? (
                            <Button
                              color="primary"
                              className="removeButton mt-2 ms-3"
                              onClick={() => accountRemove(index)}
                            >
                              Delete Row
                            </Button>
                          ) : (
                            ""
                          )}
                        </Col> */}
                            </Row>
                            <div></div>
                          </AccordionBody>
                        </AccordionItem>
                        {/* ) : (
                          <></>
                        )} */}
                        <AccordionItem>
                          <AccordionHeader targetId="2" stayOpen>
                            Cheque Configuration
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <Row>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>After Range for Cheque Books</Label>
                                  <ConfigLi
                                    control={control}
                                    placeholder="Select"
                                    name={`ban_after_range_check_book`}
                                    invalid={
                                      !!errors?.ban_after_range_check_book
                                    }
                                  />
                                  <FormFeedback invalid>
                                    {
                                      errors?.ban_after_range_check_book
                                        ?.message
                                    }
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>Enable Cheque Printing</Label>
                                  <ConfigLi
                                    control={control}
                                    placeholder="Select"
                                    name={`ban_check_printing`}
                                    invalid={!!errors?.ban_check_printing}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.ban_check_printing?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    After Cheque Printing Configuration
                                  </Label>
                                  <ConfigLi
                                    control={control}
                                    placeholder="Select"
                                    name={`ban_after_check_printing`}
                                    invalid={!!errors?.ban_after_check_printing}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.ban_after_check_printing?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>

                            {/* {entityArr?.map((dt, i) =>
                              dt?.addons?.length ? (
                                <div key={i} className="py-1">
                                  {console.log(`dt----`, dt)}
                                  <div onClick={() => handleEntityCheckbox(i)}>
                                    <input
                                      type="checkbox"
                                      className="ever-rise-checkbox"
                                      name={`entitlementListArr.${i}.master_id`}
                                      checked={entityCheckboxValues[i]}
                                    />
                                    <label className="label-padding">
                                      {dt?.name}
                                    </label>{" "}
                                  </div>
                                  <div
                                    className={`${
                                      i + 1 < entitlementListArr?.length
                                        ? "grade-control-border"
                                        : ""
                                    }`}
                                  >
                                    {entityCheckboxValues[i] && (
                                      <Row className="pl-4 pt-4 ps-3">
                                        {dt?.addons?.map((data, index) =>
                                          data?.input_type?.id == 0 ? (
                                            <Col sm={3} key={`text_${index}`}>
                                              <FormGroup className="mb-4">
                                                <Label>
                                                  {data?.label}
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </Label>
                                                <UFInput
                                                  control={control}
                                                  placeholder={data?.label}
                                                  name={`entityLi.${i}.addons.${index}.data`}
                                                  invalid={!!errors.name}
                                                />
                                                <FormFeedback invalid>
                                                  {errors.name?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            </Col>
                                          ) : data?.input_type?.id == 1 ? (
                                            <Col sm={3}>
                                              {data?.value?.map((dt, index) => (
                                                <div
                                                  sm={3}
                                                  key={`radio_${index}`}
                                                >
                                                  <div>
                                                    <input
                                                      type="radio"
                                                      id={dt}
                                                      name={`entityLi.${i}.addons.${index}.data`}
                                                      value={dt}
                                                    />
                                                     {" "}
                                                    <label for={dt}>{dt}</label>
                                                    <br></br>
                                                  </div>
                                                </div>
                                              ))}{" "}
                                            </Col>
                                          ) : data?.input_type?.id == 2 ? (
                                            <Col sm={3}>
                                              {}
                                              <Label>
                                                {data?.label}
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </Label>
                                              <DynamicDropdown
                                                control={control}
                                                placeholder={data?.label}
                                                name={`entityLi.${i}.addons.${index}.data`}
                                                invalid={
                                                  !!errors.value_dropdown
                                                }
                                                target={"body"}
                                                arr={data?.value}
                                              />
                                            </Col>
                                          ) : (
                                            <></>
                                          )
                                        )}
                                      </Row>
                                    )}
                                  </div>
                                </div>
                              ) : null
                            )} */}
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionHeader targetId="3">
                            Mailing Details
                          </AccordionHeader>
                          <AccordionBody accordionId="3">
                            <Row>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Name
                                    {/* <span className="mandatory">*</span> */}
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Name"
                                    name={`mail_name`}
                                    invalid={!!errors?.mail_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.mail_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Address
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Address"
                                    name={`mail_add`}
                                    invalid={!!errors?.mail_add}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.mail_add?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Emirate
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Emirate"
                                    name={`mail_emirate`}
                                    invalid={!!errors?.mail_emirate}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.mail_emirate?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Country
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <Country
                                    control={control}
                                    placeholder="Select"
                                    name={`mail_country`}
                                    invalid={!!errors?.mail_country}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.mail_country?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>

                            {/* {entityArr?.map((dt, i) =>
                              dt?.addons?.length ? (
                                <div key={i} className="py-1">
                                  {console.log(`dt----`, dt)}
                                  <div onClick={() => handleEntityCheckbox(i)}>
                                    <input
                                      type="checkbox"
                                      className="ever-rise-checkbox"
                                      name={`entitlementListArr.${i}.master_id`}
                                      checked={entityCheckboxValues[i]}
                                    />
                                    <label className="label-padding">
                                      {dt?.name}
                                    </label>{" "}
                                  </div>
                                  <div
                                    className={`${
                                      i + 1 < entitlementListArr?.length
                                        ? "grade-control-border"
                                        : ""
                                    }`}
                                  >
                                    {entityCheckboxValues[i] && (
                                      <Row className="pl-4 pt-4 ps-3">
                                        {dt?.addons?.map((data, index) =>
                                          data?.input_type?.id == 0 ? (
                                            <Col sm={3} key={`text_${index}`}>
                                              <FormGroup className="mb-4">
                                                <Label>
                                                  {data?.label}
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </Label>
                                                <UFInput
                                                  control={control}
                                                  placeholder={data?.label}
                                                  name={`entityLi.${i}.addons.${index}.data`}
                                                  invalid={!!errors.name}
                                                />
                                                <FormFeedback invalid>
                                                  {errors.name?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            </Col>
                                          ) : data?.input_type?.id == 1 ? (
                                            <Col sm={3}>
                                              {data?.value?.map((dt, index) => (
                                                <div
                                                  sm={3}
                                                  key={`radio_${index}`}
                                                >
                                                  <div>
                                                    <input
                                                      type="radio"
                                                      id={dt}
                                                      name={`entityLi.${i}.addons.${index}.data`}
                                                      value={dt}
                                                    />
                                                     {" "}
                                                    <label for={dt}>{dt}</label>
                                                    <br></br>
                                                  </div>
                                                </div>
                                              ))}{" "}
                                            </Col>
                                          ) : data?.input_type?.id == 2 ? (
                                            <Col sm={3}>
                                              {}
                                              <Label>
                                                {data?.label}
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </Label>
                                              <DynamicDropdown
                                                control={control}
                                                placeholder={data?.label}
                                                name={`entityLi.${i}.addons.${index}.data`}
                                                invalid={
                                                  !!errors.value_dropdown
                                                }
                                                target={"body"}
                                                arr={data?.value}
                                              />
                                            </Col>
                                          ) : (
                                            <></>
                                          )
                                        )}
                                      </Row>
                                    )}
                                  </div>
                                </div>
                              ) : null
                            )} */}
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionHeader targetId="4">
                            VAT Registration Details
                          </AccordionHeader>
                          <AccordionBody accordionId="4">
                            <Row>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    TRN
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the TRN"
                                    name={`trn`}
                                    invalid={!!errors?.trn}
                                  />
                                  <FormFeedback invalid>
                                    {errors?.trn?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Col>
                  </Row>
                  {/* //   )} */}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditLedger
