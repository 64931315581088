import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { fetchFinanceGroupLi, fetchFinanceLedgersLi } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const FinanceLedgersLi = ({
  control,
  name,
  onSelectChange,
  disabled,
  target,
  placeholder,
  clearable,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [seriesData, setSeriesData] = useState([])

//    // Group accounts by category_id
//    const groupedOptions = data?.data?.reduce((groups, account) => {
//     const groupLabel = `Category ${account.category_id}`
//     const formattedAccount = {
//       label: account.name && ucfirst(`${account.name} - (${account.code})`),
//       value: account.id,
//       ...account,
//     }
//     if (!groups[groupLabel]) groups[groupLabel] = []
//     groups[groupLabel].push(formattedAccount)
//     return groups
//   }, {})

//   // Format grouped options for react-select
//   const formattedOptions = Object.keys(groupedOptions).map(category => ({
//     label: category,
//     options: groupedOptions[category],
//   }))

//   setFetchResponse(formattedOptions)
// }

const loadOptions = async () => {
    const data = await fetchFinanceLedgersLi();
    setIsLoading(false);

    // Group by type name and organize subgroups
    const groupedData = data?.data?.reduce((acc, item) => {
      const typeName = item.account_group?.name;
      if (!typeName) return acc;

      if (!acc[typeName]) {
        acc[typeName] = {
          label: ucfirst(typeName),
          options: []
        };
      }

      // Map the parent item and its subgroups
      acc[typeName].options.push({
        label: ucfirst(item.name),
        value: item.id,
        subOptions: item.sub_groups?.map(sub => ({
          label: ucfirst(sub.name),
          value: sub.id
        })) || []
      });

      return acc;
    }, {});

    // Flatten the grouped data for use in react-select
    const series = Object.values(groupedData).map(group => ({
      label: group.label,
      options: group.options.map(option => ({
        label: option.label,
        value: option.value,
        options: option.subOptions // Add subOptions as child items
      }))
    }));

    setSeriesData(series);
  };



//   const loadOptions = async () => {
//     const data = await fetchFinanceGroupLi()
//     setIsLoading(false)
//     const series = data?.data?.map(dta => {
//       return {
//         label: dta?.name && ucfirst(`${dta.name}`),
//         value: dta.id,
//         ...dta,
//       }
//     })

//     setSeriesData(series)
//   }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={seriesData}
              placeholder={placeholder ? placeholder : "Category"}
              isLoading={isLoading}
              isClearable={clearable ? clearable : false}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              isDisabled={disabled}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : ".modal"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default FinanceLedgersLi
