import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import PurchaseOrderFilter from "./filter"
import UpdateTable from "../../../components/Common/UpdatedTable"
import moment from "moment"
import action from "../../../assets/icons/action.svg"
import {
  downloadpo,
  searchPurchaseOrder,
  updatePOStatus,
} from "../../../services/procurement/purchaseOrder"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const PurchaseOrder = ({ fromDashboard, onSelect }) => {
  document.title = "Purchase Order | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [purchaseOrderLi, setPurchaseOrderLi] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [page, setPage] = useState("")
  const [refresh, setRefresh] = useState(0)
  const [country, setCountry] = useState("")
  const [mrno, setMRNo] = useState("")
  const [poNO, setPONO] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [projectName, setProjectName] = useState("")
  const [financial, setFinancial] = useState(true)
  const navigate = useNavigate()

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchMaterialRequest = async () => {
      const response = await searchPurchaseOrder({
        params: `${
          fromDashboard
            ? `&status=0`
            : status == "0" || status == "1"
            ? `&status=${status}`
            : ""
        }${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${mrno ? `&mr_no=${mrno}` : ""}${poNO ? `&po_no=${poNO}` : ""}${
          datefrom ? `&requestdate_from=${datefrom}` : ""
        }${dateto ? `&requestdate_to=${dateto}` : ""}${
          projectName ? `&p_no=${projectName}` : ""
        }${`&financial=${financial}`}${`&sizePerPage=16`}`,
      })
      console.log(response, "response")
      setPurchaseOrderLi(response)
    }
    fetchMaterialRequest()
  }, [
    refresh,
    status,
    search,
    page,
    mrno,
    poNO,
    datefrom,
    dateto,
    projectName,
    financial,
    fromDashboard,
  ])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCountryChange = id => {
    setCountry(id)
    setPage(1)
  }

  const handleRequestChange = id => {
    setMRNo(id)
    setPage(1)
  }

  const handlePOChange = id => {
    setPONO(id)
    setPage(1)
  }

  const handleProjectName = id => {
    setProjectName(id)
    setPage(1)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleActivate = id => {
    const updateStatus = async id => {
      try {
        const response = await updatePOStatus(id)
        toastSuccess(response?.message)
      } catch (err) {
        toastError(err?.message)
      }
      setRefresh(refresh + 1)
    }
    updateStatus(id)
  }

  const columns = [
    {
      label: "PO no",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "purchaseorder_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/procurement-purchase-order/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.purchase_orders_code}
              </div>
            ) : (
              <div>{item?.purchase_orders_code}</div>
            )}
          </>
        )
      },
    },
    // {
    //   label: "MR no",
    //   renderCell: item => {
    //     return (
    //       <div
    //       >
    //         {item?.material_request_id?.material_requests_code}
    //       </div>
    //     )
    //   },
    // },
    ...(fromDashboard
      ? []
      : [
          {
            label: "MR no",
            renderCell: item => (
              <div>{item?.material_request_id?.material_requests_code}</div>
            ),
          },
        ]),
    ...(fromDashboard
      ? [{ label: "Supplier", renderCell: item => item?.supplier?.name }]
      : []),
    ...(fromDashboard
      ? []
      : [
          {
            label: "Project Code",
            renderCell: item => item?.project_master?.project_code,
          },
        ]),
    ...(fromDashboard
      ? []
      : [
          {
            label: "Request date",
            renderCell: item => moment(item?.request_date).format("DD-MM-YYYY"),
          },
        ]),
    {
      label: "Quantity",
      renderCell: item => item?.purchaseorder_lists_sum_quantity,
    },
    ...(fromDashboard
      ? []
      : [
          {
            label: "Status",
            renderCell: item => (
              <div>
                <span
                  className={`font-13 ${
                    item?.status === 1 ? "status-draft" : "status-posted"
                  }`}
                >
                  {item?.status === 1 ? "Draft" : "Posted"}
                </span>
              </div>
            ),
          },
        ]),
    ...(fromDashboard
      ? []
      : [
          {
            label: "GRN Status",
            renderCell: item => (
              <div>
                <span
                  className={`font-13 ${
                    item?.grn_status == 0
                      ? "status-inactive"
                      : item?.grn_status == 1
                      ? "status-pending"
                      : "status-recieving"
                  }`}
                >
                  {item?.grn_status == 0
                    ? "Not Started"
                    : item?.grn_status == 1
                    ? "Receiving"
                    : "Received"}
                </span>
              </div>
            ),
          },
        ]),
    {
      label: "Action",
      renderCell: item =>
        fromDashboard ? (
          <Button
            color={"primary"}
            className={"px-2 py-0 fs-13 btn btn-primary"}
            onClick={() => onSelect(item)}
            // onClick={() =>
            //   handleView({
            //     id: 4,
            //     name: "view_MR",
            //     value: item,
            //     product_id: "",
            //   })
            // }
          >
            Select
          </Button>
        ) : (
          <>
            <div>
              <span id={`PopoverLegacy-${item?.id}`}>
                <img src={action} alt="" className="pointer" />
              </span>
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.id}`}
              trigger="legacy"
            >
              <PopoverBody>
                {item?.status === 1 &&
                  loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "purchaseorder_edit"
                  ) && (
                    <div
                      className="table-popup-content"
                      onClick={() =>
                        navigate(`/procurement-purchase-order/edit/${item?.id}`)
                      }
                    >
                      Edit
                    </div>
                  )}
                {item?.status === 1 && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleActivate(item?.id)}
                  >
                    Post LPO
                  </div>
                )}
                {/* {item?.listing_status == 1 && item?.status == 0 && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/procurement-grn/add`, { state: item })
                    }
                  >
                    Convert to GRN
                  </div>
                )} */}
                {item?.status == 0 && item?.invoice_status == 0 && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/finance-invoice/add`, { state: item })
                    }
                  >
                    Create Invoice
                  </div>
                )}
                <div
                  className="table-popup-content"
                  onClick={() => downloadpo(item?.id, "po.pdf")}
                >
                  Download
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ),
    },
  ]

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          {!fromDashboard ? (
            <div className="d-flex justify-content-between">
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Purchase Order",
                    link: "/procurement-purchase-order",
                  },
                ]}
              />
              {loggedUser?.permission?.some(
                permissionItem =>
                  permissionItem?.name === "purchaseorder_create"
              ) && (
                <Link
                  to="/procurement-purchase-order/add"
                  className={"px-2 mb-3 btn btn-primary"}
                >
                  {"+ Add Purchase Order"}
                </Link>
              )}
            </div>
          ) : (
            <></>
          )}
          <Card className="me-2 px-2 pb-2">
            {!fromDashboard ? (
              <>
                <PurchaseOrderFilter
                  handleStatusChange={handleStatusChange}
                  handleSearchChange={handleSearchChange}
                  handleCountryChange={handleCountryChange}
                  handleRequestChange={handleRequestChange}
                  handleDateChange={handleDateChange}
                  handleProjectName={handleProjectName}
                  handleFinacialChange={handleFinacialChange}
                  handlePOChange={handlePOChange}
                  sampleUrl={"download/suppliers.xlsx"}
                  importUrl={"suppliers/import"}
                  isImport={false}
                />
                <hr className="table-divide" />
              </>
            ) : (
              <></>
            )}
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {purchaseOrderLi && (
                  <UpdateTable
                    className="mt-5"
                    nodes={purchaseOrderLi?.data?.data}
                    paginate={purchaseOrderLi?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth={
                      fromDashboard
                        ? "25% 25% 25% 25%"
                        : "15% 15% 15% 15% 10% 10% 10% 10%"
                    }
                    mobileWidth="30% 30% 30% 30% 20% 20% 20% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PurchaseOrder
