import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../components/Common/UpdatedTable"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import action from "../../assets/icons/action.svg"
import {
  fetchModules,
  updateModuleStatus,
} from "../../services/Inventory/inventory"
import EditModules from "./edit"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import ModuleFilter from "./filter"

const ModulesIndex = () => {
  document.title = "Permissions | Everise"

  const [page, setPage] = useState("")

  const [modules, setModules] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [count, setCount] = useState(0)
  const [editModules, setEditModules] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchModules({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setModules(response)
    }
    fetchData()
  }, [count, status, search, page])

  const handleEdit = item => {
    setEditModules({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateModuleStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Permission Name",
      renderCell: item => item?.name,
    },
    {
      label: "Slug",
      renderCell: item => item?.slug,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {/* {item?.status === 1 && ( */}
              <div
                className="table-popup-content"
                onClick={() => handleEdit(item)}
              >
                Edit
              </div>
              {/* )} */}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]
  const subColumn = [
    {
      label: "Permission Name",
      renderCell: item => {
        return <div>{item?.name}</div>
      },
    },
    {
      label: "Slug",
      renderCell: item => {
        return <div>{item?.slug}</div>
      },
    },
    {
      label: "Status",
      renderCell: item => {
        return (
          <div>
            <div
              className={`font-13 ${
                item?.status === 1 ? "status-active" : "status-inactive"
              }`}
            >
              {item?.status === 1 ? "Active" : "Inactive"}
            </div>
          </div>
        )
      },
    },
    {
      label: "Action",
      renderCell: item => {
        return (
          <div>
            <div>
              <span id={`PopoverLegacy-${item?.id}`}>
                <img src={action} alt="" className="pointer" />
              </span>
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.id}`}
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </div>

                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content`}
                  onClick={() => handleStatus(item?.id)}
                >
                  {item?.status === 1 ? "Deactivate" : "Activate"}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )
      },
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditModules("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Permissions"
              breadcrumbObject={[
                { title: "Settings", link: "/settings" },
                { title: "Permissions", link: "/settings-permission" },
              ]}
            />
            <EditModules
              key={"x"}
              onUpdate={handleUpdate}
              onReset={handleResetValue}
              buttonLabel="+&nbsp; Add Permission"
              className="px-2 mb-3 btn btn-primary"
              editItem={editModules}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <ModuleFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              // sampleUrl={"download/modules.xlsx"}
              // importUrl={"modules/import"}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table moduleTable ">
              <Col xs={12}>
                {modules?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={modules?.data?.data}
                    paginate={modules?.data}
                    columns={columns}
                    subColumn={subColumn}
                    subName={"childmodules"}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="25% 25% 25% 25%"
                    mobileWidth="40% 40% 40% 40%"
                    expand={true}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ModulesIndex
