import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import lightTrash from "../../../assets/icons/light-trash.svg"
import moment from "moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { fetchPurchase } from "../../../services/procurement/purchaseOrder"
import { postGRN } from "../../../services/procurement/grn"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import POPRoducts from "../../../components/Common/chooseFields/poproducts"
import GRNPurchaseNo from "../../../components/Common/chooseFields/grnPurchaseNo"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"

const schema = yup.object().shape({
  po_id: yup.mixed().required("Po number is required"),
  recieved_date: yup
    .mixed()
    .test(
      "is-future-date",
      "Make sure the received date is forward-looking",
      function (value) {
        if (!value) return true
        const currentDate = moment()
        const selectedDate = moment(value)
        return selectedDate.isSameOrBefore(currentDate, "day")
      }
    )
    .required("Received date is required"),

  // received_by: yup.string().required("Received by is required"),
  grnproductlist: yup.array().of(
    yup.object().shape({
      product_code: yup.mixed().required("product code is required"),
      description: yup.string().required("description is required"),
      unit: yup.mixed().required("Unit is Required"),
      received_quantity: yup
        .string()
        .matches(
          /^\d*(\.\d+)?$/,
          "Pending quantity must be a non-negative number"
        )
        .test(
          "max",
          "pending quantity must not exceed the ordered quantity",
          function (value) {
            const { pending_quantity } = this.parent
            return parseInt(value) <= pending_quantity
          }
        )
        .required("pending quantity is required"),
    })
  ),
})

const EditGRN = ({ fromDashboard, poValue, toggle }) => {
  const navigate = useNavigate()
  const { editId } = useParams()
  const id = fromDashboard ? "" : editId
  const [isLoading, setIsLoading] = useState(false)
  const [idPO, setIdPO] = useState()
  const location = useLocation()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      po_id: null,
      received_by: "",
      recieved_date: null,
      material_requests_id: "",
      grnproductlist: [
        {
          product_code: null,
          description: "",
          unit: null,
          ordered_quantity: 0,
          received_quantity: 0,
          pending_quantity: 0,
        },
      ],
    },
  })

  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onChange",
  //   shouldFocusError: true,
  // })
  const {
    fields: poFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "grnproductlist",
  })
  const watchTest = useWatch({
    control,
    name: "grnproductlist",
    defaultValue: poFields,
  })
  useEffect(() => {
    if (location?.state && !poValue) {
      reset({
        po_id: location?.state?.purchase_orders_code && {
          label: location?.state?.purchase_orders_code,
          value: location?.state?.id,
        },
      })

      fetchPotData(location?.state?.id)
      setIdPO(location?.state?.id)
    }
  }, [location])

  useEffect(() => {
    if (fromDashboard) {
      reset({
        po_id: poValue?.value && {
          label: poValue?.label,
          value: poValue?.value,
        },
      })

      handlePoChange(poValue)
    }
  }, [fromDashboard])
  const handleRegistration = async data => {
    // const Payterms = data?.pay_terms
    //   ? draftToHtml(convertToRaw(data?.pay_terms?.getCurrentContent()))
    //   : ""

    console.log(
      `Register ashika`,
      data?.grnproductlist?.reduce(
        (a, b) => a + Number(b?.received_quantity),
        0
      )
    )
    if (
      data?.grnproductlist?.reduce(
        (a, b) => a + Number(b?.received_quantity),
        0
      ) > 0
    ) {
      let arrayData = data?.grnproductlist?.map(element => ({
        product_code: element?.product_code?.value,
        description: element.description,
        unit: element.unit.value,
        ordered_quantity: element.ordered_quantity,
        received_quantity: element.received_quantity,
        pending_quantity: element.ordered_quantity - element.received_quantity,
      }))
      console.log(`arrayData`, arrayData)
      try {
        setIsLoading(true)
        const update = await postGRN({
          ...data,
          project_id: data?.project_id,
          material_requests_id: data?.material_requests_id,
          po_id: data?.po_id?.value,
          received_by: loggedUser?.data?.name,
          recieved_date: moment(data?.recieved_date).format("YYYY-MM-DD"),
          pay_terms: data?.pay_terms,
          grnproductlist: arrayData,
        })
        if (fromDashboard) {
          toggle()
        } else {
          navigate("/procurement-grn")
        }
        toastSuccess(update?.message)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    } else {
      toastError("To generate a GRN, at least one product must be received.")
    }
  }
  const handlePoChange = () => {
    const { po_id } = getValues()
    fetchPotData(po_id?.value)
    setIdPO(po_id?.value)
  }
  const fetchPotData = async idData => {
    const response = await fetchPurchase(idData)
    if (idData == response.data?.id) {
      const arrayData = response.data?.purchaseorder_lists
        ?.map(dt => {
          const TotalReceivedQty = dt.grnlist.reduce(
            (total, grnqty) => total + parseFloat(grnqty.received_quantity),
            0
          )

          const alreadyReceived = dt.grnlist.length
            ? dt.quantity - TotalReceivedQty
            : 0
          const Received = dt.grnlist.length ? alreadyReceived : dt.quantity

          return {
            product_code: dt?.product_code && {
              label: dt?.product_code.product_code,
              value: dt?.product_code.id,
            },
            description: dt.description,
            ordered_quantity: dt?.quantity,
            received_quantity: 0,
            pending_quantity: Received,
            unit: {
              value: dt?.unit?.id,
              label: dt?.unit?.unit_code,
            },
          }
        })
        .filter(item => item.pending_quantity !== 0)

      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_id: response.data?.project_id,
        material_requests_id: response?.data?.material_requests_id,
        po_date: moment(response.data?.created_at).format("DD-MM-YYYY"),
        mr_no: response.data?.material_request_id?.material_requests_code,
        mr_date: moment(response.data?.material_request_id?.created_at).format(
          "DD-MM-YYYY"
        ),
        supplier_code: response.data?.supplier?.suppliers_code,
        supplier_name: response.data?.supplier?.name,
        grnproductlist: arrayData,
        pay_terms: response.data?.pay_terms,
        recieved_date: moment().format("YYYY-MM-DD"),
      })
    }
  }
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {fromDashboard ? (
                    <div></div>
                  ) : (
                    <Breadcrumbs
                      title="Procurement"
                      breadcrumbObject={[
                        { title: "Procurement", link: "/procurement" },
                        { title: "GRN", link: "/procurement-grn" },
                        {
                          title: id ? "Edit GRN" : "Add GRN",
                          link: "",
                        },
                      ]}
                    />
                  )}
                  <LoadingButton
                    form={`request-form`}
                    color={"primary"}
                    loading={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          PO number <span className="mandatory">*</span>
                        </Label>
                        <GRNPurchaseNo
                          control={control}
                          placeholder="Enter the PO number"
                          name={"po_id"}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={handlePoChange}
                          invalid={!!errors.po_id}
                          disabled={fromDashboard ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.po_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>PO Date</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the po date "
                          name={"po_date"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>MR no</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the project name"
                          name={"mr_no"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>MR Date</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the  mr date"
                          name={"mr_date"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Supplier Code</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the supplier code"
                          name={"supplier_code"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Supplier Name</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the supplier name "
                          name={"supplier_name"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Received by <span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the requested by"
                          name={"received_by"}
                          invalid={!!errors.received_by}
                        />
                        <FormFeedback invalid>
                          {errors.received_by?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Received Date <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"recieved_date"}
                          invalid={!!errors.recieved_date}
                          maxDate={moment().format("YYYY-MM-DD")}
                        />
                        <FormFeedback invalid>
                          {errors.recieved_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />

                  <Row className="py-4 mt-4">
                    <h5>Material Details</h5>
                  </Row>

                  <Row className="mb-4">
                    <Col lg={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Ordered quantity</th>
                            <th>Received quantity</th>
                            <th>Pending quantity</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {poFields.map((item, index) => (
                            <tr key={`poFields${index}`}>
                              <td>
                                <POPRoducts
                                  control={control}
                                  placeholder="Product Code"
                                  name={`grnproductlist.${index}.product_code`}
                                  Poid={idPO}
                                  selectedProduct={watch(`grnproductlist`)}
                                  index={index}
                                  invalid={
                                    !!errors?.grnproductlist?.[index]
                                      ?.product_code
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]
                                      ?.product_code?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td>
                                <UFInput
                                  control={control}
                                  placeholder="Description"
                                  name={`grnproductlist.${index}.description`}
                                  invalid={
                                    !!errors?.grnproductlist?.[index]
                                      ?.description
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]?.description
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td>
                                <Unit
                                  control={control}
                                  placeholder="Unit"
                                  name={`grnproductlist.${index}.unit`}
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]?.unit
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td>
                                <p>{watchTest?.[index]?.ordered_quantity} </p>
                              </td>
                              <td>
                                <UFInput
                                  control={control}
                                  placeholder="Received"
                                  name={`grnproductlist.${index}.received_quantity`}
                                  invalid={
                                    !!errors?.grnproductlist?.[index]
                                      ?.received_quantity
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]
                                      ?.received_quantity?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td>
                                <p>
                                  {watchTest?.[index]?.pending_quantity -
                                    watchTest?.[index]?.received_quantity}{" "}
                                </p>
                              </td>
                              <td>
                                <div className="pb-1 px-2 d-flex justify-content-center">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => materialRemove(index)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Col
                        lg="12"
                        xs={12}
                        md={2}
                        sm={12}
                        className="d-flex justify-content-between"
                      >
                        {!fromDashboard && (
                          <Button
                            color="secondary"
                            className="addButton mt-2"
                            onClick={() =>
                              materialAppend({
                                product_code: null,
                                description: "",
                                // ordered_quantity: 0,
                                received_quantity: 0,
                                // pending_quantity: 0
                              })
                            }
                          >
                            Add Row
                          </Button>
                        )}
                      </Col>
                    </Col>
                  </Row>
                  <Row className="py-4 mt-4">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Payment Terms</Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the Payment Terms"
                          rows="5"
                          name={"pay_terms"}
                          invalid={!!errors.pay_terms}
                          className={"pb-0"}
                        />
                        {/* <RichTextEditor
                          control={control}
                          name={"pay_terms"}
                          invalid={!!errors.terms}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditGRN
