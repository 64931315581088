import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { getFinanceCatLists } from "../../../services/Settings/dropdown";

function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const FinanceCat = ({
  control,
  name,
  onSelectChange,
  disabled,
  target,
  placeholder,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([]);

  const loadOptions = async () => {
    const data = await getFinanceCatLists();
    setIsLoading(false);
    const series = data?.data?.map(dta => ({
      label: dta?.name && ucfirst(`${dta.name}`),
      value: dta.id,
      ...dta,
    }));
    setSeriesData(series);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  // useCallback to avoid re-rendering issues with onChange handler
  const handleChange = useCallback(
    e => {
      if (onSelectChange) onSelectChange(e);
    },
    [onSelectChange]
  );

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            options={seriesData}
            placeholder={placeholder || "Category"}
            isLoading={isLoading}
            inputRef={ref}
            onChange={e => {
              onChange(e);
              handleChange(e);
            }}
            value={value}
            isDisabled={disabled}
            classNamePrefix="react-select"
            className="elevate-dropdown"
            menuPortalTarget={document.querySelector(target || ".modal")}
          />
        )}
      />
    </div>
  );
};

export default FinanceCat;
