import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getAccountsLists } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const AccountsGroupLi = ({
  control,
  name,
  onSelectChange,
  target,
  placeholder,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchResponse, setFetchResponse] = useState([])

  const loadOptions = async () => {
    const data = await getAccountsLists()
    setIsLoading(false)

    // Group accounts by category_id
    const groupedOptions = data?.data?.reduce((groups, account) => {
      const groupLabel = `Category ${account.category_id}`
      const formattedAccount = {
        label: account.name && ucfirst(`${account.name} - (${account.code})`),
        value: account.id,
        ...account,
      }
      if (!groups[groupLabel]) groups[groupLabel] = []
      groups[groupLabel].push(formattedAccount)
      return groups
    }, {})

    // Format grouped options for react-select
    const formattedOptions = Object.keys(groupedOptions).map(category => ({
      label: category,
      options: groupedOptions[category],
    }))

    setFetchResponse(formattedOptions)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            options={fetchResponse}
            formatGroupLabel={(data) => <strong>{data.label}</strong>}
            placeholder={placeholder || "Select"}
            isLoading={isLoading}
            inputRef={ref}
            onChange={(e) => {
              onChange(e)
              if (onSelectChange) onSelectChange(e)
            }}
            value={value}
            isDisabled={disabled}
            classNamePrefix="react-select"
            className="elevate-dropdown"
            menuPortalTarget={document.querySelector(target || "body")}
          />
        )}
      />
    </div>
  )
}

export default AccountsGroupLi
