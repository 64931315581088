import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import {
  searchInventoryProduct,
  updateProductStatus,
} from "../../../services/Inventory/inventory"
import EditProduct from "./edit"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"
import ProductFilter from "./filter"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const Product = () => {
  document.title = "Product | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [refresh, setRefresh] = useState(0)
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [group, setGroup] = useState("")
  const [category, setCategory] = useState("")
  const [page, setPage] = useState("")

  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }
  const handleView = item => {
    setViewData(item)
    toggle()
  }
  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateProductStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "P Code",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "products_view"
            ) ? (
              <>
                <div
                  onClick={() => handleView(item)}
                  className="pointer fw-bold"
                  id={`PopoverLegacy-${item?.product_code}`}
                >
                  {item?.product_code}
                </div>
                <UncontrolledPopover
                  placement="bottom"
                  target={`PopoverLegacy-${item?.product_code}`}
                  trigger="hover"
                  className="popover-inset"
                >
                  <PopoverBody>
                    <div className="table-popup-content">
                      {item?.description}
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            ) : (
              <div>{item?.product_code}</div>
            )}
          </>
        )
      },
      sortKey: "product_code",
    },
    // {
    //   label: "Part Number",
    //   renderCell: item => item?.part_number,
    // },
    // {
    //   label: "Part Number",
    //   renderCell: item => item?.part_number,
    // },
    {
      label: "Cat Code",
      renderCell: item => {
        return (
          <>
            {" "}
            <div id={`PopoverLegacy-${item?.category?.category_code}`}>
              {item?.category?.category_code}
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.category?.category_code}`}
              trigger="hover"
            >
              <PopoverBody>
                <div className="table-popup-content">
                  {item?.category?.description}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        )
      },
    },
    // {
    //   label: "Group",
    //   renderCell: item => item?.group?.description,
    //   sortKey: "description",
    // },
    {
      label: "B Code",
      renderCell: item => {
        return (
          <>
            <div>{item?.brands?.description}</div>
          </>
        )
      },
    },
    {
      label: "T Code",
      renderCell: item => item?.types?.description,
    },
    {
      label: "U Code",
      renderCell: item => {
        return (
          <>
            <div id={`PopoverLegacy-${item?.unit?.unit_code}`}>
              {item?.unit?.unit_code}
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.unit?.unit_code}`}
              trigger="hover"
            >
              <PopoverBody>
                <div className="table-popup-content">
                  {item?.unit?.description}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        )
      },
    },
    {
      label: "Price (AED)",
      renderCell: item =>
        Number(Number(item?.price_aed).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        }),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "products_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchInventoryProduct({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${group ? `&group_id=${group}` : ""}${
          category ? `&category_id=${category}` : ""
        }${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [refresh, status, search, group, category, page])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleGroupChange = id => {
    setGroup(id)
    setPage(1)
  }
  const handleCatChange = id => {
    setCategory(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Inventory"
              breadcrumbObject={[
                { title: "Product Master", link: "/inventory" },
                { title: "Products", link: "/inventory-product" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "products_create"
            ) && (
              <EditProduct
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleReset}
                editValue={editClass}
                buttonLabel="+&nbsp; Add Products"
                className="px-2 mb-3 btn btn-primary"
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <ProductFilter
              handleRefresh={handleUpdate}
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleGroupChange={handleGroupChange}
              handleCatChange={handleCatChange}
              sampleUrl={"download/products.xlsx"}
              importUrl={"products/import"}
              isExport={
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "products_create"
                )
                  ? true
                  : false
              }
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 15% 15% 10% 10% 10% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg" unmountOnClose={false}>
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-4">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Product Code
              </Col>
              <Col className="primary-text">{viewData?.product_code}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Part Number
              </Col>
              <Col className="primary-text">{viewData?.part_number}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Description
              </Col>
              <Col className="primary-text">{viewData?.description}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Base Code
              </Col>
              <Col className="primary-text">{viewData?.description}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Category Code
              </Col>
              <Col className="primary-text">
                {viewData?.category?.category_code}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Group
              </Col>
              <Col className="primary-text">{viewData?.group?.description}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Brand Code
              </Col>
              <Col className="primary-text">
                {viewData?.brands?.description}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Type Code
              </Col>
              <Col className="primary-text">{viewData?.types?.description}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Unit Code
              </Col>
              <Col className="primary-text">{viewData?.unit?.description}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Price (ADE)
              </Col>
              <Col className="primary-text">{viewData?.price_aed}</Col>
            </Row>
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default Product
