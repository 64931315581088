import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import {
  fetchFinanceMappingSetup,
  postFinanceMappingSetup,
} from "../../../services/finance/finance"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import FinanceGroupTypeParent from "../../../components/Common/chooseFields/financeGroupParent"
import FinanceLedgersLi from "../../../components/Common/chooseFields/ledgers"

const schema = yup.object().shape({})
const EditMappingSetup = () => {
  document.title = "Mapping Setup | Ever Rise"
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("1")
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  })

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleRegistration = async data => {
    console.log(`regggg`, data)

    const storeData = data?.finance_mappings?.map((dt, i) => ({
      type:
        i === 0
          ? "purchase"
          : i === 1
          ? "sales"
          : i === 2
          ? "payroll"
          : "inventory",
      debit_account_id: dt?.debit_account_id?.value || null,
      credit_account_id: dt?.credit_account_id?.value || null,
    }))

    try {
      setIsLoading(true)
      const update = await postFinanceMappingSetup(
        { finance_mappings: storeData }
        //   {
        //   type: "procurement",
        //   po_payment_account: data?.purchase_pay?.id,
        //   po_deposit_account: data?.purchase_deposit?.id,
        //   po_invoice_payment_account: data?.purchase_pay_in?.id,
        //   po_invoice_deposit_account: data?.purchase_deposit_in?.id,
        // }
      )
      toastSuccess(update?.message)
      navigate("/finance")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchMapSetting = async () => {
    const result = await fetchFinanceMappingSetup()
    let mappingReset = result?.data?.map((dt, i) => ({
      debit_account_id: dt?.debit_account?.id
        ? {
            label: dt?.debit_account?.name,
            value: dt?.debit_account?.id,
          }
        : null,
      credit_account_id: dt?.credit_account?.id
        ? {
            label: dt?.credit_account?.name,
            value: dt?.credit_account?.id,
          }
        : null,
    }))
    reset({ finance_mappings: mappingReset })
    // reset({
    //   purchase_pay: {
    //     label: `${result?.data?.po_payment_account?.name} - ${result?.data?.po_payment_account?.code}`,
    //     value: result?.data?.po_payment_account?.id,
    //   },
    //   purchase_deposit: {
    //     label: `${result?.data?.po_deposit_account?.name} - ${result?.data?.po_deposit_account?.code}`,
    //     value: result?.data?.po_deposit_account?.id,
    //   },
    //   purchase_pay_in: {
    //     label: `${result?.data?.po_invoice_payment_account?.name} - ${result?.data?.po_invoice_payment_account?.code}`,
    //     value: result?.data?.po_invoice_payment_account?.id,
    //   },
    //   purchase_deposit_in: {
    //     label: `${result?.data?.po_invoice_deposit_account?.name} - ${result?.data?.po_invoice_deposit_account?.code}`,
    //     value: result?.data?.po_invoice_deposit_account?.id,
    //   },
    // })
  }

  useEffect(() => {
    fetchMapSetting()
  }, [])

  const mappingArr = [
    { name: "Purchase Voucher" },
    { name: "Sales Voucher" },
    { name: "Payroll Voucher" },
    { name: "Inventory Voucher" },
  ]

  // const { fields: mappingFields } = useFieldArray({
  //   control,
  //   name: "voucher_creation",
  // })

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`mapping-setup-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="Finance"
                    breadcrumbObject={[
                      { title: "Finance", link: "/finance" },
                      {
                        title: "Mapping Setup",
                        link: "/finance-mapping-setup",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`mapping-setup-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-0">
                  <Row className="">
                    {/* <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "1" ? "active pointer" : "pointer"
                          }
                          onClick={() => {
                            toggle("1")
                          }}
                        >
                          Purchase
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "2" ? "active pointer" : "pointer"
                          }
                          onClick={() => {
                            toggle("2")
                          }}
                        >
                          Payslip
                        </NavLink>
                      </NavItem>
                    </Nav> */}

                    <div className="my-2 mapping-setup-title">
                      Mapping Setups
                    </div>

                    {/* <TabContent activeTab={activeTab} className="pt-3">
                      <TabPane tabId={activeTab}>
                        {activeTab == 1 ? ( */}
                    <table className="estimation-table mb-4">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Credit Account</th>
                          <th>Debit Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mappingArr?.map((dt, i) => (
                          <tr>
                            <td>
                              <div className="mapping-setup-title ps-2">
                                {dt?.name}
                              </div>
                            </td>
                            <td className="w-35 ps-2">
                              <div>
                                <FinanceLedgersLi
                                  control={control}
                                  name={`finance_mappings.${i}.credit_account_id`}
                                  placeholder={"Acc-Code"}
                                  target={"body"}
                                />
                              </div>
                            </td>
                            <td className="w-35 ps-2">
                              <div>
                                <FinanceLedgersLi
                                  control={control}
                                  name={`finance_mappings.${i}.debit_account_id`}
                                  placeholder={"Acc-Code"}
                                  target={"body"}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                        {/* <tr>
                          <td>
                            <div className="mapping-setup-title">
                              Purchase Invoice
                            </div>
                          </td>
                          <td>
                            <div>
                              <AccountsGroupLi
                                control={control}
                                name={"purchase_pay_in"}
                                placeholder={"Acc-Code"}
                                target={"body"}
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <AccountsGroupLi
                                control={control}
                                name={"purchase_deposit_in"}
                                placeholder={"Acc-Code"}
                                target={"body"}
                              />
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    {/* ) : (
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Payment account</th>
                                <th>Deposit to</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="mapping-setup-title">
                                    Total insurance
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <AccountsGroupLi
                                      control={control}
                                      name={"purchase_pay_ti"}
                                      placeholder={"Acc-Code"}
                                      target={"body"}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <AccountsGroupLi
                                      control={control}
                                      name={"purchase_deposit_ti"}
                                      placeholder={"Acc-Code"}
                                      target={"body"}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="mapping-setup-title">
                                    Income tax paye
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <AccountsGroupLi
                                      control={control}
                                      name={"purchase_pay_itp"}
                                      placeholder={"Acc-Code"}
                                      target={"body"}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <AccountsGroupLi
                                      control={control}
                                      name={"purchase_deposit_itp"}
                                      placeholder={"Acc-Code"}
                                      target={"body"}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="mapping-setup-title">
                                    Net pay
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <AccountsGroupLi
                                      control={control}
                                      name={"purchase_pay_np"}
                                      placeholder={"Acc-Code"}
                                      target={"body"}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <AccountsGroupLi
                                      control={control}
                                      name={"purchase_deposit_np"}
                                      placeholder={"Acc-Code"}
                                      target={"body"}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </TabPane>
                    </TabContent> */}

                    {/* <Row className="mb-4 mt-2">
                      <Col sm={3} className="d-flex align-items-center">
                        <div className="mapping-setup-title">
                          Purchase order
                        </div>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Payment account
                            <span className="mandatory">*</span>
                          </Label>
                          <AccountsGroupLi
                            control={control}
                            name={"purchase_pay"}
                            placeholder={"Acc-Code"}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.purchase_pay?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Deposit to
                            <span className="mandatory">*</span>
                          </Label>
                          <AccountsGroupLi
                            control={control}
                            name={"purchase_deposit"}
                            placeholder={"Acc-Code"}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.purchase_deposit?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col sm={3} className="d-flex align-items-center">
                        <div className="mapping-setup-title">
                          Purchase Invoice
                        </div>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Payment account
                            <span className="mandatory">*</span>
                          </Label>
                          <AccountsGroupLi
                            control={control}
                            name={"purchase_pay"}
                            placeholder={"Acc-Code"}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.purchase_pay?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Deposit to
                            <span className="mandatory">*</span>
                          </Label>
                          <AccountsGroupLi
                            control={control}
                            name={"purchase_deposit"}
                            placeholder={"Acc-Code"}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.purchase_deposit?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditMappingSetup
