import React, { useEffect, useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import {
  fetchRevisions,
  updateRevisionStatus,
} from "../../../services/estimation/estimation"
import { toastSuccess } from "../../../helpers/msg_helper"
import downloadicon from "../../../assets/icons/download.svg"
import Logo from "../../../assets/images/logo.svg"
import { samplePdfFileDownload } from "../../../services/Inventory/inventory"
import {
  downloadpo,
  fetchPurchase,
} from "../../../services/procurement/purchaseOrder"
import parse from "html-react-parser"
import phoneIcon from "../../../assets/icons/phone.svg"
import webIcon from "../../../assets/icons/website.svg"
import locationIcaon from "../../../assets/icons/location.svg"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const ViewPurchaseOrder = ({ fromDashboard, viewValue }) => {
  document.title = "Purchase Order | Ever Rise"

  const navigate = useNavigate()

  const [revision, setRevisions] = useState()
  const [refresh, setRefresh] = useState(0)
  const [revisionType, setRevisionType] = useState({ index: "", status: false })
  const { id } = useParams()

  const fullUrl = window.location.pathname

  const viewId = fromDashboard ? viewValue?.value?.id : id

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  let payTerms
  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchPurchase(id)
      setRevisions(response?.data)
      payTerms = response?.data?.pay_terms
      console.log("response?.data?.length :", response?.data?.length)
      setOpen(response?.data?.length == 1 ? 0 : "")
    }
    fetchData(viewId)
  }, [refresh])

  const handleRevisionDownload = ({ id, type }) => {
    const handleCommonExport = async id => {
      const response = await samplePdfFileDownload(
        type == "short"
          ? `estimation/pdf-mini-statement/${id}`
          : `estimation/pdf/${id}`
      )
    }
    handleCommonExport(id)
  }

  const [open, setOpen] = useState("")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const handleApprove = item => {
    const updateStatus = async item => {
      const response = await updateRevisionStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }
  const handleRevisionType = index => {
    if (revisionType?.index === index) {
      setRevisionType({ index: index, status: !revisionType?.status })
    } else {
      setRevisionType({ index: index, status: true })
    }
  }
  console.log("revisionType:", revisionType)
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Purchase Order View"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: fullUrl.includes(
                      "procurement-company-purchase-order"
                    )
                      ? "Company Purchase Order"
                      : "Purchase Order",
                    link: fullUrl.includes("procurement-company-purchase-order")
                      ? `/procurement-company-purchase-order`
                      : `/procurement-purchase-order`,
                  },
                  {
                    title: fullUrl.includes(
                      "procurement-company-purchase-order"
                    )
                      ? "Company Purchase Order view"
                      : "Purchase Order view",
                    link: fullUrl.includes("procurement-company-purchase-order")
                      ? `/procurement-company-purchase-order/view/${viewId}`
                      : `/procurement-purchase-order/view/${viewId}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col lg={6} className="main-heading">
                    Purchase Order
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <img className="view-logo" src={Logo} alt="logo" />
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col lg={6} className="py-3 ">
                    <div className={"supplier-td-head pt-2"}>From</div>
                    <div className={"supplier-td-body pt-2"}>Everrise</div>
                    <div className={"supplier-td-head pt-2"}>
                      No-6/23, Nesamani Nagar, Nagercoil.
                    </div>
                    <div className={"supplier-td-head pt-2"}>
                      Mobile: +98765432543
                    </div>
                  </Col>
                  <Col lg={6} className="py-3">
                    <div className={"supplier-td-head pt-2"}>To</div>
                    <div className={"supplier-td-body pt-2"}>
                      {revision?.supplier?.name}
                    </div>
                    <div className={"supplier-td-head pt-2"}>
                      {revision?.supplier?.address}
                    </div>
                    <div className={"supplier-td-head pt-2"}>
                      Mobile: {revision?.supplier?.contact_number}
                    </div>
                  </Col>
                </Row>
                <hr className="m-0" />
                <Row className="py-4">
                  {revision?.material_request_id != null && (
                    <Col lg={3} className="py-2 pb-4">
                      <div className="supplier-td-head">MR NO</div>
                      <div className="supplier-td-body">
                        {revision?.material_request_id?.material_requests_code}
                      </div>
                    </Col>
                  )}
                  {revision?.project_master != null && (
                    <Col lg={3} className="py-2 pb-4">
                      <div className="supplier-td-head">Project Code</div>
                      <div className="supplier-td-body">
                        {revision?.project_master?.project_code}
                      </div>
                    </Col>
                  )}
                  {revision?.project_master != null && (
                    <Col lg={3} className="py-2 pb-4">
                      <div className="supplier-td-head">Project Name</div>
                      <div className="supplier-td-body">
                        {revision?.project_master?.project_name}
                      </div>
                    </Col>
                  )}
                  {/* <Col lg={3} className="py-2 pb-4">
                    <div className="supplier-td-head">Department</div>
                    <div className="supplier-td-body">Project Lorem</div>
                  </Col> */}
                  <Col lg={3} className="py-2 pb-4">
                    <div className="supplier-td-head">Credit</div>
                    <div className="supplier-td-body">Project Lorem</div>
                  </Col>
                  <Col lg={3} className="py-2 pb-4">
                    <div className="supplier-td-head">Request Date</div>
                    <div className="supplier-td-body">
                      {moment(revision?.request_date).format("DD/MM/YYYY")}
                    </div>
                  </Col>
                  <Col lg={3} className="py-2 pb-4">
                    <div className="supplier-td-head">Exp. Delivery Date</div>
                    <div className="supplier-td-body">
                      {moment(revision?.exp_Delivery_date).format("DD/MM/YYYY")}
                    </div>
                  </Col>
                  <Col lg={3} className="py-2 pb-4">
                    <div className="supplier-td-head">Contact Person</div>
                    <div className="supplier-td-body">
                      {revision?.supplier?.name}
                    </div>
                  </Col>
                  <Col lg={3} className="py-2 pb-4">
                    <div className="supplier-td-head">Contact Number</div>
                    <div className="supplier-td-body">
                      {"+" + revision?.supplier?.contact_number}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <table className="estimation-table mb-4">
                      <thead>
                        <tr>
                          <th>Sl.no</th>
                          <th>Product Code</th>
                          <th>Description</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {revision?.purchaseorder_lists?.map((dt, index) => (
                          <tr key={`po_${index}`}>
                            <td>{index + 1}</td>
                            <td>{dt?.product_code?.product_code}</td>
                            <td>{dt?.description}</td>
                            <td>{dt?.unit?.unit_code}</td>
                            <td>{dt?.quantity}</td>
                            <td>{dt?.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                  <Col sm={7}>
                    <Row className="pt-3 pay-terms">
                      <Col sm={8}>
                        <div>Payment Terms:</div>
                        <p className="pt-2 pay-terms">
                          {/* {revision?.pay_terms && parse(revision?.pay_terms)} */}
                          {revision?.pay_terms}
                        </p>
                      </Col>
                    </Row>
                    <Row className="pt-3 pay-terms">
                      <Col sm={8}>
                        <div>Remarks:</div>
                        <p className="pt-2">
                          {/* {revision?.remarks && parse(revision?.remarks)} */}
                          {revision?.remarks}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={5}>
                    <Row className="d-flex align-items-center py-3">
                      <Col sm={6} className="text-end">
                        Sub total
                      </Col>
                      <Col sm={6} className="po-view-text">
                        {revision?.sub_total}
                      </Col>
                    </Row>
                    {revision?.tax != 0 && (
                      <Row className="d-flex align-items-center py-3">
                        <Col sm={6} className="text-end">
                          VAT
                        </Col>
                        <Col sm={6} className="po-view-text">
                          {revision?.tax}%
                        </Col>
                      </Row>
                    )}
                    {revision?.discount != 0 && (
                      <Row className="d-flex align-items-center py-3">
                        <Col sm={6} className="text-end">
                          Discount
                        </Col>
                        <Col sm={6} className="po-view-text">
                          {revision?.discount}%
                        </Col>
                      </Row>
                    )}
                    {revision?.shipping_charge != 0 && (
                      <Row className="d-flex align-items-center py-3">
                        <Col sm={6} className="text-end">
                          Shipping Charge
                        </Col>
                        <Col sm={6} className="po-view-text">
                          {revision?.shipping_charge
                            ? revision?.shipping_charge
                            : 0}
                        </Col>
                      </Row>
                    )}
                    {revision?.other_charge != 0 && (
                      <Row className="d-flex align-items-center py-3">
                        <Col sm={6} className="text-end">
                          Other Charge
                        </Col>
                        <Col sm={6} className="po-view-text">
                          {revision?.other_charge ? revision?.other_charge : 0}
                        </Col>
                      </Row>
                    )}
                    <Row className="d-flex align-items-center py-3">
                      <Col sm={6} className="text-end dark-text">
                        Total Amount
                      </Col>
                      <Col sm={6} className="po-view-text">
                        {revision?.total_amount}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <hr className="m-0" /> */}
                {/* <div className="d-flex justify-content-between align-items-center pt-3">
                  <div className="po-contact">
                    <span>Contact Us</span>
                  </div>
                  <div className="po-footer d-flex align-items-center">
                    <span className="pe-2">
                      <img src={phoneIcon} alt="" height="15" />
                    </span>
                    <span>+91 98765 43210</span>
                  </div>
                  <div className="po-footer d-flex align-items-center">
                    <span className="pe-2">
                      <img src={webIcon} alt="" height="15" />
                    </span>
                    <span>everrise.com</span>
                  </div>
                  <div className="po-footer d-flex align-items-center">
                    <span className="pe-2">
                      <img src={locationIcaon} alt="" height="15" />
                    </span>
                    <span>everrise, Hongkong, China</span>
                  </div>
                </div> */}
                <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                  <div className="font-bold-800">Contact Us</div>
                  <div>
                    <img
                      src={phoneIcon}
                      alt="location"
                      className="footer-logo"
                    />{" "}
                    +{loggedUser?.settings?.phone}
                  </div>
                  <div>
                    <img src={webIcon} alt="location" className="footer-logo" />{" "}
                    {loggedUser?.settings?.website}
                  </div>
                  <div>
                    <img
                      src={locationIcaon}
                      alt="location"
                      className="footer-logo"
                    />{" "}
                    {loggedUser?.settings?.companyaddress}
                  </div>
                </div>
              </div>
              {!fromDashboard && (
                <Row>
                  <Col lg={8}>
                    <div className="d-flex justify-content-end me-2 pr-50">
                      <span></span>
                      {revision?.status === 1 && (
                        <Button
                          className="px-3 mb-3 btn-secondary me-2"
                          form={`ev-form`}
                          color={"secondary"}
                          onClick={() =>
                            navigate(
                              `/procurement-purchase-order/edit/${revision?.id}`
                            )
                          }
                        >
                          Edit
                        </Button>
                        // <div
                        //   // className="table-popup-content"
                        //   onClick={() =>
                        //     navigate(`/procurement-purchase-order/edit/${revision?.id}`)
                        //   }
                        // >
                        //   Edit
                        // </div>
                      )}
                      <Button
                        form={`supplier-form`}
                        color={"primary"}
                        className={"px-2 mb-3 btn btn-primary"}
                        onClick={() => downloadpo(viewId, "po.pdf")}
                      >
                        Download
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewPurchaseOrder
