import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Route, Routes } from "react-router-dom"
import { connect, useSelector } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
// import "./assets/scss/colorTheme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Import Fack Backend
import fakeBackend from "./helpers/AuthType/fakeBackend"
import { createSelector } from "reselect"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const selectLoginState = state => state.Login
const LoginProperties = createSelector(selectLoginState, login => ({
  loggedUser: login.loggedUser,
}))

const App = props => {
  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    // Conditionally import color theme based on loggedUser's theme setting
    if (loggedUser?.settings?.theme_option == 1) {
      import("./assets/scss/colorTheme.scss")
    }
  }, [loggedUser?.settings?.theme_option])
  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                {route.path == "/network-error" ? (
                  <div>{route.component}</div>
                ) : (
                  <Layout>{route.component}</Layout>
                )}
              </Authmiddleware>
            }
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
