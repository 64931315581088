import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

function ucfirst(str) {
  const firstLetter = str.substr(0, 1);
  return firstLetter.toUpperCase() + str.substr(1);
}

const GratuityYears = ({ control, name, onSelectChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [statusData, setStatusData] = useState([]);

  const loadOptions = async () => {
    // Create options for numbers 1 to 10
    const data = Array.from({ length: 10 }, (_, index) => ({
      label: `${index}`,
      value: index,
    }));

    setIsLoading(false);
    const statusOptions = data.map(dta => ({
      label: dta?.label && ucfirst(dta.label),
      value: dta.value,
      ...dta,
    }));

    setStatusData(statusOptions);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            options={statusData}
            placeholder={"Select"}
            isLoading={isLoading}
            inputRef={ref}
            onChange={e => {
              onChange(e);
              if (onSelectChange) onSelectChange(e);
            }}
            value={value}
            classNamePrefix="react-select"
            className="elevate-dropdown"
            menuPortalTarget={document.querySelector("body")}
          />
        )}
      />
    </div>
  );
};

export default GratuityYears;
