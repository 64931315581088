import React, { useEffect } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, Col, Container, FormGroup, Label, Row } from "reactstrap"
import ThemeOptionDrop from "../../../components/Common/chooseFields/themeDrop"
import { useForm } from "react-hook-form"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { postThemeOption } from "../../../services/Settings/settings"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
const ThemeOptions = () => {
  document.title = "Theme Option | Ever Rise"

  const { control, reset, watch } = useForm()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    if (loggedUser?.settings?.theme_option) {
      reset({
        theme_option: {
          value: loggedUser?.settings?.theme_option,
          label: loggedUser?.settings?.theme_option == 0 ? "Default" : "Yellow",
        },
      })
    }
  }, [loggedUser])

  const handleChange = async data => {
    try {
      const response = await postThemeOption({ theme_option: data?.value })
      toastSuccess(response?.message)
      window.location.reload()
    } catch (err) {
      toastError(err?.message)
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            <Breadcrumbs
              title="Theme Options"
              breadcrumbObject={[
                { title: "Theme Options", link: "/theme-options" },
              ]}
            />
            {/* <EditUser
              key={"x"}
              // onUpdate={handleUpdate}
              onReset={handleResetValue}
              buttonLabel="+&nbsp; Add User"
              className="px-2 mb-3 btn btn-primary"
              editItem={editPassword}
              fromProfile={true}
            /> */}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card ">
              <Row className="mt-3 ms-3">
                <Col sm={3}>
                  <FormGroup className="mb-4">
                    <Label>Theme</Label>
                    <ThemeOptionDrop
                      control={control}
                      name={"theme_option"}
                      onSelectChange={e => handleChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ThemeOptions
