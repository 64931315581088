import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import LoadingButton from "../../../components/Common/LoadingButton"
import { useNavigate, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import MaterialRequest from "../../../components/Common/chooseFields/materialrequest"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import SupplierList from "../../../components/Common/chooseFields/supplier"
import uploadicon from "../../../assets/icons/export-light.svg"
import lightTrash from "../../../assets/icons/light-trash.svg"
import {
  fetchPurchase,
  postPurchaseOrder,
} from "../../../services/procurement/purchaseOrder"
import { fetchRequest } from "../../../services/procurement/materialrequest"
import { fetchSupplier } from "../../../services/procurement/supplier"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import Productcode from "../../../components/Common/chooseFields/Product"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import POMaterialRequest from "../../../components/Common/chooseFields/poMaterialRequest"
import Unit from "../../../components/Common/chooseFields/unit"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import moment from "moment"
import { useSelector } from "react-redux"
import Info from "../../../assets/icons/info.svg"
import CreditPeriod from "../../../components/Common/chooseFields/creditPeriod"
import ProductList from "../../../components/Common/chooseFields/productList"

const EditPurchaseOrder = ({ fromDashboard, materialValue, toggle }) => {
  document.title = "Purchase Order | Ever Rise"
  const { action, editId } = useParams()
  const navigate = useNavigate()

  console.log(`action`, action)

  const id = fromDashboard ? "" : editId
  const schema = yup.object().shape({
    material_requests_id:
      action == "company-req"
        ? yup.mixed()
        : yup.mixed().required("MR no is Required"),
    supplier_id: yup.mixed().required("Supplier is Required"),
    contact_person: yup.string().required("Contact Person is Required"),
    creditperiod: yup.mixed().required("Credit Period is Required"),
    contact_number: yup
      .number()
      .required("Contact Number is Required")
      .typeError("Contact Number Must be Number"),
    shipping_address: yup.string().required("Shipping Address is Required"),
    remarks: yup.string().required("Remark is Required"),
    pay_terms: yup.string().required("Payment Terms is Required"),
    purchase: yup.array().of(
      yup.object().shape({
        product_code: yup.mixed().required("Project Code is Required"),
        description: yup.string().required("Description is Required"),
        quantity:
          action == "company-req"
            ? yup
                .string()
                .matches(
                  /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
                  "Quantity must be a non-negative number and cannot be zero"
                )
                .required("Quantity is required")
            : yup
                .string()
                .matches(
                  /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
                  "Quantity must be a non-negative number and cannot be zero"
                )
                .test(
                  "max",
                  "Received quantity must not exceed the ordered quantity",
                  function (value) {
                    console.log(`tt`, this.parent, value)

                    const { initial_quantity } = this.parent
                    const { received_quantity } = this.parent
                    const validateqty = initial_quantity - received_quantity
                    console.log(initial_quantity, "initial_quantity")
                    console.log(received_quantity, "received_quantity")
                    console.log(validateqty, "validateqty")
                    console.log(
                      value,
                      validateqty,
                      initial_quantity,
                      received_quantity,
                      "asdhasdiu"
                    )
                    return parseInt(value) >= Number(validateqty)
                  }
                )
                .required("Quantity is required"),
        price: yup.string().required("Price is Required"),
      })
    ),
    discount: yup
      .number()
      .min(0, "Discount value cannot be negative")
      .max(100, "Discount value cannot be greater than 100"),
    shipping_charge: yup
      .number()
      .min(0, "Shipping Charge cannot be less than 0"),
    other_charge: yup.number().min(0, "Other Charge cannot be less than 0"),
    tax: yup
      .number()
      .min(0, "Tax value cannot be negative")
      .max(100, "Tax value cannot be greater than 100"),
  })

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const [isLoading, setIsLoading] = useState(false)
  const [attachment, setAttachment] = useState()
  const [materialData, setMaterialData] = useState()
  const [supplierData, setSupplierData] = useState()
  const [projectID, setProjectID] = useState()
  const location = useLocation()

  useEffect(() => {
    if (fromDashboard) {
      reset({
        material_requests_id: materialValue?.value && {
          label: materialValue?.label,
          value: materialValue?.value,
        },
        pay_terms: loggedUser?.settings?.po_pay_term,
        remarks: loggedUser?.settings?.po_remarks,
      })

      handleMatrial(materialValue?.value)
    }
  }, [fromDashboard])
  console.log(`location`, location)

  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onChange",
  //   shouldFocusError: true,
  // })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      tax: 0,
      discount: 0,
      shipping_charge: 0,
      other_charge: 0,
      purchase: [
        {
          product_code: "",
          description: "",
          quantity: "",
          discount: "",
          price: "",
        },
      ],
    },
  })

  console.log(`pay_terms`, watch("pay_terms"), materialData)

  const calculation = type => {
    const productWatch = watch("purchase")
    const taxWatch = watch("tax")
    const discountWatch = watch("discount")
    const otherChargeWatch = watch("other_charge")
    const shippingChargeWatch = watch("shipping_charge")
    console.log(`productWatch`, productWatch)
    switch (type) {
      case "price": {
        return productWatch?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      case "quantity": {
        return productWatch?.reduce((a, b) => Number(b.quantity) + a, 0)
      }
      case "sub_total": {
        const products = productWatch || [] // Ensure productWatch is an array
        const subTotal = products.reduce((acc, product) => {
          const price = Number(product.price)
          const quantity = Number(product.quantity)
          const rowTotal = price * quantity
          return acc + rowTotal
        }, 0)
        return subTotal
      }
      // case "sub_total": {
      //   return calculation("price") * calculation("quantity")
      // }
      case "total_amount": {
        return (
          calculation("sub_total") +
          (calculation("sub_total") * Number(taxWatch)) / 100 -
          (calculation("sub_total") * Number(discountWatch)) / 100 +
          Number(otherChargeWatch) +
          Number(shippingChargeWatch)
        )
      }

      default:
        return 0
    }
  }

  console.log(
    `calculation("sub_total")`,
    calculation("sub_total"),
    calculation("price"),
    calculation("quantity")
  )

  const onAttachmentChange = event => {
    setAttachment(event?.target?.files?.[0])
  }
  useEffect(() => {
    if (location?.state && !materialValue) {
      console.log(location?.state, "location")
      reset({
        material_requests_id: location?.state?.id && {
          label: location?.state?.material_requests_code,
          value: location?.state?.id,
        },
        pay_terms: loggedUser?.settings?.po_pay_term,
        remarks: loggedUser?.settings?.po_remarks,
      })

      handleMatrial(location?.state?.id)
    }
  }, [location])
  const handleRegistration = async data => {
    if (!id && !attachment) {
      toastError("Attachment is Required")
    } else {
      const purchasePostArr = data?.purchase?.map(dt => ({
        description: dt?.description,
        price: dt?.price,
        product_code: dt?.product_code?.value,
        quantity: dt?.quantity,
        id: id ? dt?.id : "",
        unit_id: dt?.unit?.value,
      }))

      // const payTermsTemplate = data?.pay_terms
      //   ? draftToHtml(convertToRaw(data?.pay_terms?.getCurrentContent()))
      //   : ""

      // const remarkTemplate = data?.remarks
      //   ? draftToHtml(convertToRaw(data?.remarks?.getCurrentContent()))
      //   : ""

      try {
        console.log(" data in the handle register :", data)
        setIsLoading(true)
        const createPurchase = await postPurchaseOrder({
          ...data,
          project_id:
            action == "company-req" ? "" : materialData?.data?.project?.id,
          purchase: purchasePostArr,
          // pay_terms: payTermsTemplate,
          // remarks: remarkTemplate,
          material_requests_id:
            action == "company-req" ? "" : data?.material_requests_id?.value,
          credit: data?.credit?.value,
          request_date: moment(data?.request_date).format("YYYY-MM-DD"),
          exp_Delivery_date: moment(data?.exp_Delivery_date).format(
            "YYYY-MM-DD"
          ),
          supplier_id: data?.supplier_id?.id,
          sub_total: calculation("sub_total"),
          discount: data?.discount,
          other_charge: data?.other_charge,
          shipping_charge: data?.shipping_charge,
          tax: data?.tax,
          total_amount: calculation("total_amount"),
          ...(typeof attachment !== "string" && { attachment: attachment }),
        })
        if (fromDashboard) {
          toggle()
        } else {
          navigate(
            action == "company-req"
              ? "/procurement-company-purchase-order"
              : "/procurement-purchase-order"
          )
        }
        toastSuccess(createPurchase?.message)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const Requestarray = materialData?.data?.requests?.reduce(
    (total, res) => total + parseFloat(res.quantity),
    0
  )
  const quantityEntered = calculation("quantity")

  // if (quantityEntered > Requestarray) {
  //   toastError("Test")
  // }

  const handleMatrial = async e => {
    const previousDatas = getValues()

    console.log("previousDatas :", previousDatas)
    const response = await fetchRequest(e)
    setMaterialData(response)
    setProjectID(previousDatas?.material_requests_id?.id)
    const projectVar = response?.data?.project?.var_value

    if (e == response.data?.id) {
      console.log(" comming inside if")
      const Requestarray = response.data?.requests
        ?.map(dt => {
          const poFilter = dt.po_list.filter(dt => dt?.po_id?.status == 0)
          const projectMrQuantitySum = poFilter.reduce(
            (total, mr) => total + parseFloat(mr.quantity),
            0
          )
          const alreadyReceived = dt.po_list.length
            ? dt.quantity - projectMrQuantitySum
            : dt.quantity

          if (alreadyReceived > 0) {
            setValue("sub_total", calculation("sub_total"))

            const quantity = dt.po_list.length ? alreadyReceived : dt.quantity
            const supplierList = dt?.unique_suppliers
            const shortSupplier =
              supplierList?.length > 3
                ? supplierList?.slice(0, 3)
                : supplierList
            const supplierMap = shortSupplier?.map(dt => dt)
            console.log(`quan`, supplierMap)
            return {
              product_code: {
                value: dt?.product?.id,
                label: dt?.product?.product_code,
              },
              description: dt?.description,
              quantity: quantity,
              price: (dt?.product?.price_aed * projectVar) / 100,
              initial_quantity: dt?.quantity,
              received_quantity: alreadyReceived,
              unit: {
                value: dt?.unit?.id,
                label: dt?.unit?.unit_code,
              },
              short_supplier: supplierMap,
            }
          }
        })
        .filter(item => item !== undefined)

      const purchaseArr = response?.data?.requests?.map(dt => ({
        product_code: {
          value: dt?.product?.id,
          label: dt?.product?.product_code,
        },
        description: dt?.product?.description,
        quantity: dt?.quantity,
        discount: dt?.product?.discount,
        price: dt?.product?.price,
        unit: {
          value: dt?.unit?.id,
          label: dt?.unit?.unit_code,
        },
      }))
      console.log(`pro`, purchaseArr)
      reset({
        ...previousDatas,
        request_date: response?.data?.request_date,
        exp_Delivery_date: response?.data?.exp_Delivery_date,
        purchase: response?.data?.requests?.length
          ? Requestarray
          : [
              {
                product_code: null,
                description: "",
                quantity: "",
                discount: "",
                price: "",
                unit: null,
              },
            ],
      })
    }
  }

  useEffect(() => {
    const fetchEdit = async id => {
      if (id) {
        const response = await fetchPurchase(id)
        setMaterialData(response)
        setProjectID(response?.data?.material_request_id?.id)
        // const purchaseArr = response?.data?.purchaseorder_lists?.map(dt => ({
        //   product_code: {
        //     value: dt?.product_code?.id,
        //     label: dt?.product_code?.product_code,
        //   },
        //   description: dt?.product_code?.description,
        //   quantity: dt?.quantity,
        //   discount: dt?.product?.discount,
        //   price: dt?.purchaseorder_lists?.price_aed,
        // }))

        const Requestarray = response.data?.material_request_id?.requests?.map(
          dt => {
            const poFilter = dt.po_list.filter(dt => dt?.po_id?.status == 0)
            const projectMrQuantitySum = poFilter.reduce(
              (total, mr) => total + parseFloat(mr.quantity),
              0
            )
            const alreadyReceived = dt.po_list.length
              ? dt.quantity - projectMrQuantitySum
              : dt.quantity
            const quantity = dt.po_list.length ? alreadyReceived : dt.quantity
            // const alreadyReceived = dt.quantity - projectMrQuantitySum
            // const quantity = dt.po_list.length ? alreadyReceived : dt.quantity

            return {
              product_code: {
                value: dt?.product?.id,
                label: dt?.product?.product_code,
              },
              id: dt?.id,
              description: dt?.description,
              quantity: dt?.quantity,
              price: dt?.product?.price_aed,
              initial_quantity: dt?.quantity,
              received_quantity: alreadyReceived,
              unit: {
                value: dt?.unit?.id,
                label: dt?.unit?.unit_code,
              },
            }
          }
        )

        console.log(`Requestarray`, Requestarray)

        const formatTemplate = html => {
          let editorState = EditorState.createEmpty()
          if (html === null) {
            return editorState
          }
          const contentBlock = htmlToDraft(html)
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          )
          editorState = EditorState.createWithContent(contentState)
          return editorState
        }

        const previousDatas = getValues()
        console.log(`res`, response, `previousDatas`, previousDatas)
        reset({
          ...response?.data,
          pay_terms: response?.data?.pay_terms,
          // ? formatTemplate(response?.data?.pay_terms)
          // : "",
          remarks: response?.data?.remarks,
          // ? formatTemplate(response?.data?.remarks)
          // : "",
          material_requests_id: {
            value: response?.data?.material_requests_id?.id,
            label: response?.data?.material_request_id?.material_requests_code,
          },
          purchase: response?.data?.material_request_id?.requests?.length
            ? Requestarray
            : [
                {
                  product_code: null,
                  description: "",
                  quantity: "",
                  discount: "",
                  price: "",
                },
              ],
          //   request_date: moment(
          //     response?.data?.material_requests_id?.request_date
          //   ),
          //   contact_person: response?.data?.contact_person,
          //   contact_number: response?.data?.contact_number,
          supplier_id: {
            value: response?.data?.supplier?.id,
            label: response?.data?.supplier?.name,
          },
          //   shipping_address: response?.data?.shipping_address,
          sub_total: Number(response?.data?.sub_total),
        })
        setAttachment(response?.data?.attachment?.split("/").pop())
      }
    }
    if (!fromDashboard) {
      fetchEdit(id)
    }
  }, [id])

  console.log(`supplier`, supplierData)

  const handleSupplier = async e => {
    const response = await fetchSupplier(e?.id)
    setSupplierData(response)
    const previousDatas = getValues()
    reset({
      ...previousDatas,
      contact_number: response?.data?.contact?.[0]?.phone_no,
      contact_person: response?.data?.contact?.[0]?.supplier_contact_name,
      creditperiod: {
        value: response?.data?.creditperiod?.id,
        label: response?.data?.creditperiod?.label,
      },
    })
  }

  const {
    fields: purchaseOrderList,
    append: purchaseAppend,
    remove: purchaseRemove,
  } = useFieldArray({
    control,
    name: "purchase",
  })

  useEffect(() => {
    setValue("pay_terms", loggedUser?.settings?.po_pay_term)
    setValue("remarks", loggedUser?.settings?.po_remarks)
  }, [loggedUser])

  const handleCompanyProduct = (e, index) => {
    console.log(`handleCompanyProduct`, e)
    setValue(`purchase.${index}.description`, e?.description)
    setValue(`purchase.${index}.unit`, {
      label: e?.unit?.unit_code,
      value: e?.unit?.id,
    })
    setValue(`purchase.${index}.price`, e?.price_aed)
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                {fromDashboard ? (
                  <div></div>
                ) : (
                  <Breadcrumbs
                    title="Procurment"
                    breadcrumbObject={[
                      { title: "Procurment", link: "/procurement" },
                      {
                        title:
                          action == "company-req"
                            ? "Company Purchase Order"
                            : "Purchase Order",
                        link:
                          action == "company-req"
                            ? "/procurement-company-purchase-order"
                            : "/procurement-purchase-order",
                      },
                      {
                        title: id
                          ? "Edit Purchase Order"
                          : action == "company-req"
                          ? "Create Company Purchase Order"
                          : "Create Purchase Order",
                        link: id
                          ? `/procurement-purchase-order/edit/${id}`
                          : action == "company-req"
                          ? "/procurement-purchase-order/company-req"
                          : "/procurement-purchase-order/add",
                      },
                    ]}
                  />
                )}
                <LoadingButton
                  form={`supplier-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  className={"px-2 mb-3 btn btn-primary"}
                  type="submit"
                >
                  {id ? "Update" : "Save"}
                </LoadingButton>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    {action == "company-req" ? (
                      <Col sm={4}>
                        <Label>Company Request</Label>
                        <UFInput
                          // className="total-placeholder"
                          control={control}
                          name="company"
                          placeholder="Company Request"
                          disabled={true}
                        />
                      </Col>
                    ) : (
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            MR No<span className="mandatory">*</span>
                          </Label>
                          <POMaterialRequest
                            control={control}
                            name={"material_requests_id"}
                            placeholder={"MR No"}
                            target={fromDashboard ? ".modal" : "body"}
                            onSelectChange={e => handleMatrial(e?.id)}
                            disabled={id || fromDashboard ? true : false}
                          />
                          <FormFeedback invalid>
                            {errors.material_requests_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Credit<span className="mandatory">*</span>
                        </Label>
                        <PaymentMode
                          control={control}
                          placeholder="select"
                          name={"credit"}
                          invalid={!!errors.credit}
                          target={fromDashboard ? ".modal" : "body"}
                        />
                        <FormFeedback invalid>
                          {errors.credit?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Request Date</Label>
                        <UFDate
                          control={control}
                          name={"request_date"}
                          invalid={!!errors.request_date}
                        />
                        <FormFeedback invalid>
                          {errors.request_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Exp Delivery Date</Label>
                        <UFDate
                          control={control}
                          name={"exp_Delivery_date"}
                          invalid={!!errors.exp_Delivery_date}
                        />
                        <FormFeedback invalid>
                          {errors.exp_Delivery_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Supplier</Label>
                        <SupplierList
                          control={control}
                          placeholder="select"
                          name={"supplier_id"}
                          invalid={!!errors.supplier_id}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={e => handleSupplier(e)}
                        />
                        <FormFeedback invalid>
                          {errors.supplier_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Contact person
                          <span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Contact Person"
                          name={"contact_person"}
                          invalid={!!errors.contact_person}
                        />
                        <FormFeedback invalid>
                          {errors.contact_person?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Row>
                      <Col lg={4}>
                        <Row>
                          <Col sm={13}>
                            <FormGroup className="mb-4">
                              <Label>Credit Period</Label>
                              <CreditPeriod
                                control={control}
                                placeholder="select"
                                name={"creditperiod"}
                                invalid={!!errors.creditperiod}
                                target={fromDashboard ? ".modal" : "body"}
                                disable={true}
                              />
                              <FormFeedback invalid>
                                {errors.creditperiod?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>

                          <Col sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Contact number
                                <span className="mandatory">*</span>
                              </Label>
                              {/* <UFInput
                          control={control}
                          placeholder="Enter the Contact Number"
                          name={"contact_number"}
                          invalid={!!errors.contact_number}
                        /> */}
                              <PhoneInputField
                                control={control}
                                name={"contact_number"}
                                invalid={!!errors.contact_number}
                              />
                              <FormFeedback invalid>
                                {errors.contact_number?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={8}>
                        <Row>
                          <Col lg={6}>
                            <FormGroup className="mb-4">
                              <Label>
                                Shipping address
                                <span className="mandatory">*</span>
                              </Label>
                              <UFEditor
                                control={control}
                                placeholder="Enter the Address"
                                rows="5"
                                name={"shipping_address"}
                                invalid={!!errors.shipping_address}
                                className={"pb-0"}
                              />
                              <FormFeedback invalid>
                                {errors.shipping_address?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                            <FormGroup className="mb-4">
                              <Label>
                                Attachment<span className="mandatory">*</span>
                              </Label>
                              <Col className="file-import-div import-light p-4">
                                <label
                                  htmlFor="attachment"
                                  className="m-0 pointer w-100"
                                >
                                  <input
                                    type="file"
                                    id="attachment"
                                    name="attachment"
                                    accept=".pdf"
                                    onChange={event => {
                                      onAttachmentChange(event)
                                    }}
                                  />

                                  <div className="d-flex justify-content-center pb-2">
                                    <img
                                      src={uploadicon}
                                      alt="Export-light"
                                      className="Export-light "
                                    />
                                  </div>
                                  {attachment ? (
                                    <div className="d-flex justify-content-center secondary-text">
                                      <span>
                                        {attachment?.name || attachment}
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="d-flex justify-content-center secondary-text">
                                        <span className="upload">
                                          Upload&nbsp;
                                        </span>
                                        <span>Attachment</span>
                                      </div>
                                    </>
                                  )}
                                </label>
                              </Col>
                              <FormFeedback invalid>
                                {errors.attachment?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                  <hr className="m-0" />
                  <Row className="py-4 mt-4">
                    <h5>Material Details</h5>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            {/* <th>Discount (%)</th> */}
                            <th>Price</th>
                            {/* <th>Tax code</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseOrderList.map((item, index) => (
                            <tr key={`purchase${index}`}>
                              <td>
                                {action == "company-req" ? (
                                  <ProductList
                                    control={control}
                                    placeholder="Product Code"
                                    // projectid={projectID}
                                    selectedProduct={watch(`purchase`)}
                                    index={index}
                                    name={`purchase.${index}.product_code`}
                                    invalid={
                                      !!errors?.purchase?.[index]?.product_code
                                    }
                                    onSelectChange={e =>
                                      handleCompanyProduct(e, index)
                                    }
                                  />
                                ) : (
                                  <Productcode
                                    control={control}
                                    placeholder="Product Code"
                                    projectid={projectID}
                                    selectedProduct={watch(`purchase`)}
                                    index={index}
                                    name={`purchase.${index}.product_code`}
                                    invalid={
                                      !!errors?.purchase?.[index]?.product_code
                                    }
                                  />
                                )}
                              </td>
                              <td>
                                <div className="d-flex justify-content-between">
                                  <UFInput
                                    control={control}
                                    placeholder="Description"
                                    name={`purchase.${index}.description`}
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="svg-font pe-1 pointer"
                                    id={`PopoverLegacy-${index}`}
                                  >
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                  </svg>
                                  {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                  </svg> */}

                                  {/* <img
                                    src={Info}
                                    alt="info"
                                    className="pe-1 pointer"
                                    id={`PopoverLegacy-${index}`}
                                    // data-bs-toggle="tooltip"
                                    // data-bs-placement="top"
                                    // title={watch(`purchase?.${index}?.short_supplier`)?.map(dt => dt)}
                                  /> */}
                                  <UncontrolledPopover
                                    placement="bottom"
                                    target={`PopoverLegacy-${index}`}
                                    trigger="hover"
                                    container={
                                      fromDashboard ? ".modal" : "body"
                                    }
                                    // placement="top"
                                  >
                                    <PopoverBody>
                                      <div className="table-popup-content">
                                        <div className="pb-2">
                                          Recent Suppliers
                                        </div>
                                        {watch(
                                          `purchase.${index}.short_supplier`
                                        )?.map(dt => (
                                          <div className="py-1">
                                            <span>{dt?.suppliers_code}</span>{" "}
                                            <span className="ps-2">
                                              {dt?.name}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                  {console.log(
                                    `watch`,
                                    watch(`purchase.${index}.short_supplier`)
                                  )}
                                </div>
                              </td>
                              <td>
                                <Unit
                                  control={control}
                                  placeholder="Unit"
                                  name={`purchase.${index}.unit`}
                                />
                              </td>
                              <td>
                                <UFInput
                                  control={control}
                                  placeholder="Quantity"
                                  name={`purchase.${index}.quantity`}
                                  invalid={
                                    !!errors?.purchase?.[index]?.quantity
                                  }
                                />
                                <FormFeedback invalid>
                                  {errors?.purchase?.[index]?.quantity?.message}
                                </FormFeedback>
                              </td>

                              {/* <td>
                                <UFInput
                                  control={control}
                                  placeholder="Percentage"
                                  name={`purchase.${index}.percentage`}
                                />
                              </td> */}
                              {/* <td>
                                <UFInput
                                  control={control}
                                  placeholder="Percentage"
                                  name={`purchase.${index}.unit`}
                                />
                              </td> */}
                              <td>
                                <UFInput
                                  control={control}
                                  placeholder="Price"
                                  name={`purchase.${index}.price`}
                                />
                              </td>
                              {/* <td>
                                <UFInput
                                  control={control}
                                  placeholder="Tax code"
                                  name={`purchase.${index}.unit`}
                                />
                              </td> */}
                              <td>
                                <div className="pb-1 px-2 d-flex justify-content-center">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => purchaseRemove(index)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Row>
                        <Col
                          lg={7}
                          // xs={12}
                          // md={2}
                          // sm={12}
                          // className="d-flex justify-content-between"
                        >
                          {!fromDashboard && (
                            <Button
                              color="secondary"
                              className="addButton mt-2"
                              onClick={() =>
                                purchaseAppend({
                                  product_code: "",
                                  description: "",
                                  quantity: "",
                                  discount: "",
                                  price: "",
                                })
                              }
                            >
                              Add Row
                            </Button>
                          )}
                          <Row className="mt-5">
                            <Col sm={7}>
                              <FormGroup className="mb-4">
                                <Label>Payment Terms</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Payment Terms"
                                  rows="5"
                                  name={"pay_terms"}
                                  invalid={!!errors.pay_terms}
                                  className={"pb-0"}
                                />
                                {/* <RichTextEditor
                                  control={control}
                                  name={"pay_terms"}
                                  invalid={!!errors.pay_terms}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                /> */}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col sm={7}>
                              <FormGroup className="mb-4">
                                <Label>Remarks</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Remarks"
                                  rows="5"
                                  name={"remarks"}
                                  invalid={!!errors.remarks}
                                  className={"pb-0"}
                                />
                                {/* <RichTextEditor
                                  control={control}
                                  name={"remarks"}
                                  invalid={!!errors.remarks}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                /> */}
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* <Row className="w-50 d-flex align-items-center">
                          <Col lg={12} className="text-end dark-text">
                            Subtotal
                          </Col>
                          <Col lg={12} className="ps-5 light-text">
                            {calulation("material")}
                          </Col>

                          <Col lg={12} className="text-end dark-text">
                            Tax
                          </Col>
                          <Col lg={12} className="ps-5 light-text">
                            {calulation("material")}
                          </Col>
                        </Row> */}
                        </Col>

                        <Col lg={4}>
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2">
                                Sub Total
                                <span className="mandatory">*</span>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder={calculation("sub_total")}
                                  name={"sub_total"}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2">Discount(%)</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder="Enter the Discount"
                                  name={"discount"}
                                  invalid={!!errors.discount}
                                />
                                <FormFeedback invalid>
                                  {errors.tax?.discount}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2">
                                Tax
                                <span className="mandatory">*</span>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder="Enter the Amount"
                                  name={"tax"}
                                  invalid={!!errors.tax}
                                />
                                <FormFeedback invalid>
                                  {errors.tax?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2">Shipping Charge</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder="Enter the Amount"
                                  name={"shipping_charge"}
                                  invalid={!!errors.shipping_charge}
                                />
                                <FormFeedback invalid>
                                  {errors.shipping_charge?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col sm={6}>
                              <div className="pt-2">Other Charge</div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <UFInput
                                  control={control}
                                  placeholder="Enter the Amount"
                                  name={"other_charge"}
                                  invalid={!!errors.other_charge}
                                />
                                <FormFeedback invalid>
                                  {errors.other_charge?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="pt-2">
                                Total Amount
                                <span className="mandatory">*</span>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                {/* <Col sm={12}> */}
                                <UFInput
                                  className="total-placeholder"
                                  control={control}
                                  placeholder={calculation("total_amount")}
                                  name={"total_amount"}
                                  disabled={true}
                                />
                                {/* </Col> */}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditPurchaseOrder
