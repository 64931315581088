import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Logo from "../../../assets/images/logo.svg"
import { useNavigate, useParams } from "react-router-dom"
import {
  fetchHrmsPayrollView,
  postHrmsGeneratePayroll,
} from "../../../services/Hrms/hrms"
import moment from "moment"
import { UFInput } from "../../../components/Common/Fields/Input"
import { FormProvider, useForm } from "react-hook-form"
import { components } from "react-select"
import LoadingButton from "../../../components/Common/LoadingButton"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"

const PayrollView = ({ fromDashboard, idDashBoard }) => {
  document.title = "Payroll View | Ever Rise"

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const { id } = useParams()
  const path = window.location.pathname
  const isEdit = path.includes("edit")

  const navigate = useNavigate()

  const [fetchData, setFetchData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const { control, reset, handleSubmit, methods, watch } = useForm()
  useEffect(() => {
    const fetchPayroll = async id => {
      const response = await fetchHrmsPayrollView(
        fromDashboard ? idDashBoard : id
      )
      setFetchData(response)

      const payrollDeduction = response?.data?.payroll_itemsdeduction?.map(
        dt => {
          return { item_id: dt?.item_id, amount: dt?.amount, type: "deduction" }
        }
      )
      const payrollBenefits = response?.data?.payroll_itemsbenefit?.map(dt => {
        return { item_id: dt?.item_id, amount: dt?.amount, type: "benefit" }
      })
      reset({
        components: payrollDeduction,
        benefits: payrollBenefits,
        lop: Number(response?.lopamount).toFixed(2),
        loan: Number(response?.data?.loan).toFixed(2),
        employee_id: response?.data?.employee_id,
        date: response?.data?.payroll_date,
      })
    }
    if (id || idDashBoard) {
      fetchPayroll(id)
    }
  }, [id, idDashBoard])

  useEffect(() => {
    const deductionCalc = watch("components")
    const deductionTotal =
      deductionCalc
        ?.reduce((total, item) => total + Number(item?.amount), 0)
        .toFixed(2) || 0
    const finalDeduction = (
      Number(deductionTotal) +
      Number(fetchData?.lopamount) +
      Number(fetchData?.data?.loan)
    ).toFixed(2)
  }, [watch("components")])
  // console.log(`fetchData`, finalDeduction)
  const handleRegistration = async data => {
    if (isEdit) {
      setIsLoading(true)
      try {
        const editedPayroll = await postHrmsGeneratePayroll({
          ...data,
          components: [...data?.components, ...data?.benefits],
        })
        toastSuccess(editedPayroll?.message)
        navigate("/hrms-payroll-generate")
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    } else {
      console.log(`success`, id)
      navigate(`/hrms-payroll/edit/${id}`)
    }
    console.log(`handleRegistration`, data)
  }
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "HRMS", link: "/hrms" },
                  { title: "Payroll", link: "/hrms-payroll-generate" },
                  {
                    title: "Payroll View",
                    link: `/hrms-payroll/view/${id}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <FormProvider {...methods}>
                <Form
                  id={`supplier-form`}
                  onSubmit={handleSubmit(handleRegistration)}
                >
                  <div
                    className={`purchase-view-div ${fromDashboard && "wdt-96"}`}
                  >
                    <div className="d-flex justify-content-between px-2 mb-3">
                      <div>
                        <div className="main-heading">Everrise</div>
                        <div className="supplier-td-body">
                          {loggedUser?.settings?.companyaddress}
                        </div>
                      </div>
                      <div>
                        <img className="view-logo" src={Logo} alt="logo" />
                      </div>
                    </div>
                    <hr className="mx-0" />
                    <div className="px-2 mt-3">
                      <div className="pay-slip-month">
                        {`Payslip for the month of ${
                          fetchData?.data?.payroll_date
                            ? moment(fetchData?.data?.payroll_date).format(
                                "MMMM, YYYY"
                              )
                            : "-"
                        }`}
                      </div>
                      <div className="pay-slip-summary py-3">
                        EMPLOYEE PAY SUMMARY
                      </div>
                      <Row>
                        <Col sm={7}>
                          <div>
                            <Row>
                              <Col sm={5} className="summary-head py-2">
                                Employee Name
                              </Col>
                              <Col sm={7} className="summary-body py-2">
                                {": "}
                                {fetchData?.data?.employee?.first_name
                                  ? fetchData?.data?.employee?.first_name
                                  : "-"}{" "}
                                {fetchData?.data?.employee?.last_name
                                  ? fetchData?.data?.employee?.last_name
                                  : "-"}
                              </Col>

                              <Col sm={5} className="summary-head py-2">
                                Employee ID
                              </Col>
                              <Col sm={7} className="summary-body py-2">
                                {": "}
                                {fetchData?.data?.employee?.employee_code
                                  ? fetchData?.data?.employee?.employee_code
                                  : "-"}
                              </Col>

                              <Col sm={5} className="summary-head py-2">
                                Designation
                              </Col>
                              <Col sm={7} className="summary-body py-2">
                                {": "}
                                {fetchData?.data?.employee?.designation?.name
                                  ? fetchData?.data?.employee?.designation?.name
                                  : "-"}
                              </Col>

                              <Col sm={5} className="summary-head py-2">
                                Date of Joining
                              </Col>
                              <Col sm={7} className="summary-body py-2">
                                {": "}
                                {fetchData?.data?.employee?.personal_details
                                  ?.date_of_join
                                  ? moment(
                                      fetchData?.data?.employee
                                        ?.personal_details?.date_of_join
                                    ).format("DD-MM-YYYY")
                                  : "-"}
                              </Col>

                              <Col sm={5} className="summary-head py-2">
                                Pay Period
                              </Col>
                              <Col sm={7} className="summary-body py-2">
                                {": "}
                                {fetchData?.data?.payroll_date
                                  ? moment(
                                      fetchData?.data?.payroll_date
                                    ).format("MMMM, YYYY")
                                  : "-"}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col sm={5} className="d-flex align-items-center">
                          <div className="d-flex flex-column align-items-center">
                            <div className="summary-body">Employee Net Pay</div>
                            <div className="payroll-total">
                              {fetchData?.data?.net_pay
                                ? Number(
                                    fetchData?.data?.net_pay
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}{" "}
                              AED
                            </div>
                            <div className="summary-head">
                              Paid Days :{" "}
                              {fetchData?.present_dates
                                ? fetchData?.present_dates
                                : "-"}{" "}
                              {fetchData?.absentDates > 0 &&
                                `| LOP Days : ${fetchData?.absentDates}`}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr className="mx-0" />
                    <div>
                      <div className="dotted-border py-2">
                        <Row className="px-2 pay-slip-summary py-2">
                          <Col sm={6}>EARNINGS</Col>
                          <Col sm={6} className="d-flex justify-content-end">
                            AMOUNT
                          </Col>
                        </Row>
                      </div>
                      {/* <> */}
                      {fetchData?.data?.payroll_itemsbenefit?.map((dt, i) => (
                        <Row
                          className="px-2 summary-body"
                          key={`benefits_${i}`}
                        >
                          <Col sm={6} className="py-2">
                            {dt?.item?.name ? dt?.item?.name : ""}
                          </Col>

                          <Col
                            sm={6}
                            className="d-flex justify-content-end py-2"
                          >
                            {dt?.amount} AED
                          </Col>
                        </Row>
                      ))}
                      {fetchData?.data?.Payrollgenerate_benefit?.map(
                        (dt, i) => (
                          <Row
                            className="px-2 summary-body"
                            key={`benefits_${i}`}
                          >
                            <Col sm={6} className="py-2">
                              {dt?.transaction_type_name
                                ? dt?.transaction_type_name
                                : ""}
                            </Col>

                            <Col
                              sm={6}
                              className="d-flex justify-content-end py-2"
                            >
                              {dt?.total_amount} AED
                            </Col>
                          </Row>
                        )
                      )}
                      <Row className="px-2">
                        <Col sm={6} className="py-2 gross-head">
                          Gross Earnings
                        </Col>
                        <Col
                          sm={6}
                          className="d-flex justify-content-end gross-head py-2"
                        >
                          {Number(
                            fetchData?.data?.payroll_itemsbenefit
                              ?.reduce(
                                (total, item) =>
                                  total + parseFloat(item?.amount || 0),
                                0
                              )
                              .toFixed(2)
                          ) +
                            Number(
                              fetchData?.data?.Payrollgenerate_benefit?.reduce(
                                (total, item) =>
                                  total + parseFloat(item?.total_amount || 0),
                                0
                              ).toFixed(2)
                            )}{" "}
                          AED
                        </Col>
                      </Row>
                    </div>
                    {fetchData?.data?.payroll_itemsdeduction?.length ? (
                      <>
                        <hr className="mx-0" />

                        <div>
                          <div className="dotted-border py-2">
                            <Row className="px-2 pay-slip-summary py-2">
                              <Col sm={6}>DEDUCTIONS</Col>
                              <Col
                                sm={6}
                                className="d-flex justify-content-end"
                              >
                                (-)AMOUNT
                              </Col>
                            </Row>
                          </div>
                          {fetchData?.data?.payroll_itemsdeduction?.map(
                            (dt, i) => (
                              <Row
                                className="px-2 summary-body"
                                key={`benefits_${i}`}
                              >
                                <Col sm={6} className="py-2">
                                  {dt?.item?.name ? dt?.item?.name : ""}
                                </Col>
                                {isEdit ? (
                                  <>
                                    <Col sm={3}></Col>
                                    <Col sm={3} className="mt-2">
                                      <FormGroup>
                                        <UFInput
                                          control={control}
                                          placeholder="Enter Amount"
                                          name={`components.${i}.amount`}
                                          // invalid={!!errors.dt?.model_name}
                                          nonEditableValueLast="AED"
                                          className="prefix-border-right"
                                        />
                                        {/* <FormFeedback invalid>
                              {errors.dt?.model_name?.message}
                            </FormFeedback> */}
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : (
                                  <Col
                                    sm={6}
                                    className="d-flex justify-content-end py-2"
                                  >
                                    {dt?.amount} AED
                                  </Col>
                                )}
                              </Row>
                            )
                          )}
                          {fetchData?.data?.Payrollgenerate_deduction
                            ?.length ? (
                            <>
                              {fetchData?.data?.Payrollgenerate_deduction?.map(
                                (dt, i) => (
                                  <Row
                                    className="px-2 summary-body"
                                    key={`benefits_${i}`}
                                  >
                                    <Col sm={6} className="py-2">
                                      {dt?.transaction_type_name
                                        ? dt?.transaction_type_name
                                        : ""}
                                    </Col>
                                    {isEdit ? (
                                      <>
                                        <Col sm={3}></Col>
                                        <Col sm={3} className="mt-2">
                                          <FormGroup>
                                            <UFInput
                                              control={control}
                                              placeholder="Enter Amount"
                                              name={`components.${i}.amount`}
                                              // invalid={!!errors.dt?.model_name}
                                              nonEditableValueLast="AED"
                                              className="prefix-border-right"
                                            />
                                            {/* <FormFeedback invalid>
                                {errors.dt?.model_name?.message}
                              </FormFeedback> */}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    ) : (
                                      <Col
                                        sm={6}
                                        className="d-flex justify-content-end py-2"
                                      >
                                        {dt?.total_amount} AED
                                      </Col>
                                    )}
                                  </Row>
                                )
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <Row className="px-2 summary-body">
                            <Col sm={6} className="py-2">
                              Loan
                            </Col>
                            {isEdit ? (
                              <>
                                <Col sm={3}></Col>
                                <Col sm={3} className="mt-2">
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter Amount"
                                      name={`loan`}
                                      // invalid={!!errors.dt?.model_name}
                                      nonEditableValueLast="AED"
                                      className="prefix-border-right"
                                      disabled={true}
                                    />
                                    {/* <FormFeedback invalid>
                              {errors.dt?.model_name?.message}
                            </FormFeedback> */}
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <Col
                                sm={6}
                                className="d-flex justify-content-end py-2"
                              >
                                {Number(fetchData?.data?.loan).toFixed(2)} AED
                              </Col>
                            )}
                          </Row>
                          <Row className="px-2 summary-body">
                            <Col sm={6} className="py-2">
                              LOP
                            </Col>
                            {isEdit ? (
                              <>
                                <Col sm={3}></Col>
                                <Col sm={3} className="mt-2">
                                  <FormGroup>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter Amount"
                                      name={`lop`}
                                      // invalid={!!errors.dt?.model_name}
                                      nonEditableValueLast="AED"
                                      className="prefix-border-right"
                                      disabled={true}
                                    />
                                    {/* <FormFeedback invalid>
                              {errors.dt?.model_name?.message}
                            </FormFeedback> */}
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <Col
                                sm={6}
                                className="d-flex justify-content-end py-2"
                              >
                                {Number(fetchData?.lopamount).toFixed(2)} AED
                              </Col>
                            )}
                          </Row>
                          <Row className="px-2">
                            <Col sm={6} className="py-2 gross-head">
                              Total Deductions
                            </Col>
                            <Col
                              sm={6}
                              className="d-flex justify-content-end py-2 gross-head"
                            >
                              {(
                                Number(
                                  (
                                    fetchData?.data?.payroll_itemsdeduction ||
                                    []
                                  )
                                    .reduce(
                                      (total, item) =>
                                        total + parseFloat(item?.amount || 0),
                                      0
                                    )
                                    .toFixed(2)
                                ) +
                                Number(
                                  (
                                    fetchData?.data
                                      ?.Payrollgenerate_deduction || []
                                  )
                                    .reduce(
                                      (total, item) =>
                                        total +
                                        parseFloat(item?.total_amount || 0),
                                      0
                                    )
                                    .toFixed(2)
                                ) +
                                Number(
                                  parseFloat(fetchData?.lopamount || 0).toFixed(
                                    2
                                  )
                                ) +
                                Number(
                                  parseFloat(
                                    fetchData?.data?.loan || 0
                                  ).toFixed(2)
                                )
                              ).toFixed(2) || 0}{" "}
                              AED
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="px-2 summary-bg">
                      <Row className="py-2 gross-head">
                        <Col sm={6}>
                          NET PAY (Gross Earnings - Total Deductions)
                        </Col>
                        <Col sm={3}>
                          {fetchData?.data?.net_pay
                            ? fetchData?.data?.net_pay
                            : 0}{" "}
                          AED
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex justify-content-center summary-foot">
                      Total Net Payable
                      {fetchData?.data?.net_pay
                        ? Number(fetchData?.data?.net_pay).toLocaleString(
                            "en",
                            { minimumFractionDigits: 2 }
                          )
                        : 0}{" "}
                      AED{" "}
                      <span className="summary-foot-span">
                        ( {fetchData?.amount} only )
                      </span>
                    </div>
                  </div>
                </Form>
              </FormProvider>
              {!fromDashboard ? (
                <>
                  {fetchData?.data?.status == 0 && (
                    <Row>
                      <Col lg={8}>
                        <div className="d-flex justify-content-end me-2 pr-50">
                          <LoadingButton
                            form={`supplier-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            className={"px-2 mb-3 btn btn-primary"}
                            type="submit"
                          >
                            {isEdit ? "Update" : "Edit"}
                          </LoadingButton>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <></>
              )}
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PayrollView
