import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_USER, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import {
  apiError,
  loggedUserSuccess,
  loginSuccess,
  logoutUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getUserData,
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { post } from "../../../helpers/api_helper"
import { getUser } from "../../../services/inventory"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      if (response?.status) {
        localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginSuccess(response))
        history(
          response?.role?.name == "Superadmin"
            ? "/dashboard"
            : response?.role?.name == "Estimation Manager" ||
              response?.role?.name == "Estimation DE" ||
              response?.role?.name == "Estimation Lead"
            ? "/estimation"
            : "/project-master-dashboard"
        )
      }
    }
  } catch (error) {
    yield put(apiError(error?.message))
  }
}

function* loggedUser() {
  try {
    const response = yield call(getUserData)
    yield put(loggedUserSuccess(response))
  } catch (error) {
    yield put(apiError(error?.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(() => post("logout"))
    if (response?.status) {
      localStorage.removeItem("authUser")
    }
    yield put(logoutUserSuccess(response))
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(GET_USER, loggedUser)
}

export default authSaga
