import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFInput } from "../../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../../assets/icons/close.svg"
import { Link } from "react-router-dom"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  postFinanceAccountGroup,
  postFinanceAccountGroupFields,
  postFinanceCategory,
  postFinanceSubCategory,
} from "../../../services/finance/finance"
import FinanceCat from "../../../components/Common/chooseFields/financeCat"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"
import DataTypeLi from "../../../components/Common/chooseFields/dataType"

const schema = yup.object().shape({
  //   name: yup.string().required("Description is Required"),
  //   cat_id: yup.mixed().required("Category is Required"),
})

const Edit = ({ loading, onUpdate, id, error, close, ...props }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log("props?.editItem?.modal  :", props?.editItem?.value)
    if (props?.editItem?.modal) {
      reset({
        ...props?.editItem?.value,
        data_type: {
          value: props?.editItem?.value?.data_type,
          label: props?.editItem?.value?.data_type,
        },
      })
    }
  }, [props?.editItem?.modal])

  const handleSeriesAdd = async data => {
    try {
      function toSnakeCase(str) {
        return str
          .replace(/\s+/g, "_") // Replace spaces with underscores
          .replace(/([a-z])([A-Z])/g, "$1_$2") // Add underscores between camel case words
          .toLowerCase()
      }
      setIsLoading(true)
      const createdSeries = await postFinanceAccountGroupFields({
        ...data,
        data_type: data?.data_type?.value,
        label: data?.label,
        field_name: toSnakeCase(data?.label),
      })
      toastSuccess(createdSeries?.message)
      onUpdate()
      reset()
      close()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>
            {props?.editItem?.modal ? "Edit Group Fields" : "New Group Fields"}
          </h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleSeriesAdd)}>
                <Row>
                  {/* <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Field Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Field Name"
                        name={"field_name"}
                        invalid={!!errors.field_name}
                      />
                      <FormFeedback invalid>
                        {errors.field_name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col> */}
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Label<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Label"
                        name={"label"}
                        invalid={!!errors.label}
                      />
                      <FormFeedback invalid>
                        {errors.label?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Data Type<span className="mandatory">*</span>
                      </Label>
                      <DataTypeLi
                        control={control}
                        placeholder="Select"
                        name={"data_type"}
                        invalid={!!errors.data_type}
                        target={".modal"}
                      />
                      <FormFeedback invalid>
                        {errors.data_type?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditFinanceGroupField = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditFinanceGroupField
