import React from "react"
import { Link } from "react-router-dom"
import networkError from "../../assets/images/network-error.svg"

const NetworkError = () => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div>
          <img src={networkError} alt="404" className="not-found" />
          <p className="fs-24 fw-500 w-75 py-2">
            It seems there's a problem with your network connection. Please
            check your connection and try again.
          </p>
          <Link to="/" className={"px-3 py-2 btn btn-primary"}>
            Go back to Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NetworkError
