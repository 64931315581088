import React, { useState, useEffect } from "react"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import RequestType from "../../../components/Common/chooseFields/requestType"
import { Col, Form, Row } from "reactstrap"
import resetIcon from "../../../assets/icons/reset.svg"
import { useForm } from "react-hook-form"
import ProductList from "../../../components/Common/chooseFields/productList"
import UsersList from "../../../components/Common/chooseFields/userList"
import moment from "moment"
import { isEmpty } from "lodash"
import StoreStatus from "../../../components/Common/chooseFields/storeStatus"

const RequestFilter = ({
  handleTypeChange,
  handleSearchChange,
  handleDateChange,
  handleUserChange,
  handleStatusChange,
  
}) => {
  const { control, watch, reset } = useForm()

  const handleUserList = data => {
    handleUserChange(data?.value)
  }
  const handleType = data => {
    handleTypeChange(data?.label)
  }
  const handleStatus = data => {
    handleStatusChange(data?.value)
  }
  const handleRequestdate = data => {
    console.log(data,"data");
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    console.log(fromDate,toDate,"toDate");
    handleDateChange(fromDate, toDate)
  }

  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])
  const handleReset = () => {
    handleUserChange()
    handleTypeChange()
    handleDateChange()
    handleStatusChange()
    reset({
      user_id: null,
      type: null,
      req_date: null,
      search: "",
      status:null,
    })
  }
  return (
    <div className="d-flex justify-content-between align-items-center my-2 me-2">
      <Form id="user-form" className="filter-wrapper">
        <Row className="mx-0">
          <Col sm={2} className="pe-1">
            <UsersList
              control={control}
              name={"user_id"}
              placeholder={"Name"}
              target={"body"}
              onSelectChange={e => handleUserList(e)}
            />
          </Col>
          {/* <Col sm={3} className="pe-1">
            <ProductList
              control={control}
              name={"product_id"}
              placeholder={"Product No"}
              target={"body"}
              onSelectChange={e => handleProducttNum(e)}
            />
          </Col> */}
          <Col sm={2} className="pe-1">
            <RequestType
              control={control}
              name={"type"}
              onSelectChange={e => handleType(e)}
            />
          </Col>
          <Col sm={3} className="pe-1">
            <UFDate
              control={control}
              placeholder="Requested Date"
              name={"req_date"}
              mode="range"
              onDateChange={e => handleRequestdate(e)}
            />
          </Col>
          <Col sm={2} className="pe-1">
            <StoreStatus
              control={control}
              name={"status"}
              onSelectChange={e => handleStatus(e)}
            />
          </Col>
        </Row>
      </Form>
      <div className="d-flex">
        <div className="export-div pointer me-2 reset-img" onClick={() => handleReset()}>
          <img src={resetIcon} height={15} width={15} className="me-2" /> Reset
        </div>
        <UFInput
          control={control}
          placeholder="Search"
          name={"search"}
          className={"search-input"}
        />
      </div>
    </div>
  )
}

export default RequestFilter
