import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { UFDate } from "../../../components/Common/Fields/Input"
import moment from "moment"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { fetchFinanceBalanceSheet } from "../../../services/finance/finance"
import UpdateTable from "../../../components/Common/UpdatedTable"

const BalanceSheet = ({ fromDashboard }) => {
  document.title = "Balance Sheet | Ever Rise"

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [fetchedData, setFetchedData] = useState()

  const { control, watch, reset } = useForm()

  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceBalanceSheet({
        params: `${startDate ? `&start_date=${startDate}` : ""}${
          endDate ? `&end_date=${endDate}` : ""
        }`,
      })
      //   const groupedData = Object.values(
      //     responce?.data.reduce((acc, item) => {
      //       const { category_id, subcategory_id } = item

      //       // Generate a unique key based on category_id and subcategory_id
      //       const groupKey = `${category_id}_${subcategory_id}`

      //       // Check if the group already exists; if not, create it
      //       if (!acc[groupKey]) {
      //         acc[groupKey] = {
      //           category_id,
      //           subcategory_id,
      //           items: [],
      //         }
      //       }

      //       // Push the current item into the corresponding group
      //       acc[groupKey].items.push(item)

      //       return acc
      //     }, {})
      //   )

      setFetchedData(responce)
    }
    fetchData()
  }, [startDate, endDate])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const assetColumns = [
    {
      label: "Group Name",
      renderCell: item => item?.group_name,
    },
    {
      label: "Amount",
      renderCell: item =>
        item?.balance
          ? Number(Number(item?.balance).toFixed(2)).toLocaleString("en", {
              minimumFractionDigits: 2,
            })
          : 0,
    },
  ]
  const liabilityColumns = [
    {
      label: "Group Name",
      renderCell: item => item?.group_name,
    },
    {
      label: "Amount",
      renderCell: item =>
        item?.balance
          ? Number(Number(item?.balance).toFixed(2)).toLocaleString("en", {
              minimumFractionDigits: 2,
            })
          : 0,
    },
  ]
  const equityColumns = [
    {
      label: "Group Name",
      renderCell: item => item?.group_name,
    },
    {
      label: "Amount",
      renderCell: item => item?.balance,
    },
  ]
  const assetSubColumns = [
    {
      label: "Group Name",
      renderCell: item => item?.name,
    },
    {
      label: "Credit",
      renderCell: item => (item?.credit_sum ? item?.credit_sum : 0),
    },
    {
      label: "Debit",
      renderCell: item => (item?.debit_sum ? item?.debit_sum : 0),
    },
  ]
  const liabilitySubColumns = [
    {
      label: "Group Name",
      renderCell: item => item?.name,
    },
    {
      label: "Credit",
      renderCell: item => (item?.credit_sum ? item?.credit_sum : 0),
    },
    {
      label: "Debit",
      renderCell: item => (item?.debit_sum ? item?.debit_sum : 0),
    },
  ]
  const equitySubColumns = [
    {
      label: "Group Name",
      renderCell: item => item?.name,
    },
    {
      label: "Credit",
      renderCell: item => (item?.credit_sum ? item?.credit_sum : 0),
    },
    {
      label: "Debit",
      renderCell: item => (item?.debit_sum ? item?.debit_sum : 0),
    },
  ]

  const reduceBalance = (assets, amount) => {
    // Reduce the balance of each asset and sum them up
    const total = assets.reduce((sum, asset) => {
      return sum + (asset.balance - amount) // Reduce the balance and accumulate
    }, 0)

    return total // Return the total balance after reduction
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div>
            {!fromDashboard ? (
              <>
                <Breadcrumbs
                  title="Finance"
                  breadcrumbObject={[
                    { title: "Finance", link: "/finance" },
                    { title: "Balance Sheet", link: "/finance-balance-sheet" },
                  ]}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            <div className="d-flex justify-content-between align-items-center my-2 me-2">
              <div className="filter-wrapper-balance"></div>
              <div className="d-flex">
                {/* <Row className="my-2">
                <Col sm={3}> */}
                <UFDate
                  control={control}
                  name={"req_date"}
                  placeholder={"Date"}
                  mode={"range"}
                  onDateChange={e => handleRequestdate(e)}
                />
                {/* </Col>
              </Row> */}
              </div>
            </div>
            <hr className="table-divide" />
            <div className="d-flex justify-content-center">
              <div className="purchase-view-div-report">
                <div className="payroll-total d-flex justify-content-center mb-2">
                  Balance Sheet
                </div>
                <div className="d-flex justify-content-center mb-4">
                  {startDate && endDate
                    ? `${moment(startDate).format("DD-MM-YYYY")} -
                    ${moment(endDate).format("DD-MM-YYYY")}`
                    : `As of ${moment().format("DD-MM-YYYY")}`}
                </div>
                <hr className="table-divide" />

                <div className="ps-4 py-3 dotted-border-y">
                  <div className="d-flex justify-content-between">
                    <div className="mapping-setup-title py-2 text-capitalize">
                      Assets
                    </div>
                    <div className="mapping-setup-title py-2">
                      {fetchedData?.data?.asset &&
                        Number(
                          Number(
                            reduceBalance(fetchedData?.data?.asset, 0)
                          ).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}{" "}
                      AED
                    </div>
                  </div>
                  <div className="ms-3">
                    <Row className="mt-2 ever-rise-table moduleTable">
                      <Col xs={12}>
                        {fetchedData?.data?.asset && (
                          <UpdateTable
                            // className="mt-5"
                            nodes={fetchedData?.data?.asset}
                            columns={assetColumns}
                            subColumn={assetSubColumns}
                            // subName={"sub_groups"}
                            // subHeader={false}
                            tableWidth="50% 50%"
                            mobileWidth="50% 50%"
                            expand={true}
                            headerHide={true}
                          />
                        )}
                      </Col>
                    </Row>
                    {/* {fetchedData?.data?.asset && (
                      <div className="d-flex justify-content-between total-amount-div px-4">
                        <div className="text-capitalize py-2">Total</div>
                        <div className="py-2">
                          {fetchedData?.data?.asset &&
                            reduceBalance(fetchedData?.data?.asset, 0)}{" "}
                          AED
                        </div>
                      </div>
                    )} */}
                  </div>

                  {/* {fetchedData?.data?.asset?.map((dt, i) => {
                  const totalDifference = fetchedData?.data?.asset?.reduce(
                    (total, entry) => {
                      return (
                        total +
                        entry.accounts.reduce((accountTotal, account) => {
                          const credit = parseFloat(account.credit_sum)
                          const debit = parseFloat(account.debit_sum)
                          return accountTotal + (credit - debit)
                        }, 0)
                      )
                    },
                    0
                  )

                  return (
                    <div key={`name_${i}`}>
                      <div className="bal-sub-title py-2 text-capitalize">
                        {dt?.name}
                      </div>
                      <table className="estimation-table">
                        <tbody>
                          {dt?.accounts?.map((itm, j) => (
                            <tr key={`tbl_${j}`} className="py-2">
                              <td className="ps-5 text-capitalize">
                                {itm?.account_name}
                              </td>
                              <td>
                                {Number(itm?.credit_sum || 0) -
                                  Number(itm?.debit_sum || 0)}{" "}
                                AED
                              </td>
                            </tr>
                          ))}
                          <tr className="total-amount-div px-3">
                            <td sm={6} className="text-capitalize">
                              Total {dt?.name}
                            </td>
                            <td sm={6}>{totalDifference} AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })} */}
                </div>
                <div className="ps-4 py-3 dotted-border-y">
                  <div className="d-flex justify-content-between">
                    <div className="mapping-setup-title py-2 text-capitalize">
                      Liability
                    </div>
                    <div className="mapping-setup-title py-2">
                      {fetchedData?.data?.liability &&
                        Number(
                          Number(
                            reduceBalance(fetchedData?.data?.liability, 0)
                          ).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}{" "}
                      AED
                    </div>
                  </div>
                  <div className="ms-3">
                    <Row className="mt-2 ever-rise-table moduleTable ">
                      <Col xs={12}>
                        {fetchedData?.data?.liability && (
                          <UpdateTable
                            // className="mt-5"
                            nodes={fetchedData?.data?.liability}
                            columns={liabilityColumns}
                            subColumn={liabilitySubColumns}
                            subName={"details"}
                            subHeader={true}
                            tableWidth="50% 50%"
                            mobileWidth="50% 50%"
                            expand={true}
                            headerHide={true}
                          />
                        )}
                      </Col>
                    </Row>
                    {/* {fetchedData?.data?.liability && (
                      <div className="d-flex justify-content-between total-amount-div px-4">
                        <div className="text-capitalize py-2">Total</div>
                        <div className="py-2">
                          {fetchedData?.data?.liability &&
                            reduceBalance(fetchedData?.data?.liability, 0)}{" "}
                          AED
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                {/* <div className="ps-4 py-3 dotted-border-y">
                  <div className="mapping-setup-title py-2 text-capitalize">
                    Equity
                  </div>
                  <div className="ms-3">
                    <Row className="mt-2 ever-rise-table moduleTable ">
                      <Col xs={12}>
                        {fetchedData?.data?.equity && (
                          <UpdateTable
                            // className="mt-5"
                            nodes={fetchedData?.data?.equity}
                            columns={equityColumns}
                            subColumn={equitySubColumns}
                            subName={"details"}
                            subHeader={true}
                            tableWidth="50% 50%"
                            expand={true}
                          />
                        )}
                      </Col>
                    </Row>
                    {fetchedData?.data?.equity && (
                      <div className="d-flex justify-content-between total-amount-div px-4">
                        <div className="text-capitalize py-2">Total</div>
                        <div className="py-2">
                          {fetchedData?.data?.equity &&
                            reduceBalance(fetchedData?.data?.equity, 0)}{" "}
                          AED
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
                {/* <div className="ps-4 py-3 dotted-border-y">
                <div className="mapping-setup-title py-2 text-capitalize">
                  Equity
                </div>
                {fetchedData?.data?.equity?.map((dt, i) => {
                  const totalDifference = fetchedData?.data?.equity?.reduce(
                    (total, entry) => {
                      return (
                        total +
                        entry.accounts.reduce((accountTotal, account) => {
                          const credit = parseFloat(account.credit_sum)
                          const debit = parseFloat(account.debit_sum)
                          return accountTotal + (credit - debit)
                        }, 0)
                      )
                    },
                    0
                  )

                  return (
                    <div key={`name_${i}`}>
                      <div className="bal-sub-title py-2 text-capitalize">
                        {dt?.name}
                      </div>
                      <table className="estimation-table">
                        <tbody>
                          {dt?.accounts?.map((itm, j) => (
                            <tr key={`tbl_${j}`} className="py-2">
                              <td className="ps-5 text-capitalize">
                                {itm?.account_name}
                              </td>
                              <td>
                                {Number(itm?.credit_sum || 0) -
                                  Number(itm?.debit_sum || 0)}{" "}
                                AED
                              </td>
                            </tr>
                          ))}
                          <tr className="total-amount-div px-3">
                            <td sm={6} className="text-capitalize">
                              Total {dt?.name}
                            </td>
                            <td sm={6}>{totalDifference} AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })}
              </div>
              <div className="ps-4 py-3 dotted-border-y">
                <div className="mapping-setup-title py-2 text-capitalize">
                  Liability
                </div>
                {fetchedData?.data?.liability?.map((dt, i) => {
                  const totalDifference = fetchedData?.data?.liability?.reduce(
                    (total, entry) => {
                      return (
                        total +
                        entry.accounts.reduce((accountTotal, account) => {
                          const credit = parseFloat(account.credit_sum)
                          const debit = parseFloat(account.debit_sum)
                          return accountTotal + (credit - debit)
                        }, 0)
                      )
                    },
                    0
                  )

                  return (
                    <div key={`name_${i}`}>
                      <div className="bal-sub-title py-2 text-capitalize">
                        {dt?.name}
                      </div>
                      <table className="estimation-table">
                        <tbody>
                          {dt?.accounts?.map((itm, j) => (
                            <tr key={`tbl_${j}`} className="py-2">
                              <td className="ps-5 text-capitalize">
                                {itm?.account_name}
                              </td>
                              <td>
                                {Number(itm?.credit_sum || 0) -
                                  Number(itm?.debit_sum || 0)}{" "}
                                AED
                              </td>
                            </tr>
                          ))}
                          <tr className="total-amount-div px-3">
                            <td sm={6} className="text-capitalize">
                              Total {dt?.name}
                            </td>
                            <td sm={6}>{totalDifference} AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })}
              </div> */}

                {/* {fetchedData?.map((dt, i) => {
                // Calculate the total for each subcategory
                const subcategoryTotal = dt?.items?.reduce((acc, itm) => {
                  const creditAmount = Number(
                    itm?.journal_entry_details_sum_credit_amount || 0
                  )
                  const debitAmount = Number(
                    itm?.journal_entry_details_sum_debit_amount || 0
                  )
                  return acc + (creditAmount - debitAmount)
                }, 0)

                return (
                  <div key={i} className="ps-4 py-3">
                    <div className="mapping-setup-title py-2 text-capitalize">
                      {dt?.items[0]?.category?.name}
                    </div>
                    <div className="bal-sub-title py-2 text-capitalize">
                      {dt?.items[0]?.subcategory?.name}
                    </div>
                    <table className="estimation-table">
                      <tbody>
                        {dt?.items?.map((itm, i) => (
                          <tr key={i} className="py-2">
                            <td className="ps-5 text-capitalize">
                              {itm?.name}
                            </td>
                            <td>
                              {Number(
                                itm?.journal_entry_details_sum_credit_amount ||
                                  0
                              ) -
                                Number(
                                  itm?.journal_entry_details_sum_debit_amount ||
                                    0
                                )}{" "}
                              AED
                            </td>
                          </tr>
                        ))}
                        <tr className="total-amount-div px-3">
                          <td sm={6} className="text-capitalize">
                            Total {dt?.items[0]?.subcategory?.name}
                          </td>
                          <td sm={6}>{subcategoryTotal} AED</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })} */}
              </div>
            </div>
            {/* {fetchedData?.map((dt, i) => (
              <div>
                <div>{dt?.items[0]?.category?.name}</div>
                <div>{dt?.items[0]?.subcategory?.name}</div>
                {dt?.items?.map((itm, i) => (
                  <div>
                    <div>{itm?.name}</div>
                    <div>
                      {Number(
                        itm?.journal_entry_details_sum_credit_amount
                          ? itm?.journal_entry_details_sum_credit_amount
                          : 0
                      ) -
                        Number(
                          itm?.journal_entry_details_sum_debit_amount
                            ? itm?.journal_entry_details_sum_debit_amount
                            : 0
                        )}
                    </div>
                  </div>
                ))}
                <div>
                    <div>Total {dt?.items[0]?.subcategory?.name}</div>
                    <div></div>
                </div>
              </div>
            ))} */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BalanceSheet
