import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import EmployeeList from "../../../components/Common/chooseFields/employee"
import LeaveType from "../../../components/Common/chooseFields/leaveType"
import {
  fetchOtherTransactionId,
  postHrmsOtherTransaction,
} from "../../../services/Hrms/hrms"
import moment from "moment"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import TransactionType from "../../../components/Common/chooseFields/transactionType"

const schema = yup.object().shape({
  //   employee_name: yup.string().required("Employee Name is Required"),
  //   employee_id: yup.mixed().required("Employee ID is Required"),
  //   requested_date: yup.mixed().required("Request Date is Required"),
  //   leave_type: yup.mixed().required("Leave Type is Required"),
  //   leave_from: yup.mixed().required("Leave From is Required"),
  //   leave_to: yup.mixed().required("Leave To is Required"),
})
const Edit = ({ loading, onUpdate, close, getEditValue }) => {
  const [documentName, setDocumentName] = useState()
  const [loanCheck, setLoanCheck] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      employee_id: null,
      leave_type: null,
      employee_name: "",
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getEditValue) {
      console.log(`getEditValue`, getEditValue)

      reset({
        ...getEditValue?.data,
        employee_name: `${getEditValue?.data?.employee?.first_name} ${getEditValue?.data?.employee?.middle_name} ${getEditValue?.data?.employee?.last_name}`,
        employee_id: {
          label: getEditValue?.data?.employee?.employee_code,
          value: getEditValue?.data?.employee?.id,
        },
        date: getEditValue?.data?.date,
        transaction_type_id: {
          label: getEditValue?.data?.transaction_type?.name,
          value: getEditValue?.data?.transaction_type?.id,
        },
      })
      setDocumentName(getEditValue?.data?.document)
      setLoanCheck(getEditValue?.data?.is_loan == 1 ? true : false)
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postHrmsOtherTransaction({
        ...data,
        transaction_type_id: data?.transaction_type_id?.value,
        employee_id: data?.employee_id?.value,
        date: moment(data?.date).format("YYYY-MM-DD"),
        is_loan: loanCheck ? 1 : 0,
        ...(loanCheck && {
          tenure: data?.tenure,
          month_amount: Number(data?.amount) / Number(data?.tenure),
        }),
      })
      setLoanCheck(false)
      toastSuccess(updateDepartment?.message)
      onUpdate()
      close()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLoanCheck = dt => {
    setLoanCheck(!dt)
  }

  const handleEmployeeChange = e => {
    console.log(" event :", e)
    setValue(
      "employee_name",
      `${e?.first_name} ${e?.middle_name} ${e?.last_name}`
    )
  }

  const handleChange = (name, file) => {
    setValue(name, file)
    console.log(`handleChange`, name, file)
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>
            {getEditValue?.data?.id
              ? "Edit Other Charges"
              : "Add Other Charges"}
          </h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="md"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Employee ID<span className="mandatory">*</span>
                      </Label>
                      <EmployeeList
                        control={control}
                        name={"employee_id"}
                        invalid={!!errors.employee_id}
                        onSelectChange={e => handleEmployeeChange(e)}
                      />
                      <FormFeedback invalid>
                        {errors.employee_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Employee Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        rows="3"
                        name={"employee_name"}
                        disabled={true}
                        invalid={!!errors.employee_name}
                      />
                      <FormFeedback invalid>
                        {errors.employee_name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Date<span className="mandatory">*</span>
                      </Label>

                      <UFDate
                        control={control}
                        name={"date"}
                        invalid={!!errors.date}
                        // maxDate={moment().format("YYYY-MM-DD")}
                      />
                      <FormFeedback invalid>
                        {errors.date?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Transaction Type<span className="mandatory">*</span>
                      </Label>
                      <TransactionType
                        control={control}
                        name={"transaction_type_id"}
                        invalid={!!errors.transaction_type_id}
                      />
                      <FormFeedback invalid>
                        {errors.transaction_type_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Price<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        name={"amount"}
                        invalid={!!errors.amount}
                      />
                      <FormFeedback invalid>
                        {errors.amount?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Attachment<span className="mandatory">*</span>
                      </Label>
                      <SingleFileInput
                        control={control}
                        accept=".png,.jpg,.jpeg,.svg,.pdf"
                        name={`document`}
                        label="Upload File"
                        onlyIcon={true}
                        tooltip="Document Upload"
                        handleChange={handleChange}
                        downloadPath={documentName}
                      />
                      <FormFeedback invalid>
                        {errors.document?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  {watch("transaction_type_id")?.is_deduction == 1 ? (
                    <Col sm={"12"} onClick={() => handleLoanCheck(loanCheck)}>
                      <FormGroup>
                        <input
                          type="checkbox"
                          className="ever-rise-checkbox"
                          name={"is_loan"}
                          checked={loanCheck}
                        />
                        <Label className="ms-2">Is Loan</Label>
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {loanCheck ? (
                    <Row>
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Tenure (Months)<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder=""
                            name={"tenure"}
                            invalid={!!errors.tenure}
                          />
                          <FormFeedback invalid>
                            {errors.tenure?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Monthly Amount<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder={
                              watch("amount") && watch("tenure")
                                ? watch("amount") / watch("tenure")
                                : 0
                            }
                            name={"month_amount"}
                            disabled={true}
                            invalid={!!errors.month_amount}
                          />
                          <FormFeedback invalid>
                            {errors.month_amount?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Description<span className="mandatory">*</span>
                      </Label>
                      <UFEditor
                        control={control}
                        placeholder="Enter the description"
                        name={"description"}
                        rows="5"
                      />
                      <FormFeedback invalid>
                        {errors.description?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditTransactionManagement = ({
  buttonLabel,
  className,
  editValue,
  ...props
}) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchOtherTransactionId(editValue?.id)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (editValue?.id) {
      toggle()
      fetchData()
    }
  }, [editValue?.id])
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            setGetEditValue("")
            props?.onReset()
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditTransactionManagement
