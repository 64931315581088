import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
} from "reactstrap"
import UpdateTable from "../../components/Common/UpdatedTable"
import Chart from "react-apexcharts"

const FinanceDahboard = () => {
  document.title = "Finance Dashboard | Ever Rise"

  var Options = {
    Series: [
      {
        name: "",
        type: "line",
        data: [1000, 2000, 500, 200, 1000, 1000, 2000, 500, 200, 1000, 300, 1000],
      },
      //   {
      //     name: "Issued",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.issued),
      //   },
      //   {
      //     name: "Rejected",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.rejected),
      //   },
    ],
    chart: {
      type: "line",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#383838", "#525252", "#171717"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "k"
          } else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "l"
          } else {
            return value
          }
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    grid: {
      show: true,
    },
  }

  var Options1 = {
    Series: [
      {
        name: "",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      //   {
      //     name: "Issued",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.issued),
      //   },
      //   {
      //     name: "Rejected",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.rejected),
      //   },
    ],
    chart: {
      type: "line",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#383838", "#525252", "#171717"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "k"
          } else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "l"
          } else {
            return value
          }
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    grid: {
      show: true,
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Finance Dashboard"
              breadcrumbObject={[
                { title: "Finance Dashboard", link: "/finance-dashboard" },
                {
                  title: "Finance Dashboard",
                  link: "/finance-dashboard",
                },
              ]}
            />

            {/* {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurement-materiareq/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Material Request"}
              </Link>
            )} */}
          </div>
          <Card className="me-2 px-2 pb-2">
            <Row className="px-4 pt-4 pb-2 ">
              <Col sm={6}>
                <div className="finance-chart">
                  <Card>
                    <div className="finance-dash-head">
                      Sales Trend{" "}
                      <span className="finance-dash-head-span">
                        (1-Apr-2023 to 31-mar-2024)
                      </span>
                    </div>
                    <Chart
                      options={Options}
                      series={Options?.Series}
                      type="line"
                      width="100%"
                      height={300}
                    />
                  </Card>
                </div>
              </Col>
              <Col sm={6}>
                <div className="finance-chart">
                  <Card>
                    <div className="finance-dash-head">Purchase Trend</div>
                    <Chart
                      options={Options1}
                      series={Options1?.Series}
                      type="line"
                      width="100%"
                      height={300}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Trading Details</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Gross Profit</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Net Profit</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Sales Accounts</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Purchase Accounts</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Cash In/Out Flow</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Net Flow</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>In Flow</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Out Flow</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Assets/Liabilities</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Current Assets</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Current Liabilities</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row className="px-4 py-2 g-4">
              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Receivable/Payable</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Receivable</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Overdue Receivable</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Out Flow</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Net Flow</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Cash/Bank Accounts</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cash in Hand</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                        <tr>
                          <td>Bank Accounts</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Top Groups</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row className="px-4 pt-2 g-4">
              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Accounting Ratios</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Inventory Turnover</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>Debt/Equity Ratio</td>
                          <td>0:001</td>
                        </tr>
                        <tr>
                          <td>Receivable Turnover in Days</td>
                          <td>300 Days</td>
                        </tr>
                        <tr>
                          <td>Return on Investment %</td>
                          <td>100%</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Inventory Details</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Closing Stock</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td>Outwards</td>
                          <td>1,197,878,000 Cr</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FinanceDahboard
