import React from "react"
import { Link } from "react-router-dom" // Assuming you're using React Router
import { Card } from "reactstrap"
import notFound from "../../assets/images/404.svg"

const NotFound = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Card className="me-2 px-2 pb-2">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div>
                <img src={notFound} alt="404" className="not-found" />
                <p className="fs-24 fw-500 w-75 py-3">
                  OOPS, this page could not be found!
                </p>
                <Link to="/" className={"px-3 py-2 btn btn-primary"}>
                  Go back to Home
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NotFound
