import CentralStore from "../../pages/CentralStore"
import ProductList from "../../pages/CentralStore/productList"
import RequestedList from "../../pages/CentralStore/requestList"
import RequestProduct from "../../pages/CentralStore/requestProduct"
import StockTransferHistory from "../../pages/CentralStore/transferHistory"
import SiteProductList from "../../pages/SiteStore"

export const centalStoreRoutes = [
  { path: "/central-store", component: <CentralStore /> },
  { path: "/procurement/product-list", component: <ProductList /> },
  { path: "/procurement/request-product", component: <RequestProduct /> },
  { path: "/procurement/requested-list", component: <RequestedList /> },
  { path: "/procurement/stock-transfer-history", component: <StockTransferHistory /> },
  { path: "/site-store/product-list", component: <SiteProductList /> },
]
