import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import {
  fetchEmployee,
  postHrmsEmployeeControl,
} from "../../../services/Hrms/hrms"
import { capitalize } from "lodash"
import { fetchUser } from "../../../services/profile/profile"
import mail from "../../../assets/images/mail.svg"
import phone from "../../../assets/images/contact.svg"
import dummyImg from "../../../assets/images/dummyImg.svg"
import editIcon from "../../../assets/images/editIcon.svg"
import downloadIcon from "../../../assets/images/download.svg"
import editicon from "../../../assets/icons/editprofile.svg"
import moment from "moment"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Gender from "../../../components/Common/chooseFields/gender"
import Marital from "../../../components/Common/chooseFields/maritalStatus"
import EmployeeGrade from "../../../components/Common/chooseFields/empGrade"
import Languages from "../../../components/Common/chooseFields/languages"
import BloodGroup from "../../../components/Common/chooseFields/bloodGroup"
import Religion from "../../../components/Common/chooseFields/religion"
import Country from "../../../components/Common/chooseFields/country"
import StateList from "../../../components/Common/chooseFields/stateList"
import EmployeeType from "../../../components/Common/chooseFields/employeeType"
import Sponsored from "../../../components/Common/chooseFields/sponsored"
import Designation from "../../../components/Common/chooseFields/designation"
import ConfirmationAfter from "../../../components/Common/chooseFields/confirmationAfter"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import UpdateTable from "../../../components/Common/UpdatedTable"
import EditUser from "../../User/edit"
import closeIcon from "../../../assets/icons/close.svg"
import RelationshipList from "../../../components/Common/chooseFields/relationship"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import Vaccine from "../../../components/Common/chooseFields/vaccine"
import BoosterList from "../../../components/Common/chooseFields/booster"

const schema = yup.object().shape({})

const UserProfile = () => {
  document.title = "Profile | Ever Rise"

  const [employee, setEmployee] = useState()
  const [age, setAge] = useState(null)
  const [editButton, setEditButton] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [editPassword, setEditPassword] = useState({ modal: false, value: "" })
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))
  const { loggedUser } = useSelector(LoginProperties)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  })

  const imgUrl = process.env.REACT_APP_IMG_APIKEY

  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchUser({
        id: id,
        // type:
        //   activeTab == 1
        //     ? "personal"
        //     : activeTab == 2
        //     ? "education"
        //     : activeTab == 3
        //     ? "payroll"
        //     : activeTab == 4
        //     ? "documents"
        //     : activeTab == 5
        //     ? "bank"
        //     : activeTab == 6
        //     ? "loan"
        //     : "",
      })
      setEmployee(response?.data)
      setAge(
        calculateAge(
          response?.data?.basic_details?.personal_details?.date_of_birth
        )
      )
      const languages =
        response?.data?.basic_details?.personal_details?.languages?.map(dt => ({
          label: dt?.name,
          value: dt?.id,
        })) || []
      reset({
        date_of_birth:
          response?.data?.basic_details?.personal_details?.date_of_birth,
        gender_id: {
          label: response?.data?.basic_details?.gender_type?.label,
          value: response?.data?.basic_details?.gender_type?.id,
        },
        grade_id: {
          label: response?.data?.basic_details?.grade?.grade,
          value: response?.data?.basic_details?.grade?.id,
        },
        marital_status_id: {
          label: response?.data?.basic_details?.marital_status_id?.label,
          value: response?.data?.basic_details?.marital_status_id?.id,
        },
        blood_group_id: {
          label: response?.data?.basic_details?.blood_group?.label,
          value: response?.data?.basic_details?.blood_group?.id,
        },
        religion_id: {
          label:
            response?.data?.basic_details?.personal_details?.religion_id
              ?.religion,
          value:
            response?.data?.basic_details?.personal_details?.religion_id?.id,
        },
        languages_known_id: languages,
        country_id: {
          label:
            response?.data?.basic_details?.personal_details?.country_id?.name,
          value:
            response?.data?.basic_details?.personal_details?.country_id?.id,
        },
        state: {
          label: response?.data?.basic_details?.personal_details?.state?.name,
          value: response?.data?.basic_details?.personal_details?.state?.id,
        },
        employee_type_id: {
          label:
            response?.data?.basic_details?.personal_details?.employee_type_id
              ?.employee_type,
          value:
            response?.data?.basic_details?.personal_details?.employee_type_id
              ?.id,
        },
        sponsored_by_id: {
          label:
            response?.data?.basic_details?.personal_details?.sponsored_by_id
              ?.sponsored_by,
          value:
            response?.data?.basic_details?.personal_details?.sponsored_by_id
              ?.id,
        },
        designation_id: {
          label: response?.data?.basic_details?.designation?.name,
          value: response?.data?.basic_details?.designation?.id,
        },
        confirmation_after: {
          label: response?.data?.basic_details?.confirmation_after?.label,
          value: response?.data?.basic_details?.confirmation_after?.id,
        },
        personal_email_id:
          response?.data?.basic_details?.personal_details?.personal_email_id,
        personal_contact_number:
          response?.data?.basic_details?.personal_details
            ?.personal_contact_number,
        current_address:
          response?.data?.basic_details?.personal_details?.current_address,
        permanent_address:
          response?.data?.basic_details?.personal_details?.permanent_address,
        bio: response?.data?.basic_details?.bio,
        personal_email_id:
          response?.data?.basic_details?.personal_details?.personal_email_id,
        personal_contact_number:
          response?.data?.basic_details?.personal_details
            ?.personal_contact_number,
        current_address:
          response?.data?.basic_details?.personal_details?.current_address,
        permanent_address:
          response?.data?.basic_details?.personal_details?.permanent_address,
      })
    }
    if (loggedUser?.data?.id) {
      fetchData(loggedUser?.data?.id)
    }
  }, [loggedUser, refresh])

  const calculateAge = dob => {
    const birthDate = new Date(dob)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--
    }
    return age
  }

  const handleEdlit = id => {
    setEditButton(id)
  }

  const handleState = () => {
    setValue(`state`, null)
  }

  const handleEditPass = item => {
    setEditPassword({
      value: loggedUser?.data,
      modal: true,
    })
  }

  // const handleUpdate = () => {
  //   setCount(count + 1)
  // }

  const handleResetValue = () => {
    setEditPassword("")
  }

  const toggle = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
  }

  const handleView = item => {
    setViewData(item)
    console.log(item)

    const languages =
      item?.allValue?.basic_details?.personal_details?.languages?.map(dt => ({
        label: dt?.name,
        value: dt?.id,
      })) || []

    const emergencyContactArray =
      item?.allValue?.basic_details?.emergencycontacts?.map(dt => ({
        name: dt?.name,
        relationship_id: {
          label: dt?.relationships?.name,
          value: dt?.relationships?.id,
        },
        phone: dt?.phone,
        email: dt?.email,
        address: dt?.address,
      }))

    reset({
      emergency_contacts: emergencyContactArray,
      date_of_birth:
        item?.allValue?.basic_details?.personal_details?.date_of_birth,
      gender_id: {
        label: item?.allValue?.basic_details?.gender_type?.label,
        value: item?.allValue?.basic_details?.gender_type?.id,
      },
      grade_id: {
        label: item?.allValue?.basic_details?.grade?.grade,
        value: item?.allValue?.basic_details?.grade?.id,
      },
      marital_status_id: {
        label: item?.allValue?.basic_details?.marital_status_id?.label,
        value: item?.allValue?.basic_details?.marital_status_id?.id,
      },
      blood_group_id: {
        label: item?.allValue?.basic_details?.blood_group?.label,
        value: item?.allValue?.basic_details?.blood_group?.id,
      },
      religion_id: {
        label:
          item?.allValue?.basic_details?.personal_details?.religion_id
            ?.religion,
        value: item?.allValue?.basic_details?.personal_details?.religion_id?.id,
      },
      languages_known_id: languages,
      country_id: {
        label:
          item?.allValue?.basic_details?.personal_details?.country_id?.name,
        value: item?.allValue?.basic_details?.personal_details?.country_id?.id,
      },
      state: {
        label: item?.allValue?.basic_details?.personal_details?.state?.name,
        value: item?.allValue?.basic_details?.personal_details?.state?.id,
      },
      employee_type_id: {
        label:
          item?.allValue?.basic_details?.personal_details?.employee_type_id
            ?.employee_type,
        value:
          item?.allValue?.basic_details?.personal_details?.employee_type_id?.id,
      },
      sponsored_by_id: {
        label:
          item?.allValue?.basic_details?.personal_details?.sponsored_by_id
            ?.sponsored_by,
        value:
          item?.allValue?.basic_details?.personal_details?.sponsored_by_id?.id,
      },
      designation_id: {
        label: item?.allValue?.basic_details?.designation?.name,
        value: item?.allValue?.basic_details?.designation?.id,
      },
      confirmation_after: {
        label: item?.allValue?.basic_details?.confirmation_after?.label,
        value: item?.allValue?.basic_details?.confirmation_after?.id,
      },

      name: item?.value?.name,
      relationship_id: {
        label: item?.value?.relationships?.name,
        value: item?.value?.relationships?.id,
      },
      phone: item?.value?.phone,
      email: item?.value?.email,
      address: item?.value?.address,
    })
    toggle()
  }

  const column = [
    { label: "Name", renderCell: item => item?.document_title?.name },
    { label: "Document No", renderCell: item => item?.document_no },
    {
      label: "Expiry Date",
      renderCell: item => moment(item?.expiry_date).format("DD-MM-YYYY"),
    },
  ]
  const medicalColumn = [
    {
      label: "Vaccine",
      renderCell: item => (
        <div className="capitalize-letter">{item?.vaccin?.name}</div>
      ),
    },
    {
      label: "Vaccinated on",
      renderCell: item => moment(item?.date).format("DD-MM-YYYY"),
    },
    {
      label: "Booster",
      renderCell: item => (
        <div className="capitalize-letter">
          {item?.booster?.name ? item?.booster.name : "-"}{" "}
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <Button
          className="hover-button"
          onClick={() =>
            handleView({
              id: 2,
              name: "edit_medical",
              value: item,
              allValue: employee,
              product_id: "",
            })
          }
        >
          Edit
        </Button>
      ),
    },
  ]

  const columns = [
    {
      label: "Name",
      renderCell: item => item?.name,
    },
    {
      label: "Relationship",
      renderCell: item => item?.relationships?.name,
    },
    {
      label: "Phone",
      renderCell: item => item?.phone,
    },
    {
      label: "E-mail",
      renderCell: item => item?.email,
    },
    {
      label: "Address",
      renderCell: item => item?.address,
    },
    {
      label: "Action",
      renderCell: item => (
        <Button
          className="hover-button"
          onClick={() =>
            handleView({
              id: 1,
              name: "edit_emergency",
              value: item,
              allValue: employee,
              product_id: "",
            })
          }
        >
          Edit
        </Button>
      ),
    },
  ]

  const handleRegistration = async data => {
    // const data = getValues()
    console.log(`getValues`, data)
    try {
      setIsLoading(true)
      const languages = data?.languages_known_id?.map(dt => dt?.value)
      const emergencyDetails = {
        name: data?.name,
        relationship_id: data?.relationship_id?.value,
        phone: data?.phone,
        email: data?.email,
        address: data?.address,
      }
      const medicalDetail = {
        booster_id: data?.booster_id?.id,
        vaccine_id: data?.vaccine_id?.id,
        date: moment(data?.date).format("YYYY-MM-DD"),
      }
      const oldMedicalDetail = data?.medical_details?.map((dt, i) => ({
        booster_id: dt?.booster_id?.id,
        vaccine_id: dt?.vaccine_id?.id,
        date: moment(dt?.date).format("YYYY-MM-DD"),
      }))
      const oldEmergencyDetail = data?.emergency_contacts?.map((dt, index) => ({
        name: dt?.name,
        relationship_id: dt?.relationship_id?.value,
        phone: dt?.phone,
        email: dt?.email,
        address: dt?.address,
      }))
      const updatedEmergencyContacts = [
        ...(oldEmergencyDetail || []),
        emergencyDetails,
      ]
      const updatedMedicalDetail = [...(oldMedicalDetail || []), medicalDetail]
      const updateProfile = await postHrmsEmployeeControl({
        ...data,
        id: employee?.basic_details?.id,
        official_email_id: employee?.basic_details?.official_email_id,
        bio: data?.bio,
        is_user: employee?.basic_details?.is_user,
        employee_code: employee?.basic_details?.employee_code,
        first_name: employee?.basic_details?.first_name,
        blood_group_id: data?.blood_group_id?.value,
        confirmation_after: data?.confirmation_after?.value,
        country_id: data?.country_id?.value,
        designation_id: data?.designation_id?.value,
        employee_type_id: data?.employee_type_id?.value,
        grade_id: data?.grade_id?.value,
        gender_id: data?.gender_id?.value,
        languages_known_id: languages,
        marital_status_id: data?.marital_status_id?.value,
        state: data?.state?.value,
        sponsored_by_id: data?.sponsored_by_id?.value,
        religion_id: data?.religion_id?.value,
        emergency_contacts: updatedEmergencyContacts,
        medical_details: updatedMedicalDetail,
      })
      toastSuccess(updateProfile?.message)
      setRefresh(refresh + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEditButton(0)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            <Breadcrumbs
              title="My Profile"
              breadcrumbObject={[{ title: "My Profile", link: "/profile" }]}
            />
            <EditUser
              key={"x"}
              // onUpdate={handleUpdate}
              onReset={handleResetValue}
              buttonLabel="+&nbsp; Add User"
              className="px-2 mb-3 btn btn-primary"
              editItem={editPassword}
              fromProfile={true}
            />
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card profile-card">
              <Row className="p-4">
                <Col sm={12} lg={3}>
                  <div className="p-3 profile-section profile-left-card profile-sticky profile-side-height">
                    <div className="d-flex justify-content-between">
                      <div className="status-active">
                        {employee
                          ? employee?.basic_details?.personal_details
                              ?.employee_type_id?.employee_type
                          : "-"}
                      </div>
                      <div className="profile-emp-id">
                        {employee?.basic_details?.employee_code
                          ? employee?.basic_details?.employee_code
                          : "-"}
                      </div>
                    </div>

                    <div className="py-4">
                      <div className="d-flex justify-content-center">
                        {/* <div className="ratio"> */}
                        <img
                          src={
                            employee?.basic_details?.photo
                              ? `${imgUrl}${employee?.basic_details?.photo}`
                              : dummyImg
                          }
                          alt="profile image"
                          className="profile-emp-dp"
                        />

                        {/* <div className="d-flex justify-end px-15 py-50 h-100 w-1/1 absolute">
                          <div className="cursor-pointer">
                            <label htmlFor="profile_image" role="button">
                              <img
                                width={70}
                                height={50}
                                role="button"
                                src={editicon}
                                // onClick={handleEditIconClick}
                                alt="image"
                              />
                            </label>
                            <input
                              type="file"
                              id="profile_image"
                              name="profile_image"
                              control={control}
                              multiple
                              accept="image/png, image/jpeg"
                              // onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="user-text-dark d-flex justify-content-center">
                        {employee
                          ? `${capitalize(
                              employee?.basic_details?.first_name
                            )} ${capitalize(
                              employee?.basic_details?.middle_name
                            )} ${capitalize(
                              employee?.basic_details?.last_name
                            )}`
                          : "-"}
                      </div>
                      <div className="user-text-light d-flex justify-content-center">
                        {employee?.basic_details?.designation?.name
                          ? employee?.basic_details?.designation?.name
                          : "-"}
                      </div>
                    </div>
                    <hr className="m-0 faded-hr" />
                    <div className="py-4 mb-5 d-flex justify-content-center">
                      <div>
                        <div className="user-text-light py-2">
                          <img alt="mail" src={mail} />
                          <span className="ps-2">
                            {employee
                              ? employee?.basic_details?.official_email_id
                              : "-"}
                          </span>
                        </div>
                        <div className="user-text-light py-2">
                          <img alt="phone" src={phone} />
                          <span className="ps-2">
                            {employee
                              ? "+" +
                                employee?.basic_details?.official_contact_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className=" text-danger pb-3 mt-auto">
                      <div
                        className="pointer d-flex align-self-end justify-content-center"
                        onClick={() => handleEditPass()}
                      >
                        Change Password
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} lg={9}>
                  <div className="profile-section">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">Your Bio</div>
                        <div>
                          {editButton !== 1 ? (
                            <img
                              alt="edit"
                              src={editIcon}
                              className="pointer"
                              onClick={() => handleEdlit(1)}
                            />
                          ) : (
                            <>
                              <Button
                                className="hover-button me-2"
                                onClick={() => handleEdlit(0)}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                form={`profile-form`}
                                color={"primary"}
                                loading={isLoading}
                                disabled={isLoading}
                                className={"px-2 btn btn-primary"}
                                type="submit"
                                // onClick={handleRegistration}
                              >
                                Save
                              </LoadingButton>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="py-2">
                        {editButton !== 1 ? (
                          <>
                            {employee?.basic_details?.bio
                              ? employee?.basic_details?.bio
                              : "-"}
                          </>
                        ) : (
                          <Col sm={11}>
                            <FormProvider>
                              <Form
                                id={`profile-form`}
                                onSubmit={handleSubmit(handleRegistration)}
                              >
                                <FormGroup className="form-margin">
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter Bio"
                                    rows="3"
                                    name={"bio"}
                                    invalid={!!errors.bio}
                                  />
                                  <FormFeedback invalid>
                                    {errors.bio?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Form>
                            </FormProvider>
                          </Col>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="profile-section my-4">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">
                          Personal Information
                        </div>
                        <div>
                          {editButton !== 2 ? (
                            <img
                              alt="edit"
                              src={editIcon}
                              className="pointer"
                              onClick={() => handleEdlit(2)}
                            />
                          ) : (
                            <>
                              <Button
                                className="hover-button me-2"
                                onClick={() => handleEdlit(0)}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                form={`profile-form`}
                                color={"primary"}
                                loading={isLoading}
                                disabled={isLoading}
                                className={"px-2 btn btn-primary"}
                                type="submit"
                                // onClick={handleRegistration}
                              >
                                Save
                              </LoadingButton>
                            </>
                          )}
                        </div>
                      </div>
                      <FormProvider>
                        <Form
                          id={`profile-form`}
                          onSubmit={handleSubmit(handleRegistration)}
                        >
                          <div className="custom-container px-3">
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Date of Birth
                              </div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? moment(
                                          employee?.basic_details
                                            ?.personal_details?.date_of_birth
                                        ).format("DD-MM-YYYY")
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <UFDate
                                        control={control}
                                        name={"date_of_birth"}
                                        invalid={!!errors.date_of_birth}
                                      />
                                      <FormFeedback invalid>
                                        {errors.date_of_birth?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">Age</div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>{age ? age : "-"}</>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <UFInput
                                        control={control}
                                        placeholder={age}
                                        name={"age"}
                                        disabled={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">Gender</div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details?.gender_type
                                          ?.label
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Gender
                                        control={control}
                                        placeholder="select"
                                        name={"gender_id"}
                                        invalid={!!errors.gender_id}
                                        target={"body"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.gender_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">Grade</div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details?.grade?.grade
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <EmployeeGrade
                                        control={control}
                                        placeholder="Select"
                                        name={"grade_id"}
                                        invalid={!!errors.grade_id}
                                        target={"body"}
                                        disabled={true}
                                        // onSelectChange={e => handleGradeChange(e)}
                                      />
                                      <FormFeedback invalid>
                                        {errors.grade_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Marital Status
                              </div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.marital_status_id?.label
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Marital
                                        control={control}
                                        placeholder="select"
                                        name={"marital_status_id"}
                                        invalid={!!errors.marital_status_id}
                                        target={"body"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.marital_status_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Languages Known
                              </div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee?.basic_details?.personal_details
                                      ?.languages?.length
                                      ? employee?.basic_details?.personal_details?.languages?.map(
                                          (dt, index) => (
                                            <>
                                              <span>{dt?.name}</span>
                                              {index + 1 <
                                                employee?.basic_details
                                                  ?.personal_details?.languages
                                                  ?.length && <span>, </span>}
                                            </>
                                          )
                                        )
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Languages
                                        control={control}
                                        placeholder="select"
                                        name={"languages_known_id"}
                                        invalid={!!errors.languages_known_id}
                                        target={"body"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.languages_known_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>

                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Blood Group
                              </div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details?.blood_group
                                          ?.label
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <BloodGroup
                                        control={control}
                                        placeholder="Select"
                                        name={"blood_group_id"}
                                        invalid={!!errors.blood_group_id}
                                        target={"body"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.blood_group_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">Religion</div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.religion_id
                                          ?.religion
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Religion
                                        control={control}
                                        placeholder="select"
                                        name={"religion_id"}
                                        invalid={!!errors.religion_id}
                                        target={"body"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.religion_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">Country</div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.country_id?.name
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Country
                                        control={control}
                                        placeholder="select"
                                        name={"country_id"}
                                        invalid={!!errors.country_id}
                                        target={"body"}
                                        onSelectChange={() => handleState()}
                                      />
                                      <FormFeedback invalid>
                                        {errors.country_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">State</div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.state?.name
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <StateList
                                        control={control}
                                        placeholder="select"
                                        name={"state"}
                                        selectedCountry={watch("country_id")}
                                      />
                                      <FormFeedback invalid>
                                        {errors.state?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Employee Type
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.employee_type_id
                                          ?.employee_type
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <EmployeeType
                                        control={control}
                                        placeholder="select"
                                        name={"employee_type_id"}
                                        invalid={!!errors.employee_type_id}
                                        target={"body"}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors.employee_type_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Sponsored By
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.sponsored_by_id
                                          ?.sponsored_by
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Sponsored
                                        control={control}
                                        placeholder="select"
                                        name={"sponsored_by_id"}
                                        invalid={!!errors.sponsored_by_id}
                                        target={"body"}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors.sponsored_by_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Current Job
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee?.basic_details?.designation?.name
                                      ? employee?.basic_details?.designation
                                          ?.name
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <Designation
                                        control={control}
                                        placeholder="Select"
                                        name={"designation_id"}
                                        invalid={!!errors.designation_id}
                                        target={"body"}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors.designation_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Confimation After
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.confirmation_after?.label
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <ConfirmationAfter
                                        control={control}
                                        placeholder="Select "
                                        name={"confirmation_after"}
                                        invalid={!!errors.confirmation_after}
                                        target={"body"}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors.confirmation_after?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Personal Email ID
                              </div>
                              <div className="supplier-td-body">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.personal_email_id
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <UFInput
                                        control={control}
                                        placeholder="Enter the Email ID"
                                        name={"personal_email_id"}
                                        invalid={!!errors.personal_email_id}
                                      />
                                      <FormFeedback invalid>
                                        {errors.personal_email_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Personal Number
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? "+" +
                                        employee?.basic_details
                                          ?.personal_details
                                          ?.personal_contact_number
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <PhoneInputField
                                        control={control}
                                        name={"personal_contact_number"}
                                        invalid={
                                          !!errors.personal_contact_number
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.personal_contact_number
                                            ?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>

                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Current Address
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.current_address
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <UFEditor
                                        control={control}
                                        placeholder="Enter the Current Address"
                                        rows="3"
                                        name={"current_address"}
                                        invalid={!!errors.current_address}
                                        className={"pb-0"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.current_address?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                            <div className="pt-4 custom-col-lg">
                              <div className="supplier-td-head">
                                Permanent Address
                              </div>
                              <div className="supplier-td-body capitalize-letter">
                                {editButton !== 2 ? (
                                  <>
                                    {employee
                                      ? employee?.basic_details
                                          ?.personal_details?.permanent_address
                                      : "-"}
                                  </>
                                ) : (
                                  <Col sm={11}>
                                    <FormGroup className="form-margin">
                                      <UFEditor
                                        control={control}
                                        placeholder="Enter the Permanent Address"
                                        rows="3"
                                        name={"permanent_address"}
                                        invalid={!!errors.permanent_address}
                                        className={"pb-0"}
                                      />

                                      <FormFeedback invalid>
                                        {errors.permanent_address?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </div>
                            </div>
                          </div>
                        </Form>
                      </FormProvider>
                    </div>
                  </div>

                  <div className="profile-section my-4">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">Emergency Contact</div>
                        <div>
                          <Button
                            className="hover-button me-2"
                            onClick={() =>
                              handleView({
                                id: 1,
                                name: "edit_emergency",
                                allValue: employee,
                                value: "",
                                product_id: "",
                              })
                            }
                          >
                            Add Emergency Contact
                          </Button>
                          {/* {editButton !== 3 ? (
                            <img
                              alt="edit"
                              src={editIcon}
                              className="pointer"
                              onClick={() => handleEdlit(3)}
                            />
                          ) : (
                            <>
                              <Button
                                className="hover-button me-2"
                                onClick={() => handleEdlit(0)}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                form={`profile-form`}
                                color={"primary"}
                                loading={isLoading}
                                disabled={isLoading}
                                className={"px-2 btn btn-primary"}
                                type="submit"
                              >
                                Save
                              </LoadingButton>
                            </>
                          )} */}
                        </div>
                      </div>

                      {editButton !== 3 && (
                        <Row className="pt-4 ever-rise-table profileTable ">
                          <Col xs={12}>
                            {employee && (
                              <UpdateTable
                                className="mt-5"
                                nodes={
                                  employee?.basic_details?.emergencycontacts
                                }
                                columns={columns}
                                tableWidth="15% 15% 20% 20% 20% 10%"
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                      {/* <div className="custom-container px-3">
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Name</div>
                          <div className="supplier-td-body">
                            {editButton !== 3 ? (
                              <>
                                {employee?.basic_details?.name
                                  ? employee?.basic_details?.name
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Name"
                                    name={"contact_name"}
                                    invalid={!!errors.contact_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.contact_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Relationship</div>
                          <div className="supplier-td-body">
                            {editButton !== 3 ? (
                              <>
                                {employee?.basic_details?.relationship
                                  ? employee?.basic_details?.relationship
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Relationship"
                                    name={"relationship"}
                                    invalid={!!errors.relationship}
                                  />
                                  <FormFeedback invalid>
                                    {errors.relationship?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Phone Number</div>
                          <div className="supplier-td-body">
                            {editButton !== 3 ? (
                              <>
                                {employee?.personal_details?.emergency_no
                                  ? "+" +
                                    employee?.personal_details?.emergency_no
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <PhoneInputField
                                    control={control}
                                    name={"emergency_no"}
                                    invalid={!!errors.emergency_no}
                                  />
                                  <FormFeedback invalid>
                                    {errors.emergency_no?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Email</div>
                          <div className="supplier-td-body">
                            {editButton !== 3 ? (
                              <>
                                {employee?.basic_details?.emergency_email
                                  ? "+" +
                                    employee?.basic_details?.emergency_email
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Email ID"
                                    name={"emergency_email"}
                                    invalid={!!errors.emergency_email}
                                  />
                                  <FormFeedback invalid>
                                    {errors.emergency_email?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Address</div>
                          <div className="supplier-td-body">
                            {editButton !== 3 ? (
                              <>
                                {employee
                                  ? employee?.personal_details
                                      ?.permanent_address
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter the Permanent Address"
                                    rows="3"
                                    name={"permanent_address"}
                                    invalid={!!errors.permanent_address}
                                    className={"pb-0"}
                                  />

                                  <FormFeedback invalid>
                                    {errors.permanent_address?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="profile-section my-4">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">
                          Employment Information
                        </div>
                      </div>

                      <div className="custom-container px-3">
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Grade</div>
                          <div className="supplier-td-body">
                            {employee
                              ? employee?.basic_details?.grade?.grade
                              : "-"}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Group Code</div>
                          <div className="supplier-td-body">
                            {employee?.basic_details?.personal_details
                              ?.camp_code_id
                              ? employee?.basic_details?.personal_details
                                  ?.camp_code_id?.camp_code
                              : "-"}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">DOJ</div>
                          <div className="supplier-td-body">
                            {employee
                              ? moment(
                                  employee?.personal_details?.date_of_join
                                ).format("DD-MM-YYYY")
                              : "-"}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Department</div>
                          <div className="supplier-td-body">
                            {employee?.basic_details?.department?.name
                              ? employee?.basic_details?.department?.name
                              : "-"}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Designation</div>
                          <div className="supplier-td-body">
                            {employee?.basic_details?.designation?.name
                              ? employee?.basic_details?.designation?.name
                              : "-"}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Emp. Type</div>
                          <div className="supplier-td-body">
                            {employee
                              ? employee?.basic_details?.employment_type?.label
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile-section my-4">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">Medical Details</div>
                        <div>
                          <Button
                            className="hover-button me-2"
                            onClick={() =>
                              handleView({
                                id: 2,
                                name: "edit_medical",
                                allValue: employee,
                                value: "",
                                product_id: "",
                              })
                            }
                          >
                            Add Medical Detail
                          </Button>
                          {/* {editButton !== 4 ? (
                            <img
                              alt="edit"
                              src={editIcon}
                              className="pointer"
                              onClick={() => handleEdlit(4)}
                            />
                          ) : (
                            <>
                              <Button
                                className="hover-button me-2"
                                onClick={() => handleEdlit(0)}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                form={`profile-form`}
                                color={"primary"}
                                loading={isLoading}
                                disabled={isLoading}
                                className={"px-2 btn btn-primary"}
                                type="submit"
                              >
                                Save
                              </LoadingButton>
                            </>
                          )} */}
                        </div>
                      </div>

                      {editButton !== 4 && (
                        <Row className="pt-4 ever-rise-table profileTable ">
                          <Col xs={12}>
                            {employee && (
                              <UpdateTable
                                className="mt-5"
                                nodes={employee?.basic_details?.medical_details}
                                columns={medicalColumn}
                                tableWidth="30% 30% 30%"
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                      {/* <div className="custom-container px-3">
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Vaccine</div>
                          <div className="supplier-td-body">
                            {editButton !== 4 ? (
                              <>
                                {employee?.basic_details?.vaccine
                                  ? employee?.basic_details?.vaccine
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFInput
                                    control={control}
                                    placeholder="Vaccine"
                                    name={"vaccine"}
                                    invalid={!!errors.vaccine}
                                  />
                                  <FormFeedback invalid>
                                    {errors.vaccine?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Vaccinated on</div>
                          <div className="supplier-td-body">
                            {editButton !== 4 ? (
                              <>
                                {employee?.basic_details?.vaccinated_on
                                  ? moment(
                                      employee?.basic_details?.vaccinated_on
                                    ).format("DD-MM-YYYY")
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFDate
                                    control={control}
                                    name={"vaccinated_on"}
                                    invalid={!!errors.vaccinated_on}
                                  />
                                  <FormFeedback invalid>
                                    {errors.vaccinated_on?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                        <div className="pt-4 custom-col-lg">
                          <div className="supplier-td-head">Booster</div>
                          <div className="supplier-td-body">
                            {editButton !== 4 ? (
                              <>
                                {employee?.basic_details?.booster
                                  ? employee?.basic_details?.booster
                                  : "-"}
                              </>
                            ) : (
                              <Col sm={11}>
                                <FormGroup className="form-margin">
                                  <UFInput
                                    control={control}
                                    placeholder="Booster"
                                    name={"booster"}
                                    invalid={!!errors.booster}
                                  />
                                  <FormFeedback invalid>
                                    {errors.booster?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="profile-section my-4">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">Personal Documents</div>
                      </div>
                      <Row className="pt-4 ever-rise-table profileTable ">
                        <Col xs={12}>
                          {employee && (
                            <UpdateTable
                              className="mt-5"
                              nodes={
                                employee?.basic_details?.employee_documents
                              }
                              columns={column}
                              tableWidth="25% 25% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="profile-section mt-4">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="user-text-dark">Assets</div>
                        <div>
                          <img
                            alt="edit"
                            src={downloadIcon}
                            width={18}
                            height={18}
                            className="pointer"
                          />
                        </div>
                      </div>
                      <Row className="pt-4 ever-rise-table profileTable ">
                        <Col xs={12}>
                          {employee && (
                            <UpdateTable
                              className="mt-5"
                              nodes={
                                employee?.basic_details?.employee_documents
                              }
                              columns={column}
                              tableWidth="25% 25% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Container>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={viewData?.id == 1 ? "md" : "md"}
            fullscreen={viewData?.id == 1 ? "md" : "md"}
            unmountOnClose={false}
            // className="assign-modal"
          >
            <>
              <FormProvider>
                <Form
                  id={`profile-form`}
                  onSubmit={handleSubmit(handleRegistration)}
                >
                  <ModalHeader className="custom-modal-header">
                    <div className="d-flex justify-content-between">
                      <h5>
                        {viewData?.id == 1
                          ? "Emergency Contact"
                          : viewData?.id == 2
                          ? "Medical Detail"
                          : ""}
                      </h5>
                      <img
                        src={closeIcon}
                        alt=""
                        onClick={toggle}
                        width="10"
                        role="button"
                      />
                    </div>
                    {console.log("viewData :", viewData)}
                  </ModalHeader>
                  <ModalBody>
                    {viewData?.id == 1 ? (
                      <>
                        <Row>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Name
                                <span className="mandatory">*</span>
                              </Label>
                              <UFInput
                                control={control}
                                placeholder="Enter the Name"
                                name={`name`}
                                invalid={!!errors?.name}
                              />
                              <FormFeedback invalid>
                                {errors?.name?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Relationship
                                <span className="mandatory">*</span>
                              </Label>
                              <RelationshipList
                                control={control}
                                placeholder="Select"
                                name={`relationship_id`}
                                invalid={!!errors?.relationship_id}
                              />
                              <FormFeedback invalid>
                                {errors?.relationship_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Phone Number
                                <span className="mandatory">*</span>
                              </Label>
                              <PhoneInputField
                                control={control}
                                name={`phone`}
                                invalid={!!errors?.phone}
                              />
                              <FormFeedback invalid>
                                {errors?.phone?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Email
                                <span className="mandatory">*</span>
                              </Label>
                              <UFInput
                                control={control}
                                placeholder="Enter the email"
                                name={`email`}
                                invalid={!!errors?.email}
                              />
                              <FormFeedback invalid>
                                {errors?.email?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Address
                                <span className="mandatory">*</span>
                              </Label>
                              <UFEditor
                                control={control}
                                placeholder="Enter the Current Address"
                                rows="5"
                                name={`address`}
                                invalid={!!errors?.address}
                                className={"pb-0"}
                              />
                              <FormFeedback invalid>
                                {errors?.email?.address}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          {/* <div className=" d-flex justify-content-end py-3">
                        <LoadingButton
                          color={"primary"}
                          onClick={() => toggle()}
                          type="submit"
                        >
                          Update
                        </LoadingButton>
                      </div> */}
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Vaccine
                                <span className="mandatory">*</span>
                              </Label>
                              <Vaccine
                                control={control}
                                placeholder="Select"
                                name={`vaccine_id`}
                                invalid={!!errors?.vaccine_id}
                              />
                              <FormFeedback invalid>
                                {errors?.vaccine_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Vaccinated on
                                <span className="mandatory">*</span>
                              </Label>
                              <UFDate
                                control={control}
                                name={`date`}
                                invalid={!!errors?.date}
                              />
                              <FormFeedback invalid>
                                {errors?.date?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={12} xs={12} md={5} sm={12}>
                            <FormGroup className="mb-4">
                              <Label>
                                Booster
                                <span className="mandatory">*</span>
                              </Label>
                              <BoosterList
                                control={control}
                                name={`booster_id`}
                                invalid={!!errors?.booster_id}
                              />
                              <FormFeedback invalid>
                                {errors?.booster_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <LoadingButton
                      color={"primary"}
                      onClick={() => toggle()}
                      type="submit"
                    >
                      Update
                    </LoadingButton>
                  </ModalFooter>
                </Form>
              </FormProvider>
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
