import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { UFDate } from "../../../components/Common/Fields/Input"
import moment from "moment"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { fetchFinanceBalanceSheet } from "../../../services/finance/finance"

const BalanceSheet = ({ fromDashboard }) => {
  document.title = "Balance Sheet | Ever Rise"

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [fetchedData, setFetchedData] = useState()

  const { control, watch, reset } = useForm()

  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceBalanceSheet({
        params: `${startDate ? `&start_date=${startDate}` : ""}${
          endDate ? `&end_date=${endDate}` : ""
        }`,
      })
      //   const groupedData = Object.values(
      //     responce?.data.reduce((acc, item) => {
      //       const { category_id, subcategory_id } = item

      //       // Generate a unique key based on category_id and subcategory_id
      //       const groupKey = `${category_id}_${subcategory_id}`

      //       // Check if the group already exists; if not, create it
      //       if (!acc[groupKey]) {
      //         acc[groupKey] = {
      //           category_id,
      //           subcategory_id,
      //           items: [],
      //         }
      //       }

      //       // Push the current item into the corresponding group
      //       acc[groupKey].items.push(item)

      //       return acc
      //     }, {})
      //   )

      setFetchedData(responce)
    }
    fetchData()
  }, [startDate, endDate])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div>
            {!fromDashboard ? (
              <>
                <Breadcrumbs
                  title="Finance"
                  breadcrumbObject={[
                    { title: "Finance", link: "/finance" },
                    { title: "Balance Sheet", link: "/finance-balance-sheet" },
                  ]}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            <Row className="my-2">
              <Col sm={3}>
                <UFDate
                  control={control}
                  name={"req_date"}
                  placeholder={"Request Date"}
                  mode={"range"}
                  onDateChange={e => handleRequestdate(e)}
                />
              </Col>
            </Row>
            <hr className="table-divide" />

            <div className="purchase-view-div">
              <div className="main-heading d-flex justify-content-center mb-4">
                Balance Sheet
              </div>
              <hr className="table-divide" />

              <div className="ps-4 py-3 dotted-border-y">
                <div className="mapping-setup-title py-2 text-capitalize">
                  Assets
                </div>
                {fetchedData?.data?.asset?.map((dt, i) => {
                  const totalDifference = fetchedData?.data?.asset?.reduce(
                    (total, entry) => {
                      return (
                        total +
                        entry.accounts.reduce((accountTotal, account) => {
                          const credit = parseFloat(account.credit_sum)
                          const debit = parseFloat(account.debit_sum)
                          return accountTotal + (credit - debit)
                        }, 0)
                      )
                    },
                    0
                  )

                  return (
                    <div key={`name_${i}`}>
                      <div className="bal-sub-title py-2 text-capitalize">
                        {dt?.name}
                      </div>
                      <table className="estimation-table">
                        <tbody>
                          {dt?.accounts?.map((itm, j) => (
                            <tr key={`tbl_${j}`} className="py-2">
                              <td className="ps-5 text-capitalize">
                                {itm?.account_name}
                              </td>
                              <td>
                                {Number(itm?.credit_sum || 0) -
                                  Number(itm?.debit_sum || 0)}{" "}
                                AED
                              </td>
                            </tr>
                          ))}
                          <tr className="total-amount-div px-3">
                            <td sm={6} className="text-capitalize">
                              Total {dt?.name}
                            </td>
                            <td sm={6}>{totalDifference} AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })}
              </div>
              <div className="ps-4 py-3 dotted-border-y">
                <div className="mapping-setup-title py-2 text-capitalize">
                  Equity
                </div>
                {fetchedData?.data?.equity?.map((dt, i) => {
                  const totalDifference = fetchedData?.data?.equity?.reduce(
                    (total, entry) => {
                      return (
                        total +
                        entry.accounts.reduce((accountTotal, account) => {
                          const credit = parseFloat(account.credit_sum)
                          const debit = parseFloat(account.debit_sum)
                          return accountTotal + (credit - debit)
                        }, 0)
                      )
                    },
                    0
                  )

                  return (
                    <div key={`name_${i}`}>
                      <div className="bal-sub-title py-2 text-capitalize">
                        {dt?.name}
                      </div>
                      <table className="estimation-table">
                        <tbody>
                          {dt?.accounts?.map((itm, j) => (
                            <tr key={`tbl_${j}`} className="py-2">
                              <td className="ps-5 text-capitalize">
                                {itm?.account_name}
                              </td>
                              <td>
                                {Number(itm?.credit_sum || 0) -
                                  Number(itm?.debit_sum || 0)}{" "}
                                AED
                              </td>
                            </tr>
                          ))}
                          <tr className="total-amount-div px-3">
                            <td sm={6} className="text-capitalize">
                              Total {dt?.name}
                            </td>
                            <td sm={6}>{totalDifference} AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })}
              </div>
              <div className="ps-4 py-3 dotted-border-y">
                <div className="mapping-setup-title py-2 text-capitalize">
                  Liability
                </div>
                {fetchedData?.data?.liability?.map((dt, i) => {
                  const totalDifference = fetchedData?.data?.liability?.reduce(
                    (total, entry) => {
                      return (
                        total +
                        entry.accounts.reduce((accountTotal, account) => {
                          const credit = parseFloat(account.credit_sum)
                          const debit = parseFloat(account.debit_sum)
                          return accountTotal + (credit - debit)
                        }, 0)
                      )
                    },
                    0
                  )

                  return (
                    <div key={`name_${i}`}>
                      <div className="bal-sub-title py-2 text-capitalize">
                        {dt?.name}
                      </div>
                      <table className="estimation-table">
                        <tbody>
                          {dt?.accounts?.map((itm, j) => (
                            <tr key={`tbl_${j}`} className="py-2">
                              <td className="ps-5 text-capitalize">
                                {itm?.account_name}
                              </td>
                              <td>
                                {Number(itm?.credit_sum || 0) -
                                  Number(itm?.debit_sum || 0)}{" "}
                                AED
                              </td>
                            </tr>
                          ))}
                          <tr className="total-amount-div px-3">
                            <td sm={6} className="text-capitalize">
                              Total {dt?.name}
                            </td>
                            <td sm={6}>{totalDifference} AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })}
              </div>

              {/* {fetchedData?.map((dt, i) => {
                // Calculate the total for each subcategory
                const subcategoryTotal = dt?.items?.reduce((acc, itm) => {
                  const creditAmount = Number(
                    itm?.journal_entry_details_sum_credit_amount || 0
                  )
                  const debitAmount = Number(
                    itm?.journal_entry_details_sum_debit_amount || 0
                  )
                  return acc + (creditAmount - debitAmount)
                }, 0)

                return (
                  <div key={i} className="ps-4 py-3">
                    <div className="mapping-setup-title py-2 text-capitalize">
                      {dt?.items[0]?.category?.name}
                    </div>
                    <div className="bal-sub-title py-2 text-capitalize">
                      {dt?.items[0]?.subcategory?.name}
                    </div>
                    <table className="estimation-table">
                      <tbody>
                        {dt?.items?.map((itm, i) => (
                          <tr key={i} className="py-2">
                            <td className="ps-5 text-capitalize">
                              {itm?.name}
                            </td>
                            <td>
                              {Number(
                                itm?.journal_entry_details_sum_credit_amount ||
                                  0
                              ) -
                                Number(
                                  itm?.journal_entry_details_sum_debit_amount ||
                                    0
                                )}{" "}
                              AED
                            </td>
                          </tr>
                        ))}
                        <tr className="total-amount-div px-3">
                          <td sm={6} className="text-capitalize">
                            Total {dt?.items[0]?.subcategory?.name}
                          </td>
                          <td sm={6}>{subcategoryTotal} AED</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })} */}
            </div>
            {/* {fetchedData?.map((dt, i) => (
              <div>
                <div>{dt?.items[0]?.category?.name}</div>
                <div>{dt?.items[0]?.subcategory?.name}</div>
                {dt?.items?.map((itm, i) => (
                  <div>
                    <div>{itm?.name}</div>
                    <div>
                      {Number(
                        itm?.journal_entry_details_sum_credit_amount
                          ? itm?.journal_entry_details_sum_credit_amount
                          : 0
                      ) -
                        Number(
                          itm?.journal_entry_details_sum_debit_amount
                            ? itm?.journal_entry_details_sum_debit_amount
                            : 0
                        )}
                    </div>
                  </div>
                ))}
                <div>
                    <div>Total {dt?.items[0]?.subcategory?.name}</div>
                    <div></div>
                </div>
              </div>
            ))} */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BalanceSheet
