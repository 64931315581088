import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import {
  fetchHrmsGradeControl,
  updateGradeControlStatus,
} from "../../../services/Hrms/hrms"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"
import LedgerFilter from "./filter"
import { fetchFinanceLedgerSearch } from "../../../services/finance/finance"

const LedgerFinance = () => {
  document.title = "Ledger | Ever Rise"

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [country, setCountry] = useState("")
  const [page, setPage] = useState("")

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateGradeControlStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Ledger Name",
      renderCell: item => {
        return (
          <div
          // onClick={() => navigate(`/procurement-supplier/view/${item?.id}`)}
          // className="pointer fw-bold"
          >
            {item?.name ? capitalizeFirstLetter(item?.name) : "-"}
          </div>
        )
      },
    },
    {
      label: "Opening Balance",
      renderCell: item => item?.opening_balance,
    },
    // {
    //   label: "Status",
    //   renderCell: item => (
    //     <div>
    //       <span
    //         className={`font-13 ${
    //           item?.status == 1 ? "status-active" : "status-inactive"
    //         }`}
    //       >
    //         {item?.status == 1 ? "Active" : "Inactive"}
    //       </span>
    //     </div>
    //   ),
    // },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {/* {item?.status == 1 && ( */}
              <div
                className="table-popup-content"
                onClick={() => navigate(`/finance-ledger/edit/${item?.id}`)}
              >
                Edit
              </div>
              {/* )} */}
              {/* <div
                className={`${
                  item?.status == 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div> */}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchFinanceLedgerSearch({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${country ? `&country_id=${country}` : ""}${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [refresh, status, search, country, page])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCountryChange = id => {
    setCountry(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Procurement"
              breadcrumbObject={[
                { title: "Finance", link: "/finance" },
                { title: "Ledger", link: "/finance-ledger" },
              ]}
            />
            <Link
              to="/finance-ledger/add"
              className={"px-2 mb-3 btn btn-primary"}
            >
              {"+ Add Ledger"}
            </Link>
          </div>
          <Card className="me-2 px-2 pb-2">
            <LedgerFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCountryChange={handleCountryChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="40% 40% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LedgerFinance
