import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import lightTrash from "../../../assets/icons/light-trash.svg"
import moment from "moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { fetchPurchase } from "../../../services/procurement/purchaseOrder"
import { fetchGRN, postGRN } from "../../../services/procurement/grn"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import POPRoducts from "../../../components/Common/chooseFields/poproducts"
import GRNPurchaseNo from "../../../components/Common/chooseFields/grnPurchaseNo"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import GRNCastNo from "../../../components/Common/chooseFields/grnCosting"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import CreditPeriod from "../../../components/Common/chooseFields/creditPeriod"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import FinanceSubCat from "../../../components/Common/chooseFields/financeSubCat"
import {
  fetchCostCenterData,
  postAccRecievable,
  postGRNCosting,
} from "../../../services/finance/grnCosting"
import Customer from "../../../components/Common/chooseFields/customer"
import CostCenterList from "../../../components/Common/chooseFields/costCenterList"
import FinanceCat from "../../../components/Common/chooseFields/financeCat"
import { postFinanceSubCategory } from "../../../services/finance/finance"
import FinanceSubCatId from "../../../components/Common/chooseFields/financeSubCatId"
import AccountsRecivableList from "../../../components/Common/chooseFields/accountRecievableList"

const schema = yup.object().shape({
  // cus_id: yup.mixed().required("Customer Name is required"),
  // cos_id: yup.mixed().required("Cost Center is required"),
  // payment_mode_id: yup.mixed().required("Payment Mode is required"),
  // sub_cat_id: yup.mixed().required("Sub-Category is required"),
  // transaction_date: yup
  //   .mixed()
  //   .test(
  //     "is-future-date",
  //     "Make sure the received date is forward-looking",
  //     function (value) {
  //       if (!value) return true
  //       const currentDate = moment()
  //       const selectedDate = moment(value)
  //       return selectedDate.isSameOrBefore(currentDate, "day")
  //     }
  //   )
  //   .required("Received date is required"),
  // pay: yup
  //   .string()
  //   .matches(
  //     /^([+]?)\d+(\.\d{1,4})?$/,
  //     "Price must be a valid number with up to 4 digits after the decimal point"
  //   )
  //   .required("Price is required"),
  //   recieved_date: yup
  //     .mixed()
  //     .test(
  //       "is-future-date",
  //       "Make sure the received date is forward-looking",
  //       function (value) {
  //         if (!value) return true
  //         const currentDate = moment()
  //         const selectedDate = moment(value)
  //         return selectedDate.isSameOrBefore(currentDate, "day")
  //       }
  //     )
  //     .required("Received date is required"),
  // received_by: yup.string().required("Received by is required"),
  //   grnproductlist: yup.array().of(
  //     yup.object().shape({
  //       product_code: yup.mixed().required("product code is required"),
  //       description: yup.string().required("description is required"),
  //       unit: yup.mixed().required("Unit is Required"),
  //       received_quantity: yup
  //         .string()
  //         .matches(
  //           /^\d*(\.\d+)?$/,
  //           "Pending quantity must be a non-negative number"
  //         )
  //         .test(
  //           "max",
  //           "pending quantity must not exceed the ordered quantity",
  //           function (value) {
  //             const { pending_quantity } = this.parent
  //             return parseInt(value) <= pending_quantity
  //           }
  //         )
  //         .required("pending quantity is required"),
  //     })
  //   ),
})

const EditAccountReceivable = ({ fromDashboard, poValue, toggle }) => {
  document.title = "Account Receivable | Ever Rise"

  const navigate = useNavigate()
  const { editId } = useParams()
  const id = fromDashboard ? "" : editId
  const [isLoading, setIsLoading] = useState(false)
  const [idPO, setIdPO] = useState()
  const [accReceivableVal, setAccReceivableVal] = useState()
  const [refresh, setRefresh] = useState(0)

  const location = useLocation()

  const loggedUser = useSelector(state => state.Login.loggedUser)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      pay: 0,
      cus_id: null,
      cos_id: null,
      transaction_date: moment().format("YYYY-MM-DD"),
    },
  })

  const [activeTab, setActiveTab] = useState(
    watch("account_id")?.is_company == 0 ? "1" : "2"
  )

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  useEffect(() => {
    if (location?.state && !poValue) {
      reset({
        grn_id: location?.state?.purchase_orders_code && {
          label: location?.state?.purchase_orders_code,
          value: location?.state?.id,
        },
      })

      fetchPotData(location?.state?.id)
      setIdPO(location?.state?.id)
    }
  }, [location])

  useEffect(() => {
    if (fromDashboard) {
      reset({
        grn_id: poValue?.value && {
          label: poValue?.label,
          value: poValue?.value,
        },
      })

      handleCostChange(poValue)
    }
  }, [fromDashboard])

  const toggles = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleRegistration = async data => {

    try {
      setIsLoading(true)
      const update = await postAccRecievable({
        // ...data,
        project_id: data?.project_id?.id,
        cus_id: data?.cus_id?.value,
        account_id: data?.account_id?.id,
        description: data?.description,
        amount: activeTab == 1 ? data?.pay : data?.value,
        // sub_cat_id: data?.sub_cat_id?.value,
        // pay: data?.pay,
        // payment_mode_id: data?.payment_mode_id?.value,
        // transaction_date: data?.transaction_date,
        // description: data?.description,
        // transaction_doc: data?.transaction_doc,
        // pay_terms: data?.pay_terms,
        // cos_id: data?.cos_id?.value,
        // status: 1,
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/finance-account-receivable")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCodeChange = () => {
    const data = getValues()
    reset({ ...data, cos_id: null })
  }

  const handleCostChange = () => {
    const { cos_id } = getValues()
    fetchPotData(cos_id?.value)
    setIdPO(cos_id?.value)
  }
  const fetchPotData = async idData => {
    const response = await fetchCostCenterData(idData)
    setAccReceivableVal(response)
    if (idData == response.data?.id) {
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_id: response.data?.project_id,
        po_date: moment(response.data?.created_at).format("DD-MM-YYYY"),
        mr_no: response.data?.material_request_id?.material_requests_code,
        mr_date: moment(response.data?.material_request_id?.created_at).format(
          "DD-MM-YYYY"
        ),
        supplier_name: response.data?.supplier?.name,
        pay_terms: response.data?.pay_terms,
      })
    }
  }
  const handleChange = (name, file) => {
    setValue(name, file[0])
  }
  const handleSubCat = async ({ cat_id, name }) => {
    try {
      const response = await postFinanceSubCategory({
        cat_id: cat_id?.value,
        name: name,
      })
      toastSuccess(response?.message)
      setValue("sub_cat_id", null)
      setRefresh(refresh + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }
  const handleFinanceCat = () => {
    const data = getValues()
    reset({ ...data, subcategory_id: null, account_id: null })
  }
  const handleFinanceSubCat = () => {
    const data = getValues()
    reset({ ...data, account_id: null })
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {fromDashboard ? (
                    <div></div>
                  ) : (
                    <Breadcrumbs
                      title="Finance"
                      breadcrumbObject={[
                        { title: "Finance", link: "/finance" },
                        {
                          title: "Account Receivable",
                          link: "/finance-account-receivable",
                        },
                        {
                          title: id
                            ? "Edit Account Receivable"
                            : "Add Account Receivable",
                          link: "",
                        },
                      ]}
                    />
                  )}
                  {watch("sub_cat_id")?.label == "Add Others" ? (
                    <></>
                  ) : (
                    <LoadingButton
                      form={`request-form`}
                      color={"primary"}
                      loading={isLoading}
                      className={"px-2 mb-3 btn btn-primary"}
                      type="submit"
                    >
                      {id ? "Update" : "Save"}
                    </LoadingButton>
                  )}
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Category<span className="mandatory">*</span>
                        </Label>
                        <FinanceCat
                          control={control}
                          placeholder="Enter the Category"
                          name={"category_id"}
                          invalid={!!errors.category_id}
                          onSelectChange={handleFinanceCat}
                        />
                        <FormFeedback invalid>
                          {errors.category_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Sub-Category<span className="mandatory">*</span>
                        </Label>
                        <FinanceSubCatId
                          control={control}
                          placeholder="Sub-Category"
                          name={"subcategory_id"}
                          invalid={!!errors.subcategory_id}
                          selectedCategory={watch("category_id")}
                          onSelectChange={handleFinanceSubCat}
                        />
                        <FormFeedback invalid>
                          {errors.subcategory_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Accounts<span className="mandatory">*</span>
                        </Label>
                        <AccountsRecivableList
                          control={control}
                          placeholder="Accounts"
                          name={"account_id"}
                          invalid={!!errors.account_id}
                          selectedSubCategory={watch("subcategory_id")}
                        />
                        <FormFeedback invalid>
                          {errors.account_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  {watch("account_id") ? (
                    <>
                      <Nav tabs>
                        {watch("account_id")?.is_company == 0 && (
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "1" ? "active pointer" : "pointer"
                              }
                              onClick={() => {
                                toggles("1")
                              }}
                            >
                              Project
                            </NavLink>
                          </NavItem>
                        )}
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "2" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggles("2")
                            }}
                          >
                            Company
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "3" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggles("3")
                            }}
                          >
                            Others
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="pt-3">
                        <TabPane tabId={activeTab}>
                          {watch("account_id")?.is_company === 0 &&
                          activeTab == 1 ? (
                            <>
                              <Row className="mb-4 mt-2">
                                <Col sm={4}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Customer{" "}
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <Customer
                                      control={control}
                                      placeholder="select"
                                      name={"cus_id"}
                                      invalid={!!errors.cus_id}
                                      target={"body"}
                                      onSelectChange={handleCodeChange}
                                      disabled={id ? true : false}
                                    />
                                    <FormFeedback invalid>
                                      {errors.cus_id?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                {watch("cus_id") != null && (
                                  <Col sm={4}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Project Id{" "}
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <CostCenterList
                                        control={control}
                                        placeholder="select"
                                        name={"cos_id"}
                                        invalid={!!errors.cos_id}
                                        target={"body"}
                                        param={watch("cus_id")}
                                        onSelectChange={handleCostChange}
                                      />
                                      <FormFeedback invalid>
                                        {errors.cos_id?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                )}
                              </Row>
                              <hr className="m-0" />

                              <Row className="py-4 mt-4">
                                <h5>Amount</h5>
                              </Row>
                              {watch("cos_id") != null && (
                                <Row className="mb-4">
                                  <Col lg={12}>
                                    <table className="estimation-table mb-4">
                                      <thead>
                                        <tr>
                                          <th>Total Value</th>
                                          <th>Paid Amount</th>
                                          <th>Balance Amount</th>
                                          <th>Current pay</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            {
                                              accReceivableVal?.data?.project_id
                                                ?.amount
                                            }
                                          </td>
                                          <td>
                                            {accReceivableVal?.data
                                              ?.recivables_sum_amount
                                              ? accReceivableVal?.data
                                                  ?.recivables_sum_amount
                                              : 0}
                                          </td>
                                          <td>
                                            {Number(
                                              accReceivableVal?.data?.project_id
                                                ?.amount
                                            ) -
                                              Number(
                                                accReceivableVal?.data
                                                  ?.recivables_sum_amount !=
                                                  null
                                                  ? accReceivableVal?.data
                                                      ?.recivables_sum_amount
                                                  : 0
                                              ) -
                                              Number(watch("pay"))}
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <UFInput
                                                control={control}
                                                placeholder="Enter Amount"
                                                name={"pay"}
                                                className={"search-input"}
                                              />
                                              <FormFeedback invalid>
                                                {errors.pay?.message}
                                              </FormFeedback>
                                            </FormGroup>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Col>
                                </Row>
                              )}
                              {/* <Row>
                                <Col sm={3}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Project Code
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <ProjectNo
                                      control={control}
                                      name={"project_id"}
                                      invalid={!!errors.project_id}
                                    />
                                    <FormFeedback invalid>
                                      {errors.project_id?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} lg={8}>
                                  <Card className="me-2 px-2 pb-2 payable-ht">
                                    <Row className="mt-2 ever-rise-table">
                                      <Col xs={12}>
                                        {grnData && (
                                          <UpdateTable
                                            className="mt-5"
                                            nodes={grnData?.data?.data}
                                            paginate={grnData?.data}
                                            columns={columns}
                                            CurrentPage={page}
                                            handlePagination={handlePaginate}
                                            tableWidth="5% 30% 30% 35%"
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                                <Col sm={12} lg={4}>
                                  <Card className="me-2 px-2 pb-2 payable-ht">
                                    <Row className="py-2">
                                      <Col sm={8}>
                                        <FormGroup className="mb-4">
                                          <ProjectNo
                                            control={control}
                                            name={"project_id"}
                                            invalid={!!errors.project_id}
                                            disabled={true}
                                          />
                                          <FormFeedback invalid>
                                            {errors.project_id?.message}
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row className="px-2 pb-2 pay-slip-summary">
                                      <Col sm={8}>Grn Costing N0</Col>
                                      <Col sm={4}>Price</Col>
                                    </Row>
                                    {grnList?.map((dt, i) => (
                                      <Row
                                        className="p-2 summary-body"
                                        key={`grnList_${i}`}
                                      >
                                        <Col sm={8}>{dt?.code}</Col>
                                        <Col sm={4}>{dt?.pay}</Col>
                                      </Row>
                                    ))}
                                    <Row className="px-2">
                                      <Col
                                        sm={8}
                                        className="d-flex justify-content-end"
                                      >
                                        Total
                                      </Col>
                                      <Col sm={4}>{totalPrice}</Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row> */}
                            </>
                          ) : activeTab == 2 ? (
                            <>
                              <Row className="mb-4 mt-2">
                                <Col sm={4}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Customer{" "}
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <Customer
                                      control={control}
                                      placeholder="select"
                                      name={"cus_id"}
                                      invalid={!!errors.cus_id}
                                      target={"body"}
                                      onSelectChange={handleCodeChange}
                                      disabled={id ? true : false}
                                    />
                                    <FormFeedback invalid>
                                      {errors.cus_id?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={4}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Description
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFEditor
                                      control={control}
                                      placeholder="Enter the description"
                                      name={"description"}
                                      rows="5"
                                    />
                                    <FormFeedback invalid>
                                      {errors.description?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={4}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Value
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter the Value"
                                      name={"value"}
                                      invalid={!!errors.value}
                                    />
                                    <FormFeedback invalid>
                                      {errors.value?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row className="py-2">
                                <Col sm={3}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Description
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFEditor
                                      control={control}
                                      placeholder="Enter the description"
                                      name={"description"}
                                      rows="5"
                                    />
                                    <FormFeedback invalid>
                                      {errors.description?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={3}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Value
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFInput
                                      control={control}
                                      placeholder="Enter the Value"
                                      name={"value"}
                                      invalid={!!errors.value}
                                    />
                                    <FormFeedback invalid>
                                      {errors.value?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          {/* <Row className="ever-rise-table">
                        <Col xs={12}>hii 1</Col>
                      </Row> */}
                        </TabPane>
                        {/* <TabPane tabId="2">

                </TabPane> */}
                      </TabContent>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Customer <span className="mandatory">*</span>
                        </Label>
                        <Customer
                          control={control}
                          placeholder="select"
                          name={"cus_id"}
                          invalid={!!errors.cus_id}
                          target={"body"}
                          onSelectChange={handleCodeChange}
                          disabled={id ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.cus_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {watch("cus_id") != null && (
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Cost Center <span className="mandatory">*</span>
                          </Label>
                          <CostCenterList
                            control={control}
                            placeholder="select"
                            name={"cos_id"}
                            invalid={!!errors.cos_id}
                            target={"body"}
                            param={watch("cus_id")}
                            onSelectChange={handleCostChange}
                          />
                          <FormFeedback invalid>
                            {errors.cos_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Payment Mode<span className="mandatory">*</span>
                        </Label>
                        <PaymentMode
                          control={control}
                          placeholder="select"
                          name={"payment_mode_id"}
                          invalid={!!errors.payment_mode_id}
                          target={"body"}
                        />
                        <FormFeedback invalid>
                          {errors.payment_mode_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Transaction Date<span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"transaction_date"}
                          invalid={!!errors.transaction_date}
                          maxDate={moment().format("YYYY-MM-DD")}
                        />
                        <FormFeedback invalid>
                          {errors.transaction_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Document<span className="mandatory">*</span>
                        </Label>
                        <SingleFileInput
                          accept=".png,.jpg,.jpeg,.svg,.pdf"
                          name={`transaction_doc`}
                          label="Upload File"
                          onlyIcon={true}
                          tooltip="Document Upload"
                          handleChange={handleChange}
                          // downloadPath={certificateSts}
                        />
                        <FormFeedback invalid>
                          {errors.transaction_doc?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Sub-Category<span className="mandatory">*</span>
                        </Label>
                        <FinanceSubCat
                          control={control}
                          placeholder="select"
                          name={"sub_cat_id"}
                          invalid={!!errors.sub_cat_id}
                          target={"body"}
                          loadUpdate={refresh}
                        />
                        <FormFeedback invalid>
                          {errors.sub_cat_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Description</Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the description"
                          name={"description"}
                          rows="5"
                        />
                        <FormFeedback invalid>
                          {errors.description?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={8}>
                      {watch("sub_cat_id")?.label == "Add Others" ? (
                        <Row>
                          <Col sm={6}>
                            <FormGroup className="mb-4">
                              <Label>
                                Category<span className="mandatory">*</span>
                              </Label>
                              <FinanceCat
                                control={control}
                                placeholder="Enter the Category"
                                name={"cat_id"}
                                invalid={!!errors.cat_id}
                              />
                              <FormFeedback invalid>
                                {errors.cat_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup className="mb-4">
                              <Label>
                                Sub Category<span className="mandatory">*</span>
                              </Label>
                              <UFInput
                                control={control}
                                placeholder="Enter the Sub Category"
                                name={"name"}
                                invalid={!!errors.name}
                              />
                              <FormFeedback invalid>
                                {errors.name?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col className="d-flex justify-content-end" sm={12}>
                            <Button
                              type="button"
                              color="primary"
                              className="btn-sm btn-rounded waves-effect waves-light"
                              onClick={() =>
                                handleSubCat({
                                  cat_id: watch("cat_id"),
                                  name: watch("name"),
                                })
                              }
                            >
                              Add Sub Cat
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <hr className="m-0" />

                  <Row className="py-4 mt-4">
                    <h5>Amount</h5>
                  </Row>
                  {watch("cos_id") != null && (
                    <Row className="mb-4">
                      <Col lg={12}>
                        <table className="estimation-table mb-4">
                          <thead>
                            <tr>
                              <th>Total Value</th>
                              <th>Paid Amount</th>
                              <th>Balance Amount</th>
                              <th>Current pay</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {accReceivableVal?.data?.project_id?.amount}
                              </td>
                              <td>
                                {accReceivableVal?.data?.recivables_sum_amount
                                  ? accReceivableVal?.data
                                      ?.recivables_sum_amount
                                  : 0}
                              </td>
                              <td>
                                {Number(
                                  accReceivableVal?.data?.project_id?.amount
                                ) -
                                  Number(
                                    accReceivableVal?.data
                                      ?.recivables_sum_amount != null
                                      ? accReceivableVal?.data
                                          ?.recivables_sum_amount
                                      : 0
                                  ) -
                                  Number(watch("pay"))}
                              </td>
                              <td>
                                <FormGroup>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter Amount"
                                    name={"pay"}
                                    className={"search-input"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.pay?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}
                  <Row className="py-4">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Payment Terms</Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the Payment Terms"
                          rows="5"
                          name={"pay_terms"}
                          invalid={!!errors.pay_terms}
                          className={"pb-0"}
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditAccountReceivable
