import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { UFDate } from "../../../components/Common/Fields/Input"
import moment from "moment"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { fetchFinanceTrialBalance } from "../../../services/finance/finance"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Logo from "../../../assets/images/logo.svg"
import { useNavigate } from "react-router-dom"
import arrow from "../../../assets/icons/arrow.svg"
import resetIcon from "../../../assets/icons/reset.svg"

const TrialBalance = ({ fromDashboard }) => {
  document.title = "Trial Balance | Ever Rise"

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [fetchedDataFilter, setFetchedDataFilter] = useState()
  const [debitTotal, setDebitTotal] = useState(0)
  const [creditTotal, setCreditTotal] = useState(0)
  const [refresh, setRefresh] = useState(0)

  const { control, watch, reset } = useForm()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceTrialBalance({
        params: `${startDate ? `&start_date=${startDate}` : ""}${
          endDate ? `&end_date=${endDate}` : ""
        }`,
      })
      setFetchedData(responce)
    }
    fetchData()
  }, [startDate, endDate, refresh])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const assetColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <span className="fw-500 fs-14">{item?.group_name}</span>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.debit
            ? Number(Number(item?.debit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.credit
            ? Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
  ]
  const liabilityColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <span className="fw-500 fs-14">{item?.group_name}</span>
      ),
    },
    {
      label: "Amount",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.debit
            ? Number(Number(item?.debit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.credit
            ? Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
  ]
  const incomeColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <span className="fw-500 fs-14">{item?.group_name}</span>
      ),
    },
    {
      label: "Amount",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.debit
            ? Number(Number(item?.debit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.credit
            ? Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
  ]
  const expenseColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <span className="fw-500 fs-14">{item?.group_name}</span>
      ),
    },
    {
      label: "Amount",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.debit
            ? Number(Number(item?.debit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {item?.credit
            ? Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </div>
      ),
    },
  ]

  const assetSubColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <div
          onClick={() =>
            navigate(`/finance-ledger-acc`, {
              state: { item, startDate, endDate },
            })
          }
          className="pointer fw-bold ps-3"
        >
          {item?.ledger_name}
          <span className="ps-1">
            <img src={arrow} alt="" width="7" />
          </span>
        </div>
      ),
    },
    {
      label: "Debit",
      renderCell: item => (
        <>
          {item?.debit
            ? Number(Number(item?.debit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </>
      ),
    },
    {
      label: "Credit",
      renderCell: item => (
        <>
          {item?.credit
            ? Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              }) + " AED"
            : "-"}
        </>
      ),
    },
  ]

  const filterNonZeroEntries = entries =>
    entries?.filter(entry => entry.debit !== 0 || entry.credit !== 0)

  useEffect(() => {
    const filteredData = {
      asset: filterNonZeroEntries(fetchedData?.data.asset),
      expense: filterNonZeroEntries(fetchedData?.data.expense),
      income: filterNonZeroEntries(fetchedData?.data.income),
      liability: filterNonZeroEntries(fetchedData?.data.liability),
    }
    setFetchedDataFilter(filteredData)
  }, [fetchedData])

  const reduceBalance = (assets, amount) => {
    // Reduce the balance of each asset and sum them up
    const total = assets.reduce((sum, asset) => {
      return sum + (asset.balance - amount) // Reduce the balance and accumulate
    }, 0)

    return total // Return the total balance after reduction
  }

  const getTotalValue = (data, key) => {
    console.log(`data:`, data)

    return data.reduce((total, item) => {
      return total + (item?.[key] || 0) // Add the specified key's value, defaulting to 0 if undefined
    }, 0)
  }

  useEffect(() => {
    if (fetchedData) {
      const totalDebit = Object.keys(fetchedData?.data).reduce((sum, key) => {
        return sum + getTotalValue(fetchedData?.data[key], "debit")
      }, 0)

      const totalCredit = Object.keys(fetchedData?.data).reduce((sum, key) => {
        return sum + getTotalValue(fetchedData?.data[key], "credit")
      }, 0)

      setDebitTotal(totalDebit)
      setCreditTotal(totalCredit)

      console.log(`totalDebit:`, totalDebit)
      console.log(`totalCredit:`, totalCredit)
    }
  }, [fetchedData])

  const handleReset = () => {
    setRefresh(refresh + 1)
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!fromDashboard ? (
                <>
                  <Breadcrumbs
                    title="Finance"
                    breadcrumbObject={[
                      { title: "Finance", link: "/finance" },
                      {
                        title: "Trial Balance",
                        link: "/finance-trial-balance",
                      },
                    ]}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="pe-2 d-flex">
              <div
                className="export-div pointer reset-img me-2"
                onClick={() => handleReset()}
              >
                <img src={resetIcon} height={15} width={15} className="" />{" "}
              </div>
              <UFDate
                control={control}
                name={"req_date"}
                placeholder={"Date"}
                mode={"range"}
                onDateChange={e => handleRequestdate(e)}
              />
            </div>
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            {/* <div className="d-flex justify-content-between align-items-center my-2 me-2">
              <div className="filter-wrapper-balance"></div>
              <div className="d-flex">
                <UFDate
                  control={control}
                  name={"req_date"}
                  placeholder={"Date"}
                  mode={"range"}
                  onDateChange={e => handleRequestdate(e)}
                />
              </div>
            </div> */}
            {/* <hr className="table-divide" /> */}
            {/* <div className="d-flex justify-content-between">
              <div className="purchase-view-div-report"> */}
            <div className="d-flex justify-content-between align-items-center mt-2 py-2 px-2">
              <img className="view-logo" src={Logo} alt="logo" />

              <div className="payroll-total ">Trial Balance</div>
              <div className="fs-14">
                {startDate && endDate
                  ? `${moment(startDate).format("DD-MM-YYYY")} -
                    ${moment(endDate).format("DD-MM-YYYY")}`
                  : `As of ${moment().format("DD-MM-YYYY")}`}
              </div>
            </div>
            <hr className="table-divide" />
            <table className="estimation-table">
              <thead>
                <tr>
                  <th className="w-50">Account Name</th>
                  <th className="w-25">Debit</th>
                  <th className="w-25">Credit</th>
                </tr>
              </thead>
            </table>
            <div className="trial-scroll">
              <div className="ps-4 pb-3 dotted-border-y">
                <div className="d-flex justify-content-between">
                  <div className="mapping-setup-title py-2 text-capitalize">
                    Assets
                  </div>
                </div>
                <div className="ms-2">
                  <Row className="mt-2 ever-rise-table reportTable trial-table">
                    <Col xs={12}>
                      {fetchedDataFilter?.asset && (
                        <UpdateTable
                          // className="mt-5"
                          nodes={fetchedDataFilter?.asset}
                          columns={assetColumns}
                          subColumn={assetSubColumns}
                          subName={"ledgers"}
                          subHeader={false}
                          tableWidth="50% 25% 25%"
                          mobileWidth="50% 25% 25%"
                          expand={true}
                          headerHide={true}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="ps-4 py-3 dotted-border-y">
                <div className="d-flex justify-content-between">
                  <div className="mapping-setup-title py-2 text-capitalize">
                    Liability
                  </div>
                </div>
                <div className="ms-2">
                  <Row className="mt-2 ever-rise-table reportTable trial-table ">
                    <Col xs={12}>
                      {fetchedDataFilter?.liability && (
                        <UpdateTable
                          nodes={fetchedDataFilter?.liability}
                          columns={liabilityColumns}
                          subColumn={assetSubColumns}
                          subName={"ledgers"}
                          subHeader={false}
                          tableWidth="50% 25% 25%"
                          mobileWidth="50% 25% 25%"
                          expand={true}
                          headerHide={true}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="ps-4 py-3 dotted-border-y">
                <div className="d-flex justify-content-between">
                  <div className="mapping-setup-title py-2 text-capitalize">
                    Income
                  </div>
                </div>
                <div className="ms-2">
                  <Row className="mt-2 ever-rise-table reportTable trial-table ">
                    <Col xs={12}>
                      {fetchedDataFilter?.liability && (
                        <UpdateTable
                          nodes={fetchedDataFilter?.income}
                          columns={incomeColumns}
                          subColumn={assetSubColumns}
                          subName={"ledgers"}
                          tableWidth="50% 25% 25%"
                          mobileWidth="50% 25% 25%"
                          headerHide={true}
                          expand={true}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="ps-4 py-3">
                <div className="d-flex justify-content-between">
                  <div className="mapping-setup-title py-2 text-capitalize">
                    Expense
                  </div>
                </div>
                <div className="ms-2">
                  <Row className="mt-2 ever-rise-table reportTable trial-table ">
                    <Col xs={12}>
                      {fetchedDataFilter?.liability && (
                        <UpdateTable
                          nodes={fetchedDataFilter?.expense}
                          columns={expenseColumns}
                          subColumn={assetSubColumns}
                          subName={"ledgers"}
                          tableWidth="50% 25% 25%"
                          mobileWidth="50% 25% 25%"
                          headerHide={true}
                          expand={true}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <table className="estimation-table trial-balance-total">
                <thead>
                  <tr>
                    <th className="w-50">Total</th>
                    <th className="w-25 ps-3">
                      {Number(
                        Number(debitTotal ? debitTotal : 0).toFixed(2)
                      ).toLocaleString("en", { minimumFractionDigits: 2 })}
                    </th>
                    <th className="w-25 ps-3">
                      {Number(
                        Number(creditTotal ? creditTotal : 0).toFixed(2)
                      ).toLocaleString("en", { minimumFractionDigits: 2 })}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* </div> */}
            {/* <div className="d-flex my-2">
                <UFDate
                  control={control}
                  name={"req_date"}
                  placeholder={"Date"}
                  mode={"range"}
                  onDateChange={e => handleRequestdate(e)}
                />
              </div>
            </div> */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TrialBalance
