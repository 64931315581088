import { get, post } from "../../helpers/api_helper"

// grade
export const postHrmsGrade = data => {
  return !data?.id
    ? post(`grade/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`grade/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsGrade = data => {
  return get(`grade/search?${data?.params}`)
}

export const updateGradeStatus = id => {
  return get(`grade/updatestatus/${id}`)
}

export const fetchGradeEdit = data => {
  return get(`grade/fetch/${data}`)
}

// Employee type

export const postHrmsEmployeeType = data => {
  return !data?.id
    ? post(`employee-type/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`employee-type/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsEmployeeType = data => {
  return get(`employee-type/search?${data?.params}`)
}

export const updateEmployeTypeStatus = id => {
  return get(`employee-type/updatestatus/${id}`)
}

export const fetchEmployeeTypeEdit = data => {
  return get(`employee-type/fetch/${data}`)
}

// Gratuity

export const postHrmsGratuity = data => {
  return !data?.id
    ? post(`gratuity/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`gratuity/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsGratuity = data => {
  return get(`gratuity/search?${data?.params}`)
}

export const updateGratuityStatus = id => {
  return get(`gratuity/updatestatus/${id}`)
}

export const fetchGratuityEdit = data => {
  return get(`gratuity/fetch/${data}`)
}

// Qualification

export const postHrmsQualification = data => {
  return !data?.id
    ? post(`qualifications/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`qualifications/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsQualification = data => {
  return get(`qualifications/search?${data?.params}`)
}

export const updateQualificationStatus = id => {
  return get(`qualifications/updatestatus/${id}`)
}

export const fetchQualificationEdit = data => {
  return get(`qualifications/fetch/${data}`)
}

// Religion

export const postHrmsReligion = data => {
  return !data?.id
    ? post(`religions/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`religions/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsReligion = data => {
  return get(`religions/search?${data?.params}`)
}

export const updateReligionStatus = id => {
  return get(`religions/updatestatus/${id}`)
}

export const fetchReligionEdit = data => {
  return get(`religions/fetch/${data}`)
}
// Leave Type

export const postHrmsLeaveType = data => {
  return !data?.id
    ? post(`leave-type/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`leave-type/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsLeaveType = data => {
  return get(`leave-type/search?${data?.params}`)
}

export const updateLeaveTypeStatus = id => {
  return get(`leave-type/updatestatus/${id}`)
}

export const fetchLeaveTypeEdit = data => {
  return get(`leave-type/fetch/${data}`)
}

// Language
export const postHrmsLanguage = data => {
  return !data?.id
    ? post(`language/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`language/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsLanguage = data => {
  return get(`language/search?${data?.params}`)
}

export const updateLanguageStatus = id => {
  return get(`language/updatestatus/${id}`)
}

export const fetchLanguageEdit = data => {
  return get(`language/fetch/${data}`)
}

// Department
export const postHrmsDepartment = data => {
  return !data?.id
    ? post(`department/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`department/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsDepartment = data => {
  return get(`department/search?${data?.params}`)
}

export const updateDepartmentStatus = id => {
  return get(`department/updatestatus/${id}`)
}

export const fetchDepartmentEdit = data => {
  return get(`department/fetch/${data}`)
}

// Designation
export const postHrmsDesignation = data => {
  return !data?.id
    ? post(`designation/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`designation/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsDesignation = data => {
  return get(`designation/search?${data?.params}`)
}

export const updateDesignationStatus = id => {
  return get(`designation/updatestatus/${id}`)
}

export const fetchDesignationEdit = data => {
  return get(`designation/fetch/${data}`)
}

// Entitlement
export const postHrmsEntitlement = data => {
  return !data?.id
    ? post(`entitlement/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`entitlement/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsEntitlement = data => {
  return get(`entitlement/search?${data?.params}`)
}

export const updateEntitlementStatus = id => {
  return get(`entitlement/updatestatus/${id}`)
}

export const fetchEntitlementEdit = data => {
  return get(`entitlement/fetch/${data}`)
}

// TransactionType
export const postHrmsTransactionType = data => {
  return !data?.id
    ? post(`transaction-type/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`transaction-type/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// other transaction search
export const fetchHrmsOtherTransaction = data => {
  return get(`other-payments/search?${data?.params}`)
}

export const fetchOtherTransactionId = data => {
  return get(`other-payments/fetch/${data}`)
}

// other transaction
export const postHrmsOtherTransaction = data => {
  return !data?.id
    ? post(`other-payments/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`other-payments/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsTransactionType = data => {
  return get(`transaction-type/search?${data?.params}`)
}

export const updateTransactionTypeStatus = id => {
  return get(`transaction-type/updatestatus/${id}`)
}

export const fetchTransactionTypeEdit = data => {
  return get(`transaction-type/fetch/${data}`)
}

// Benefits
export const postHrmsBenefits = data => {
  console.log(`chk data--`, data)
  return data?.id == ""
    ? post(`benefits/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`benefits/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsBenefits = data => {
  return get(`benefits/search?${data?.params}`)
}

export const updateBenefitsStatus = id => {
  return get(`benefits/updatestatus/${id}`)
}

export const fetchBenefitsEdit = data => {
  return get(`benefits/fetch/${data}`)
}

// Ticket Class
export const postHrmsTicketClass = data => {
  return !data?.id
    ? post(`ticket-class/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`ticket-class/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsTicketClass = data => {
  return get(`ticket-class/search?${data?.params}`)
}

export const updateTicketClassStatus = id => {
  return get(`ticket-class/updatestatus/${id}`)
}

export const fetchTicketClassEdit = data => {
  return get(`ticket-class/fetch/${data}`)
}

// Route
export const postHrmsRoute = data => {
  return !data?.id
    ? post(`route/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`route/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsRoute = data => {
  return get(`route/search?${data?.params}`)
}

export const updateRouteStatus = id => {
  return get(`route/updatestatus/${id}`)
}

export const fetchRouteEdit = data => {
  return get(`route/fetch/${data}`)
}

// Sponsor
export const postHrmsSponsor = data => {
  return !data?.id
    ? post(`sponsors/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`sponsors/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsSponsor = data => {
  return get(`sponsors/search?${data?.params}`)
}

export const updateSponsorStatus = id => {
  return get(`sponsors/updatestatus/${id}`)
}

export const fetchSponsorEdit = data => {
  return get(`sponsors/fetch/${data}`)
}

// degree

export const postHrmsDegree = data => {
  return !data?.id
    ? post(`education-degree/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`education-degree/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsDegree = data => {
  return get(`education-degree/search?${data?.params}`)
}

export const updateDegreeStatus = id => {
  return get(`education-degree/updatestatus/${id}`)
}

export const fetchDegreeEdit = data => {
  return get(`education-degree/fetch/${data}`)
}

// education department

export const postHrmsEducationDepartment = data => {
  return !data?.id
    ? post(`education-department/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`education-department/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsEducationDepartment = data => {
  return get(`education-department/search?${data?.params}`)
}

export const updateEducationDepartmentStatus = id => {
  return get(`education-department/updatestatus/${id}`)
}

export const fetchEducationDepartmentEdit = data => {
  return get(`education-department/fetch/${data}`)
}
// employee document

export const postHrmsEmployeeDocument = data => {
  return !data?.id
    ? post(`employee-document-details/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`employee-document-details/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsEmployeeDocument = data => {
  return get(`employee-document-details/search?${data?.params}`)
}

export const updateEmployeeDocumentStatus = id => {
  return get(`employee-document-details/updatestatus/${id}`)
}

export const fetchEmployeeDocumentEdit = data => {
  return get(`employee-document-details/fetch/${data}`)
}

// payroll

export const postHrmsPayroll = data => {
  return !data?.id
    ? post(`payroll-component/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`payroll-component/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsPayroll = data => {
  return get(`payroll-component/search?${data?.params}`)
}

export const updatePayrollStatus = id => {
  return get(`payroll-component/updatestatus/${id}`)
}

export const fetchPayrollEdit = data => {
  return get(`payroll-component/fetch/${data}`)
}

// department

export const postHrmsDocument = data => {
  return !data?.id
    ? post(`documents/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`documents/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsDocument = data => {
  return get(`documents/search?${data?.params}`)
}

export const updateDocumentStatus = id => {
  return get(`documents/updatestatus/${id}`)
}

export const fetchDocumentEdit = data => {
  return get(`documents/fetch/${data}`)
}

// hrms grade control

export const postHrmsGradeControl = data => {
  console.log(`data`, data)
  return !data?.id
    ? post(`grade-controll/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`grade-controll/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsGradeControlEdit = data => {
  return get(`grade-controll/fetch/${data}`)
}

export const fetchHrmsGradeControl = data => {
  return get(`grade-controll/search?${data?.params}`)
}

export const updateGradeControlStatus = id => {
  return get(`grade-controll/updatestatus/${id}`)
}

// hrms payroll component

export const postHrmsPayrollControl = data => {
  console.log(`data`, data)
  return !data?.id
    ? post(`payroll-controll/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`payroll-controll/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsPayrollControl = data => {
  return get(`payroll-controll/search?${data?.params}`)
}

export const updatePayrollControlStatus = id => {
  return get(`payroll-controll/updatestatus/${id}`)
}

export const fetchHrmsPayrollControlEdit = data => {
  return get(`payroll-controll/fetch/${data}`)
}

// Employee component

export const postHrmsEmployeeControl = data => {
  console.log(`data`, data)
  return !data?.id
    ? post(`employee-details/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`employee-details/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
export const postAssignEmployee = data => {
  console.log(`data`, data)
  return !data?.id
    ? post(`project-master/assign-employee`, data, {
        headers: {
          // "Content-Type": "multipart/form-data",
        },
      })
    : post(`employee-details/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsEmployee = data => {
  return get(`employee-details/search?${data?.params}`)
}

export const fetchEmployee = data => {
  return get(
    `employee-details/fetch/${data?.id}${
      data?.type ? `?extra=${data?.type}` : ""
    }`
  )
}
export const employeeStatusUpdate = data => {
  return get(`employee-details/updatestatus/${data}`)
}
// Employee Education component

export const postHrmsEmployeeEducation = data => {
  console.log(`data`, data)
  return !data?.id
    ? post(`employee-education-details/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`employee-education-details/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchEmployeeEducation = data => {
  return get(`employee-education-details/fetch/${data}`)
}

export const fileDownload = ({ url, path, downloadName, extension }) => {
  return get(`${url}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${downloadName}_${Date.now()}.${extension}`)
    document.body.appendChild(link)
    link.click()
  })
}
//increment
export const postHrmsEmployeeIncrement = data => {
  return !data?.id
    ? post(`employee-salary-details/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`employee-salary-details/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchHrmsEmployeeSalaryList = data => {
  return get(`employee-salary-details/search?${data?.params}`)
}
export const fetchHrmsEmployeePayrollList = data => {
  return get(`payroll-generate/search?${data?.params}`)
}

//Leave Management
export const searchHrmsLeaveManagement = data => {
  return get(`leave-request/search?${data?.params}`)
}
export const fetchLeaveManagement = data => {
  return get(`leave-request/fetch/${data}`)
}
export const updateLeaveManagementStatus = data => {
  return get(`leave-request/updatestatus/${data}`)
}
export const postHrmsLeaveManagement = data => {
  return !data?.id
    ? post(`leave-request/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`leave-request/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
//Time Sheet

export const searchHrmsTimeSheet = data => {
  return get(`employee-attendence/search?${data?.params}`)
}
export const searchHrmsTimeSheetUpdate = data => {
  return get(`employee-attendence/search-update?${data?.params}`)
}
export const fetchTimeSheet = data => {
  return get(`leave-request/fetch/${data}`)
}

export const postHrmsTimeSheet = data => {
  return !data?.id
    ? post(`attendance-absent/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`attendance-absent/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
export const postHrmsGeneratePayroll = data => {
  return !data?.employee_id
    ? post(`payroll-generate/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`payroll-generate/update/${data?.employee_id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
export const searchHrmsPayrollGenerate = data => {
  return get(`payroll-generate/search?${data?.params}`)
}
export const fetchHrmsPayrollView = data => {
  return get(`payroll-generate/fetch/${data}`)
}
export const updatePayrollGenerayeStatus = data => {
  return get(`payroll-generate/updatestatus/${data}`)
}

// EOS Search
export const searchHrmsEos = data => {
  return get(`eos/search?${data?.params}`)
}

export const hrmsEosUpdateStatus = data => {
  return get(`eos/updatestatus/${data}`)
}

// eos fetch emp data
export const fetchEosEmpData = data => {
  return get(`eos/eos_data?${data?.params}`)
}
export const fetchEos = id => {
  return get(`eos/fetch/${id}`)
}

export const postHrmsEos = data => {
  return !data?.id
    ? post(`eos/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`eos/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const postHrmsGratuityControl = data => {
  return post(`gratuity-controll/edit`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
export const postHrmsYearMonthControl = data => {
  return post(`settings/update-hrms-att-date`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
export const postHrmsRelovingControl = data => {
  return post(`reliving-type-controll/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
export const postHrmsAnnualLeaveControl = data => {
  return post(`anual-leave-type-controll/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const fetchGratuityControl = () => {
  return get(`gratuity-controll/fetch`)
}
export const fetchRelivingControl = () => {
  return get(`reliving-type-controll/fetch`)
}
export const fetchAnnualLeaveControl = () => {
  return get(`anual-leave-type-controll/fetch`)
}
