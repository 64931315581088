import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { fetchStoreHistory } from "../../../services/centralStore/centralStore"
import TransferFilter from "./filter"
import { fetchTransactionHistory } from "../../../services/finance/transactionHistory"
import moment from "moment"
import { isEmpty } from "lodash"
import { UFDate } from "../../../components/Common/Fields/Input"
import { useForm } from "react-hook-form"
import resetIcon from "../../../assets/icons/reset.svg"

const TransactionHistory = ({ fromDashboard, projectId }) => {
  document.title = "Transaction History | Everise"

  const [activeTab, setActiveTab] = useState("1")
  const [search, setSearch] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [page, setPage] = useState("")
  const [ccNo, setCCNo] = useState("")
  const [type, setType] = useState("")
  // const [productNo, setProductNo] = useState("")
  const [thNo, setTHNo] = useState("")
  const [financial, setFinancial] = useState(true)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [company, setCompany] = useState(false)

  const { control, watch, reset } = useForm({
    defaultValues: {
      req_date: null,
    },
  })

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const handleCCChange = id => {
    setCCNo(id)
    setPage(1)
  }

  const handleTypeChange = id => {
    setType(id)
    setPage(1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  // const handleProductChange = data => {
  //   setProductNo(data)
  //   setPage(1)
  // }
  const handleTHChange = data => {
    setTHNo(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTransactionHistory({
        params: `${
          activeTab &&
          `&type=${
            activeTab == 1 ? "all" : activeTab == 2 ? "receivable" : "payable"
          }`
        }${search ? `&searchText=${search}` : "&searchText"}${
          ccNo ? `&costcenter_id=${ccNo}` : ""
        }${type ? `&type=${type}` : ""}${thNo ? `&th_no=${thNo}` : ""}${
          projectId ? `&project_id=${projectId}` : ""
        }${`&financial=${financial}`}${
          startDate ? `&start_date=${startDate}` : ""
        }${endDate ? `&end_date=${endDate}` : ""}${
          company == true ? `&company_search=""` : ""
        }${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setFetchedData(response)
    }
    fetchData(activeTab)
  }, [
    activeTab,
    type,
    financial,
    ccNo,
    search,
    thNo,
    projectId,
    startDate,
    endDate,
    company,
    page,
  ])

  const columns = [
    {
      label: "TH ID",
      renderCell: item => item?.code,
    },
    {
      label: "Project CC",
      renderCell: item =>
        item?.costcenter_id?.description
          ? item?.costcenter_id?.description
          : "-",
    },
    {
      label: "Credit/Debit",
      renderCell: item => <div className="capitalize-letter">{item?.type}</div>,
    },
    // {
    //   label: "Sub-Cat ID",
    //   renderCell: item => item?.sub_cat_id?.code,
    // },
    {
      label: "Amount",
      renderCell: item =>
        `${Number(Number(item?.amount).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })} AED`,
    },
  ]

  const handleRequestdate = data => {
    console.log(data, "data")
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    console.log(fromDate, toDate, "toDate")
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const handleReset = () => {
    reset({ req_date: null })
    setStartDate("")
    setEndDate("")
  }

  const handleCompanyChange = id => {
    setCompany(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <Breadcrumbs
                title="Finance"
                breadcrumbObject={[
                  { title: "Finance", link: "/finance" },
                  {
                    title: "Transaction History",
                    link: "/finance-transaction-history",
                  },
                ]}
              />
            ) : (
              <></>
            )}
          </div>

          <Card
            className={`me-2 px-2 pb-2 ${fromDashboard ? "dash-table-bg" : ""}`}
          >
            {fromDashboard ? (
              <div className="px-2 py-3">Transaction History</div>
            ) : (
              <TransferFilter
                handleCCChange={handleCCChange}
                handleTypeChange={handleTypeChange}
                handleSearchChange={handleSearchChange}
                // handleProductChange={handleProductChange}
                handleTHChange={handleTHChange}
                handleFinacialChange={handleFinacialChange}
                handleCompanyChange={handleCompanyChange}
              />
            )}
            <hr className="table-divide" />
            <div>
              <Nav tabs className={`${fromDashboard ? "tab-active-bg" : ""}`}>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "1" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("1")
                      setPage(1)
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "2" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("2")
                      setPage(1)
                    }}
                  >
                    Receivable
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "3" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("3")
                      setPage(1)
                    }}
                  >
                    Payable
                  </NavLink>
                </NavItem>
              </Nav>
              {fromDashboard && (
                <Row className="pt-2">
                  <Col sm={6}>
                    <UFDate
                      control={control}
                      placeholder="Date"
                      name={"req_date"}
                      mode="range"
                      className="ufinput-dashboard"
                      onDateChange={e => handleRequestdate(e)}
                    />
                  </Col>
                  <Col sm={3}></Col>
                  <Col sm={3}>
                    <div
                      className="pointer me-2 text-white btn btn-primary float-end"
                      onClick={() => handleReset()}
                    >
                      <img
                        src={resetIcon}
                        height={15}
                        width={15}
                        className="me-2 svg-clr-reset"
                      />{" "}
                      Reset
                    </div>
                  </Col>
                </Row>
              )}
              {/* dashboard-update-table */}
              <TabContent
                activeTab={activeTab}
                className="pt-3 "
              >
                {/* dashboard-table-pan */}
                <TabPane tabId={activeTab} className="">
                  <Row
                    className={`ever-rise-table ${
                      fromDashboard ? "dashboardTable" : ""
                    } `}
                  >
                    <Col xs={12}>
                      {fetchedData?.data?.data && (
                        <UpdateTable
                          className="mt-5"
                          nodes={fetchedData?.data?.data}
                          paginate={fetchedData?.data}
                          columns={columns}
                          CurrentPage={page}
                          handlePagination={handlePaginate}
                          tableWidth="25% 25% 25% 25%"
                          mobileWidth="40% 40% 40% 40%"
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId="2">

                </TabPane> */}
              </TabContent>
            </div>
            {/* <Row className="mt-2 ever-rise-table">
              <Col xs={12}></Col>
            </Row> */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TransactionHistory
