import React, { useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap"
import dummyImg from "../../../assets/images/dummyImg.svg"
import mail from "../../../assets/images/mail.svg"
import phone from "../../../assets/images/contact.svg"

const HrSelfService = () => {
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            <Breadcrumbs
              title="HR Self Service"
              breadcrumbObject={[
                { title: "HR Self Service", link: "/hr-self-service" },
              ]}
            />
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card profile-card">
              <Row className="p-4">
                <Col sm={12} lg={3}>
                  <div className="p-3 profile-section profile-left-card profile-sticky profile-side-height">
                    <div className="d-flex justify-content-between">
                      <div className="status-active">
                        {/* {employee
                          ? employee?.basic_details?.personal_details
                              ?.employee_type_id?.employee_type
                          : "-"} */}
                        Full Time
                      </div>
                      <div className="profile-emp-id">
                        {/* {employee?.basic_details?.employee_code
                          ? employee?.basic_details?.employee_code
                          : "-"} */}
                        EREM003
                      </div>
                    </div>

                    <div className="py-4">
                      <div className="d-flex justify-content-center">
                        {/* <div className="ratio"> */}
                        <img
                          // src={
                          //   employee?.basic_details?.photo
                          //     ? `${imgUrl}${employee?.basic_details?.photo}`
                          //     : dummyImg
                          // }
                          src={dummyImg}
                          alt="profile image"
                          className="profile-emp-dp"
                        />

                        {/* <div className="d-flex justify-end px-15 py-50 h-100 w-1/1 absolute">
                          <div className="cursor-pointer">
                            <label htmlFor="profile_image" role="button">
                              <img
                                width={70}
                                height={50}
                                role="button"
                                src={editicon}
                                // onClick={handleEditIconClick}
                                alt="image"
                              />
                            </label>
                            <input
                              type="file"
                              id="profile_image"
                              name="profile_image"
                              control={control}
                              multiple
                              accept="image/png, image/jpeg"
                              // onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="user-text-dark d-flex justify-content-center">
                        {/* {employee
                          ? `${capitalize(
                              employee?.basic_details?.first_name
                            )} ${capitalize(
                              employee?.basic_details?.middle_name
                            )} ${capitalize(
                              employee?.basic_details?.last_name
                            )}`
                          : "-"} */}
                        David Max Smith
                      </div>
                      <div className="user-text-light d-flex justify-content-center">
                        {/* {employee?.basic_details?.designation?.name
                          ? employee?.basic_details?.designation?.name
                          : "-"} */}
                        Electrician
                      </div>
                    </div>
                    <hr className="m-0 faded-hr" />
                    <div className="py-4 mb-5 d-flex justify-content-center">
                      <div>
                        <div className="user-text-light py-2">
                          <img alt="mail" src={mail} />
                          <span className="ps-2">
                            {/* {employee
                              ? employee?.basic_details?.official_email_id
                              : "-"} */}
                            david@everrise.com
                          </span>
                        </div>
                        <div className="user-text-light py-2">
                          <img alt="phone" src={phone} />
                          <span className="ps-2">
                            {/* {employee
                              ? "+" +
                                employee?.basic_details?.official_contact_number
                              : "-"} */}
                            +971 98765 43328
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="profile-emp-reporting-capsule mt-auto">
                      <div className="d-flex p-2">
                        <div>
                          <img
                            src={dummyImg}
                            alt="profile image"
                            className="profile-emp-reporting-to"
                          />
                        </div>
                        <div className="ps-2">
                          <div className="reporting-to-text">Reporting to</div>
                          <div className="reporting-to-name">Samir Ahamad</div>
                        </div>
                      </div>
                      {/* <div
                        className="pointer d-flex align-self-end justify-content-center"
                        onClick={() => handleEditPass()}
                      >
                        Change Password
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col sm={12} lg={9}>
                  <Nav tabs className="d-flex justify-content-between">
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "1" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("1")
                        }}
                      >
                        HR Self Service
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "2" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("2")
                        }}
                      >
                        Attendance
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "3" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("3")
                        }}
                      >
                        Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "4" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("4")
                        }}
                      >
                        Claim
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "5" ? "active pointer" : "pointer"
                        }
                        onClick={() => {
                          toggle("5")
                        }}
                      >
                        Payslip
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HrSelfService
