import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
} from "reactstrap"

const FinanceProjectDashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Finance Dashboard"
              breadcrumbObject={[
                { title: "Finance Dashboard", link: "/finance-dashboard" },
                {
                  title: "Finance Dashboard",
                  link: "/finance-dashboard",
                },
              ]}
            />

            {/* {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurement-materiareq/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Material Request"}
              </Link>
            )} */}
          </div>
          <Card className="me-2 px-2 pb-2">
            
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FinanceProjectDashboard
