import ModulesIndex from "../../pages/modules"
import RolesIndex from "../../pages/Roles"
import User from "../../pages/User"
import UserManagement from "../../pages/UserManagement"

export const userManagementRoutes = [
  { path: "/user-management", component: <UserManagement /> },
  { path: "/user-management-roles", component: <RolesIndex /> },
  { path: "/user-management-permission", component: <ModulesIndex /> },
  { path: "/user-management-users", component: <User /> },
]
