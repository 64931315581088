import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const Status = ({ control, name, onSelectChange, dashboardBg }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [countriesData, setCountriesData] = useState([])

  const loadOptions = async () => {
    const data = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 0 },
    ]
    setIsLoading(false)
    const countries = data.map(dta => {
      return {
        label: dta?.label && ucfirst(dta.label),
        value: dta.id,
        ...dta,
      }
    })

    setCountriesData(countries)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={countriesData}
              placeholder={"Status"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className={`elevate-dropdown ${
                dashboardBg ? "elevate-dropdown-new-bg" : ""
              }`}
              menuPortalTarget={document.querySelector("body")}
            />
          )
        }}
      />
    </div>
  )
}

export default Status
