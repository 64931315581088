import { DRIVER_SET, DRIVER_SET_SUCCESS } from "./actionType"
// import {
//   LOGIN_USER,
//   LOGIN_SUCCESS,
//   LOGOUT_USER,
//   LOGOUT_USER_SUCCESS,
//   API_ERROR,
//   GET_USER,
//   GET_USER_SUCCESS,
// } from "./actionTypes"

const initialState = {
  component: "",
}

const DriverGuide = (state = initialState, action) => {
  switch (action.type) {
    case DRIVER_SET:
      state = {
        ...state,
        driverAccess: action.payload,
      }
      break
    case DRIVER_SET_SUCCESS:
      state = {
        ...state,
        driverAccess: action.payload,
      }
      break
    // case GET_USER:
    //   state = {
    //     ...state,
    //   }
    //   break
    // case GET_USER_SUCCESS:
    //   state = {
    //     ...state,
    //     loggedUser: action.payload,
    //   }
    //   break
    // case LOGOUT_USER:
    //   state = { ...state }
    //   break
    // case LOGOUT_USER_SUCCESS:
    //   state = { ...state }
    //   break
    // case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default DriverGuide
